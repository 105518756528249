export const DialCountrInfo = [
  { cntNme: 'INDIA', dlVal: '+91', cntCd: 'IND' },
  { cntNme: 'UNITED STATES', dlVal: '+1', cntCd: 'USA' },
  { cntNme: 'CANADA', dlVal: '+1', cntCd: 'CAN' },
  { cntNme: 'UNITED KINGDOM', dlVal: '+44', cntCd: 'GBR' },
  { cntNme: 'AUSTRALIA', dlVal: '+61', cntCd: 'AUS' },
  { cntNme: 'GERMANY', dlVal: '+49', cntCd: 'DEU' },
  { cntNme: 'FRANCE', dlVal: '+33', cntCd: 'FRA' },
  { cntNme: 'CHINA', dlVal: '+86', cntCd: 'CHN' },
  { cntNme: 'JAPAN', dlVal: '+81', cntCd: 'JPN' },
  { cntNme: 'RUSSIA', dlVal: '+7', cntCd: 'RUS' },
  { cntNme: 'BRAZIL', dlVal: '+55', cntCd: 'BRA' },
  { cntNme: 'SOUTH AFRICA', dlVal: '+27', cntCd: 'ZAF' },
  { cntNme: 'SOUTH KOREA', dlVal: '+82', cntCd: 'KOR' },
  { cntNme: 'MEXICO', dlVal: '+52', cntCd: 'MEX' },
  { cntNme: 'ITALY', dlVal: '+39', cntCd: 'ITA' },
  { cntNme: 'SPAIN', dlVal: '+34', cntCd: 'ESP' },
  { cntNme: 'NETHERLANDS', dlVal: '+31', cntCd: 'NLD' },
  { cntNme: 'SWEDEN', dlVal: '+46', cntCd: 'SWE' },
  { cntNme: 'NORWAY', dlVal: '+47', cntCd: 'NOR' },
  { cntNme: 'SWITZERLAND', dlVal: '+41', cntCd: 'CHE' },
  { cntNme: 'ARGENTINA', dlVal: '+54', cntCd: 'ARG' },
  { cntNme: 'TURKEY', dlVal: '+90', cntCd: 'TUR' },
  { cntNme: 'SAUDI ARABIA', dlVal: '+966', cntCd: 'SAU' },
  { cntNme: 'INDONESIA', dlVal: '+62', cntCd: 'IDN' },
  { cntNme: 'VIETNAM', dlVal: '+84', cntCd: 'VNM' },
  { cntNme: 'THAILAND', dlVal: '+66', cntCd: 'THA' },
  { cntNme: 'NEW ZEALAND', dlVal: '+64', cntCd: 'NZL' },
  { cntNme: 'SINGAPORE', dlVal: '+65', cntCd: 'SGP' },
  { cntNme: 'MALAYSIA', dlVal: '+60', cntCd: 'MYS' },
  { cntNme: 'PHILIPPINES', dlVal: '+63', cntCd: 'PHL' },
  { cntNme: 'EGYPT', dlVal: '+20', cntCd: 'EGY' },
  { cntNme: 'NIGERIA', dlVal: '+234', cntCd: 'NGA' },
  { cntNme: 'PAKISTAN', dlVal: '+92', cntCd: 'PAK' },
  { cntNme: 'BANGLADESH', dlVal: '+880', cntCd: 'BGD' },
  { cntNme: 'SRI LANKA', dlVal: '+94', cntCd: 'LKA' },
  { cntNme: 'NEPAL', dlVal: '+977', cntCd: 'NPL' },
  { cntNme: 'IRAN', dlVal: '+98', cntCd: 'IRN' },
  { cntNme: 'IRAQ', dlVal: '+964', cntCd: 'IRQ' },
  { cntNme: 'ISRAEL', dlVal: '+972', cntCd: 'ISR' },
  { cntNme: 'UNITED ARAB EMIRATES', dlVal: '+971', cntCd: 'ARE' },
  { cntNme: 'QATAR', dlVal: '+974', cntCd: 'QAT' },
  { cntNme: 'KUWAIT', dlVal: '+965', cntCd: 'KWT' },
  { cntNme: 'OMAN', dlVal: '+968', cntCd: 'OMN' },
  { cntNme: 'BAHRAIN', dlVal: '+973', cntCd: 'BHR' },
  { cntNme: 'LEBANON', dlVal: '+961', cntCd: 'LBN' },
  { cntNme: 'JORDAN', dlVal: '+962', cntCd: 'JOR' },
  { cntNme: 'GREECE', dlVal: '+30', cntCd: 'GRC' },
  { cntNme: 'PORTUGAL', dlVal: '+351', cntCd: 'PRT' },
  { cntNme: 'POLAND', dlVal: '+48', cntCd: 'POL' },
  { cntNme: 'HUNGARY', dlVal: '+36', cntCd: 'HUN' },
  { cntNme: 'CZECH REPUBLIC', dlVal: '+420', cntCd: 'CZE' },
  { cntNme: 'BELGIUM', dlVal: '+32', cntCd: 'BEL' },
  { cntNme: 'DENMARK', dlVal: '+45', cntCd: 'DNK' },
  { cntNme: 'FINLAND', dlVal: '+358', cntCd: 'FIN' },
  { cntNme: 'IRELAND', dlVal: '+353', cntCd: 'IRL' },
  { cntNme: 'AUSTRIA', dlVal: '+43', cntCd: 'AUT' },
  { cntNme: 'ROMANIA', dlVal: '+40', cntCd: 'ROU' },
  { cntNme: 'BULGARIA', dlVal: '+359', cntCd: 'BGR' },
  { cntNme: 'HONG KONG', dlVal: '+852', cntCd: 'HKG' },
  { cntNme: 'TAIWAN', dlVal: '+886', cntCd: 'TWN' },
  { cntNme: 'ICELAND', dlVal: '+354', cntCd: 'ISL' },
  { cntNme: 'MONACO', dlVal: '+377', cntCd: 'MCO' },
  { cntNme: 'LUXEMBOURG', dlVal: '+352', cntCd: 'LUX' },
];
