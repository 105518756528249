import React, { useState } from 'react';
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row, Col, Button, TabContent, TabPane,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import moment from 'moment';
import { ResumeTypeProps } from '../../resume.type';
import CertInfo from './info.cert';
import { CertData, GetCertDataResData } from '../../../../../../../services/campus/certifications/get-cert/get-cert.types';
import { AddNewCertifications } from './add.new.cert';
import { successAlert, errorAlert } from '../../../../../../../utils/alert';
import LoaderData from '../../../../../../../utils/loader';
import { ListCert } from './list.cert';
import { getResultCert } from '../../../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../../../utils/service/localstorage-service';
import EditCert from './edit.cert';
import { CertAddDataInput } from '../../../../../../../services/campus/certifications/update-cert/update-cert.types';

export const Certifications: React.FC<ResumeTypeProps> = ({ rootResumeTp, resumeTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [operationTp, setOperationTp] = useState('list-cert');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isAddApiCld, setIsAddApiCld] = useState(false);
  const [isEditApiCld, setIsEditApiCld] = useState(false);
  const [isDELApiCld, setIsDELApiCld] = useState('');
  const [loaderResume, setLoaderResume] = useState(false);
  const [minEndDate, setMinEndDate] = useState('');
  const getCertData = useSelector((state: RootState) => state.campusXcel.getCert);
  const [myListCert, setListMyCert] = useState<GetCertDataResData>({
    cert: [],
  });
  const [myEditCert, setMyEditCert] = React.useState<CertAddDataInput>({
    id: '',
    ops: 'UPDATE',
    nme: '',
    certF: '',
    sDte: '',
    eDte: '',
    desc: '',
  });

  React.useEffect(() => {
    if (resumeTp === 'certificate' && rootResumeTp === 'resume-act') {
      setLoaderResume(true);
      dispatch(getResultCert({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'CERT',
      }));
    }
  }, [resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (isAddApiCld || isDELApiCld === 'EXECUTED' || isEditApiCld) {
      setLoaderResume(true);
      dispatch(getResultCert({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'CERT',
      }));
    }
  }, [isAddApiCld, isDELApiCld, isEditApiCld]);

  React.useEffect(() => {
    if (getCertData.isError && getCertData.message !== '' && resumeTp === 'certificate' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(getCertData.message);
      setErrorShowAlert(true);
      setIsAddApiCld(false);
      setIsEditApiCld(false);
      setIsDELApiCld('');
    }
  }, [getCertData.isError, getCertData.message, resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (!getCertData.isError && getCertData.message !== '' && resumeTp === 'certificate' && rootResumeTp === 'resume-act' && operationTp === 'list-cert') {
      setListMyCert({ ...myListCert, cert: getCertData.cert });
      setLoaderResume(false);
      setIsAddApiCld(false);
      setIsEditApiCld(false);
      setIsDELApiCld('');
    }
  }, [getCertData.isError, getCertData.message, resumeTp, rootResumeTp, operationTp, getCertData.cert.length]);

  React.useEffect(() => {
    if (getCertData.isError && getCertData.message !== '' && resumeTp === 'certificate' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(getCertData.message);
      setErrorShowAlert(true);
      setIsAddApiCld(false);
      setIsEditApiCld(false);
      setIsDELApiCld('');
    }
  }, [getCertData.isError, getCertData.message, resumeTp, rootResumeTp]);

  const handleEditStatus = (editDetails: CertData) => {
    window.scrollTo(0, 0);
    setMyEditCert({
      ...myEditCert,
      id: editDetails.id,
      certF: editDetails.certF,
      nme: editDetails.nme,
      sDte: editDetails.sDte,
      eDte: editDetails.eDte,
      desc: editDetails.desc,
    });
    setOperationTp('edit-cert');
  };
  const handleCertEditChange = (e: any) => {
    setMyEditCert({ ...myEditCert, [e.target.name]: e.target.value });
  };
  const handleEditCertDate = (e:any) => {
    const dateData = moment(e.target.value).format('YYYY-MM-DD');
    setMyEditCert({ ...myEditCert, [e.target.name]: dateData });
    if (e.target.name === 'sDte') {
      const formattedStartDate = moment(e.target.value).format('YYYY-MM-DD');
      setMinEndDate(formattedStartDate);
      setMyEditCert({ ...myEditCert, sDte: dateData, eDte: formattedStartDate });
    }
  };

  return (
    <div>
      { loaderResume ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="margin-bottom mr">
        <Col lg={{ size: 12, order: 1 }} xs={{ order: 1 }}>
          <div className="align-end">
            {operationTp === 'list-cert'
              ? (
                <Button className="button-border" id="addNewId" onClick={() => setOperationTp('add-cert')}>
                  ADD<FontAwesomeIcon icon={faPlus} className="mx-1" />
                </Button>
              ) : operationTp === 'add-cert' || operationTp === 'edit-cert'
                ? (
                  <Button className="button-border" id="listProjId" onClick={() => setOperationTp('list-cert')}>
                    <FontAwesomeIcon icon={faArrowLeft} className="mx-1" />
                    Go Back
                  </Button>
                ) : null}
          </div>
          <hr className="hr-dasshed-lite margin-zero" />
          <div>
            <TabContent activeTab={operationTp}>
              <TabPane tabId="list-cert">
                {myListCert.cert.length === 0 ? (
                  <div className="align-center pt-5">
                    <img
                      alt="..."
                      height="40px"
                      width="40px"
                      src="/images/common/certificate_none.png"
                    />
                    <span className="input-checkbox-text-grey ml">No Certificate Found</span>
                  </div>
                )
                  : (
                    <Row className="align-center">
                      {myListCert.cert.map((data, index) => (
                        <Col xs="12" lg="10" key={`${index.toString()}`}>
                          <ListCert
                            certDetails={data}
                            index={index}
                            handleEditStatus={handleEditStatus}
                            rootResumeTp={rootResumeTp}
                            resumeTp={operationTp}
                            setAlertMsg={setAlertMsg}
                            setLoaderResume={setLoaderResume}
                            setSuccessShowAlert={setSuccessShowAlert}
                            setErrorShowAlert={setErrorShowAlert}
                            showErrorsAlert={showErrorsAlert}
                            setIsDELApiCld={setIsDELApiCld}
                            isDELApiCld={isDELApiCld}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
              </TabPane>
              <TabPane tabId="add-cert">
                <AddNewCertifications
                  rootResumeTp={rootResumeTp}
                  resumeTp={operationTp}
                  setOperationTp={setOperationTp}
                  setAlertMsg={setAlertMsg}
                  setLoaderResume={setLoaderResume}
                  setSuccessShowAlert={setSuccessShowAlert}
                  setErrorShowAlert={setErrorShowAlert}
                  showErrorsAlert={showErrorsAlert}
                  setIsAddApiCld={setIsAddApiCld}
                />
              </TabPane>
              <TabPane tabId="edit-cert">
                <EditCert
                  certDetails={myEditCert}
                  handleCertEditChange={handleCertEditChange}
                  handleEditCertDate={handleEditCertDate}
                  minEndDate={minEndDate}
                  resumeTp={operationTp}
                  rootResumeTp={rootResumeTp}
                  setOperationTp={setOperationTp}
                  setAlertMsg={setAlertMsg}
                  setLoaderResume={setLoaderResume}
                  setSuccessShowAlert={setSuccessShowAlert}
                  setErrorShowAlert={setErrorShowAlert}
                  setIsEditApiCld={setIsEditApiCld}
                />
              </TabPane>
            </TabContent>
          </div>
        </Col>
        <Col lg={{ size: 12, order: 2 }} xs={{ order: 2 }}>
          <div className="pt-5">
            <CertInfo />
          </div>
        </Col>
      </Row>
    </div>
  );
};
