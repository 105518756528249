import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import {
  Row, Col,
  CardHeader,
  CardBody,
  Card,
  Button,
} from 'reactstrap';
import {
  faArrowLeft,
  faLightbulb, faPlay, faQuoteLeft, faQuoteRight,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResumeVideo } from '../../../campus/common/video-profile/video';
import { errorAlert } from '../../../../../utils/alert';
import { getConfig } from '../../../../../utils/config/config';
import LoaderData from '../../../../../utils/loader';
import { ResumeVideoInput } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';

const { AWS_CXUSER_BUCKET } = getConfig();

export type ResumeInfoProps = {
  setResumeVideo: (updater: (prevState: ResumeVideoInput) => ResumeVideoInput) => void;
  resumeVideo:ResumeVideoInput;
  handleUpdateResumeVideo: (fileName: string) => void;
  myProfile: any;
  loaderVideoResume: boolean;
  setLoaderVideoResume: (loaderVideoResume: boolean) => void;
};

export const XcelianVideoInfo: React.FC<ResumeInfoProps> = ({
  setResumeVideo, resumeVideo, handleUpdateResumeVideo, myProfile, loaderVideoResume, setLoaderVideoResume,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [uploadFileName, setUploadFileName] = useState('');
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  const handleShowVideo = () => {
    setShowVideo(!showVideo);
  };

  React.useEffect(() => {
    if (uploadFileName && showVideo && alertMsg === 'Uploaded Successfully') {
      setLoaderVideoResume(true);
      setResumeVideo((prevState) => ({
        ...prevState,
        vidNme: uploadFileName,
      }));
      handleUpdateResumeVideo(uploadFileName);
    }
  }, [uploadFileName, showVideo, alertMsg]);

  return (
    <div className="pt-2 margin-bottom mx-2">
      { loaderVideoResume ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="align-center">
        <Col xs="12" lg="12">
          <Card className="video-card">
            <CardHeader className="campus-header-with-bg align-center">
              <div className=" my-2">
                <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
                <span className="video-header-text">Present Yourself in</span>
                <FontAwesomeIcon className="video-header-text mx-2" icon={faQuoteLeft} />
                <span className="video-quote-text">60 Seconds</span>
                <FontAwesomeIcon className="video-header-text mx-2" icon={faQuoteRight} />
              </div>
            </CardHeader>
            <CardBody className="mx-1 padding-zero">
              {resumeVideo.vidUrl !== '' && !showVideo
                ? (
                  <Row className="align-center my-3">
                    <Col xs="12" lg="12">
                      <ReactPlayer
                        url={resumeVideo.vidUrl}
                        controls
                        width="100%"
                        height="auto"
                        playsinline
                        config={{
                          vimeo: {
                            playerOptions: {
                              fullscreen: true,
                            },
                          },
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
              {!showVideo
                ? (
                  <div>
                    <div className="text-center-aligned">
                      <span className="text-lite-grey">Highlight These Key Points for a Stronger Video Profile</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Introduction: </span>
                      <span className="video-sub-header-text">Provide a brief introduction to who you are and your academic background.</span>
                      <span className="text-lite">(What is your name, and what are you currently studying?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Skills and Strengths: </span>
                      <span className="video-sub-header-text">Highlight your key strengths relevant to your career goals or the job market.</span>
                      <span className="text-lite">(What are your top skills or strengths, and how have you applied them?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Achievements: </span>
                      <span className="video-sub-header-text">Mention a noteworthy accomplishment that demonstrates your abilities.</span>
                      <span className="text-lite">(Can you briefly share one significant achievement or project you’re proud of?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Career Aspirations: </span>
                      <span className="video-sub-header-text">Show ambition and direction regarding their future career path.</span>
                      <span className="text-lite">(What are your future career goals or aspirations?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Why You? </span>
                      <span className="video-sub-header-text">Give a quick value proposition that sets you apart from others.</span>
                      <span className="text-lite">(Why should an employer or recruiter choose you?)</span>
                    </div>
                    <div className="align-center my-3">
                      <Button className="campus-button-all" onClick={handleShowVideo}>
                        <FontAwesomeIcon className="mr" icon={faVideoCamera} />
                        CREATE NEW VIDEO PROFILE
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="align-end">
                      <Button className="button-icon" onClick={handleShowVideo}>
                        <FontAwesomeIcon className="mr" icon={faArrowLeft} />
                        Back
                      </Button>
                    </div>
                    <Row>
                      <Col xs="12" lg="12">
                        <ResumeVideo
                          fileNameId={`${myProfile.UserCd}`}
                          awsFolder={`xcelian-res-video/${myProfile.UserTp}/${myProfile.UserCd}`}
                          awsBucket={AWS_CXUSER_BUCKET}
                          setUploadFileName={setUploadFileName}
                          setErrorShowAlert={setErrorShowAlert}
                          setAlertMsg={setAlertMsg}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};

export default XcelianVideoInfo;
