import { CertPutResponse, CertPutResults } from './update-cert.service.types';

export const CertPutTransform: (response: CertPutResponse) => CertPutResults = (certResults) => {
  const { data, is_error, message } = certResults;
  const result :CertPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
