import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Row, Col, Button,
  CardImg,
} from 'reactstrap';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import Accordion from '../../../../../../../utils/Accordion/Accordion';

const maxLength = 90;
export const CertInfo: React.FC = () => {
  const [isCompetitiveTxt, setIsCompetitiveTxt] = useState(false);
  const competitiveTxt = 'The job market is constantly evolving, and employers often prioritize candidates with the latest skills and certifications. Updating your certifications demonstrates your commitment to professional growth and enhances your competitiveness.';
  const handleCompetitive = () => {
    setIsCompetitiveTxt(!isCompetitiveTxt);
  };
  const [isIndusPaceTxt, setIsIndusPaceTxt] = useState(false);
  const indusPaceTxt = 'Industries and technologies rapidly evolve. By updating your certifications, you can stay abreast of the latest trends, tools, and practices, ensuring your knowledge and skills remain relevant.';
  const handleIndusPace = () => {
    setIsIndusPaceTxt(!isIndusPaceTxt);
  };
  const [isBoostEmployTxt, setIsBoostEmployTxt] = useState(false);
  const boostEmployTxt = 'Up-to-date certifications increase your employability by showcasing your proficiency in the latest industry standards. Employers value candidates who invest in their professional development and show a willingness to adapt to changing requirements.';
  const handleBoostEmploy = () => {
    setIsBoostEmployTxt(!isBoostEmployTxt);
  };
  const [isValidateExpertTxt, setIsValidateExpertTxt] = useState(false);
  const validateExpert = 'Certifications serve as a third-party validation of your knowledge and expertise in a specific field. Regular updates reinforce your credibility and provide evidence of your ongoing commitment to learning.';
  const handleValidateExpert = () => {
    setIsValidateExpertTxt(!isValidateExpertTxt);
  };
  const [isExpandSkillTxt, setIsExpandSkillTxt] = useState(false);
  const expandSkill = 'Certifications serve as a third-party validation of your knowledge and expertise in a specific field. Regular updates reinforce your credibility and provide evidence of your ongoing commitment to learning.';
  const handleExpandSkill = () => {
    setIsExpandSkillTxt(!isExpandSkillTxt);
  };
  const [isCredTrustTxt, setIsCredTrustTxt] = useState(false);
  const credTrust = 'Certifications act as a mark of credibility, instilling trust in clients, colleagues, and employers. Regular updates reinforce your professional reputation, allowing others to rely on your knowledge and expertise.';
  const handleCredTrust = () => {
    setIsCredTrustTxt(!isCredTrustTxt);
  };
  const [isIntelectGrowTxt, setIsIntelectGrowTxt] = useState(false);
  const intelectGrow = 'Pursuing certifications and updating them fosters personal and intellectual growth. It keeps you intellectually engaged, expands your perspectives, and helps you maintain a curious and learning-oriented mindset.';
  const handleIntelectGrow = () => {
    setIsIntelectGrowTxt(!isIntelectGrowTxt);
  };

  return (
    <Accordion
      title={(
        <div className="my-1">
          <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
          <span>Do you Know ?</span>
        </div>
          )}
      defaultOpen
      targetId="myCampus"
    >
      <Row className="pt-5 align-center resume-margin-top">
        <Col xs="12">
          <Row className="align-center">
            <Col lg={{ size: 7, order: 1 }} xs={{ order: 2 }}>
              <div className="align-text-justify mx-1">
                <span className="hr-style">
                  <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faStar} />Keep pace with industry advancements :
                  <span className="dashboard-thread-text mx-1">
                    {isIndusPaceTxt ? `${indusPaceTxt}` : `${indusPaceTxt.slice(0, maxLength)}`}
                    {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                  </span>
                  {isIndusPaceTxt
                    ? (
                      <Button onClick={handleIndusPace} className="dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleIndusPace} className="dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg={{ size: 2, order: 2 }} xs={{ order: 1 }}>
              <div className="align-center pt-4">
                <CardImg
                  alt="..."
                  className="mx-1 do-know-cert"
                  src="/images/campus/cert-1.png"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-center pt-4">
            <Col lg={{ size: 7, order: 2 }} xs={{ order: 2 }}>
              <div className="align-text-justify mx-1">
                <span className="hr-style"><FontAwesomeIcon className="quest-menu-icon mx-2" icon={faStar} />Boost employability:
                  <span className="dashboard-thread-text mx-1">
                    {isBoostEmployTxt ? `${boostEmployTxt}` : `${boostEmployTxt.slice(0, maxLength)}`}
                    {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                  </span>
                  {isBoostEmployTxt
                    ? (
                      <Button onClick={handleBoostEmploy} className="dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleBoostEmploy} className="dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg={{ size: 2, order: 1 }} xs={{ order: 1 }}>
              <div className="align-center pt-4">
                <CardImg
                  alt="..."
                  className="mx-1 do-know-cert"
                  src="/images/campus/cert-2.png"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-center pt-4">
            <Col lg={{ size: 7, order: 1 }} xs={{ order: 2 }}>
              <div className="align-text-justify mx-1">
                <span className="hr-style"><FontAwesomeIcon className="quest-menu-icon mx-2" icon={faStar} />Validate your expertise:
                  <span className="dashboard-thread-text mx-1">
                    {isValidateExpertTxt ? `${validateExpert}` : `${validateExpert.slice(0, maxLength)}`}
                    {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                  </span>
                  {isValidateExpertTxt
                    ? (
                      <Button onClick={handleValidateExpert} className="dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleValidateExpert} className="dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg={{ size: 2, order: 2 }} xs={{ order: 1 }}>
              <div className="align-center pt-4">
                <CardImg
                  alt="..."
                  className="mx-1 do-know-cert"
                  src="/images/campus/cert-3.png"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-center pt-4">
            <Col lg={{ size: 7, order: 2 }} xs={{ order: 2 }}>
              <div className="align-text-justify mx-1">
                <span className="hr-style"><FontAwesomeIcon className="quest-menu-icon mx-2" icon={faStar} />Expand your skill set:
                  <span className="dashboard-thread-text mx-1">
                    {isExpandSkillTxt ? `${expandSkill}` : `${expandSkill.slice(0, maxLength)}`}
                    {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                  </span>
                  {isExpandSkillTxt
                    ? (
                      <Button onClick={handleExpandSkill} className="dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleExpandSkill} className="dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg={{ size: 2, order: 1 }} xs={{ order: 1 }}>
              <div className="align-center pt-4">
                <CardImg
                  alt="..."
                  className="mx-1 do-know-cert"
                  src="/images/campus/cert-4.png"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-center pt-4">
            <Col lg={{ size: 7, order: 1 }} xs={{ order: 2 }}>
              <div className="align-text-justify mx-1">
                <span className="hr-style"><FontAwesomeIcon className="quest-menu-icon mx-2" icon={faStar} />Enhance credibility and trust:
                  <span className="dashboard-thread-text mx-1">
                    {isCredTrustTxt ? `${credTrust}` : `${credTrust.slice(0, maxLength)}`}
                    {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                  </span>
                  {isCredTrustTxt
                    ? (
                      <Button onClick={handleCredTrust} className="dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleCredTrust} className="dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg={{ size: 2, order: 2 }} xs={{ order: 1 }}>
              <div className="align-center pt-4">
                <CardImg
                  alt="..."
                  className="mx-1 do-know-cert"
                  src="/images/campus/cert-5.png"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-center pt-4">
            <Col lg={{ size: 7, order: 2 }} xs={{ order: 2 }}>
              <div className="align-text-justify mx-1">
                <span className="hr-style"><FontAwesomeIcon className="quest-menu-icon mx-2" icon={faStar} />Stay competitive :
                  <span className="dashboard-thread-text mx-1">
                    {isCompetitiveTxt ? `${competitiveTxt}` : `${competitiveTxt.slice(0, maxLength)}`}
                    {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                  </span>
                  {isCompetitiveTxt
                    ? (
                      <Button onClick={handleCompetitive} className="dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleCompetitive} className="dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg={{ size: 2, order: 1 }} xs={{ order: 1 }}>
              <div className="align-center pt-4">
                <CardImg
                  alt="..."
                  className="mx-1 do-know-cert"
                  src="/images/campus/cert-6.png"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-center pt-4">
            <Col lg={{ size: 7, order: 1 }} xs={{ order: 2 }}>
              <div className="align-text-justify mx-1">
                <span className="hr-style"><FontAwesomeIcon className="quest-menu-icon mx-2" icon={faStar} />Personal and intellectual growth:
                  <span className="dashboard-thread-text mx-1">
                    {isIntelectGrowTxt ? `${intelectGrow}` : `${intelectGrow.slice(0, maxLength)}`}
                    {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                  </span>
                  {isIntelectGrowTxt
                    ? (
                      <Button onClick={handleIntelectGrow} className="dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleIntelectGrow} className="dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg={{ size: 2, order: 2 }} xs={{ order: 1 }}>
              <div className="align-center pt-4">
                <CardImg
                  alt="..."
                  className="mx-1 do-know-cert"
                  src="/images/campus/cert-7.png"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Accordion>

  );
};
export default CertInfo;
