export const setTokenToLocalStorage = (token: string) => {
  localStorage.setItem('token', token);
};
export const getTokenFromLocalStorage = () => localStorage.getItem('token') || '';
export const setUserProfileLocalStorage = (userProfile: any) => {
  localStorage.setItem('userProfile', userProfile);
};
export const getUserProfileLocalStorage = () => localStorage.getItem('userProfile');

// export const setProfileUrlStorage = (urlData: string) => {
//   localStorage.setItem('profileUrl', urlData);
// };
// export const getProfileUrlLocalStorage = () => localStorage.getItem('profileUrl') || '';
export const clearKeysFromLocalStorage = () => {
  localStorage.clear();
};

// export const setTokenSessionStorage = (token: string) => {
//   sessionStorage.setItem('token', token);
// };

// export const setUserProfileLocalStorage = (userProfile: any) => {
//   sessionStorage.setItem('userProfile', userProfile);
// };

// export const getUserProfileLocalStorage = () => sessionStorage.getItem('userProfile');

// export const setProfileUrlStorage = (urlData: string) => {
//   sessionStorage.setItem('profileUrl', urlData);
// };

// export const getProfileUrlLocalStorage = () => sessionStorage.getItem('profileUrl') || '';

// export const clearKeysFromLocalStorage = () => {
//   sessionStorage.clear();
// };
