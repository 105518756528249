import { GetSadminDashResponse, GetSadminDashResults } from './get.sadmin.dashboard.service.types';

export const GetSadminDashTransform: (response: GetSadminDashResponse) => GetSadminDashResults = (getSadminDashResults) => {
  const { data, is_error, message } = getSadminDashResults;
  const result: GetSadminDashResults = {
    data: {
      subsCount: 0,
      CMPS: 0,
      RCRT: 0,
      CORP: 0,
      XCLN: 0,
      NWSLTR: 0,
      PDCST: 0,
      SKLMENT: 0,
      jobsCount: 0,
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
