import { JobStatusResponse, JobStatusResults } from './job-status.service.types';

export const JobStatusTransform: (response: JobStatusResponse) => JobStatusResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :JobStatusResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data;
  }
  return result;
};
