import AWS from 'aws-sdk';
import moment from 'moment-timezone';
import { getConfig } from '../config/config';
import { UploadS3FilesTransportType } from '../../services/common/upload-s3-files/upload-s3-files.types';
import { DelPostTransportType } from '../../services/campus/dashboard/post/del-post-file-s3/del-post.types';
import { GetS3FilesTransportType } from '../../services/common/get-s3-files/get-s3files.types';
import { MetaInput, UpdateS3CxMetaTransportType } from '../../services/aIntelligence/update-cx-meta/update-s3-cx-meta.types';
import { GetS3CxMetaTransportType } from '../../services/aIntelligence/get-all-cx-meta/get-s3-cx-meta.types';

const {
  USERNAME, PASSWORD, AWS_ACCESS_KEY, AWS_SECRET, AWS_REGION,
} = getConfig();

const s3 = new AWS.S3({
  accessKeyId: AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRET,
  region: AWS_REGION,
});

// const getLastIndexNumber = async (bucketName: string, folderName: string): Promise<number> => {
//   const params = {
//     Bucket: bucketName,
//     Prefix: `${folderName}/`, // Include the folder prefix
//   };

//   try {
//     const data = await s3.listObjectsV2(params).promise();
//     const fileKeys = data.Contents?.map((item) => item.Key) || [];
//     const lastIndex = fileKeys.reduce((maxIndex, fileKey) => {
//       if (fileKey) {
//         const match = fileKey.match(/_(\d+)\.\w+$/); // Match the index number in the file name
//         if (match) {
//           const index = parseInt(match[1], 10);
//           return index > maxIndex ? index : maxIndex;
//         }
//       }
//       return maxIndex;
//     }, 0);
//     return lastIndex;
//   } catch (error) {
//     // eslint-disable-next-line no-console
//     console.error('Error getting last index number:', error);
//     throw error;
//   }
// };

export const updateMetaDataS3 = async (
  bucketName: string,
  fileName: string,
  folderName: string,
  metaJson: MetaInput,
): Promise<UpdateS3CxMetaTransportType> => {
  if (!bucketName || !fileName || !folderName) {
    return { success: false, message: 'Bucket name, file name, and folder name must be provided.' };
  }

  try {
    // Check if the file exists in S3
    const checkFileExists = async (): Promise<boolean> => {
      try {
        await s3.headObject({ Bucket: bucketName, Key: `${folderName}/${fileName}` }).promise();
        return true;
      } catch (error) {
        if (error === 'NotFound') {
          return false;
        }
        throw error;
      }
    };

    // Function to fetch JSON data from S3
    const fetchJsonFile = async (): Promise<any[]> => {
      const params = {
        Bucket: bucketName,
        Key: `${folderName}/${fileName}`,
      };

      try {
        const data = await s3.getObject(params).promise();
        if (!data.Body) {
          throw new Error('No data received from S3');
        }
        const jsonData = JSON.parse(data.Body.toString('utf-8') || '[]');
        return jsonData;
      } catch (error) {
        throw new Error(`Failed to fetch file: ${error}`);
      }
    };

    // Check if the file exists
    const fileExists = await checkFileExists();
    let existingData: MetaInput[] = [];

    if (fileExists) {
      existingData = await fetchJsonFile();
    }

    // Check for duplicates
    const isDuplicate = existingData.some(
      (item) => item.nme.toLowerCase() === metaJson.nme.toLowerCase(),
    );

    if (isDuplicate) {
      return { success: false, message: 'Duplicate entry found. The file was not updated.' };
    }

    // Update the file with new entry
    const updatedData = [...existingData, metaJson];
    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${fileName}`,
      Body: JSON.stringify(updatedData),
      ContentType: 'application/json',
      // ACL: 'public-read',
    };

    // Upload updated JSON file to S3
    await s3.putObject(params).promise();

    return { success: true, message: 'File updated successfully.' };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error uploading META files:', error);
    return { success: false, message: 'Failed to update the file.' };
  }
};

export const metaListS3File = async (
  bucketName: string,
  fileName: string,
  folderName: string,
  search: string,
  maxResults: number,
  nextToken: string,
): Promise<GetS3CxMetaTransportType> => {
  try {
    // Fetch JSON data from S3
    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${fileName}`,
    };

    const data = await s3.getObject(params).promise();
    const jsonData: any[] = JSON.parse(data.Body?.toString('utf-8') || '[]');

    // Lowercase search term for case-insensitive comparison
    const lowerCaseSearch = search.toLowerCase();

    // Filter by search term across all fields dynamically
    const filteredData = jsonData.filter((record) => Object.values(record).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearch)));

    // Implement pagination
    const startIndex = nextToken ? parseInt(nextToken, 10) : 0;
    const paginatedData = filteredData.slice(startIndex, startIndex + maxResults);
    const newNextToken = paginatedData.length === maxResults ? (startIndex + maxResults).toString() : undefined;

    return {
      success: true,
      data: paginatedData,
      message: newNextToken ? `More data available. Next token: ${newNextToken}` : 'No more data available',
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    return {
      success: false,
      data: [],
      message: 'Failed to get data from S3',
    };
  }
};

export const uploadS3File = async (
  bucketName: string,
  fileNmeInit: string,
  folderName: string,
  formData: FormData,
  isEdit: boolean,
): Promise<UploadS3FilesTransportType> => {
  try {
    if (isEdit === true) {
      try {
        // const lastIndex = await getLastIndexNumber(bucketName, folderName);
        const uploadPromises = Array.from(formData.entries()).map(async ([key, file]) => {
          if (file instanceof File) {
            // const indxNum = lastIndex + index + 1;
            const parts = key.split('.');
            const extension = parts.pop();
            const baseFileName = fileNmeInit.split('.').shift();
            const filename = `${baseFileName}.${extension}`;
            const s3Key = `${folderName}/${filename}`;
            // const parts = key.split('.');
            // const extension = parts[parts.length - 1];
            // const filename = `${fileNmeInit}_${indxNum}.${extension}`;
            // const s3Key = `${folderName}/${filename}`;

            const res = await s3.upload({
              Bucket: bucketName,
              Key: s3Key,
              Body: file,
              // ACL: 'public-read',
            }).promise();

            return { data: res.Key };
          }
          throw new Error('Invalid file type');
        });

        const uploadResults = await Promise.all(uploadPromises);
        if (uploadResults.length > 0) {
          return uploadResults[0];
        }
        return { data: 'none' };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error uploading files:', error);
        throw error;
      }
    }
    const uploadPromises = Array.from(formData.entries()).map(async ([key, file]) => {
      if (file instanceof File) {
        // const indxNum = index + 1;
        // const parts = file.name.split('.');
        const parts = key.split('.');
        const extension = parts.pop();
        const baseFileName = fileNmeInit.split('.').shift();
        // const extension = parts[parts.length - 1];
        const filename = `${baseFileName}.${extension}`;
        const s3Key = `${folderName}/${filename}`;

        const res = await s3.upload({
          Bucket: bucketName,
          Key: s3Key,
          Body: file,
          // ACL: 'public-read',
        }).promise();

        return { data: res.Key };
      }
      throw new Error('Invalid file type');
    });
    const uploadResults = await Promise.all(uploadPromises);
    if (uploadResults.length > 0) {
      return uploadResults[0];
    }
    return { data: 'none' };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const getListS3File = async (
  bucketName: string,
  folderName: string,
  identifierTp: string,
): Promise<GetS3FilesTransportType> => {
  const params = {
    Bucket: bucketName,
    Prefix: folderName,
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const fileKeys = data.Contents?.map((item) => {
      const fileName = item.Key?.split('/').pop() || '';
      const url = s3.getSignedUrl('getObject', {
        Bucket: bucketName,
        Key: item.Key,
        Expires: 60 * 60,
        ...(fileName.endsWith('.pdf')
          ? {
            ResponseContentDisposition: 'inline',
            ResponseContentType: 'application/pdf',
          }
          : {}),
      });
      return { url, nme: fileName };
    }) || [];
    return { data: fileKeys, identifierTp };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading files: ', error);
    return { data: [], identifierTp };
  }
};

export const deleteS3File = async (bucketName: string, filename: string): Promise<DelPostTransportType> => {
  await s3.deleteObject({
    Bucket: bucketName,
    Key: filename,
  }).promise();
  return { data: 'success' };
};

export const getToken = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};
const requestOptions = (method: string, token: string, requestType: string, body?: BodyInit) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
  },
  body: JSON.stringify(body),
});

export const getJSON = async (url: RequestInfo, token: string, requestType: string) => {
  const response = await fetch(url, requestOptions('GET', token, requestType));
  const data = await response.json();
  return data;
};
export const postJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('POST', token, requestType, body));
  const data = await response.json();
  return data;
};
export const putJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('PUT', token, requestType, body));
  const data = await response.json();
  return data;
};

export const deleteJSON = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'DELETE', headers });
  const data = await response.json();
  return data;
};
export const getLogin = async (url: RequestInfo, requestType: string, userName: string, passKey: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${userName}:${passKey}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

export const getPublicJSON = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

const requestOptionsPrivateFiles = (method: string, requestType: string, requestFile: string, requestData: string, token: string, formdata: FormData) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
    'Request-Data': requestData,
    'Request-File': requestFile,
    // 'Content-Type': formdata.type,
  },
  body: formdata,
});

// const requestOptionsPublicFile = (method: string, requestType: string, bodyData: any) => ({
//   method,
//   headers: {
//     'Request-Type': requestType,
//     Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
//   },
//   body: bodyData,
// });

const requestPublicData = (method: string, requestType: string, token:string, bodyData: any) => ({
  method,
  headers: {
    'Request-Type': requestType,
    'token-data': token,
    // Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  },
  body: JSON.stringify(bodyData),
});

// export const postPublicFileData = async (url: RequestInfo, requestType: string, fileUpload:string, body: BodyInit) => {
//   const formData = new FormData();
//   formData.append('data', JSON.stringify(body));
//   formData.append('files', fileUpload);
//   const response = await fetch(url, requestOptionsPublicFile('POST', requestType, '', '', formData));
//   const data = await response.json();
//   return data;
// };

// export const postPublicFormData = async (url: RequestInfo, requestType: string, body: BodyInit) => {
//   // const formData = new FormData();
//   // formData.append('data', JSON.stringify(body));
//   const response = await fetch(url, requestOptionsPublicFile('POST', requestType, body));
//   const data = await response.json();
//   return data;
// };

export const postPublicData = async (url: RequestInfo, requestType: string, token: string, body: BodyInit) => {
  const response = await fetch(url, requestPublicData('POST', requestType, token, body));
  const data = await response.json();
  return data;
};

export const putPublicData = async (url: RequestInfo, requestType: string, token: string, body: BodyInit) => {
  const response = await fetch(url, requestPublicData('PUT', requestType, token, body));
  const data = await response.json();
  return data;
};

export const putCommonTokenApi = async (url: RequestInfo, requestType: string, token: string) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'PUT', headers });
  const data = await response.json();
  return data;
};
// Remove below later
export const putCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'PUT', headers });
  const data = await response.json();
  return data;
};

export const getCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const getCommonApiToken = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const putPrivateFileData = async (url: RequestInfo, token: string, requestType: string, requestFile: string, requestData: string, fileData: FormData) => {
  const response = await fetch(url, requestOptionsPrivateFiles('PUT', requestType, requestFile, requestData, token, fileData));
  const data = await response.json();
  return data;
};

// export const postCertFileData = async (url: RequestInfo, token: string, requestType: string, requestData: string, fileUpload:string, body: BodyInit) => {
//   const formData = new FormData();
//   formData.append('certData', JSON.stringify(body));
//   formData.append('certFiles', fileUpload);
//   const response = await fetch(url, requestOptionsPrivateFiles('PUT', requestType, '', requestData, token, formData));
//   const data = await response.json();
//   return data;
// };

export const formatDate = (postDate: any) => {
  const now = moment();
  const date = moment.tz(postDate, 'Asia/Kolkata').add(5, 'hours').add(30, 'minutes');
  if (now.isSame(date, 'day')) {
    return `Today ${date.format('hh:mm A')}`;
  }
  if (now.year() === date.year()) {
    return date.format('DD MMM hh:mm A');
  }
  return date.format('DD MMM YYYY hh:mm A');
};
