import { putJSON } from '../../../../../utils';
import { getConfig } from '../../../../../utils/config/config';
import { OrgProjOptions, OrgProjResponse } from './org-proj.service.types';

const { CAMPUSXCEL_API_ROOT } = getConfig();
export const CampusOrgProjPutRequest = ({
  inputBody, token, uTp, requestType,
}: OrgProjOptions): Promise<OrgProjResponse> => {
  if (token) {
    return putJSON(`${CAMPUSXCEL_API_ROOT}?uTp=${uTp}`, token, requestType, inputBody as unknown as BodyInit);
  }
  return putJSON(`${CAMPUSXCEL_API_ROOT}?uTp=${uTp}`, '', requestType, inputBody as unknown as BodyInit);
};
