import { XclnResumePutResponse, XclnResumePutResults } from './update.resume.info.service.types';

export const XclnResumePutTransform: (response: XclnResumePutResponse) => XclnResumePutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :XclnResumePutResults = {
    message: '',
    error: false,
    jdFileName: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
    result.jdFileName = data;
  }
  return result;
};
