import React from 'react';
import CXTable from '../../../../../utils/CXTable/cx.table';
import { ListBatchProps } from './batch.type';

export const AllBatchesList: React.FC<ListBatchProps> = ({
  pagedData, currentPage, recordsPerPage, totalRecords, handlePageChange, setEditRecord, setDeleteRecord,
}) => {
  const columns = [
    { header: 'Batch ID', accessor: 'BatchCd' },
    { header: 'Name', accessor: 'bNme' },
    { header: 'Start Date', accessor: 'sDte' },
    { header: 'End Date', accessor: 'eDte' },
    { header: 'Stream', accessor: 'strm' },
    { header: 'Course', accessor: 'crs' },
  ];
  return (
    <div>
      <CXTable
        tableId="cmpsBatchId"
        columns={columns}
        data={pagedData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        setEditRecord={setEditRecord}
        setDeleteRecord={setDeleteRecord}
        setOtherObj={() => (false)}
        oSts=""
      />
    </div>
  );
};

export default AllBatchesList;
