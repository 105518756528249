import { CandPutStsResponse, CandPutStsResults } from './update-cand-sts.service.types';

export const CandPutStsTransform: (response: CandPutStsResponse) => CandPutStsResults = (batchResults) => {
  const {
    data, is_error, message,
  } = batchResults;
  const result :CandPutStsResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
    result.data = data;
  }
  return result;
};
