import React from 'react';
import {
  Card, Row, Col, Button, Input,
  CardHeader,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { EditCertProps } from '../../resume.type';
import { clearCert, clearResultCert, getCert } from '../../../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../../../utils/service/localstorage-service';

export const EditCert: React.FC<EditCertProps> = ({
  certDetails,
  handleCertEditChange,
  handleEditCertDate,
  minEndDate,
  rootResumeTp,
  resumeTp,
  setOperationTp,
  setAlertMsg,
  setLoaderResume,
  setSuccessShowAlert,
  setErrorShowAlert,
  setIsEditApiCld,
}) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const certEditResponse = useSelector((state: RootState) => state.campusXcel.updateCert);
  const handleSubmitEditCert = () => {
    setLoaderResume(true);
    dispatch(getCert({
      inputBody: certDetails,
      requestType: APIHeader.REQ_UPDATE_CERT,
      uTp: UserType.CMPS,
      token: tokenData,
    }));
    dispatch(clearResultCert());
  };
  React.useEffect(() => {
    if (certEditResponse.error && certEditResponse.message !== '' && resumeTp === 'edit-cert' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(certEditResponse.message);
      setErrorShowAlert(true);
    }
  }, [certEditResponse.error, certEditResponse.message, rootResumeTp, resumeTp]);

  React.useEffect(() => {
    if (!certEditResponse.error && certEditResponse.message !== '' && resumeTp === 'edit-cert' && rootResumeTp === 'resume-act') {
      setAlertMsg(certEditResponse.message);
      dispatch(clearCert());
      Promise.resolve(
        setIsEditApiCld(true),
      ).then(() => {
        setOperationTp('list-cert');
      }).then(() => {
        setLoaderResume(false);
      }).then(() => {
        setSuccessShowAlert(true);
      });
    }
  }, [certEditResponse.error, certEditResponse.message, rootResumeTp, resumeTp]);
  return (
    <div className="mb-3 pt-3">
      <Card className="form-card-border">
        <CardHeader className="form-card-header mx-1">
          <div className="align-center my-2">
            <span className="quest-menu-icon">Edit Certificate</span>
          </div>
        </CardHeader>
        <Row className="my-1">
          <Col xs="12">
            <Row>
              <Col xs="12">
                <div className="text-right-login-campus-register pt-4 mx-1">
                  <h6 className="text-lite ml-1">Certificate Name<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Enter Your Certificate Name"
                    type="text"
                    name="nme"
                    required
                    value={certDetails.nme}
                    onChange={(e) => handleCertEditChange(e)}
                    className="campus-input"
                  />
                </div>
              </Col>
              <Col lg="6" md="11" xs="12">
                <div className="text-right-login-campus-register mx-1 pt-4">
                  <h6 className="text-lite ml-1">Start Date</h6>
                  <Input
                    type="date"
                    name="sDte"
                    value={certDetails.sDte}
                    onChange={(e) => handleEditCertDate(e)}
                    className="campus-input"
                  />
                </div>
              </Col>
              <Col lg="6" md="11" xs="12">
                <div className="text-right-login-campus-register mx-1 pt-4">
                  <h6 className="text-lite ml-1">End Date</h6>
                  <Input
                    type="date"
                    name="eDte"
                    min={minEndDate}
                    value={certDetails.eDte}
                    onChange={(e) => handleEditCertDate(e)}
                    className="campus-input"
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="text-right-login-campus-register mx-1 pt-4">
                  <h6 className="text-lite ml-1">Description</h6>
                  <Input
                    placeholder="Enter Your Certificate Description"
                    type="textarea"
                    name="desc"
                    value={certDetails.desc}
                    onChange={(e) => handleCertEditChange(e)}
                    className="campus-text-area-desc"
                  />
                </div>
                <span className="text-lite-grey ml-1">Describe more about your certifications.</span>
              </Col>
              <Col xs="12">
                <div className="align-center my-3">
                  <Button className="campus-button-all mx-1" onClick={handleSubmitEditCert}>
                    SAVE
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EditCert;
