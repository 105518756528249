import { EnquiryStatusPutResponse, EnquiryStatusPutResults } from './update.enquiry.status.service.types';

export const EnquiryStatusPutTransform: (response: EnquiryStatusPutResponse) => EnquiryStatusPutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :EnquiryStatusPutResults = {
    message: '',
    error: false,
    data: {
      id: '',
      message: '',
    },
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
