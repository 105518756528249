import { ListPostResponse, ListPostResults } from './list-post.service.types';

export const ListPostTransform: (response: ListPostResponse) => ListPostResults = (listPostResults) => {
  const { data, is_error, message } = listPostResults;
  const result: ListPostResults = {
    tCnt: 0,
    fCnt: 0,
    postData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.postData = data.postData;
    result.message = 'executed';
  }
  return result;
};
