import { ListMETAResponse, ListMETAResults } from './list-meta.service.types';

export const ListMETATransform: (response: ListMETAResponse) => ListMETAResults = (listClientResults) => {
  const { data, is_error, message } = listClientResults;
  const result: ListMETAResults = {
    data: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};
