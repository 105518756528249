import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const AboutCampusXcel: React.FC = () => (
  <div className="mb-3 pt-5 why-campus-xcel">
    <div className="pt-5 mx-2">
      <div className="text-center-aligned">
        <span className="text-intro-header display-6">
          About Us - Discover Our Story
        </span>
      </div>
      <Row className="mx-1 pt-2 align-center">
        <Col lg={{ size: 7, order: 1 }} xs={{ order: 1 }}>
          <div className="pt-5 align-text-justify mx-1">
            <span className="text-sub-intro">
              At CampusXcel, we believe in the power of potential. Our journey began with a simple idea: to create a platform that not only connects people to job opportunities but also equips them with the skills, knowledge, and support they need to thrive in today’s competitive landscape.
              <br /><br />We are passionate about empowering students, recent graduates, and professionals at every stage of their careers. From comprehensive interview preparation to personalized mentoring and access to schemes, we are dedicated to guiding our users on their unique paths to success.
              <br /><br />Our mission is to be more than just a job portal. We are your partner in growth, providing a launchpad for your career to take off. With a commitment to innovation and a focus on your future, CampusXcel is here to help you achieve your dreams.
              <br /><br />Join us on this journey and let’s build a brighter future together.
            </span>
          </div>
        </Col>
        <Col lg={{ size: 4, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
          <div className="align-center mx-4">
            <CardImg
              alt="..."
              src="/images/home/campus-flow.png"
            />
          </div>
        </Col>
      </Row>
      <div className="mb-3 p-4" />
    </div>
  </div>
);

export default AboutCampusXcel;
