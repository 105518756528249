import React from 'react';
import { Row } from 'reactstrap';
// import { Link } from 'react-router-dom';
import './unauthorized.scss';

export const ComingSoon: React.FC = () => (
  <Row className="unauthorized-container">
    <div className="vert-center-unauthorized">
      <div>
        <img
          alt="..."
          height="auto"
          width="200px"
          src="/images/common/coming-soon.png"
        />
      </div>
    </div>
  </Row>
);

export default ComingSoon;
