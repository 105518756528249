import { CommentResponse, CommentResults } from './comment.service.types';

export const CommentTransform: (response:CommentResponse) => CommentResults = (commentResults) => {
  const { data, is_error, message } = commentResults;
  const result : CommentResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
