import React from 'react';
import { ListCampusProps } from '../../onboard.type';
import CXTable from '../../../../../../utils/CXTable/cx.table';

export const AllRecruitUserList: React.FC<ListCampusProps> = ({
  pagedData, currentPage, recordsPerPage, totalRecords, handlePageChange, setEditRecord, setDeleteRecord,
}) => {
  const columns = [
    { header: 'Client', accessor: '' },
    { header: 'User ID', accessor: 'UserCd' },
    { header: 'Name', accessor: '' },
    { header: 'Phone', accessor: '' },
    { header: 'Email', accessor: 'eId' },
  ];

  return (
    <div>
      <CXTable
        tableId="recruit-user"
        columns={columns}
        data={pagedData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        setEditRecord={setEditRecord}
        setDeleteRecord={setDeleteRecord}
        setOtherObj={() => (false)}
        oSts=""
      />
    </div>
  );
};

export default AllRecruitUserList;
