import React from 'react';
import '../dashboard.scss';
import { Button } from 'reactstrap';

  interface DataProps {
    htmlContent: string;
    htmlTitle: string;
    handleChangeEditValue: ()=> void;
    // handleUpdateMediaDetails: (MediaTp: string, MediaId: string) => void;
  }

const DetailsNewsletter: React.FC<DataProps> = ({
  htmlContent,
  htmlTitle,
  handleChangeEditValue,
}) => (
  <div>
    <div className="align-start mr mb-3">
      <Button onClick={handleChangeEditValue} className="button-sadmin-filled">Edit</Button>
    </div>
    <div className="mb-3">
      <span className="dash-sadmin-news-header-text">
        {htmlTitle}
      </span>
    </div>
    <div
      className="detail-newsletter"
            // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  </div>
);

export default DetailsNewsletter;
