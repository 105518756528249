import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import Select, { MultiValue } from 'react-select';
import '../../../../recruiter.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdatePayPhase3TypeProps } from '../../../../recruiter.type';
import {
  editJobsReq,
} from '../../../../../../../../store/campusXcel/actions';
import {
  AddonPerkType,
  AmountTpye,
  APIHeader, ListExpRange1, ListExpRange2, numberToWords, PayStructure, SuppPayType, UserType,
} from '../../../../../../../../utils/constants';
import { errorAlert } from '../../../../../../../../utils/alert';

export const UpdateJobPhase3: React.FC<UpdatePayPhase3TypeProps> = ({
  // jobPhase,
  setLoadingJob,
  setErrorShowAlert,
  setAlertMsg,
  tokenData,
  setAddJobRes,
  addJobRes,
  updatePh3Pay,
  setUpdatePh3Pay,
  // setJobPhStatus,
  apiReqTp,
  setApiReqTp,
}) => {
  const dispatch = useDispatch();
  const [isSuppPay, setIsSuppPay] = React.useState(false);
  const [initOSpay, setInitOSpay] = React.useState('');
  const [isOPerk, setIsOPerk] = React.useState(false);
  const [initOPerk, setInitOPerk] = React.useState('');
  const [showErrorsAlertRange, setErrorShowAlertRange] = React.useState(false);
  const [alertRangeMsg, setAlertRangeMsg] = React.useState('');
  const updateJobPhase3Response = useSelector((state: RootState) => state.campusXcel.updateJob);
  const [, setMultiSuppTp] = React.useState<MultiValue<{ label: string; value: string; }>>([]);
  const [, setMultiPerkTp] = React.useState<MultiValue<{ label: string; value: string; }>>([]);

  // const handleBackToPhase2 = () => {
  //   setApiReqTp('EDIT-BACK-REQ-TO-PH2');
  // };

  const handleChangeUpdatePay = (event: any) => {
    setUpdatePh3Pay({ ...updatePh3Pay, [event.target.name]: event.target.value });
  };

  // React.useEffect(() => {
  //   if (jobPhase === 'phase-3' && addJobRes.JobCd !== '' && addJobRes.JobId !== '') {
  //     setUpdatePh3Pay({ ...updatePh3Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //   }
  // }, [jobPhase, addJobRes.JobCd, addJobRes.JobId]);

  const handleUpdatePhase3Pay = (e: any) => {
    e.preventDefault();
    if (updatePh3Pay.JobCd !== '' || updatePh3Pay.JobId !== '') {
      if (apiReqTp === 'NEW-ADD-REQ-TO-PH3') {
        setLoadingJob(true);
        dispatch(editJobsReq({
          inputBody: updatePh3Pay,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PHASE3',
        }));
      }
      if (apiReqTp === 'EDIT-BACK-REQ-TO-PH3' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH3' || apiReqTp === 'PSTG3-req') {
        setLoadingJob(true);
        dispatch(editJobsReq({
          inputBody: updatePh3Pay,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PHASE3',
        }));
      }
    }
  };

  React.useEffect(() => {
    if (updateJobPhase3Response.error && updateJobPhase3Response.message !== '') {
      setLoadingJob(false);
      setAlertMsg(updateJobPhase3Response.message);
      setErrorShowAlert(true);
    }
  }, [updateJobPhase3Response.error, updateJobPhase3Response.message]);

  React.useEffect(() => {
    if (!updateJobPhase3Response.error && updateJobPhase3Response.data.JobCd !== '' && updateJobPhase3Response.data.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH3') {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase3Response.data.JobCd,
        JobId: updateJobPhase3Response.data.JobId,
        NxtStg: updateJobPhase3Response.data.NxtStg,
      });
      setApiReqTp('NEW-ADD-REQ-TO-PH4');
    }
  }, [updateJobPhase3Response.error, updateJobPhase3Response.data.JobCd, updateJobPhase3Response.data.JobId]);

  React.useEffect(() => {
    if (!updateJobPhase3Response.error && updateJobPhase3Response.data.JobCd !== '' && updateJobPhase3Response.data.JobId !== '' && (apiReqTp === 'EDIT-BACK-REQ-TO-PH3' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH3' || apiReqTp === 'PSTG3-req')) {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase3Response.data.JobCd,
        JobId: updateJobPhase3Response.data.JobId,
        NxtStg: updateJobPhase3Response.data.NxtStg,
      });
      setApiReqTp('EDIT-FORWARD-REQ-TO-PH4');
    }
  }, [updateJobPhase3Response.error, updateJobPhase3Response.data.JobCd, updateJobPhase3Response.data.JobId]);

  const amountNameoptions = AmountTpye.map((amnt) => ({
    label: amnt.label,
    value: amnt.value,
  }));

  const selectedAmntNameOptions = amountNameoptions && amountNameoptions.find(
    (option) => (option.value === updatePh3Pay.amntTp),
  );

  const handleSelectAmountTypeData = (selectedOption: any) => {
    if (selectedOption) {
      setUpdatePh3Pay({
        ...updatePh3Pay,
        amntTp: selectedOption.value,
      });
    }
  };

  const payNameoptions = PayStructure.map((pay) => ({
    label: pay.label,
    value: pay.value,
  }));

  const selectedPayNameOptions = payNameoptions && payNameoptions.find(
    (option) => (option.value === updatePh3Pay.payBy),
  );

  const suppPayNameoptions = SuppPayType.map((suppPay) => ({
    label: suppPay.label,
    value: suppPay.value,
  }));

  const handleSelectSuppPayType = () => updatePh3Pay.suppPay.map((item) => {
    const foundOption = suppPayNameoptions.find((option) => option.value === item);
    return foundOption ? { label: foundOption.label, value: foundOption.value } : { label: item, value: item };
  });

  const handleAllSuppPay = (e: MultiValue<{ label: string; value: string; }>) => {
    if (e && e.length) {
      const suppPayV = e.map(({ value }) => value);
      const hasOthersPay = suppPayV.includes('OTRS');
      setUpdatePh3Pay({
        ...updatePh3Pay,
        suppPay: suppPayV,
        oSuppPay: hasOthersPay ? updatePh3Pay.oSuppPay : [],
      });
      setMultiSuppTp(e);
      setIsSuppPay(hasOthersPay);
    } else {
      setUpdatePh3Pay({
        ...updatePh3Pay,
        suppPay: [],
        oSuppPay: [],
      });
      setMultiSuppTp([]);
      setIsSuppPay(false);
    }
  };

  const perkNameoptions = AddonPerkType.map((perk) => ({
    label: perk.label,
    value: perk.value,
  }));

  const handleSelectPerkType = () => updatePh3Pay.perk.map((item) => {
    const foundOption = perkNameoptions.find((option) => option.value === item);
    return foundOption ? { label: foundOption.label, value: foundOption.value } : { label: item, value: item };
  });

  const handleAllPerk = (e: MultiValue<{ label: string; value: string; }>) => {
    if (e && e.length) {
      const perkV = e.map(({ value }) => value);
      const hasOthers = perkV.includes('OTRS');
      setUpdatePh3Pay({
        ...updatePh3Pay,
        perk: perkV,
        oPerk: hasOthers ? updatePh3Pay.oPerk : [],
      });
      setMultiPerkTp(e);
      setIsOPerk(hasOthers);
    } else {
      setUpdatePh3Pay({
        ...updatePh3Pay,
        perk: [],
        oPerk: [],
      });
      setMultiPerkTp([]);
      setIsOPerk(false);
    }
  };

  const handleSelectPayData = (selectedOption: any) => {
    if (selectedOption) {
      setUpdatePh3Pay({
        ...updatePh3Pay,
        payBy: selectedOption.value,
      });
    }
  };
  // Handle Multiple Others Suppliment Pay

  const handleKeyPressOSPay = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' && initOSpay.trim()) {
      e.preventDefault();
      const newOSpay = initOSpay.split(',').map((oSpay) => oSpay.trim());
      setUpdatePh3Pay({
        ...updatePh3Pay,
        oSuppPay: [...updatePh3Pay.oSuppPay, ...newOSpay],
      });
      setInitOSpay('');
    }
  };

  const removeOSPay = (oSpayToRemove: string) => {
    setUpdatePh3Pay({
      ...updatePh3Pay,
      oSuppPay: updatePh3Pay.oSuppPay.filter((oSpay) => oSpay !== oSpayToRemove),
    });
  };

  const handleChangeUpdateOSPay = (e: any) => {
    setInitOSpay(e.target.value);
  };

  // Handle Others Suppliment Pay

  // Handle Experience Range Start
  const expRange1Nameoptions = ListExpRange1.map((expR1) => ({
    value: expR1.value,
    label: expR1.label,
  }));

  const selectedExpRange1Nameoptions = expRange1Nameoptions && expRange1Nameoptions.find(
    (option) => (option.value === updatePh3Pay.expR1),
  );

  const expRange2Nameoptions = ListExpRange2.map((expR2) => ({
    value: expR2.value,
    label: expR2.label,
  }));

  const selectedExpRange2Nameoptions = expRange2Nameoptions && expRange2Nameoptions.find(
    (option) => (option.value === updatePh3Pay.expR2),
  );

  const handleSelectExpRange1Data = (selectedOption: any) => {
    if (selectedOption) {
      const updatedExpR1 = selectedOption.value;
      // Check if expR2 is already set and is less than or equal to the newly selected expR1
      if (updatePh3Pay.expR2 && updatePh3Pay.expR2 <= updatedExpR1) {
        setErrorShowAlertRange(true);
        setAlertRangeMsg('Experience range is not correct');
      } else {
        // Update expR1 if conditions are met
        setUpdatePh3Pay({
          ...updatePh3Pay,
          expR1: updatedExpR1,
        });
      }
    }
  };

  const handleSelectExpRange2Data = (selectedOption: any) => {
    if (selectedOption) {
      const updatedExpR2 = selectedOption.value;
      // Check if expR1 is already set and expR2 is greater than expR1
      if (updatePh3Pay.expR1 && updatedExpR2 <= updatePh3Pay.expR1) {
        setErrorShowAlertRange(true);
        setAlertRangeMsg('Experience range is not correct');
      } else {
        // Update expR2 if conditions are met
        setUpdatePh3Pay({
          ...updatePh3Pay,
          expR2: updatedExpR2,
        });
      }
    }
  };
  // Handle Experience Range End

  const handleKeyPress = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' && initOPerk.trim()) {
      e.preventDefault();
      const newPerks = initOPerk.split(',').map((perk) => perk.trim());
      setUpdatePh3Pay({
        ...updatePh3Pay,
        oPerk: [...updatePh3Pay.oPerk, ...newPerks],
        // oPerk: '', // Clear input after adding
      });
      setInitOPerk('');
    }
  };

  const removePerk = (perkToRemove: string) => {
    setUpdatePh3Pay({
      ...updatePh3Pay,
      oPerk: updatePh3Pay.oPerk.filter((perk) => perk !== perkToRemove),
    });
  };

  const handleChangeUpdatePerk = (e: any) => {
    // const { value, name } = e.target;
    setInitOPerk(e.target.value);
    // setUpdatePh3Pay({
    //   ...updatePh3Pay,
    //   [name]: value,
    // });
  };

  // Handle Multiple Perk

  return (
    <div className="my-1">
      {showErrorsAlertRange ? (
        errorAlert(false, alertRangeMsg, showErrorsAlertRange, setErrorShowAlertRange)
      ) : null}
      <Form className="job-add-card" onSubmit={handleUpdatePhase3Pay}>
        <div className="my-2 mb-3 align-center">
          <span className="text-lite-grey-sub">Add Pay & Benefits</span>
        </div>
        <Row className="align-center pt-5">
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <div className={updatePh3Pay.payBy !== '' ? 'cx-jobs-dashed-card' : ''}>
                <span className="text-lite ml-1 align-start">Filter Pay Structure</span>
                <Select
                  value={updatePh3Pay.payBy === '' ? null : selectedPayNameOptions}
                  options={payNameoptions}
                  onChange={handleSelectPayData}
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '7px',
                      padding: 1,
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  noOptionsMessage={() => 'No Pay Structure Found'}
                  placeholder="Select Pay Structure..."
                />
                {updatePh3Pay.payBy !== ''
                  ? (
                    <Col xs="12" lg="12">
                      <div className="pt-4 mx-1">
                        <Row className="pt-1">
                          <Col xs="12" lg="7">
                            <div className="pt-1">
                              <span className="text-lite ml-1 align-start">Amount</span>
                              <Input
                                placeholder="Enter Amount"
                                type="text"
                                name="amnt"
                                value={updatePh3Pay.amnt}
                                onChange={handleChangeUpdatePay}
                                className="campus-input"
                              />
                            </div>
                          </Col>
                          <Col xs="12" lg="5">
                            <div className="pt-4">
                              <Select
                                value={selectedAmntNameOptions}
                                options={amountNameoptions}
                                onChange={handleSelectAmountTypeData}
                                required
                                styles={{
                                  control: (base: any) => ({
                                    ...base,
                                    '&:hover': { borderColor: '#8B0000' },
                                    border: '1px solid #DDE0E4',
                                    minHeight: '50px',
                                    borderRadius: '5px',
                                    padding: 1,
                                    // width: '130px',
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    color: '#575656',
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    '&:focus': {
                                      borderColor: '#E1EDF8',
                                      boxShadow: 'none',
                                      color: '#575656',
                                      background: '#fff',
                                    },
                                  }),
                                  option: (provided: any, state: { isSelected: any; }) => ({
                                    ...provided,
                                    color: state.isSelected ? '#383838' : '#212121',
                                    padding: 15,
                                    textAlign: 'left',
                                    background: state.isSelected ? '#FAFCFB' : '#fff',
                                    '&:hover': {
                                      background: '#FAFCFB',
                                    },
                                  }),
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {updatePh3Pay.amnt !== ''
                        ? <span className="text-lite pt-2 align-start ml-2">{numberToWords(parseInt(updatePh3Pay.amnt))} / { AmountTpye.find((amntTp) => amntTp.value === updatePh3Pay.amntTp)?.label || ''}</span>
                        : null}
                    </Col>
                  ) : null}
              </div>
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <div className={isSuppPay ? 'cx-jobs-dashed-card' : ''}>
                <span className="text-lite ml align-start">Suplementary Pay</span>
                <Select
                  options={suppPayNameoptions}
                  onChange={handleAllSuppPay}
                  value={handleSelectSuppPayType()}
                  isMulti
                  styles={{
                    multiValue: (provided: any) => ({
                      ...provided,
                      background: '#f6eeeb',
                      padding: '1px',
                    }),
                    menu: (base) => ({
                      ...base,
                      position: 'relative',
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#F6B26B' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 1,
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  noOptionsMessage={() => 'No Supplemental Payment Found'}
                  placeholder="Enter Supplemental Payment"
                />
                {isSuppPay
                  ? (
                    <div className="pt-2 mx-1 margin-state-desk">
                      <span className="text-lite ml-1 align-start">Other Supplemental Payment</span>
                      <Input
                        placeholder="Enter Additional Supplemental Pay"
                        type="text"
                        name="initOSpay"
                        value={initOSpay}
                        onChange={handleChangeUpdateOSPay}
                        onKeyDown={handleKeyPressOSPay}
                        className="campus-input"
                      />
                      <span className="text-lite ml-1 align-start">Press Enter after typing to add supplimental pay</span>

                      <div className="mt-3 align-start badge-container">
                        {updatePh3Pay.oSuppPay.map((oSpay, index) => (
                          <Badge
                            key={`${index.toString()}`}
                            pill
                            className="chip-box mx-1 my-1"
                            onClick={() => removeOSPay(oSpay)}
                            style={{ cursor: 'pointer' }}
                          >
                            {oSpay} &times;
                          </Badge>
                        ))}
                      </div>
                    </div>
                  ) : null}
              </div>
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <div className={isOPerk ? 'cx-jobs-dashed-card' : ''}>
                <span className="text-lite ml align-start">Additional Perk</span>
                <Select
                  options={perkNameoptions}
                  onChange={handleAllPerk}
                  value={handleSelectPerkType()}
                  isMulti
                  styles={{
                    multiValue: (provided: any) => ({
                      ...provided,
                      background: '#f6eeeb',
                      padding: '1px',
                    }),
                    menu: (base) => ({
                      ...base,
                      position: 'relative',
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#F6B26B' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 1,
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  noOptionsMessage={() => 'No Additional Perk Found'}
                  placeholder="Enter Additional Perk"
                />
                {isOPerk
                  ? (
                    <div className="pt-2 mx-1 margin-state-desk">
                      <span className="text-lite ml-1 align-start">Other Perk</span>
                      <Input
                        placeholder="Enter Additional Perk"
                        type="text"
                        name="initOPerk"
                        value={initOPerk}
                        onChange={handleChangeUpdatePerk}
                        onKeyDown={handleKeyPress}
                        className="campus-input"
                      />
                      <span className="text-lite ml-1 align-start">Press Enter after typing to add the desired perk</span>

                      <div className="mt-3 align-start badge-container">
                        {updatePh3Pay.oPerk.map((perk, index) => (
                          <Badge
                            key={`${index.toString()}`}
                            pill
                            className="chip-box mx-1 my-1"
                            onClick={() => removePerk(perk)}
                            style={{ cursor: 'pointer' }}
                          >
                            {perk} &times;
                          </Badge>
                        ))}
                      </div>
                    </div>
                  ) : null}
              </div>
            </div>
            <div className="pt-2" />
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 align-start">
              <span className="text-lite-grey-sub">What range of experience are you seeking?</span>
            </div>
            <div className="align-start">
              <div className="pt-4 mr">
                <span className="text-lite align-start">Max Experience</span>
                <Select
                  value={selectedExpRange2Nameoptions}
                  options={expRange2Nameoptions}
                  onChange={handleSelectExpRange2Data}
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '40px',
                      borderRadius: '5px',
                      padding: 1,
                      width: '130px',
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                />
              </div>
              <div className="pt-4 ml-1">
                <span className="text-lite align-start">Minimum Required</span>
                <Select
                  value={selectedExpRange1Nameoptions}
                  options={expRange1Nameoptions}
                  onChange={handleSelectExpRange1Data}
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '40px',
                      borderRadius: '5px',
                      padding: 1,
                      width: '130px',
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                />
              </div>
            </div>
            <div className="pt-3" />
            <hr className="hr-dasshed-lite" />
          </Col>
          <Col xs="6" lg="5">
            {/* <div className="align-start mx-2">
              <Button className="button-transparent-jobs mr-2" onClick={handleBackToPhase2}>
                <FontAwesomeIcon icon={faArrowLeft} className="mr" />
                Back
              </Button>
            </div> */}
          </Col>
          <Col xs="6" lg="5">
            <div className="align-end mx-2">
              <Button className="button-transparent">
                Continue
                <FontAwesomeIcon icon={faArrowRight} className="ml" />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="pt-4" />
    </div>
  );
};

export default UpdateJobPhase3;
