import React from 'react';
import {
  CardImg, Col, Nav, NavItem, NavLink, Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { JobCompTypeProps } from '../../../recruiter.type';
import { AddJobPhaseComponent } from './create-job-phase/phase.jobs.component';
import { successAlert, errorAlert } from '../../../../../../../utils/alert';
import LoaderData from '../../../../../../../utils/loader';

export const AddJobComponents: React.FC<JobCompTypeProps> = ({
  reqClientId,
  tokenData,
  addJobRes,
  setAddJobRes,
  jobPhStatus,
  // setJobPhStatus,
  addNewJob,
  setAddNewJob,
  updatePh2Job,
  setUpdatePh2Job,
  apiReqTp,
  setApiReqTp,
  updatePh3Pay,
  setUpdatePh3Pay,
  updatePh4JD,
  setUpdatePh4JD,
  updatePh5Pref,
  setUpdatePh5Pref,
  previewPh6,
  setPreviewPh6,
  setLoadingHomeJob,
  setOperationTp,
}) => {
  const [activeTab, setActiveTab] = React.useState('add-new');
  const [loadingJob, setLoadingJob] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');

  return (
    <div className="pt-3">
      { loadingJob ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="">
        <Col xs="12" lg="12">
          <Row className="my-1">
            <Col lg={{ size: 12, order: 1 }} xs={{ order: 1 }}>
              <div className="recruit-job-bg my-1">
                <div className="align-start my-1">
                  <Nav tabs className="campus-onboard-tab">
                    <div className="pt-1">
                      <div className="campus-column-onboard-tab-left">
                        <NavItem className="cursor-pointer">
                          <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'add-new'} onClick={() => setActiveTab('add-new')}>
                            <div className="align-center">
                              <CardImg src="/images/icons/add-job.png" className="campus-tab-onboard-icon-img" alt="Icon 1" />
                            </div>
                            <div className="align-center">
                              <span className="campus-tab-text mx-1">Create New Job</span>
                            </div>
                          </NavLink>
                        </NavItem>
                      </div>
                      {/* <div className="campus-column-onboard-tab-right">
                        <NavItem className="cursor-pointer">
                          <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'add-existing'} onClick={() => setActiveTab('add-existing')}>
                            <div className="align-center">
                              <CardImg src="/images/icons/template.png" className="campus-tab-onboard-icon-img" alt="Icon 1" />
                            </div>
                            <div className="align-center">
                              <span className="campus-tab-text mx-1">Use Template</span>
                            </div>
                          </NavLink>
                        </NavItem>
                      </div> */}
                    </div>
                  </Nav>
                </div>
                <div className="my-1">
                  <TabContent activeTab={activeTab}>
                    {activeTab === 'add-new' && (
                    <TabPane tabId="add-new">
                      {/* <div className="align-start ml-1 pt-2">
                        <span className="text-lite-dark">Add New Job</span>
                      </div> */}
                      <AddJobPhaseComponent
                        reqClientId={reqClientId}
                        setLoadingJob={setLoadingJob}
                        setSuccessShowAlert={setSuccessShowAlert}
                        setErrorShowAlert={setErrorShowAlert}
                        setAlertMsg={setAlertMsg}
                        tokenData={tokenData}
                        activeTab={activeTab}
                        addJobRes={addJobRes}
                        setAddJobRes={setAddJobRes}
                        jobPhStatus={jobPhStatus}
                        // setJobPhStatus={setJobPhStatus}
                        addNewJob={addNewJob}
                        setAddNewJob={setAddNewJob}
                        updatePh2Job={updatePh2Job}
                        setUpdatePh2Job={setUpdatePh2Job}
                        apiReqTp={apiReqTp}
                        setApiReqTp={setApiReqTp}
                        updatePh3Pay={updatePh3Pay}
                        setUpdatePh3Pay={setUpdatePh3Pay}
                        updatePh4JD={updatePh4JD}
                        setUpdatePh4JD={setUpdatePh4JD}
                        updatePh5Pref={updatePh5Pref}
                        setUpdatePh5Pref={setUpdatePh5Pref}
                        previewPh6={previewPh6}
                        setPreviewPh6={setPreviewPh6}
                        setLoadingHomeJob={setLoadingHomeJob}
                        setOperationTp={setOperationTp}
                      />
                    </TabPane>
                    )}
                    {/* {activeTab === 'add-existing' && (
                    <TabPane tabId="add-existing">
                      <div className="text-line">Use Existing Template</div>
                      use Template
                    </TabPane>
                    )} */}
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddJobComponents;
