import { GetStaffDashResponse, GetStaffDashResults } from './get.staff.dashboard.service.types';

export const GetStaffDashTransform: (response: GetStaffDashResponse) => GetStaffDashResults = (getStaffDashResults) => {
  const { data, is_error, message } = getStaffDashResults;
  const result: GetStaffDashResults = {
    data: {
      jobPending: 0,
      jobLive: 0,
      jobBlock: 0,
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
