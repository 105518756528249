import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import jwt_decode from 'jwt-decode';
import {
  Modal, Col, Row, Button, UncontrolledTooltip, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, Popover, PopoverBody,
  CardImg,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// faCircleUser
import {
  faBars, faBell, faEnvelope, faGear, faUserLock, faXmark, faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import {
  Routes, Route, NavLink,
} from 'react-router-dom';
import {
  clearKeysFromLocalStorage, getTokenFromLocalStorage, getUserProfileLocalStorage,
  setUserProfileLocalStorage,
} from '../../utils/service/localstorage-service';
import { CXUnauthorized } from '../unauthorized/unauthorized.component';
import './exclusive.scss';
import { PasskeyUpdate } from '../../components/exclusive/common/passkey-reset/passkey.component';
import { ModalPasskeyUpdate } from '../../components/exclusive/common/passkey-reset/modal-reset-passkey.component';
import { SuperAdminDashboard } from '../../components/exclusive/cx-admin/dashboard/dashboard.component';
import { ComingSoon } from '../unauthorized/coming-soon.component';
import { SuperAdminAboutMe } from '../../components/exclusive/cx-admin/about-me/about-me.component';
import { OnboardComponent } from '../../components/exclusive/cx-admin/onboarding/onboard.component';
import { SideSuperAdminDrawer } from '../../components/exclusive/common/sidebar/sidebar.sadmin';
import { FileDetails } from '../../services/common/get-s3-files/get-s3files.types';
import { getProfile, getS3FilesReq } from '../../store/campusXcel/actions';
import { UserType, APIHeader } from '../../utils/constants';
import { getConfig } from '../../utils/config/config';
import { errorAlert } from '../../utils/alert';

const { AWS_CXUSER_BUCKET } = getConfig();

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<SuperAdminDashboard />} />
    <Route path="profile" element={<SuperAdminAboutMe />} />
    <Route path="onboarding" element={<OnboardComponent />} />
    <Route path="passkey" element={<PasskeyUpdate uTp="CXADM" />} />
    <Route path="calendar" element={<ComingSoon />} />
  </Routes>
);

export const SuperAdminPrivateComponent: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [, setEmailId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileLocalStorage();
  const [userId, setUserId] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [avatarSrc, setAvatarSrc] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);
  const getAvatarResponse = useSelector((state: RootState) => state.campusXcel.getS3Files);
  const loginProfileResponse = useSelector((state: RootState) => state.campusXcel.getProfileData);
  const [clickCXComponent, setClickCXComponent] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [resetPassKey, setResetPassKey] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [openMNotification, setOpenMNotification] = useState(false);
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [notifDesc, setNotifDesc] = useState('');
  const [showFooter, setShowFooter] = useState(false);
  const passKeyModal = () => setResetPassKey(!resetPassKey);
  // const path = location.pathname.split('/');

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  React.useEffect(() => {
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      if (decodedToken !== undefined || decodedToken !== null) {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          setIsTokenExpired(true);
          clearKeysFromLocalStorage();
          history('/sadmin/login', { replace: true });
        }
      }
    }
  }, [tokenData, history]);

  // React.useEffect(() => {
  //   if (getProfileUrl !== '') {
  //     setImageUrl(JSON.parse(getProfileUrl));
  //   }
  // }, []);

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  const menuCloseIconClick = () => {
    setMenuCollapse(false);
  };

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClickCXComponent(!clickCXComponent);
  };
  const handleNotification = (notif: string) => {
    setMenuCollapse(false);
    if (notif === 'avatar') {
      setNotifDesc('avatar');
    } else if (notif === 'passkey') {
      setNotifDesc('passkey');
    }
    setOpenNotification(!openNotification);
  };

  const handleMobileNotification = () => {
    setOpenMNotification(!openMNotification);
  };

  React.useEffect(() => {
    if (tokenData !== '' && !isTokenExpired) {
      dispatch(getProfile({
        uTp: UserType.CXADM,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: tokenData,
      }));
    }
  }, [tokenData, isTokenExpired]);

  React.useEffect(() => {
    if (tokenData !== '' && isTokenExpired) {
      setErrorShowAlert(true);
      setAlertMsg(loginProfileResponse.message);
      history('/sadmin/login');
      setIsTokenExpired(false);
    }
  }, [tokenData, loginProfileResponse.message, isTokenExpired]);

  React.useEffect(() => {
    if (loginProfileResponse.profileData.eId !== '' && tokenData !== '' && !isTokenExpired) {
      setUserProfileLocalStorage(JSON.stringify(loginProfileResponse));
      setFirstName(loginProfileResponse.profileData.fNme);
      setLastName(loginProfileResponse.profileData.lNme);
      setEmailId(loginProfileResponse.profileData.eId);
      setIsTokenExpired(false);
    }
  }, [loginProfileResponse.profileData.eId, tokenData, isTokenExpired]);

  // Getting Avatar from S3 Start
  React.useEffect(() => {
    setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    setUserTp(userProfile && JSON.parse(userProfile).profileData.UserTp);
  }, [userProfile && JSON.parse(userProfile).profileData.UserCd, userProfile && JSON.parse(userProfile).profileData.UserTp]);

  React.useEffect(() => {
    if (userTp !== '' && userId !== '') {
      dispatch(getS3FilesReq({
        requestType: '',
        bucketNme: AWS_CXUSER_BUCKET,
        folderName: `avatars/${userTp}-${userId}.png`,
        identifierTp: 'avatar',
      }));
    }
  }, [userTp, userId]);

  React.useEffect(() => {
    if (getAvatarResponse.data.length !== 0) {
      setAvatarSrc(getAvatarResponse.data);
    }
  }, [getAvatarResponse.data]);
  // Getting Avatar from S3 End

  return (
    <div className="cx-exclusive-bg">
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {tokenData !== '' ? (
        <div>
          {userProfile && JSON.parse(userProfile).profileData.isPKReset ? (
            <Modal isOpen={resetPassKey} className="my-modal-container-likes">
              <Row>
                <Col lg="9" xs="8">
                  <div className="my-2 align-start ml-2">
                    <p>Update Password</p>
                  </div>
                </Col>
                <Col lg="3" xs="4">
                  <div className="align-end">
                    <Button className="button-icon" onClick={passKeyModal}>
                      <FontAwesomeIcon className="close-button" icon={faXmarkSquare} />
                      <span className="text-lite-grey-date mx-1">Close</span>
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="pt-1">
                <ModalPasskeyUpdate setIsResetPasskey={setIsResetPasskey} uTp="CXADM" />
              </div>
            </Modal>
          )
            : null}
          <div className="campus-display-device">
            <SideSuperAdminDrawer
              menuCollapse={menuCollapse}
              menuIconClick={menuIconClick}
              notifDesc={notifDesc}
              menuCloseIconClick={menuCloseIconClick}
              firstName={firstName}
              lastName={lastName}
              avatarSrc={avatarSrc}
            />
            <Row className="campus-exclusive-header">
              <Col lg="12" className="campus-float-right mx-2">
                <Button color="default" id="useremails"><FontAwesomeIcon icon={faEnvelope} className="campus-header-icon" /></Button>
                <UncontrolledTooltip
                  target="useremails"
                >
                  Emails
                </UncontrolledTooltip>
                <Button color="default" id="notifications"><FontAwesomeIcon icon={faBell} className="campus-header-icon" /></Button>
                <UncontrolledTooltip
                  target="notifications"
                >
                  Notifications
                </UncontrolledTooltip>
                <Button className="button-icon" id="passkeyDeskId" onClick={() => handleNotification('')}><FontAwesomeIcon icon={faGear} className="campus-header-icon" /></Button>
                {/* <UncontrolledTooltip
                      target="passkeyDeskId"
                      placement="top"
                    >
                      Settings
                    </UncontrolledTooltip> */}
                <CardImg className="campus-image-logo" src="/campusXcel.png" alt="cxLogo" />
                <Popover placement="bottom" isOpen={openNotification} target="passkeyDeskId" toggle={() => handleNotification('')}>
                  <PopoverBody>
                    <Nav>
                      <ul className="menu-list">
                        {/* <NavItem className="menu-list">
                              <NavLink to="avatar" onClick={() => handleNotification('avatar')} className="menu-link-notification">
                                <FontAwesomeIcon className="campus-notification-icon" icon={faCircleUser} />
                                <span className="campus-notification-txt mx-2">Avatar</span>
                              </NavLink>
                            </NavItem> */}
                        <NavItem className="menu-list">
                          <NavLink to="passkey" onClick={() => handleNotification('passkey')} className="menu-link-notification">
                            <FontAwesomeIcon className="campus-notification-icon" icon={faUserLock} />
                            <span className="campus-notification-txt mx-2">Password</span>
                          </NavLink>
                        </NavItem>
                      </ul>
                    </Nav>
                  </PopoverBody>
                </Popover>
              </Col>
            </Row>
            <Row className="align-start">
              <Col lg="12">
                <div className="campus-exclusive-margin-left">
                  <ExclusiveRoutes />
                </div>
                <div className="pt-5" />
              </Col>
            </Row>
          </div>
          <div className="campus-display-device-mobile">
            <Navbar className="sticky-top campus-nav-bg">
              <NavbarBrand href="#" className="pt-1 margin-logo">
                <CardImg className="campus-image-logo-mobile ml" src="/campusXcel.png" alt="cxLogo" />
              </NavbarBrand>
              <NavbarToggler className="campus-toggle align-center mr" onClick={handleClick}>
                <CardImg
                  alt="..."
                  className="img-fluid rounded-circle shadow-lg campus-avatar-user"
                  src={avatarSrc[0].url !== '' ? avatarSrc[0].url : '/images/common/avatar.png'}
                />
                {changeIcon ? <FontAwesomeIcon icon={faXmark} className="campus-close-btn-mobile" /> : <FontAwesomeIcon icon={faBars} className="campus-open-btn-mobile" />}
              </NavbarToggler>
              <Collapse isOpen={clickCXComponent} navbar className="sadmin-collapse-bg">
                <Nav className="ml-auto">
                  <NavItem className="mptb campus-link-txt-border mx-3 pt-3 my-2">
                    <NavLink to="dashboard" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <CardImg className="menu-img-cx" src="/images/icons/dashboard.png" alt="dash" />
                      <span className="sadmin-link-txt ml">Dashboard</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3 my-2">
                    <NavLink to="onboarding" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <CardImg className="menu-img-cx" src="/images/icons/onboard.png" alt="onboarding" />
                      <span className="sadmin-link-txt ml">Onboarding</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3 my-2">
                    <NavLink to="mentorship" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <CardImg className="menu-img-cx" src="/images/icons/mentorship.png" alt="mentor" />
                      <span className="sadmin-link-txt ml">Mentorship</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3 my-2">
                    <NavLink to="calendar" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <CardImg className="menu-img-cx" src="/images/icons/schedule.png" alt="sche" />
                      <span className="sadmin-link-txt ml">Calendar</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3 my-2">
                    <NavLink to="profile" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <CardImg className="menu-img-cx" src="/images/icons/my-profile.png" alt="prof" />
                      <span className="sadmin-link-txt ml">My Profile</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className="align-center my-4">
                  <Button onClick={() => { clearKeysFromLocalStorage(); clearKeysFromLocalStorage(); window.location.href = '/sadmin/login'; }} className="button-border">
                    <CardImg className="menu-img-cx" src="/images/icons/logout.png" alt="dash" /> Logout
                  </Button>
                </div>
              </Collapse>
            </Navbar>
            <Row>
              <Col lg="12" className="margin-mob-all">
                <ExclusiveRoutes />
              </Col>
              <div className="pt-5" />
            </Row>
            <footer className={showFooter ? '' : 'footer-visible'}>
              <Popover placement="top" isOpen={openMNotification} target="passkeyMId" toggle={handleMobileNotification}>
                <PopoverBody>
                  <Nav>
                    <ul className="menu-list">
                      {/* <NavItem className="menu-list">
                        <NavLink to="avatar" onClick={handleMobileNotification} className="menu-link-notification">
                          <FontAwesomeIcon className="campus-notification-icon" icon={faCircleUser} />
                          <span className="campus-notification-txt mx-2">Avatar</span>
                        </NavLink>
                      </NavItem> */}
                      <NavItem className="menu-list pt-2">
                        <NavLink to="passkey" onClick={handleMobileNotification} className="menu-link-notification">
                          <FontAwesomeIcon className="campus-notification-icon" icon={faUserLock} />
                          <span className="campus-notification-txt mx-2">Password</span>
                        </NavLink>
                      </NavItem>
                    </ul>
                  </Nav>
                </PopoverBody>
              </Popover>
              <Row>
                <Col xs="3" />
                <Col xs="2">
                  <Button className="button-icon" id="useremails"><FontAwesomeIcon icon={faEnvelope} className="campus-header-icon" /></Button>
                  <UncontrolledTooltip
                    target="useremails"
                  >
                    Emails
                  </UncontrolledTooltip>
                </Col>
                <Col xs="2">
                  <Button className="button-icon" id="notifications"><FontAwesomeIcon icon={faBell} className="campus-header-icon" /></Button>
                  <UncontrolledTooltip
                    target="notifications"
                  >
                    Notifications
                  </UncontrolledTooltip>
                </Col>
                <Col xs="2">
                  <Button className="button-icon" id="passkeyMId" onClick={handleMobileNotification}><FontAwesomeIcon icon={faGear} className="campus-header-icon" /></Button>
                </Col>
                <Col xs="3" />
              </Row>
            </footer>
          </div>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default SuperAdminPrivateComponent;
