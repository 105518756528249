import { XcelianResumePreviewResponse, XcelianResumePreviewResults } from './xcelian.preview.service.types';

export const XcelianResumePreviewTransform: (response: XcelianResumePreviewResponse) => XcelianResumePreviewResults = (resumeObjResults) => {
  const { data, is_error, message } = resumeObjResults;
  const result :XcelianResumePreviewResults = {
    resumeData: {
      dob: '',
      gnd: '',
      oGnd: '',
      pCd: '',
      city: '',
      resCnt: 0,
      st: '',
      addr: '',
      resSkills: [],
      obj: '',
      covL: '',
      vidUrl: '',
      orgData: [],
      eduData: [],
      certData: [],
      langData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
    result.resumeData = data;
  }
  return result;
};
