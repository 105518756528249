import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Button,
  Col, Row,
} from 'reactstrap';
import './likes.scss';
import { NavLink } from 'react-router-dom';
import { LikeProps } from './likes.type';
import { addFollowReq } from '../../../../../../store/campusXcel/actions';
import { formatDate } from '../../../../../../utils';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../../utils/service/localstorage-service';

const LikesComponent: React.FC<LikeProps> = ({ listUserLikes }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  // const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const followResponse = useSelector((state: RootState) => state.campusXcel.addFollowData);
  const handleSubmitFollow = (folowCd:string, followTp:string) => {
    dispatch(addFollowReq({
      requestType: APIHeader.REQ_ADD_FOLLOWER,
      uTp: UserType.CMPS,
      token: tokenData,
      folwUsrCd: folowCd,
      folwUsrTP: followTp,
    }));
  };

  React.useEffect(() => {
    if (followResponse.error && followResponse.message !== '') {
      setErrorShowAlert(true);
    }
  }, [followResponse.error, followResponse.message]);

  // React.useEffect(() => {
  //   if (!followResponse.error && followResponse.message !== '') {
  //     setSuccessShowAlert(true);
  //   }
  // }, [followResponse.error, followResponse.message]);

  return (
    <div className="mx-1">
      {listUserLikes.likeUserData.map((likeUserData, indx) => (
        <Row key={likeUserData.userId + indx.toString()} className="pt-1">
          {showErrorsAlert ? <span className="wrong-pass-key">Some Error Occured. Try again !!!</span> : null}
          <Col xs="12">
            <div style={{ display: 'flex' }}>
              <div>
                {likeUserData.avtr === ''
                  ? (
                    <img
                      alt="..."
                      className="cx-avatar-user-small"
                      src="/images/common/avatar.png"
                    />
                  )
                  : (
                    <img
                      alt="..."
                      className="cx-avatar-user-small"
                      src={likeUserData.avtr}
                    />
                  ) }
              </div>
              <div className="ml-1">
                <div className="padding-zero">
                  <NavLink to={likeUserData.prof} className="dashboard-link" target="_blank">
                    <span className="dashboard-post-text">
                      {likeUserData.nme}
                    </span>
                  </NavLink>
                  {likeUserData.isSlfFolw ? null
                    : likeUserData.isFlng
                      ? (
                        <>
                          <span className="text-lite-grey-comments ml">|</span>
                          <Button className="button-icon mb-1" onClick={() => handleSubmitFollow(likeUserData.userId, likeUserData.userTp)} disabled>
                            <span className="dashboard-follow-text">+ Following</span>
                          </Button>
                        </>
                      )
                      : (
                        <>
                          <span className="text-lite-grey-comments mx-2">|</span>
                          <Button className="button-icon" onClick={() => handleSubmitFollow(likeUserData.userId, likeUserData.userTp)}>
                            <span className="dashboard-follow-text mb">+ Follow</span>
                          </Button>
                        </>
                      )}
                </div>
                <div>
                  <span className="dashboard-sub-post-text">
                    {likeUserData.crs !== '' ? `${likeUserData.crs}, ` : null}{likeUserData.strm !== '' ? likeUserData.strm : null}
                  </span>
                </div>
                <div>
                  <span className="dashboard-sub-post-text">
                    {likeUserData.sYr !== '' && likeUserData.eYr !== '' ? `Batch of ${likeUserData.sYr} - ${likeUserData.eYr},  ` : null}
                    {likeUserData.folwCnt} Followers
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <div className="align-end">
            <span className="text-lite-grey-date">{formatDate(likeUserData.updtOn)}</span>
          </div>
          {/* <Col lg="2" md="3" xs="2">
            <div className="align-center pt-1">
              {likeUserData.avtr === ''
                ? (
                  <img
                    alt="..."
                    className="cx-avatar-user-small"
                    src="/images/common/avatar.png"
                  />
                )
                : (
                  <img
                    alt="..."
                    className="cx-avatar-user-small"
                    src={likeUserData.avtr}
                  />
                ) }
            </div>
          </Col>
          <Col lg="7" md="6" xs="6">
            <div className="pt-1">
              <span className="dashboard-post-text">{likeUserData.fNme}{' '}{likeUserData.lNme}</span>
            </div>
            <div>
              <span className="dashboard-sub-post-text">Admin, Faculty of Science, {likeUserData.folwCnt} Followers</span>
            </div>
          </Col> */}
          <hr className="dashboard-hr-color" />
        </Row>
      ))}
    </div>
  );
};
export default LikesComponent;
