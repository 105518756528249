// components/CustomTable.tsx
import React from 'react';
import {
  Button, Col, Row,
} from 'reactstrap';
import './xcelian.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { formatUserDate } from '../../../../../../utils/constants';

interface TableColumn {
  header: string;
  accessor: string;
}

interface TableProps {
  tableId: string;
  columns: TableColumn[];
  data: any[];
  currentPage: number;
  recordsPerPage: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  handleEditData: (userType: string, userCode: string, crntSts: string, uName:string) => void;
}

const XcelianTable: React.FC<TableProps> = ({
  tableId,
  columns,
  data,
  currentPage,
  recordsPerPage,
  totalRecords,
  onPageChange,
  handleEditData,
}) => {
  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);

  return (
    <div className="table-container">
      {data && data.length > 0
        ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={`${index.toString()}`}>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={`${index.toString()}`}>
                    {columns.map((column, colIndex) => (
                      <td key={`${colIndex.toString()}`}>
                        {column.accessor
                          ? row[column.accessor]
                          : (
                            <>
                              {column.header === 'Action' && tableId === 'xcelianUser' && (
                              <div className="align-center">
                                <Button disabled={!row.isAcs && row.lgnSts === 'NACC'} onClick={() => handleEditData(row.UserTp, row.UserCd, 'BLCK', `${row.fNme} ${row.lNme}`)} className="admin-xcelian-button-blocked">
                                  BLOCK
                                </Button>
                                <Button disabled={row.isAcs && row.lgnSts === 'APPR'} onClick={() => handleEditData(row.UserTp, row.UserCd, 'APPR', `${row.fNme} ${row.lNme}`)} className="admin-xcelian-button-approve ml">
                                  APPROVE
                                </Button>
                              </div>
                              )}
                              {column.header === 'Name' && (
                              <div className="align-center">
                                {`${row.fNme} ${row.lNme}`}
                              </div>
                              )}
                              {column.header === 'Phone' && (
                              <div className="align-center">
                                {`${row.dlCd}-${row.ph}`}
                              </div>
                              )}
                              {column.header === 'Updated On' && (
                              <div className="align-center">
                                {`${formatUserDate(row.updtOn)}`}
                              </div>
                              )}
                              {column.header === 'Login Status' && (
                              <div className="align-center">
                                {row.lgnSts === 'APPR' && row.isAcs
                                  ? <FontAwesomeIcon className="access-pass" icon={faCheckCircle} />
                                  : <FontAwesomeIcon className="access-blocked" icon={faBan} /> }
                              </div>
                              )}
                            </>
                          )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Row>
              <Col xs="5" lg="5">
                <div className="align-start my-2">
                  <span className="text-lite">Total Records: {totalRecords}</span>
                </div>
              </Col>
              <Col xs="7" lg="7">
                <div className="align-end my-2">
                  <Button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                    className="table-button"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                  <div className="mx-2 table-margin-bot">
                    <span className="table-text">
                      {currentPage + 1} / {totalPages}
                    </span>
                  </div>
                  <Button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage >= totalPages - 1}
                    className="table-button"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="table-norecords">
            <span className="table-text-no">No Record Found</span>
          </div>
        )}
    </div>
  );
};

export default XcelianTable;
