import React from 'react';
import Navbar from '../navbar/cx.navbar';
import './market.scss';
import { QallAudit } from './qall-audit/qallAudit.home';

export const MarketPlaceComponent: React.FC = () => (
  <div className="campusXcel-bg-component">
    <Navbar />
    <div className="align-center header-market-one">
      <span className="text-intro-header-market text-center-aligned">
        Marketplace
      </span>
    </div>
    <div className="my-2">
      <QallAudit />
    </div>
  </div>
);

export default MarketPlaceComponent;
