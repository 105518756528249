import { DELClientResponse, DELClientResults } from './del-client.service.types';

export const DELClientTransform: (response: DELClientResponse) => DELClientResults = (passKeyResults) => {
  const { is_error, message } = passKeyResults;
  const result :DELClientResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};
