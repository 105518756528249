import React from 'react';
import {
  Row, Col, Card, CardImg,
  Modal,
} from 'reactstrap';
import { useParams, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGears, faDotCircle, faBriefcase, faCircleDot, faCertificate, faGlobe, faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import { LoaderData } from '../../../utils/loader';
import { clearXcelianPreviewResume, getLoginToken, getXcelianShareResume } from '../../../store/campusXcel/actions';
import { errorAlert } from '../../../utils/alert';
import { UserType, APIHeader, formatUserDateNoHr } from '../../../utils/constants';
// import DownloadTemplate1 from '../../exclusive/xcelian/my-resume/template/template1/download';
// import myProfile from '../../exclusive/xcelian/myprofile/my.profile';
import { XcelianResumeShareData } from '../../../services/staffing/xcelian/share-resume/xcelian.share.resume.types';

const EResumeShareComponent: React.FC = () => {
  const dispatch = useDispatch();
  const { resCd, xclnTp, xclnId } = useParams();
  const [passReviewCd, setPassReviewCd] = React.useState('');
  const [loadingResume, setLoadingResume] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const [zoomCert, setZoomCert] = React.useState('');
  const [previewResume, setPreviewResume] = React.useState<XcelianResumeShareData>({
    dob: '',
    gnd: '',
    oGnd: '',
    pCd: '',
    city: '',
    st: '',
    addr: '',
    resSkills: [],
    obj: '',
    covL: '',
    vidUrl: '',
    orgData: [],
    eduData: [],
    certData: [],
    langData: [],
  });
  const getResumeXcelianPreviewResponse = useSelector((state: RootState) => state.campusXcel.xcelianResumeShare);

  const handleOpenCert = (inputCert: string) => {
    setZoomCert(inputCert);
  };

  React.useEffect(() => {
    if (resCd !== '' && xclnTp !== '' && xclnId !== '') {
      setLoadingResume(true);
      dispatch(getLoginToken({
        userName: '',
        passKey: '',
        isPublic: true,
        uTp: UserType.CXAPI,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  }, [resCd, xclnTp, xclnId]);

  React.useEffect(() => {
    if (loginTokenData.isError && loginTokenData.token !== '') {
      setLoadingResume(false);
    }
  }, [loginTokenData.token, loginTokenData.isError]);

  React.useEffect(() => {
    // Ensure all necessary values are defined before proceeding
    if (resCd && xclnTp && xclnId && !loginTokenData.isError && loginTokenData.token) {
      setLoadingResume(true);
      setPassReviewCd('UPDT_PUB_RES');
      dispatch(getXcelianShareResume({
        requestType: APIHeader.REQ_INTERVIEW_XCELIAN_SHARE_URL,
        uTp: UserType.CXAPI,
        token: loginTokenData.token || '',
        xclnTp: xclnTp || '',
        xclnId: xclnId || '',
        resCd: resCd || '',
        opsMode: 'SHRDER',
      }));
    }
  }, [resCd, xclnTp, xclnId, loginTokenData.token]);

  React.useEffect(() => {
    if (getResumeXcelianPreviewResponse.isError && getResumeXcelianPreviewResponse.message !== '' && passReviewCd === 'UPDT_PUB_RES') {
      setLoadingResume(false);
      setAlertMsg(getResumeXcelianPreviewResponse.message);
      setErrorShowAlert(true);
      dispatch(clearXcelianPreviewResume());
    }
  }, [getResumeXcelianPreviewResponse.isError, getResumeXcelianPreviewResponse.message, passReviewCd]);

  React.useEffect(() => {
    if (!getResumeXcelianPreviewResponse.isError && getResumeXcelianPreviewResponse.message === 'executed' && passReviewCd === 'UPDT_PUB_RES') {
      setLoadingResume(false);
      if (typeof getResumeXcelianPreviewResponse.resumeData !== 'string') {
        setPreviewResume(getResumeXcelianPreviewResponse.resumeData);
      }
      dispatch(clearXcelianPreviewResume());
    }
  }, [getResumeXcelianPreviewResponse.isError, getResumeXcelianPreviewResponse.message, passReviewCd]);

  return (
    <div>
      { loadingResume ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <NavLink to="/" className="nav-logo">
        <CardImg
          style={{
            height: '45px', width: 'auto',
          }}
          src="/campusXcel.png"
          alt="campusXcelLogo"
        />
      </NavLink>
      <Row>
        <Col xs="12">
          {/* <div className="float-right-template-1">
            <DownloadTemplate1 previewResume={previewResume} myProfile={myProfile} />
          </div> */}
        </Col>
      </Row>
      <Card className="template-card-design">
        <Row className="mx-1 pt-3 align-center">
          <Col xs="12" lg="7">
            {/* <Row>
              <Col xs="12 ml-1" lg="12">
                <span className="template-header">{myProfile.fNme}{' '}{myProfile.lNme}</span>
              </Col>
              <Col xs="12 ml-1 pt-1">
                <FontAwesomeIcon icon={faEnvelope} className="menu-icon-cc" />
                <span className="template-desc-text ml">{myProfile.cEId}</span>
              </Col>
              <Col xs="12 ml">
                <FontAwesomeIcon icon={faPhone} className="menu-icon-cc" />
                <span className="template-desc-text ml">{myProfile.dlCd} {myProfile.cPh}</span>
              </Col>
            </Row> */}
            {previewResume.obj !== ''
              ? (
                <Row className="mx-1">
                  <Col xs="12">
                    <div className="pt-5">
                      <span className="template-header-content">Career Objective</span>
                    </div>
                  </Col>
                  <Col className="mx-1 pt-2">
                    <div className="align-text-justify">
                      <span className="template-resume-project-header">{previewResume.obj}</span>
                    </div>
                  </Col>
                </Row>
              ) : null}
          </Col>
          <Col xs="12" lg="4 pt-1">
            <ReactPlayer
              url={previewResume.vidUrl}
              controls
              width="100%"
              height="auto"
              playsinline
              config={{
                vimeo: {
                  playerOptions: {
                    fullscreen: true,
                  },
                },
              }}
            />
            <div className="align-center">
              <span className="text-lite-grey">My Video Profile</span>
            </div>
          </Col>
        </Row>
        {previewResume && previewResume.resSkills && previewResume.resSkills.length > 0
          ? (
            <Row className="mx-1">
              <Col xs="12" lg="11">
                <div className="pt-4">
                  <FontAwesomeIcon icon={faGears} className="mr template-about-text" />
                  <span className="template-header-content">Skills</span>
                </div>
              </Col>
              <Col className="mx-1 pt-2" lg="11">
                <Row>
                  {previewResume && previewResume.resSkills && previewResume.resSkills.map((skillGroup, skillGroupIndex) => (
                    <div key={`group-${skillGroupIndex.toString()}`} className="my-1">
                      <span className="template-header">
                        <FontAwesomeIcon icon={faDotCircle} className="mr xcelian-resume-project-role-icon" />{skillGroup.sklName}:
                        <span className="ml template-resume-project-header">{skillGroup.skillData.join(', ')}</span>
                      </span>
                    </div>
                  ))}
                </Row>
              </Col>
            </Row>
          ) : null}
        {previewResume.orgData.length > 0
          ? (
            <Row className="mx-1">
              <Col xs="12">
                <div className="pt-4">
                  <span className="template-header-content">Professional Experience</span>
                </div>
              </Col>
              <Col className="mx-1 pt-2">
                {previewResume && previewResume.orgData && previewResume.orgData
                  .sort((orgA, orgB) => (orgB.isCrnt ? 1 : 0) - (orgA.isCrnt ? 1 : 0)) // Sort to bring currently working entries to the top
                  .map((org, orgIndex) => (
                    <div className="mb-4" key={`${orgIndex.toString()}`}>
                      <div className="align-start">
                        <span className="template-about-text">
                          <FontAwesomeIcon icon={faBriefcase} className="mr template-exp-icon" />
                          {org.sDte !== '' ? formatUserDateNoHr(org.sDte) : 'NA'} - {org.isCrnt ? 'Currently Working' : org.eDte !== '' ? formatUserDateNoHr(org.eDte) : 'NA'}
                        </span>
                      </div>
                      <div className="align-start">
                        <span className="template-header">
                          {org.dsg}, {org.org} ({org.orgLoc})
                        </span>
                      </div>
                      <div className="my-2">
                        <div className="my-1">
                          <span className="template-project-exp">Collaborative Projects</span>
                        </div>
                        {org && org.prjs
                          .sort((projA, projB) => (projB.isCrnt ? 1 : 0) - (projA.isCrnt ? 1 : 0)) // Sort to bring currently working entries to the top
                          .map((project, projIndex) => (
                            <div className="mb-1" key={`${projIndex.toString()}`}>
                              <div className="align-start">
                                <span className="template-header">
                                  {project.prjNme} {project.isCrnt ? <span className="template-about-text-current">- Currently working</span> : null}
                                </span>
                              </div>
                              <div className="align-start align-text-justify">
                                <span className="template-resume-project-header">
                                  {project.desc}
                                </span>
                              </div>
                              <div className="align-start pt-3">
                                <span className="text-lite my-3">Skills Achieved: </span>
                                {org.prjs[projIndex].skls.map((skill, index) => (
                                  <span key={`prjskls-${index.toString()}`} className="template-resume-project-header ml">
                                    <FontAwesomeIcon icon={faCircleDot} className="template-resume-project-role-icon mx-1" />
                                    {skill}
                                  </span>
                                ))}
                              </div>
                              <div className="pt-2">
                                <div className="">
                                  <span className="text-lite mr">My Roles & Responsibilities</span>
                                </div>
                                <div className="ml">
                                  {org.prjs[projIndex].myRls.map((role, index) => (
                                    <div key={`prjrols-${index.toString()}`} className="align-text-justify my-1">
                                      <span className="xcelian-resume-project-header">
                                        <FontAwesomeIcon icon={faCircleDot} className="xcelian-resume-project-role-icon mr" />
                                        {role}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Col>
            </Row>
          ) : null}
        {previewResume.certData.length > 0
          ? (
            <Row className="mx-1">
              <Col xs="12">
                <div className="pt-4">
                  <span className="template-header-content">Certifications & Achievements</span>
                </div>
              </Col>
              <Col className="mx-1 pt-2">
                {previewResume && previewResume.certData && previewResume.certData
                  .map((cert, certIndex) => (
                    <Row className="mb-3" key={`${certIndex.toString()}`}>
                      <Col xs="12" lg="10">
                        <div className="align-start">
                          <span className="template-header">
                            <FontAwesomeIcon icon={faCertificate} className="mr template-exp-icon" />
                            {cert.certNme}
                          </span>
                        </div>
                        {cert.sDte !== '' && cert.eDte !== ''
                          ? (
                            <div className="align-start ml">
                              <span className="template-resume-project-header">
                                {cert.sDte !== '' ? formatUserDateNoHr(cert.sDte) : 'NA'} - {cert.eDte !== '' ? formatUserDateNoHr(cert.eDte) : 'NA'}
                              </span>
                            </div>
                          ) : null}
                        <div className="align-start ml">
                          <span className="template-resume-project-header">
                            {cert.desc}
                          </span>
                        </div>
                      </Col>
                      {cert.certUrl !== ''
                        ? (
                          <Col xs="12" lg="2">
                            <div className="align-center">
                              <CardImg
                                alt="..."
                                className="cert-img-e-res cursor-pointer"
                                src={cert.certUrl}
                                onClick={() => handleOpenCert(`CertImg-${certIndex}`)}
                              />
                            </div>
                            <div className="align-center">
                              <span className="text-lite-grey">click to zoom</span>
                            </div>
                          </Col>
                        ) : null}
                      <Modal
                        show={zoomCert === `CertImg-${certIndex}` || false}
                        onClose={() => handleOpenCert('')}
                        disCancel={false}
                        targetId={`CertImgTarget-${certIndex}`}
                        title=""
                        size="MD"
                      >
                        <Row>
                          <Col xs="12">
                            <CardImg
                              alt="..."
                              src={cert.certUrl}
                            />
                          </Col>
                        </Row>
                      </Modal>
                    </Row>
                  ))}
              </Col>
            </Row>
          ) : null}
        {previewResume.eduData.length > 0
          ? (
            <Row className="mx-1">
              <Col xs="12">
                <div className="pt-4">
                  <span className="template-header-content">Language Proficiency</span>
                </div>
              </Col>
              <Col className="mx-1 pt-2">
                {previewResume && previewResume.langData && previewResume.langData
                  .map((lang, langIndex) => (
                    <div className="mb-3" key={`${langIndex.toString()}`}>
                      <div className="align-start">
                        <span className="template-header">
                          <FontAwesomeIcon icon={faGlobe} className="mr template-exp-icon" />
                          {lang.langNme}
                        </span>
                        <span className="mx-2 text-lite">-</span>
                        <span className="template-resume-project-header">
                          {lang.level}
                        </span>
                      </div>
                    </div>
                  ))}
              </Col>
            </Row>
          ) : null}
        {previewResume.eduData.length > 0
          ? (
            <Row className="mx-1">
              <Col xs="12">
                <div className="pt-4">
                  <span className="template-header-content">Education</span>
                </div>
              </Col>
              <Col className="mx-1 pt-2">
                {previewResume && previewResume.eduData && previewResume.eduData
                  .map((edu, eduIndex) => (
                    <div className="mb-3" key={`${eduIndex.toString()}`}>
                      <div className="align-start">
                        <span className="template-header">
                          <FontAwesomeIcon icon={faGraduationCap} className="mr template-exp-icon" />
                          {edu.cmpsNme}
                        </span>
                      </div>
                      <div className="align-start ml">
                        <span className="template-resume-project-header">
                          {edu.spec}
                        </span>
                      </div>
                      <div className="align-start ml">
                        <span className="template-resume-project-header">
                          {edu.sDte !== '' ? formatUserDateNoHr(edu.sDte) : 'NA'} - {edu.eDte !== '' ? formatUserDateNoHr(edu.eDte) : 'NA'}
                        </span>
                      </div>
                    </div>
                  ))}
              </Col>
            </Row>
          ) : null}
        <Row className="mx-1">
          {previewResume.dob !== '' && previewResume.addr !== '' && previewResume.city !== '' && previewResume.st !== '' && previewResume.pCd !== ''
            ? (
              <Col xs="12">
                <div className="pt-4">
                  <span className="template-header-content">Personal Information</span>
                </div>
              </Col>
            ) : null}
          {previewResume.dob !== ''
            ? (
              <Col xs="12">
                <div className="pt-1">
                  <span className="text-lite">DOB: </span>
                  <span className="template-resume-project-header">{previewResume.dob !== '' ? formatUserDateNoHr(previewResume.dob) : 'NA'}</span>
                </div>
              </Col>
            ) : null}
          {previewResume.addr !== '' && previewResume.city !== '' && previewResume.st !== '' && previewResume.pCd !== ''
            ? (
              <Col xs="12">
                <div className="pt-1">
                  <span className="text-lite">Address: </span>
                  <span className="template-resume-project-header">{previewResume.addr}, {previewResume.city}, {previewResume.st} - {previewResume.pCd}</span>
                </div>
              </Col>
            ) : null}
        </Row>
        <div className="pt-4" />
      </Card>
    </div>
  );
};
export default EResumeShareComponent;
