import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './ats.scss';
import React from 'react';
import { CXATSNavbar } from '../navbar/cx-ats.navbar';
import AboutAts from './about-ats';
import { SalesAts } from './sales';

export const IntroATS: React.FC = () => (
  <div>
    <CXATSNavbar />
    <div className="pt-5 bg-ats">
      <Row className="align-center pt-4 mx-3">
        <Col lg="5" xs="12">
          <div className="margin-mob-sides">
            <div className="text-center-aligned">
              <span className="ats-header">
                Streamline Hiring Process
              </span>
            </div>
            <div className="text-center-aligned">
              <span className="ats-op-header">
                AI-Powered Interview Tracking
              </span>
            </div>
            <div className="pt-4 text-center-aligned mx-3">
              <span className="text-intro">
                Effortlessly manage and track job interviews with our AI-driven system, designed for a seamless and efficient hiring experience
              </span>
            </div>
            <div className="align-center pt-5">
              <SalesAts />
            </div>
            {/* ADD CHATbot here */}
          </div>
        </Col>
        <Col lg="4" xs="12">
          <div className="align-center mx-3">
            <CardImg
              alt="..."
              src="/images/home/indust-connects.png"
              className="image-intro"
            />
          </div>
        </Col>
      </Row>
      <div className="pt-3">
        <Row className="home-key-highlight-box pt-3">
          <Col lg="2" xs="6">
            <div className="align-center pt-4">
              <CardImg
                alt="..."
                className="image-intro-ats"
                src="/images/icons/brand.png"
              />
            </div>
            <div className="text-center-aligned margin-icons-bottom pt-2">
              <span className="text-sub-about">Employer Branding</span>
            </div>
          </Col>
          <Col lg="2" xs="6">
            <div className="align-center pt-4">
              <CardImg
                alt="..."
                className="image-intro-ats"
                src="/images/icons/technology.png"
              />
            </div>
            <div className="text-center-aligned margin-icons-bottom pt-2">
              <span className="text-sub-about">AI-Driven Candidate Matching</span>
            </div>
          </Col>
          <Col lg="2" xs="6">
            <div className="align-center pt-4">
              <CardImg
                alt="..."
                className="image-intro-ats"
                src="/images/icons/robot.png"
              />
            </div>
            <div className="text-center-aligned margin-icons-bottom pt-2">
              <span className="text-sub-about">Automated Interview Scheduling</span>
            </div>
          </Col>
          <Col lg="2" xs="6">
            <div className="align-center pt-4">
              <CardImg
                alt="..."
                className="image-intro-ats"
                src="/images/icons/swatches.png"
              />
            </div>
            <div className="text-center-aligned margin-icons-bottom pt-2">
              <span className="text-sub-about">Integrated Assessment Tools</span>
            </div>
          </Col>
          <Col lg="2" xs="6">
            <div className="align-center pt-4">
              <CardImg
                alt="..."
                className="image-intro-ats"
                src="/images/icons/review.png"
              />
            </div>
            <div className="text-center-aligned margin-icons-bottom pt-2">
              <span className="text-sub-about">Real-Time Feedback & Analytics</span>
            </div>
          </Col>
          <Col lg="2" xs="6">
            <div className="align-center pt-4">
              <CardImg
                alt="..."
                className="image-intro-ats"
                src="/images/icons/job-offer.png"
              />
            </div>
            <div className="text-center-aligned margin-icons-bottom pt-2">
              <span className="text-sub-about">Automated Offer & Onboarding</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className="pt-1">
      <AboutAts />
    </div>
    <div className="mb-3 p-4" />
  </div>
);

export default IntroATS;
