import { ListBatchResponse, ListBatchResults } from './list-batch.service.types';

export const ListBatchTransform: (response: ListBatchResponse) => ListBatchResults = (listBatchResults) => {
  const { data, is_error, message } = listBatchResults;
  const result: ListBatchResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        AcadCd: '',
        BatchCd: '',
      },
      batchData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
