import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import jwt_decode from 'jwt-decode';
import {
  Col, Row, Button, Navbar, NavbarBrand,
  CardImg,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// faCircleUser
import {
  faBars, faBell, faEnvelope, faGear, faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import {
  Routes, Route,
} from 'react-router-dom';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  clearKeysFromLocalStorage, getTokenFromLocalStorage, getUserProfileLocalStorage,
  setUserProfileLocalStorage,
} from '../../utils/service/localstorage-service';
import { CXUnauthorized } from '../unauthorized/unauthorized.component';
import './exclusive.scss';
import { ModalPasskeyUpdate } from '../../components/exclusive/common/passkey-reset/modal-reset-passkey.component';
import { PasskeyUpdate } from '../../components/exclusive/common/passkey-reset/passkey.component';
import { FileDetails } from '../../services/common/get-s3-files/get-s3files.types';
import { clearGetS3FilesReq, getProfile, getS3FilesReq } from '../../store/campusXcel/actions';
import { UserType, APIHeader } from '../../utils/constants';
import { getConfig } from '../../utils/config/config';
import Modal from '../../utils/Modal/Modal';
import { MyAvatar } from '../../components/exclusive/common/my-avatar/avatar';
import Popover from '../../utils/Popover/Popover';
import RecruitInterview from '../../components/exclusive/staffing/recruiters/common/interviews/recruit.interview.component';
import RecruitTools from '../../components/exclusive/staffing/recruiters/common/tools/recruit.tools.component';
import AdminRecruitClients from '../../components/exclusive/staffing/recruiters/recruit-admin/clients/recruit.clients.component';
import AdminRecruitEmployee from '../../components/exclusive/staffing/recruiters/recruit-admin/employees/recruit.employee.component';
import { RecruitJobs } from '../../components/exclusive/staffing/recruiters/common/jobs/recruit.jobs.component';
import { RecruitProfile } from '../../components/exclusive/staffing/recruiters/common/profile/recruit.profile.component';
import { errorAlert } from '../../utils/alert';
import JobResumeComponent from '../../components/exclusive/staffing/recruiters/common/jobs/resumes/resume.component';
import { RecruiterDashboard } from '../../components/exclusive/staffing/recruiters/dashboard/dashboard.component';
// import { JobResumeComponent } from '../../components/exclusive/staffing/recruiters/common/jobs/resumes/resume.component';

const { AWS_CXUSER_BUCKET } = getConfig();

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<RecruiterDashboard />} />
    <Route path="jobs" element={<RecruitJobs />} />
    <Route path="job/:jobOrg/:jobId" element={<JobResumeComponent />} />
    <Route path="interviews" element={<RecruitInterview />} />
    <Route path="tools" element={<RecruitTools />} />
    <Route path="my-clients" element={<AdminRecruitClients />} />
    <Route path="my-employees" element={<AdminRecruitEmployee />} />
    <Route path="profile" element={<RecruitProfile />} />
    <Route path="passkey" element={<PasskeyUpdate uTp="RCRT" />} />
  </Routes>
);

export const RecruitAdminPrivateComponent: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileLocalStorage();
  const [myCorp, setMyCorp] = useState('');
  const [emailId, setEmailId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userId, setUserId] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [acadLogoId, setAcadLogoId] = React.useState('');
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [avatarSrc, setAvatarSrc] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);
  const [acadSrc, setAcadSrc] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);
  const getAvatarResponse = useSelector((state: RootState) => state.campusXcel.getS3Files);
  const loginProfileResponse = useSelector((state: RootState) => state.campusXcel.getProfileData);
  const [clickCXComponent, setClickCXComponent] = React.useState(false);
  const [resetPassKey, setResetPassKey] = useState(true);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [showModalAcademy, setShowModalAcademy] = useState(false);
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const passKeyModal = () => setResetPassKey(!resetPassKey);
  // const path = location.pathname.split('/');

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  React.useEffect(() => {
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      // console.log('Check Decode token', decodedToken, Date.now());
      if (decodedToken && decodedToken.exp) {
        // Compare the expiration time in milliseconds
        const isExpired = decodedToken.exp * 1000 < Date.now();
        if (isExpired) {
          setIsTokenExpired(true);
          history('/recruit/login');
        }
      }
    }
  }, [tokenData, history, isTokenExpired]);

  //   const handleClick = () => {
  //     setChangeIcon(!changeIcon);
  //     setClickCXComponent(!clickCXComponent);
  //   };
  //   const handleNotification = () => {
  //     setOpenNotification(!openNotification);
  //   };

  //   const handleMobileNotification = () => {
  //     setOpenMNotification(!openMNotification);
  //   };

  React.useEffect(() => {
    if (tokenData !== '' && !isTokenExpired) {
      dispatch(getProfile({
        uTp: UserType.RCRT,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: tokenData,
      }));
    }
  }, [tokenData, isTokenExpired]);

  React.useEffect(() => {
    if (tokenData !== '' && isTokenExpired) {
      setErrorShowAlert(true);
      setAlertMsg(loginProfileResponse.message);
      history('/recruit/login');
      setIsTokenExpired(false);
    }
  }, [tokenData, loginProfileResponse.message, isTokenExpired]);

  React.useEffect(() => {
    if (loginProfileResponse.profileData.eId !== '' && tokenData !== '' && !isTokenExpired) {
      setUserProfileLocalStorage(JSON.stringify(loginProfileResponse));
      setFirstName(loginProfileResponse.profileData.fNme);
      setLastName(loginProfileResponse.profileData.lNme);
      setEmailId(loginProfileResponse.profileData.eId);
      setMyCorp(loginProfileResponse.profileData.rcrtNme);
      setIsTokenExpired(false);
    }
  }, [loginProfileResponse.profileData.eId, tokenData, isTokenExpired]);

  // Getting Avatar from S3 Start
  React.useEffect(() => {
    setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    setUserTp(userProfile && JSON.parse(userProfile).profileData.UserTp);
  }, [userProfile && JSON.parse(userProfile).profileData.UserCd, userProfile && JSON.parse(userProfile).profileData.UserTp]);

  // Getting Acadmey Logo from S3 Start
  React.useEffect(() => {
    setAcadLogoId(userProfile && JSON.parse(userProfile).profileData.cmpsCd);
  }, [userProfile && JSON.parse(userProfile).profileData.cmpsCd]);

  React.useEffect(() => {
    if (userTp !== '' && userId !== '') {
      dispatch(getS3FilesReq({
        requestType: '',
        bucketNme: AWS_CXUSER_BUCKET,
        folderName: `avatars/${userTp}-${userId}_1.png`,
        identifierTp: 'avatar',
      }));
    }
  }, [userTp, userId]);

  React.useEffect(() => {
    if (acadSrc[0].url === '') {
      dispatch(getS3FilesReq({
        requestType: '',
        bucketNme: AWS_CXUSER_BUCKET,
        folderName: `academy/${acadLogoId}.png`,
        identifierTp: 'academy',
      }));
    }
  }, [acadLogoId]);

  React.useEffect(() => {
    if (getAvatarResponse.data.length !== 0) {
      if (getAvatarResponse.identifierTp === 'avatar' && avatarSrc[0].url === '') {
        setAvatarSrc(getAvatarResponse.data);
      } else if (getAvatarResponse.identifierTp === 'academy' && acadSrc[0].url === '') {
        setAcadSrc(getAvatarResponse.data);
      }
      dispatch(clearGetS3FilesReq());
    }
  }, [getAvatarResponse, avatarSrc[0].url, acadSrc[0].url]);
  // Getting Avatar from S3 End

  const handleEditAcadLogo = () => {
    setShowModalAcademy(!showModalAcademy);
  };
  return (
    <div className="cx-exclusive-bg">
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {tokenData !== '' ? (
        <div>
          {userProfile && JSON.parse(userProfile).profileData.isPKReset ? (
            <Modal
              show={resetPassKey}
              onClose={() => (false)}
              disCancel
              targetId="showModelAcademy"
              title=""
              size="MD"
            >
              {/* <Modal isOpen={resetPassKey} className="my-modal-container-likes"> */}
              <Row>
                <Col lg="9" xs="8">
                  <div className="my-2 align-start ml-2">
                    <p>Update Password</p>
                  </div>
                </Col>
                <Col lg="3" xs="4">
                  <div className="align-end">
                    <Button className="button-icon" onClick={passKeyModal}>
                      <FontAwesomeIcon className="close-button" icon={faXmarkSquare} />
                      <span className="text-lite-grey-date mx-1">Close</span>
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="pt-1">
                <ModalPasskeyUpdate setIsResetPasskey={setIsResetPasskey} uTp="RCRT" />
              </div>
            </Modal>
          )
            : null}
          <div className="mx-1">
            <Navbar className="campus-nav-bg">
              <NavbarBrand href="#" className="pt-1 margin-logo">
                <div className="pt-1 my-1 align-start">
                  {userProfile && JSON.parse(userProfile).profileData.acadLogoId !== ''
                    ? (
                      <CardImg
                        alt="..."
                        className="campus-acad-logo"
                        src={acadSrc[0].url !== '' ? acadSrc[0].url : '/campusXcel.png'}
                      />
                    ) : null}
                  <Button onClick={handleEditAcadLogo} className="button-icon">
                    <FontAwesomeIcon className="academy-edit-icon" icon={faEdit} />
                  </Button>
                </div>
                <Modal
                  show={showModalAcademy || false}
                  onClose={() => setShowModalAcademy(false)}
                  disCancel={false}
                  targetId="showModelAcademy"
                  title=""
                  size="MD"
                >
                  <div className="mx-3">
                    <MyAvatar imgUrl={acadSrc[0].url} fileName={`${acadLogoId}`} folderName="academy" defaultFIleUrl="/images/common/defImage.png" />
                  </div>
                  <div className="pt-3" />
                </Modal>
              </NavbarBrand>
              <footer className={showFooter ? '' : 'footer-visible'}>
                <div className="align-center">
                  <Button className="button-icon mx-2 padding-zero">
                    <FontAwesomeIcon icon={faEnvelope} className="campus-header-icon" />
                  </Button>
                  <Button className="button-icon mx-2 padding-zero">
                    <FontAwesomeIcon icon={faBell} className="campus-header-icon" />
                  </Button>
                  <Button className="button-icon mx-2 padding-zero">
                    <FontAwesomeIcon icon={faGear} className="campus-header-icon" />
                  </Button>
                </div>
              </footer>
              <Popover
                header={(
                  <div className="mx-2 pt-4 mb-2 text-center-aligned ">
                    <div className="line-height-1">
                      <span className="nav-header-acad-text">{firstName} {lastName}</span>
                    </div>
                    <div className="line-height-1">
                      <span className="text-lite-grey">{emailId}</span>
                    </div>
                    <div className="line-height-3">
                      <span className="text-lite-grey">{myCorp}</span>
                    </div>
                  </div>
                )}
                content={(
                  <div ref={popoverRef} className="">
                    <hr className="mx-2 hr-dasshed-lite" />
                    <div className="my-3 ml-1">
                      <div className="my-2">
                        <Button
                          className="recruit-button-icon"
                          onClick={() => {
                            history('dashboard');
                            setClickCXComponent(true);
                          }}
                        >
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/dashboard.png" alt="dash" />
                          Dashboard
                        </Button>
                      </div>
                      <div className="my-2">
                        <Button
                          className="recruit-button-icon"
                          onClick={() => {
                            history('jobs');
                            setClickCXComponent(true);
                          }}
                        >
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/jobs.png" alt="jobs" />
                          Jobs
                        </Button>
                      </div>
                      <div className="my-2">
                        <Button
                          className="recruit-button-icon"
                          onClick={() => {
                            history('interviews');
                            setClickCXComponent(true);
                          }}
                        >
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/cv.png" alt="interview" />
                          Interviews
                        </Button>
                      </div>
                      <div className="my-2">
                        <Button
                          className="recruit-button-icon"
                          onClick={() => {
                            history('tools');
                            setClickCXComponent(true);
                          }}
                        >
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/tools.png" alt="tools" />
                          Tools
                        </Button>
                      </div>
                      <div className="my-2">
                        <Button
                          className="recruit-button-icon"
                          onClick={() => {
                            history('my-clients');
                            setClickCXComponent(true);
                          }}
                        >
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/org.png" alt="org" />
                          Clients
                        </Button>
                      </div>
                      <div className="my-2">
                        <Button
                          className="recruit-button-icon"
                          onClick={() => {
                            history('my-employees');
                            setClickCXComponent(true);
                          }}
                        >
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/org-users.png" alt="org-users" />
                          Employees
                        </Button>
                      </div>
                      <div className="my-2">
                        <Button
                          className="recruit-button-icon"
                          onClick={() => {
                            history('profile');
                            setClickCXComponent(true);
                          }}
                        >
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/my-profile.png" alt="profile" />
                          My Profile
                        </Button>
                      </div>
                      <div className="my-2">
                        <Button className="recruit-button-icon" onClick={() => { clearKeysFromLocalStorage(); window.location.href = '/recruit/login'; }}>
                          <CardImg className="recruit-menu-img-cx mr" src="/images/icons/logout.png" alt="logout" /> Logout
                        </Button>
                      </div>
                    </div>
                  </div>
                        )}
                position="bottom"
                customClassName="recruit-popover"
                isClickClose={clickCXComponent}
                setIsClickClose={setClickCXComponent}
              >
                <div className="recruit-toggle-nav align-center">
                  <CardImg
                    alt="..."
                    className="img-fluid rounded-circle shadow-lg recruit-avatar-user"
                    src={avatarSrc[0].url !== '' ? avatarSrc[0].url : '/images/common/avatar.png'}
                  />
                  <FontAwesomeIcon icon={faBars} className="recruit-open-btn-mobile" />
                </div>
              </Popover>
              {/* <footer className={showFooter ? '' : 'footer-visible'}>
                <Popover placement="top" isOpen={openMNotification} target="passkeyMId" toggle={handleMobileNotification}>
                  <PopoverBody>
                    <Nav>
                      <ul className="menu-list">
                        <NavItem className="menu-list pt-2">
                          <NavLink to="passkey" onClick={handleMobileNotification} className="menu-link-notification">
                            <FontAwesomeIcon className="campus-notification-icon" icon={faUserLock} />
                            <span className="campus-notification-txt mx-2">Password</span>
                          </NavLink>
                        </NavItem>
                      </ul>
                    </Nav>
                  </PopoverBody>
                </Popover>
                <Row>
                  <Col xs="3" />
                  <Col xs="2">
                    <Button className="button-icon" id="useremails"><FontAwesomeIcon icon={faEnvelope} className="campus-header-icon" /></Button>
                    <UncontrolledTooltip
                      target="useremails"
                    >
                      Emails
                    </UncontrolledTooltip>
                  </Col>
                  <Col xs="2">
                    <Button className="button-icon" id="notifications"><FontAwesomeIcon icon={faBell} className="campus-header-icon" /></Button>
                    <UncontrolledTooltip
                      target="notifications"
                    >
                      Notifications
                    </UncontrolledTooltip>
                  </Col>
                  <Col xs="2">
                    <Button className="button-icon" id="passkeyMId" onClick={handleMobileNotification}><FontAwesomeIcon icon={faGear} className="campus-header-icon" /></Button>
                  </Col>
                  <Col xs="3" />
                </Row>
              </footer> */}
            </Navbar>
            <Row>
              <Col lg="12" className="margin-recruit">
                <ExclusiveRoutes />
              </Col>
              <div className="pt-5" />
            </Row>
          </div>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default RecruitAdminPrivateComponent;
