import { putJSON } from '../../../../../utils';
import { getConfig } from '../../../../../utils/config/config';
import { CommentOptions, CommentResponse } from './comment.service.types';

const { CAMPUSXCEL_CMPUS_API_ROOT } = getConfig();

export const CommentPutRequest = ({
  inputBody, token, requestType, uTp, actId, cmntId, acdId,
}: CommentOptions): Promise<CommentResponse> => {
  if (cmntId === '') {
    return putJSON(`${CAMPUSXCEL_CMPUS_API_ROOT}?uTp=${uTp}&actId=${actId}&acdId=${acdId}`, token, requestType, inputBody as unknown as BodyInit);
  }
  return putJSON(`${CAMPUSXCEL_CMPUS_API_ROOT}?uTp=${uTp}&actId=${actId}&cmntId=${cmntId}&acdId=${acdId}`, token, requestType, inputBody as unknown as BodyInit);
};
