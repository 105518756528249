import React from 'react';
import {
  Button,
  Card, CardImg, Col, Input, Label, Row,
} from 'reactstrap';
import {
  Chart, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ResumeInputHandleProps } from '../template/resume.template.type';
import {
  clearXcelianShareResume,
  clearXclnResumeFilePutReq, getXcelianShareResume, uploadS3FilesReq, xclnResumeFilePutReq,
} from '../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../utils/constants';
import { errorAlert, successAlert } from '../../../../../utils/alert';
import LoaderData from '../../../../../utils/loader';
import { getConfig } from '../../../../../utils/config/config';
import { XclnJdFile } from '../../../../../services/staffing/resume-preview/add-resume-file/update.resume.file.types';
import XcelianPersonalInfo from './personal.info';
import Accordion from '../../../../../utils/Accordion/Accordion';
import { XcelianSkillsInfo } from './skills.info';
import XcelianObjInfo from './obj.info';
import XcelianOrgProjInfo from './orgProj.info';
import { XcelianEducationInfo } from './education.info';
import XcelianCertificationsInfo from './certificates.info';
import { XcelianLanguageInfo } from './lang.info';
import XcelianCoverInfo from './cover.info';
import Popover from '../../../../../utils/Popover/Popover';
import XcelianVideoInfo from './video.info';
import Offcanvas from '../../../../../utils/OffCanvas/OffcanvasComponent';

Chart.register(ArcElement, Tooltip, Legend);

const { AWS_CXUSER_BUCKET } = getConfig();

export const XcelianResumeInput: React.FC<ResumeInputHandleProps> = ({
  tokenData,
  myProfile,
  handleResumeInfoData,
  resumePersonalInfo,
  handleGenderRegister,
  setResumePersonalInfo,
  handleUpdatePersonalInfo,
  setResumeSkills,
  resumeSkills,
  handleUpdateSkills,
  handleResumeObjectiveData,
  resumeObjective,
  handleUpdateObjective,
  resumeOrgProj,
  setResumeOrgProj,
  handleUpdateOrgProject,
  setResumeEducation,
  resumeEducation,
  handleUpdateEduction,
  setResumeCert,
  resumeCert,
  handleUpdateCertifications,
  handleDeleteCertImage,
  setResumeLang,
  resumeLang,
  handleUpdateLanguage,
  handleResumeCoverLetterData,
  resumeCoverLetter,
  handleUpdateCoverLetter,
  clickCXComponent,
  setClickCXComponent,
  setResumeVideo,
  resumeVideo,
  handleUpdateResumeVideo,
  loaderVideoResume,
  setLoaderVideoResume,
  handleGetXcelianPreview,
  isPasscodePerv,
  setIsPasscodePerv,
  handleChangeXcelianPreview,
  eResumePass,
  setEResumePass,
  previewResumeCnt,
}) => {
  const dispatch = useDispatch();
  const [loadingResumeParse, setLoadingResumeParse] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [loadingPin, setLoadingPin] = React.useState(false);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [inputPasscode, setInputPasscode] = React.useState('');
  const [genLinkUrl, setGenLinkUrl] = React.useState('');
  const [isCopied, setIsCopied] = React.useState(false);
  const [modalVideoProfile, setModalVideoProfile] = React.useState(false);
  const [passReviewCd, setPassReviewCd] = React.useState('');
  // const uploadResumeS3AwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const xcelianResumeFileStatusResponse = useSelector((state: RootState) => state.campusXcel.xcelianResumeFileUpdate);
  const xcelianResumePassPreviewResponse = useSelector((state: RootState) => state.campusXcel.xcelianResumeShare);
  const [resResumePass, setResResumePass] = React.useState(false);
  const [dataArrayAccountDough] = React.useState<number[]>([100, 0]); // setDataArrayAccountDough
  const [resumeData, setResumeData] = React.useState<XclnJdFile>({
    resumeFile: '',
    file: undefined,
  });

  const handleCopyToClipboard = (copyUrl: string) => {
    navigator.clipboard.writeText(copyUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000); // Reset "Copied" message after 2 seconds
    });
  };

  const dataAccountDough = {
    labels: ['Pending', 'Completed'], // These will be hidden on the chart
    datasets: [
      {
        data: dataArrayAccountDough,
        backgroundColor: ['#f8d6d6', '#038e08'],
        hoverBackgroundColor: ['#f8d6d6', '#038e08'],
      },
    ],
  };

  const optionsAccountDough = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        font: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: () => '',
          label: (context: any) => {
            const value = context.parsed || 0;
            return `${value.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')} %`; // Show only value in tooltip
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  const handleGenPassCode = (e: any) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) { // Allows only numbers
      setInputPasscode(value);
    }
  };

  const handleUpdateEResumePass = () => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '' && /^\d{1,6}$/.test(inputPasscode)) {
      setLoadingResumeParse(true);
      setPassReviewCd('UPDT_RES');
      dispatch(getXcelianShareResume({
        requestType: APIHeader.REQ_INTERVIEW_XCELIAN_SHARE_URL,
        uTp: UserType.XCLN,
        token: tokenData,
        xclnTp: myProfile.UserTp,
        xclnId: myProfile.UserCd,
        resCd: inputPasscode,
        opsMode: 'SELF',
      }));
    } else {
      setErrorShowAlert(true);
      setAlertMsg('Input should be 6 digit always');
    }
  };

  // Handle Update or preview Passcode Xcelian Data
  React.useEffect(() => {
    if (xcelianResumePassPreviewResponse.isError && xcelianResumePassPreviewResponse.message !== '' && passReviewCd === 'UPDT_RES') {
      setLoadingResumeParse(false);
      dispatch(clearXcelianShareResume());
      setErrorShowAlert(true);
      setPassReviewCd('');
      setAlertMsg(xcelianResumePassPreviewResponse.message);
    }
  }, [xcelianResumePassPreviewResponse.isError, xcelianResumePassPreviewResponse.message, passReviewCd]);

  React.useEffect(() => {
    if (!xcelianResumePassPreviewResponse.isError && xcelianResumePassPreviewResponse.message === 'executed' && myProfile.UserTp !== '' && myProfile.UserCd !== '' && passReviewCd === 'UPDT_RES') {
      setResResumePass(true);
      setInputPasscode('');
      setPassReviewCd('');
      setLoadingResumeParse(false);
      setGenLinkUrl(xcelianResumePassPreviewResponse.resumeData.toString());
      dispatch(clearXcelianShareResume());
    }
  }, [xcelianResumePassPreviewResponse.isError, xcelianResumePassPreviewResponse.message, myProfile.UserTp, myProfile.UserCd, passReviewCd]);

  const handleUploadResumeFilesData = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      const { files } = e.target;
      if (!files || files.length === 0) return;

      const file = files[0]; // Get the first file
      const originalFileName = file.name;
      const fileExtension = originalFileName?.split('.').pop();
      const uniqueFileName = `${myProfile.UserTp}-${myProfile.UserCd}.${fileExtension}`;
      setResumeData({ ...resumeData, resumeFile: uniqueFileName, file });

      const resumeCopy = { ...resumeData, resumeFile: uniqueFileName };
      setLoadingResumeParse(true);
      dispatch(xclnResumeFilePutReq({
        inputBody: resumeCopy,
        requestType: APIHeader.REQ_UPDATE_XCELIAN_RESUME_FILE,
        uTp: UserType.XCLN,
        token: tokenData,
      }));
    }
  };

  // Handle getResumeLoad API success/error
  React.useEffect(() => {
    if (xcelianResumeFileStatusResponse.error && xcelianResumeFileStatusResponse.message !== '') {
      setLoadingResumeParse(false);
      dispatch(clearXclnResumeFilePutReq());
      setErrorShowAlert(true);
      setAlertMsg(xcelianResumeFileStatusResponse.message);
    } else if (!xcelianResumeFileStatusResponse.error && xcelianResumeFileStatusResponse.message === 'executed' && myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      dispatch(clearXclnResumeFilePutReq());
      // const { file } = resumeData.file;
      const uniqueFileName = xcelianResumeFileStatusResponse.jdFileName;
      if (resumeData.file) {
        const formData = new FormData();
        formData.append(uniqueFileName, resumeData.file);
        dispatch(uploadS3FilesReq({
          bucketNme: AWS_CXUSER_BUCKET,
          folderName: `cand-resm/${myProfile.UserTp}/${myProfile.UserCd}`,
          fileNmeInit: uniqueFileName,
          formData,
          requestType: '',
          isEdit: false,
        }));
      } else {
        setErrorShowAlert(true);
        setAlertMsg('File is undefined, cannot upload.');
      }
    }
  }, [xcelianResumeFileStatusResponse, resumeData, dispatch, myProfile]);

  const toggleVideo = () => setModalVideoProfile(!modalVideoProfile);
  return (
    <div>
      { loadingResumeParse ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="width-margin-resume-input">
        <Col xs="12">
          <div>
            <Card className="xcelian-card">
              <Row>
                <Col xs="12" lg="8">
                  <div className="align-center pt-2">
                    <span className="text-lite-grey-sub">Current Resume status</span>
                  </div>
                  <div className="align-center">
                    <span className="text-lite-grey">Completed: 0%</span>
                  </div>
                  <div className="align-center pt-3">
                    <div className="xcelian-upload-box align-center mx-2">
                      <Input
                        type="file"
                        accept=".pdf, .docx"
                        name="fileData"
                        className="xcelian-upload-box-input"
                        onChange={(e) => handleUploadResumeFilesData(e)}
                      />
                      <span className="register-file-link">Upload Your Resume</span>
                    </div>
                  </div>
                  <div className="align-center ml mb-2 pt-1">
                    <span className="dashboard-sub-post-text-lite">Upload your resume directly</span>
                  </div>
                </Col>
                <Col xs="12" lg="4">
                  <Row className="align-center">
                    <Col xs="7" lg="12">
                      <div className="my-2">
                        <Doughnut data={dataAccountDough} options={optionsAccountDough} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-5">
            <Card className="xcelian-card-form">
              <Row className="pt-2">
                <Col xs="12" lg="12">
                  <div className="pt-1 align-center">
                    <CardImg src="/images/icons/e-resume.png" className="dashboard-home-icon mr" alt="Home Icon" />
                    <span className="xcelian-resume-covverletter">
                      E Resume
                    </span>
                  </div>
                  <div className="pt-1 align-center">
                    <span className="text-lite">
                      Digital Gateway to a Smarter Career Path
                    </span>
                  </div>
                  <div className="my-1 align-center">
                    <span className="text-lite-grey mr mb-1">Utilized: </span>
                    <span className="share-icon mb-1">
                      {previewResumeCnt} <span className="text-lite">/</span> 20
                    </span>
                  </div>
                  <div className="my-1 align-center">
                    <Button
                      className="button-transparent-buy ml"
                    >
                      Buy more Links
                    </Button>
                  </div>
                </Col>
                <Col xs="12">
                  <Card className="cx-jobs-dashed-card-main">
                    <Row className="align-center">
                      <Col xs="12">
                        <div className="align-center pt-3">
                          <Button
                            className="job-button-approve mx-1"
                            onClick={() => {
                              setIsPasscodePerv(!isPasscodePerv);
                              setEResumePass(false);
                            }}
                          >
                            Preview
                          </Button>
                          <Button
                            className="button-transparent-jobs mx-1"
                            onClick={() => {
                              setEResumePass(!eResumePass);
                              setIsPasscodePerv(false);
                            }}
                          >
                            Generate New <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
                          </Button>
                        </div>
                      </Col>
                      {eResumePass
                        ? (
                          <Col xs="12">
                            <Row className="align-center pt-3">
                              <Col xs="11" lg="11">
                                <Label for="eResume" className="text-lite ml">Generate New Passcode</Label>
                                <Input
                                  type="text"
                                  value={inputPasscode}
                                  maxLength={6}
                                  onChange={handleGenPassCode}
                                  className="campus-input"
                                />
                                {resResumePass
                                  ? (
                                    <span className="correct-pass-key">
                                      {window.location.origin}/{genLinkUrl}
                                      <Button
                                        onClick={() => handleCopyToClipboard(`${window.location.origin}/${genLinkUrl}`)}
                                        className="button-transparent-jobs padding-zero"
                                      >
                                        <FontAwesomeIcon className="ml" icon={faCopy} />{isCopied ? 'Copied !' : 'Copy'}
                                      </Button>
                                    </span>
                                  )
                                  : <span className="text-lite-grey">Secure with a Passcode: Only those with the generated link can access your profile</span>}
                              </Col>
                              <Col xs="11" lg="11">
                                <div className="align-end mr pt-2">
                                  <Button
                                    className="job-button-approve mx-1"
                                    onClick={handleUpdateEResumePass}
                                  >
                                    Create URL
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                      {isPasscodePerv
                        ? (
                          <Col xs="12">
                            <Row className="align-center pt-3">
                              <Col xs="11" lg="11">
                                <Label for="eResume" className="text-lite ml">Enter Passcode</Label>
                                <Input
                                  type="text"
                                  // value={inputPasscode}
                                  maxLength={6}
                                  onChange={handleChangeXcelianPreview}
                                  className="campus-input"
                                />
                              </Col>
                              <Col xs="11" lg="11">
                                <div className="align-end mr pt-2">
                                  <Button
                                    className="job-button-approve mx-1"
                                    onClick={handleGetXcelianPreview}
                                  >
                                    Get Preview
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        ) : null}

                    </Row>
                  </Card>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-5">
            <Card className="xcelian-card-form">
              <Row className="pt-2">
                <Col xs="12" lg="12">
                  <div className="pt-1 align-center">
                    <CardImg src="/images/icons/email.png" className="dashboard-home-icon mr" alt="Home Icon" />
                    <span className="xcelian-resume-covverletter">
                      Instant Cover Letter Generator
                    </span>
                  </div>
                  <div className="pt-1 align-center">
                    <span className="text-lite">
                      Create, copy, and share in seconds!
                    </span>
                  </div>
                </Col>
              </Row>
              <div className="pt-3 align-center">
                <Popover
                  header=""
                  content={(
                    <div ref={popoverRef}>
                      <div className="pt-2">
                        <XcelianCoverInfo
                          handleResumeCoverLetterData={handleResumeCoverLetterData}
                          resumeCoverLetter={resumeCoverLetter}
                        />
                      </div>
                      <div className="my-4 align-center">
                        <Button onClick={handleUpdateCoverLetter} className="job-button-approve" disabled={resumeCoverLetter.covL === ''}>
                          SAVE
                        </Button>
                      </div>
                    </div>
                        )}
                  position="right"
                  customClassName="recruit-popover"
                  isClickClose={clickCXComponent}
                  setIsClickClose={setClickCXComponent}
                >
                  <div className="job-button-approve">
                    Generate
                  </div>
                </Popover>
              </div>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-5">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Video Profile</span>
              </div>
              <div className="pt-1 align-center">
                <CardImg src="/images/icons/record.png" className="dashboard-home-icon mr" alt="Home Icon" />
              </div>
              <div className="pt-1 text-center-aligned mx-2">
                <span className="text-lite">
                  Use your video profile to enhance recognition
                </span>
              </div>
              <div className="pt-3 align-center">
                <Button className="job-button-approve" onClick={toggleVideo}>
                  Record
                </Button>
              </div>
              <div className="pt-2" />
            </Card>
            <Offcanvas
              isOpen={modalVideoProfile}
              toggle={() => toggleVideo()}
            >
              <div className="pt-3">
                <XcelianVideoInfo
                  setResumeVideo={setResumeVideo}
                  resumeVideo={resumeVideo}
                  handleUpdateResumeVideo={handleUpdateResumeVideo}
                  myProfile={myProfile}
                  loaderVideoResume={loaderVideoResume}
                  setLoaderVideoResume={setLoaderVideoResume}
                />
              </div>
            </Offcanvas>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-4">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Personal Information</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="12">
                  <Accordion
                    title={(
                      <span className="input-card-header-sub-text">
                        <CardImg src="/images/icons/approved-user.png" className="dashboard-home-icon mb mr" alt="Home Icon" />
                        Update Details
                      </span>
                      )}
                    defaultOpen={false}
                    targetId="myPersonalInfo"
                  >
                    <div className="pt-2 mx-1">
                      <XcelianPersonalInfo
                        handleResumeInfoData={handleResumeInfoData}
                        resumePersonalInfo={resumePersonalInfo}
                        handleGenderRegister={handleGenderRegister}
                        setResumePersonalInfo={setResumePersonalInfo}
                        tokenData={tokenData}
                        setLoadingPin={setLoadingPin}
                        loadingPin={loadingPin}
                      />
                    </div>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mx-1">
                <Col xs="12" lg="12 align-end pt-3">
                  <Button onClick={handleUpdatePersonalInfo} className="job-button-approve" disabled={(resumePersonalInfo.gnd === '' && resumePersonalInfo.oGnd === '') || resumePersonalInfo.pCd === '' || loadingPin}>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-4">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Skills</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="12">
                  <Accordion
                    title={(
                      <span className="input-card-header-sub-text">
                        <CardImg src="/images/icons/skills.png" className="dashboard-home-icon mb mr" alt="Home Icon" />
                        Update Skills
                      </span>
                      )}
                    defaultOpen={false}
                    targetId="mySkills"
                  >
                    <div className="pt-2 mx-1">
                      <XcelianSkillsInfo
                        setResumeSkills={setResumeSkills}
                        resumeSkills={resumeSkills}
                        tokenData={tokenData}
                      />
                    </div>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mx-1">
                <Col xs="12" lg="12 align-end pt-3">
                  <Button onClick={handleUpdateSkills} className="job-button-approve" disabled={resumeSkills.skills.length === 0}>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-4">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Career Objective</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="12">
                  <Accordion
                    title={(
                      <span className="input-card-header-sub-text">
                        <CardImg src="/images/icons/goal.png" className="dashboard-home-icon mb mr" alt="Home Icon" />
                        Update Objective
                      </span>
                      )}
                    defaultOpen={false}
                    targetId="myObjective"
                  >
                    <div className="pt-2 mx-1">
                      <XcelianObjInfo
                        handleResumeObjectiveData={handleResumeObjectiveData}
                        resumeObjective={resumeObjective}
                      />
                    </div>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mx-1">
                <Col xs="12" lg="12 align-end pt-3">
                  <Button onClick={handleUpdateObjective} className="job-button-approve" disabled={resumeObjective.obj === ''}>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-4">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Experience</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="12">
                  <Accordion
                    title={(
                      <span className="input-card-header-sub-text">
                        <CardImg src="/images/icons/project.png" className="dashboard-home-icon mb mr" alt="Home Icon" />
                        Update Experience
                      </span>
                      )}
                    defaultOpen={false}
                    targetId="myExperience"
                  >
                    <div className="pt-2 mx-1">
                      <XcelianOrgProjInfo
                        setResumeOrgProj={setResumeOrgProj}
                        resumeOrgProj={resumeOrgProj}
                      />
                    </div>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mx-1">
                <Col xs="12" lg="12 align-end pt-3">
                  <Button onClick={handleUpdateOrgProject} className="job-button-approve" disabled={resumeOrgProj.orgData.length === 0}>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-4">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Eduation</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="12">
                  <Accordion
                    title={(
                      <span className="input-card-header-sub-text">
                        <CardImg src="/images/icons/graduate-cap.png" className="dashboard-home-icon mb mr" alt="Home Icon" />
                        Update Education
                      </span>
                      )}
                    defaultOpen={false}
                    targetId="myExperience"
                  >
                    <div className="pt-2 mx-1">
                      <XcelianEducationInfo
                        setResumeEducation={setResumeEducation}
                        resumeEducation={resumeEducation}
                      />
                    </div>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mx-1">
                <Col xs="12" lg="12 align-end pt-3">
                  <Button onClick={handleUpdateEduction} className="job-button-approve" disabled={resumeEducation.eduData.length === 0}>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-4">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Certifications & Achievements</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="12">
                  <Accordion
                    title={(
                      <span className="input-card-header-sub-text">
                        <CardImg src="/images/icons/digital-certificate.png" className="dashboard-home-icon mb mr" alt="Home Icon" />
                        Update Details
                      </span>
                      )}
                    defaultOpen={false}
                    targetId="myExperience"
                  >
                    <div className="pt-2 mx-1">
                      <XcelianCertificationsInfo
                        setResumeCert={setResumeCert}
                        resumeCert={resumeCert}
                        handleDeleteCertImage={handleDeleteCertImage}
                      />
                    </div>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mx-1">
                <Col xs="12" lg="12 align-end pt-3">
                  <Button onClick={handleUpdateCertifications} className="job-button-approve">
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-4">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Language Proficiency</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="12">
                  <Accordion
                    title={(
                      <span className="input-card-header-sub-text">
                        <CardImg src="/images/icons/languages.png" className="dashboard-home-icon mb mr" alt="Home Icon" />
                        Update Proficiency
                      </span>
                      )}
                    defaultOpen={false}
                    targetId="myExperience"
                  >
                    <div className="pt-2 mx-1">
                      <XcelianLanguageInfo
                        setResumeLang={setResumeLang}
                        resumeLang={resumeLang}
                      />
                    </div>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mx-1">
                <Col xs="12" lg="12 align-end pt-3">
                  <Button onClick={handleUpdateLanguage} className="job-button-approve" disabled={resumeLang.langData.length === 0}>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <div className="pt-4" />
      </Row>
    </div>
  );
};

export default XcelianResumeInput;
