import React from 'react';
import {
  Button,
  Col, Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import {
  getRecruitInterviewStatus,
  clearRecruitInterviewStatus,
  listRecruitInterviewReq,
  clearListRecruitInterviewReq,
} from '../../../../../../../../store/campusXcel/actions';
import JobsAccordion from '../../../../../../../../utils/Accordion/Jobs-Accordion';
import { errorAlert, successAlert } from '../../../../../../../../utils/alert';
import { APIHeader, UserType } from '../../../../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../../../../utils/service/localstorage-service';
import { RecruitInterviewData } from '../../../../../../../../services/staffing/interviews/list-recruit-interview/list.recruit.interview.types';
import { RecruitInterviewStatusAddDataInput } from '../../../../../../../../services/staffing/interviews/recruit-interview-status/update.recruit.sts.types';
import AllJobsInterview from './jobs-interview-list';

interface StatusProps {
  intvStatus: string;
  interviewCd: string;
}

const JobInterviewComponent: React.FC<StatusProps> = ({
  intvStatus,
  interviewCd,
}) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const isMobileJob = window.innerWidth <= 468;
  const [loadingIntv, setLoadingIntv] = React.useState(false);
  const [showErrorsIntvAlert, setErrorIntvShowAlert] = React.useState(false);
  const [showSuccessIntvAlert, setSuccessIntvShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [recordsPerPage] = React.useState<number>(30);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const listRecruitInterviewResponse = useSelector((state: RootState) => state.campusXcel.listRecruitInterview);
  const recruitInterviewStatusResponse = useSelector((state: RootState) => state.campusXcel.updateRecruitInterviewStatus);
  const [listRecruitInterview, setListRecruitInterview] = React.useState<RecruitInterviewData>({
    tCnt: 0,
    fCnt: 0,
    recruitIntvData: [],
    lastEvalKey: {
      IntvCd: '',
      IntvId: '',
    },
  });
  const [recruitInterviewStatus, setRecruitInterviewStatus] = React.useState<RecruitInterviewStatusAddDataInput>({
    IntvId: '',
    IntvCd: '',
    crntSts: '',
  });

  const handleUpdateInterviewStatus = (intvCode: string, intvType: string, currentSts: string) => {
    setLoadingIntv(true);
    const updatedRecruitInterviewStatus = {
      ...recruitInterviewStatus,
      IntvId: intvType,
      IntvCd: intvCode,
      crntSts: currentSts,
    };
    setRecruitInterviewStatus(updatedRecruitInterviewStatus);
    dispatch(getRecruitInterviewStatus({
      inputBody: updatedRecruitInterviewStatus,
      requestType: APIHeader.REQ_UPDATE_RECRUIT_INTERVIEW_STATUS,
      uTp: UserType.RCRT,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (recruitInterviewStatusResponse.error && recruitInterviewStatusResponse.message !== '') {
      setLoadingIntv(false);
      setAlertMsg(recruitInterviewStatusResponse.message);
      setErrorIntvShowAlert(true);
      dispatch(clearRecruitInterviewStatus());
    }
  }, [recruitInterviewStatusResponse.error, recruitInterviewStatusResponse.message]);

  React.useEffect(() => {
    if (!recruitInterviewStatusResponse.error && recruitInterviewStatusResponse.message === 'executed') {
      setLoadingIntv(false);
      dispatch(clearRecruitInterviewStatus());
      dispatch(clearListRecruitInterviewReq());
      dispatch(listRecruitInterviewReq({
        requestType: APIHeader.REQ_ALL_INTERVIEW_RECRUIT,
        uTp: UserType.RCRT,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        filterBy: interviewCd,
        token: tokenData,
      }));
    }
  }, [recruitInterviewStatusResponse.error, recruitInterviewStatusResponse.message]);

  React.useEffect(() => {
    if (intvStatus === 'interview' && interviewCd !== '') {
      setLoadingIntv(true);
      dispatch(listRecruitInterviewReq({
        requestType: APIHeader.REQ_ALL_INTERVIEW_RECRUIT,
        uTp: UserType.RCRT,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        filterBy: interviewCd,
        token: tokenData,
      }));
    }
  }, [intvStatus, interviewCd]);

  const fetchMoreData = () => {
    setLoadingIntv(true);
    dispatch(listRecruitInterviewReq({
      requestType: APIHeader.REQ_ALL_INTERVIEW_RECRUIT,
      uTp: UserType.RCRT,
      limit: recordsPerPage,
      evalKey: evalKeyText,
      evalTp: evalTpText,
      isFilter: 'NO',
      token: tokenData,
      isSearch: 'NO',
    }));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  React.useEffect(() => {
    if (listRecruitInterviewResponse.isError && listRecruitInterviewResponse.message !== '') {
      setLoadingIntv(false);
      setAlertMsg(listRecruitInterviewResponse.message);
      setErrorIntvShowAlert(true);
      dispatch(clearListRecruitInterviewReq());
    }
  }, [listRecruitInterviewResponse.isError, listRecruitInterviewResponse.message]);

  React.useEffect(() => {
    if (!listRecruitInterviewResponse.isError && listRecruitInterviewResponse.message === 'executed') {
      setLoadingIntv(false);
      if (
        listRecruitInterviewResponse.data
        && Object.keys(listRecruitInterviewResponse.data).length > 0
        && Array.isArray(listRecruitInterviewResponse.data.recruitIntvData)
      ) {
        const newPageData = listRecruitInterviewResponse.data.recruitIntvData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setListRecruitInterview({
          tCnt: listRecruitInterviewResponse.data.tCnt,
          fCnt: listRecruitInterviewResponse.data.fCnt,
          recruitIntvData: listRecruitInterviewResponse.data.recruitIntvData,
          lastEvalKey: listRecruitInterviewResponse.data.lastEvalKey,
        });
        if (listRecruitInterviewResponse.data.lastEvalKey !== null) {
          setEvalKeyText(listRecruitInterviewResponse.data.lastEvalKey.IntvId);
          setEvalTpText(listRecruitInterviewResponse.data.lastEvalKey.IntvCd);
        }
        setTotalRecords(listRecruitInterviewResponse.data.tCnt);
      }
    }
    dispatch(clearListRecruitInterviewReq());
  }, [listRecruitInterviewResponse.isError, listRecruitInterviewResponse.message]);

  const selectStyles = {
    singleValue: (base: any, state: any) => ({
      ...base,
      color: state.isDisabled ? '#383838' : base.color,
    }),
    menu: (base: any) => ({
      ...base,
      position: 'absolute',
    }),
    control: (base: any) => {
      // Determine if the viewport is mobile
      const isMobile = window.innerWidth <= 468; // Adjust based on your breakpoint
      return {
        ...base,
        '&:hover': { borderColor: '#fabdbd' },
        border: isMobile ? '1px solid #E1EDF8' : 'none',
        borderBottom: '1px solid #E1EDF8',
        backgroundColor: '#fff',
        minHeight: isMobile ? '55px' : '40px',
        marginRight: '10px',
        flex: 1,
        width: isMobile ? '300px' : '',
        borderRadius: isMobile ? '5px' : '1px',
        color: '#575656',
        fontSize: isMobile ? '14px' : '15px', // Change font size for mobile
        boxShadow: 'none',
        '&:focus': {
          borderColor: '#E1EDF8',
          boxShadow: 'none',
          color: '#575656',
          background: '#f9f9f9',
        },
      };
    },
    option: (provided: any, state: { isSelected: any; }) => ({
      ...provided,
      color: state.isSelected ? '#383838' : '#212121',
      padding: 15,
      textAlign: 'left',
      background: state.isSelected ? '#FAFCFB' : '#fff',
      '&:hover': {
        background: '#FAFCFB',
      },
    }),
  };

  return (
    <div className="bg-jobs-public">
      {showErrorsIntvAlert ? (
        errorAlert(false, alertMsg, showErrorsIntvAlert, setErrorIntvShowAlert)
      ) : null}
      {showSuccessIntvAlert ? (
        successAlert(false, alertMsg, showSuccessIntvAlert, setSuccessIntvShowAlert)
      ) : null}
      <Row className="pt-2">
        <Col xs="12" lg="12">
          <JobsAccordion
            title="Filter your search"
            defaultOpen={isMobileJob}
            targetId="myFilter"
            disabled={false}
          >
            <div className="search-container align-center">
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="Filter by Organization"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Location"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Skills"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Job Type"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Work Prefrence"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="Miscellaneous"
                  isDisabled
                />
              </div>
              <Button className="button-search-jobs"><FontAwesomeIcon id="jSearch" icon={faMagnifyingGlass} className="mx-2" /></Button>
            </div>
          </JobsAccordion>
        </Col>
        <Col xs="12" lg="12">
          <Row className="align-start pt-3">
            {loadingIntv ? (
              <div className="loader-campusxcel align-center">
                <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
                <span className="mx-2 text-lite">Jobs loading... <br />Please wait....</span>
              </div>
            )
              : (
                <Col lg="12" xs="12">
                  <div className="order-right-xcln-jobs">
                    <Row className="align-start">
                      <Col xs="12" lg="12">
                        <Row className="padding-zero">
                          {!isMobileJob
                            ? (
                              <Col xs="12" lg="12">
                                <div className="align-end mr-2 pt-2">
                                  <Button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 0}
                                    className="xcln-job-button-nxt "
                                  >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                  </Button>
                                  <div className="mx-2 table-margin-bot">
                                    <span className="table-text">
                                      {currentPage + 1} / {totalPages}
                                    </span>
                                  </div>
                                  <Button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage >= totalPages - 1}
                                    className="xcln-job-button-nxt"
                                  >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                  </Button>
                                </div>
                              </Col>
                            ) : null}
                        </Row>
                      </Col>
                      <Col xs="12" lg="12" className="">
                        <div className="pt-5">
                          {pagedData[currentPage] && listRecruitInterview.tCnt > 0
                            ? (
                              <AllJobsInterview
                                data={pagedData[currentPage]}
                                handleUpdateInterviewStatus={handleUpdateInterviewStatus}
                              />
                            ) : (
                              <div className="align-center">
                                <span className="table-text-no">No resumes found</span>
                              </div>
                            )}
                        </div>
                        {isMobileJob && listRecruitInterview.tCnt > 0
                          ? (
                            <div className="align-end mr">
                              <Button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 0}
                                className="xcln-job-button-nxt "
                              >
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </Button>
                              <div className="mx-2 table-margin-bot">
                                <span className="table-text">
                                  {currentPage + 1} / {totalPages}
                                </span>
                              </div>
                              <Button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage >= totalPages - 1}
                                className="xcln-job-button-nxt"
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </Button>
                            </div>
                          ) : null}
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default JobInterviewComponent;
