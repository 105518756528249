import { getConfig } from '../../../../utils/config/config';
import { getCommonApiToken } from '../../../../utils/service';
import { XcelianResumeShareOptions, XcelianResumeShareResponse } from './xcelian.share.resume.service.types';

const { CAMPUSXCEL_STAFFING_API_ROOT } = getConfig();

export const XcelianResumeShareRequest = ({
  token, requestType, uTp, xclnTp, xclnId, resCd, opsMode,
}: XcelianResumeShareOptions): Promise<XcelianResumeShareResponse> => getCommonApiToken(
  `${CAMPUSXCEL_STAFFING_API_ROOT}?uTp=${uTp}&xclnTp=${encodeURIComponent(xclnTp)}&xclnId=${encodeURIComponent(xclnId)}&resCd=${encodeURIComponent(resCd)}&opsMode=${encodeURIComponent(opsMode)}`,
  requestType,
  token,
);
