import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faTrashCan, faPenToSquare, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, Row, Col,
  CardImg,
} from 'reactstrap';
import Modal from '../../../../../../utils/Modal/Modal';
import { MyAvatar } from '../../../../common/my-avatar/avatar';

interface TableColumn {
  header: string;
  accessor: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  currentPage: number;
  recordsPerPage: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  setEditRecord: (editRecord: any) => void;
  setDeleteRecord: (deleteRecord: any) => void;
}

const AdminRecruitListClientsTable: React.FC<TableProps> = ({
  columns,
  data,
  currentPage,
  recordsPerPage,
  totalRecords,
  onPageChange,
  setEditRecord,
  setDeleteRecord,
}) => {
  const [showModalLogo, setShowModalLogo] = React.useState('');
  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);
  const handleDeleteData = (record: any) => {
    setDeleteRecord(record); // Pass the record to be deleted
  };

  const handleEditData = (record: any) => {
    setEditRecord(record); // Pass the record to be edited
  };
  const handleEditLogo = (logoData: string) => {
    setShowModalLogo(logoData);
  };

  return (
    <div className="table-container">
      {data.length > 0
        ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={`${index.toString()}`}>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={`${index.toString()}`}>
                    {columns.map((column, colIndex) => (
                      <td key={`${colIndex.toString()}`}>
                        {column.accessor
                          ? row[column.accessor]
                          : (
                            <>
                              {column.header === 'Action' && (
                              <div className="align-center">
                                <Button onClick={() => handleDeleteData(row)} className="button-icon-danger padding-zero">
                                  <FontAwesomeIcon icon={faTrashCan} className="table-icon-danger" />
                                </Button>
                                <Button onClick={() => handleEditData(row)} className="button-icon-edit">
                                  <FontAwesomeIcon icon={faPenToSquare} className="table-icon-prim" />
                                </Button>
                              </div>
                              )}
                              {column.header === 'Name' && (
                              <div className="align-center">
                                <Button
                                  onClick={() => {
                                    const url = row.web.startsWith('http://') || row.web.startsWith('https://')
                                      ? row.web
                                      : `http://${row.web}`;
                                    window.open(url, '_blank');
                                    window.scrollTo(0, 0);
                                  }}
                                  className="button-icon padding-zero"
                                >
                                  {row.nme}
                                </Button>
                              </div>
                              )}
                              {column.header === 'Phone1' && (
                              <div className="align-center">
                                {`${row.dlCd1}-${row.cntPh1}`}
                              </div>
                              )}
                              {column.header === 'Phone2' && row.cntPh2 !== '' && (
                              <div className="align-center">
                                {`${row.dlCd2} ${row.cntPh2}`}
                              </div>
                              )}
                              {column.header === 'Logo' && (
                              <div className="align-center">
                                <CardImg
                                  alt="..."
                                  className="size-client-logo"
                                  src={(row.logoUrl !== '' && row.logoUrl !== undefined && row.logoUrl !== null) ? row.logoUrl : '/images/icons/org.png'}
                                />
                                <Button onClick={() => handleEditLogo(row.ClntId)} className="button-icon">
                                  <FontAwesomeIcon className="academy-edit-icon" icon={faEdit} />
                                </Button>
                                <Modal
                                  show={showModalLogo === row.ClntId || false}
                                  onClose={() => setShowModalLogo('')}
                                  disCancel={false}
                                  targetId="showModelLogo"
                                  title=""
                                  size="MD"
                                >
                                  <div className="mx-3">
                                    <MyAvatar imgUrl={(row.logoUrl !== '' && row.logoUrl !== undefined && row.logoUrl !== null) ? row.logoUrl : '/images/icons/org.png'} fileName={`${row.ClntId}`} folderName={`staff-clients/${row.ClntCd}`} defaultFIleUrl="/images/icons/org.png" />
                                  </div>
                                  <div className="pt-3" />
                                </Modal>
                              </div>
                              )}
                            </>
                          )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Row>
              <Col xs="5" lg="5">
                <div className="align-start my-2">
                  <span className="text-lite">Total Records: {totalRecords}</span>
                </div>
              </Col>
              <Col xs="7" lg="7">
                <div className="align-end my-2">
                  <Button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                    className="table-button"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                  <div className="mx-2 table-margin-bot">
                    <span className="table-text">
                      {currentPage + 1} / {totalPages}
                    </span>
                  </div>
                  <Button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage >= totalPages - 1}
                    className="table-button"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="table-norecords">
            <span className="table-text-no">No Record Found</span>
          </div>
        )}
    </div>
  );
};

export default AdminRecruitListClientsTable;
