import { ListUserLikesResponse, ListUserLikesResults } from './list-user-likes.service.types';

export const ListUserLikesTransform: (response: ListUserLikesResponse) => ListUserLikesResults = (listUserLikesResults) => {
  const { data, is_error, message } = listUserLikesResults;
  const result: ListUserLikesResults = {
    tCnt: 0,
    fCnt: 0,
    likeUserData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.likeUserData = data.likeUserData;
    result.message = 'executed';
  }
  return result;
};

// import { ListUserLikesResponse, ListUserLikesResults } from './list-user-likes.service.types';

// export const ListUserLikesTransform: (response: ListUserLikesResponse) => ListUserLikesResults = (listUserLikesResults) => {
//   const { data, is_error, message } = listUserLikesResults;
//   const result: ListUserLikesResults = {
//     tCnt: 0,
//     fCnt: 0,
//     likeUserData: [],
//     isError: false,
//     message: '',
//   };
//   console.log('data logie', data);
//   if (is_error) {
//     result.isError = is_error;
//     result.message = message;
//   } else {
//     result.tCnt = data.tCnt;
//     result.fCnt = data.fCnt;
//     result.likeUserData = data.likeUserData;
//     result.message = 'executed';
//   }
//   return result;
// };
