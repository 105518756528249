import React from 'react';
import {
  Button, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import { OrgDetails, OrgProjInput, Projs } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';
import { errorAlert } from '../../../../../utils/alert';

export type ResumeInfoProps = {
  resumeOrgProj: OrgProjInput;
  setResumeOrgProj: (resumeOrgProj : OrgProjInput)=> void;
};

export const XcelianOrgProjInfo: React.FC<ResumeInfoProps> = ({
  resumeOrgProj,
  setResumeOrgProj,
}) => {
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');

  const handleOrgChange = (index: number, field: keyof OrgDetails, value: any) => {
    const updatedOrgData = [...resumeOrgProj.orgData];
    updatedOrgData[index] = { ...updatedOrgData[index], [field]: value };
    setResumeOrgProj({ ...resumeOrgProj, orgData: updatedOrgData });
  };

  const handleProjChange = (orgIndex: number, projIndex: number, field: keyof Projs, value: any) => {
    const updatedOrgData = [...resumeOrgProj.orgData];
    const updatedProjs = [...updatedOrgData[orgIndex].prjs];
    updatedProjs[projIndex] = { ...updatedProjs[projIndex], [field]: value };
    updatedOrgData[orgIndex].prjs = updatedProjs;
    setResumeOrgProj({ ...resumeOrgProj, orgData: updatedOrgData });
  };

  const addOrg = () => {
    const lastOrg = resumeOrgProj.orgData[resumeOrgProj.orgData.length - 1];
    if (lastOrg && (lastOrg.org === '' || lastOrg.orgLoc === '')) {
      setAlertMsg('Some Fields are empty Kindly Fill before adding next Organization');
      setErrorShowAlert(true);
      return;
    }
    if (lastOrg && !lastOrg.isCrnt && lastOrg.eDte === '') {
      setAlertMsg('End Date is required for past organizations. Kindly fill it before adding the next Organization.');
      setErrorShowAlert(true);
      return;
    }
    setResumeOrgProj({
      ...resumeOrgProj,
      orgData: [
        ...resumeOrgProj.orgData,
        {
          org: '',
          orgLoc: '',
          isCrnt: false, // Ensure this is a boolean
          sDte: '',
          eDte: '',
          dsg: '',
          prjs: [],
        },
      ],
    });
  };

  const addProj = (orgIndex: number) => {
    const org = resumeOrgProj.orgData[orgIndex];
    const lastProj = org.prjs[org.prjs.length - 1];

    // Check if last project fields are empty
    if (lastProj && (lastProj.prjNme === '' || lastProj.desc === '')) {
      setAlertMsg('Some Fields are empty. Kindly fill before adding the next Project.');
      setErrorShowAlert(true);
      return;
    }
    const updatedOrgData = [...resumeOrgProj.orgData];
    updatedOrgData[orgIndex].prjs.push({
      prjNme: '',
      isCrnt: false, // Ensure this is a boolean
      desc: '',
      skls: [],
      myRls: [],
    });
    setResumeOrgProj({ ...resumeOrgProj, orgData: updatedOrgData });
  };

  const removeOrg = (index: number) => {
    const updatedOrgData = resumeOrgProj.orgData.filter((_, i) => i !== index);
    setResumeOrgProj({ ...resumeOrgProj, orgData: updatedOrgData });
  };

  const removeProj = (orgIndex: number, projIndex: number) => {
    const updatedOrgData = [...resumeOrgProj.orgData];
    updatedOrgData[orgIndex].prjs = updatedOrgData[orgIndex].prjs.filter((_, i) => i !== projIndex);
    setResumeOrgProj({ ...resumeOrgProj, orgData: updatedOrgData });
  };

  const handleSkillKeyPress = (orgIndex: number, projIndex: number, e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter
      const newSkill = e.target.value.trim();
      if (newSkill && !resumeOrgProj.orgData[orgIndex].prjs[projIndex].skls.includes(newSkill)) {
        handleProjChange(orgIndex, projIndex, 'skls', [
          ...resumeOrgProj.orgData[orgIndex].prjs[projIndex].skls,
          newSkill,
        ]);
        e.target.value = ''; // Clear input field
      }
    }
  };

  const handleRoleKeyPress = (orgIndex: number, projIndex: number, e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter
      const newRole = e.target.value.trim();
      if (newRole && !resumeOrgProj.orgData[orgIndex].prjs[projIndex].myRls.includes(newRole)) {
        handleProjChange(orgIndex, projIndex, 'myRls', [
          ...resumeOrgProj.orgData[orgIndex].prjs[projIndex].myRls,
          newRole,
        ]);
        e.target.value = ''; // Clear input field
      }
    }
  };

  const handleDeleteSkill = (orgIndex: number, projIndex: number, skillIndex: number) => {
    const updatedOrgData = [...resumeOrgProj.orgData];

    // Filter out the skill at skillIndex
    updatedOrgData[orgIndex].prjs[projIndex].skls = updatedOrgData[orgIndex].prjs[projIndex].skls.filter((_, i) => i !== skillIndex);

    setResumeOrgProj({ ...resumeOrgProj, orgData: updatedOrgData });
  };

  const handleDeleteRole = (orgIndex: number, projIndex: number, roleIndex: number) => {
    const updatedOrgData = [...resumeOrgProj.orgData];

    // Filter out the role at roleIndex
    updatedOrgData[orgIndex].prjs[projIndex].myRls = updatedOrgData[orgIndex].prjs[projIndex].myRls.filter((_, i) => i !== roleIndex);

    setResumeOrgProj({ ...resumeOrgProj, orgData: updatedOrgData });
  };

  return (
    <Form>
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {resumeOrgProj.orgData.map((org, orgIndex) => {
        const isAnyOrgCurrent = resumeOrgProj.orgData.some((orgCrnt) => orgCrnt.isCrnt); // Check if any org is current
        return (
          <div key={`${orgIndex.toString()}`} className="mb-3">
            <div className="align-end">
              <Button className="button-icon" onClick={() => removeOrg(orgIndex)}>
                <FontAwesomeIcon className="xcelian-resume-project-icon" icon={faMinusSquare} />
              </Button>
            </div>
            <span className="xcelian-resume-org-text">{org.org !== '' ? `${org.org} (${org.orgLoc})` : `Organization ${orgIndex + 1}`}</span>
            <Row>
              <Col xs="12 pt-3">
                <FormGroup>
                  <Label for={`org-${orgIndex}`} className="text-lite ml">Organization Name</Label>
                  <Input
                    type="text"
                    id={`org-${orgIndex}`}
                    value={org.org}
                    onChange={(e) => handleOrgChange(orgIndex, 'org', e.target.value)}
                    className="campus-input"
                  />
                </FormGroup>
              </Col>
              <Col xs="12 pt-2">
                <FormGroup>
                  <Label for={`orgLoc-${orgIndex}`} className="text-lite ml">Location</Label>
                  <Input
                    type="text"
                    id={`orgLoc-${orgIndex}`}
                    value={org.orgLoc}
                    onChange={(e) => handleOrgChange(orgIndex, 'orgLoc', e.target.value)}
                    className="campus-input"
                  />
                </FormGroup>
              </Col>
              <Col xs="12 pt-2">
                <FormGroup>
                  <Label for={`dsg-${orgIndex}`} className="text-lite ml">Designation</Label>
                  <Input
                    type="text"
                    id={`dsg-${orgIndex}`}
                    value={org.dsg}
                    onChange={(e) => handleOrgChange(orgIndex, 'dsg', e.target.value)}
                    className="campus-input"
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup check className="ml my-1">
              <Label check>
                <Input
                  type="checkbox"
                  checked={org.isCrnt}
                  disabled={isAnyOrgCurrent && !org.isCrnt} // Disable if another org is current
                  onChange={(e) => handleOrgChange(orgIndex, 'isCrnt', e.target.checked)}
                  className="campus-check-box"
                />{' '}
                <span className={isAnyOrgCurrent && !org.isCrnt ? 'xcelian-resume-project-header-dis' : 'xcelian-resume-project-header'}>Is this Current Organization</span>
              </Label>
            </FormGroup>
            <Row>
              <Col xs="12 pt-2" lg="6 pt-2">
                <FormGroup>
                  <Label for={`sDte-${orgIndex}`} className="text-lite ml">Start Date</Label>
                  <Input
                    type="date"
                    id={`sDte-${orgIndex}`}
                    value={org.sDte}
                    onChange={(e) => handleOrgChange(orgIndex, 'sDte', e.target.value)}
                    className="campus-input"
                  />
                </FormGroup>
              </Col>
              <Col xs="12 pt-2" lg="6 pt-2">
                <FormGroup>
                  <Label for={`eDte-${orgIndex}`} className="text-lite ml">End Date</Label>
                  <Input
                    type="date"
                    id={`eDte-${orgIndex}`}
                    value={org.eDte}
                    disabled={org.isCrnt} // Disable end date if it's the current organization
                    onChange={(e) => handleOrgChange(orgIndex, 'eDte', e.target.value)}
                    className="campus-input"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Row className="pt-3">
                  <Col xs="12">
                    <div className={org.prjs.length > 0 ? 'align-start' : 'align-center'}>
                      <Button className="xcelian-upload-box" onClick={() => addProj(orgIndex)} disabled={org.prjs.length > 0}>
                        Add New Project
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12">
                {org.prjs.map((proj, projIndex) => {
                  const isAnyProjCurrent = org.prjs.some((projcts) => projcts.isCrnt);
                  const isLastProj = projIndex === org.prjs.length - 1; // Check if any project is current
                  return (
                    <div key={`${projIndex.toString()}`} className="mb-2">
                      <Row>
                        <Col xs="12 pt-2">
                          <FormGroup>
                            <Label for={`prjNme-${orgIndex}-${projIndex}`} className="text-lite ml">Project Name</Label>
                            <Input
                              type="text"
                              id={`prjNme-${orgIndex}-${projIndex}`}
                              value={proj.prjNme}
                              onChange={(e) => handleProjChange(orgIndex, projIndex, 'prjNme', e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="12 pt-2">
                          <FormGroup>
                            <Label for={`desc-${orgIndex}-${projIndex}`} className="text-lite ml">Description</Label>
                            <Input
                              type="textarea"
                              id={`desc-${orgIndex}-${projIndex}`}
                              value={proj.desc}
                              onChange={(e) => handleProjChange(orgIndex, projIndex, 'desc', e.target.value)}
                              className="campus-text-area-desc"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="12 pt-2">
                          <FormGroup>
                            <Label for={`skls-${orgIndex}`} className="text-lite ml">Skills</Label>
                            <Input
                              type="text"
                              id={`skls-${orgIndex}`}
                              onKeyDown={(e) => handleSkillKeyPress(orgIndex, projIndex, e)}
                              className="campus-input"
                            />
                            <div className="pt-1 ml">
                              {org.prjs[projIndex].skls.map((skill, index) => (
                                <span key={`prjskls-${index.toString()}`} className="chip-box mx-1">{skill}
                                  <Button
                                    onClick={() => handleDeleteSkill(orgIndex, projIndex, index)}
                                    className="button-icon padding-zero ml mb-1"
                                    aria-label={`Delete ${skill}`}
                                  >
                                    &times;
                                  </Button>
                                </span>
                              ))}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xs="12 pt-2">
                          <FormGroup>
                            <Label for={`myRls-${orgIndex}`} className="text-lite ml">My Roles</Label>
                            <Input
                              type="text"
                              id={`myRls-${orgIndex}`}
                              onKeyDown={(e) => handleRoleKeyPress(orgIndex, projIndex, e)}
                              className="campus-input"
                            />
                            <div className="pt-2 ml">
                              {org.prjs[projIndex].myRls.map((role, index) => (
                                <div key={`prjrols-${index.toString()}`}>
                                  <span className="xcelian-resume-project-header">
                                    <FontAwesomeIcon icon={faCircleDot} className="xcelian-resume-project-role-icon mr" />
                                    {role}
                                  </span>
                                  <Button
                                    onClick={() => handleDeleteRole(orgIndex, projIndex, index)}
                                    className="button-icon padding-zero ml-1 mb-1"
                                    aria-label={`Delete ${role}`}
                                  >
                                    &times;
                                  </Button>
                                </div>
                              ))}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xs="12 pt-1">
                          <FormGroup check className="ml">
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={proj.isCrnt}
                                disabled={isAnyProjCurrent && !proj.isCrnt} // Disable if another project is current
                                onChange={(e) => handleProjChange(orgIndex, projIndex, 'isCrnt', e.target.checked)}
                                className="campus-check-box"
                              />{' '}
                              <span className={isAnyProjCurrent && !proj.isCrnt ? 'xcelian-resume-project-header-dis' : 'xcelian-resume-project-header'}>Is this Current Project</span>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs="12 pt-2">
                          <div className="align-end">
                            <Button className="button-icon mr" onClick={() => removeProj(orgIndex, projIndex)}>
                              <FontAwesomeIcon className="xcelian-resume-project-icon" icon={faMinusSquare} />
                            </Button>
                            <Button className="xcelian-upload-box" onClick={() => addProj(orgIndex)} disabled={!isLastProj || proj.prjNme === '' || proj.desc === ''}>
                              Add More Projects
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Col>
            </Row>
            <hr className="hr-lite my-4" />
          </div>
        );
      })}
      <div className="align-center">
        <Button className="campus-button-all" onClick={addOrg}>
          Add New Organization
        </Button>
      </div>
    </Form>

  );
};

export default XcelianOrgProjInfo;
