import { faCaretRight, faIndianRupee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import {
  batch, Fade, Animator, Move,
} from 'react-scroll-motion';
import {
  Button, Card, CardImg, Col, Modal, ModalHeader, Row,
} from 'reactstrap';
import './training.scss';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import Navbar from '../navbar/cx.navbar';
import { ContactForm } from '../contact-form/contact-form';

export const IntroTraining: React.FC = () => {
  const history = useNavigate();
  const FadeUp = batch(Fade(), Move());
  const [openContactIndex, setOpenContactIndex] = React.useState<number | null>(null);
  const toggleContact = (index: number) => {
    if (openContactIndex === index) {
      setOpenContactIndex(null);
    } else {
      setOpenContactIndex(index);
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  const courses = [
    {
      id: 1,
      image: '/images/training/python.jpg',
      title: 'Backend Developer with Python',
      description: 'Real-world project exposure, mentorship from industry professionals, and a career-focused curriculum.',
      features: [
        'Master Python backend frameworks like Django/Flask',
        'Build secure and scalable REST APIs with real-world projects',
        'Database integration using PostgreSQL/MongoDB',
      ],
      price: '12,000',
      oldPrice: '18,000',
      duration: '40 hrs/5 Months',
    },
    {
      id: 2,
      image: '/images/training/react.jpg',
      title: 'Frontend Developer with React',
      description: 'Real-world project exposure, mentorship from industry professionals, and a career-focused curriculum.',
      features: [
        'Learn to create dynamic, responsive web apps using React',
        'Work with TypeScript for scalable and robust front-end code',
        'Gain hands-on experience with state management tools like Redux',
      ],
      price: '15,000',
      oldPrice: '22,000',
      duration: '50 hrs/6 Months',
    },
    {
      id: 3,
      image: '/images/training/react-python.jpg',
      title: 'Full Stack Developer (Python + React)',
      description: 'Real-world project exposure, mentorship from industry professionals, and a career-focused curriculum.',
      features: [
        'Combine Python backend with React frontend to build end-to-end web applications',
        'Hands-on training in API integration and deployment using Docker',
        'Learn project architecture and CI/CD implementation',
      ],
      price: '25,000',
      oldPrice: '38,000',
      duration: '80 hrs/8 Months',
    },
    {
      id: 4,
      image: '/images/training/aws-react-py.jpg',
      title: 'Cloud-First Full Stack Developer with AWS',
      description: 'Real-world project exposure, mentorship from industry professionals, and a career-focused curriculum.',
      features: [
        'Build serverless applications with AWS Lambda and API Gateway using React and Python',
        'Master AWS services like DynamoDB, S3, and CloudWatch.',
        'Understand cost optimization and scaling using cloud-native architecture',
      ],
      price: '38,000',
      oldPrice: '49,000',
      duration: '120 hrs/8 Months',
    },
  ];
  return (
    <div>
      <Navbar />
      <Animator animation={batch(FadeUp)} className="training-bg">
        <Row className="align-center">
          <Col lg="7">
            <div className="mx-2 text-center-aligned">
              <span className="display-6 training-intro-header">
                Empowering Industry-Ready Innovators with Expertise Beyond Boundaries
              </span>
            </div>
            <div className="pt-5 text-center-aligned mx-3">
              <span className="text-intro-training pt-3">
                An inventive online learning platform committed to shaping job-ready individuals,
                instilling confidence through immersive and hands-on training that encompasses
                both soft skills and tech expertise.
              </span>
            </div>
            <div className="pt-4 mx-2 text-center-aligned">
              <span className="training-text-360-header pt-5">Hands-on skills, job prep, or mentorship aspirations. <br />Click to Register </span>
            </div>
            <div className="my-5 align-center">
              <Button onClick={() => { history('/xcelian/register'); window.scrollTo(0, 0); }} className="intro-btn-filled">Join for Free <FontAwesomeIcon icon={faCaretRight} /></Button>
            </div>
          </Col>
          <Col lg="5">
            <div className="align-center1">
              <CardImg
                alt="..."
                src="/images/training/training.jpg"
              />
            </div>
          </Col>
        </Row>
        <div className="align-center pt-5">
          <span className="text-intro-training">SkillForge: Ignite Excellence</span>
        </div>
        <Row className="align-center pt-4 mx-2">
          <Col lg="2" xs="6" className="pt-3">
            <Card className="align-center mp-1 train-intro-min-margin">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights1"
                  src="/images/training/industry.png"
                />
              </div>
              <div className="text-center-aligned pt-2">
                <span className="text-about">Industry-Driven Learning</span>
              </div>
            </Card>
          </Col>
          <Col lg="2" xs="6" className="pt-3">
            <Card className="align-center mp-1 train-intro-min-margin">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights-3c1"
                  src="/images/training/selfpaced.png"
                />
              </div>
              <div className="text-center-aligned pt-2">
                <span className="text-about">Self-Paced and Guided</span>
              </div>
            </Card>
          </Col>
          <Col lg="2" xs="6" className="pt-3">
            <Card className="align-center mp-1 train-intro-min-margin">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights1"
                  src="/images/home/key-pointers/training.png"
                />
              </div>
              <div className="text-center-aligned pt-2">
                <span className="text-about">Hands-On Experience</span>
              </div>
            </Card>
          </Col>
          <Col lg="2" xs="6" className="pt-3">
            <Card className="align-center mp-1 train-intro-min-margin">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights1"
                  src="/images/training/career-centric.png"
                />
              </div>
              <div className="text-center-aligned pt-2">
                <span className="text-about">Career-Focused Pathway</span>
              </div>
            </Card>
          </Col>
          <Col lg="2" xs="6" className="pt-3">
            <Card className="align-center mp-1 train-intro-min-margin">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights1"
                  src="/images/training/proj-centric.png"
                />
              </div>
              <div className="text-center-aligned pt-2">
                <span className="text-about">Project-Centric Approach</span>
              </div>
            </Card>
          </Col>
          <Col lg="2" xs="6" className="pt-3">
            <Card className="align-center mp-1 train-intro-min-margin">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights1"
                  src="/images/training/blue-print.png"
                />
              </div>
              <div className="text-center-aligned pt-2">
                <span className="text-about">Bridging Knowledge to Execution</span>
              </div>
            </Card>
          </Col>
        </Row>
        <div className="text-center-aligned pt-5">
          <span className="text-intro-training pt-5">Diverse Training Programs for Comprehensive Skill Development</span>
        </div>
        {courses.map((course, indexCourse) => (
          <Row key={course.id} className="align-center pt-4 mx-1">
            <Col lg="8" xs="12" className="pt-3">
              <Card className="card-shadow mp">
                <Row>
                  <Col lg="4">
                    <div className="align-center mp-1">
                      <CardImg alt="..." src={course.image} />
                    </div>
                  </Col>
                  <Col lg="8">
                    <Row className="pt-2">
                      <Col xs="12">
                        <div className="text-center-aligned">
                          <h5 className="training-intro-header">{course.title}</h5>
                        </div>
                        <div className="line-height-cx mx-1 pt-2">
                          <span className="text-lite-grey-sub-train">{course.description}</span>
                        </div>
                      </Col>
                      <Col xs="12">
                        {course.features.map((feature, index) => (
                          <div key={`${index.toString}`} className="pt-1">
                            <span className="text-lite-grey-sub-train">
                              <FontAwesomeIcon icon={faCircleCheck} className="train-blue-icon mx-1" />
                              {feature}
                            </span>
                          </div>
                        ))}
                      </Col>
                      <Col xs="6" lg="6">
                        <div className="pt-3">
                          <span className="text-lite-grey-sub-train">
                            <FontAwesomeIcon icon={faIndianRupee} className="mx-1" />
                            {course.price}
                          </span>
                          <span className="text-lite-grey-sub-train-strike ml-1">
                            <FontAwesomeIcon icon={faIndianRupee} className="mx-1" />
                            {course.oldPrice}
                          </span>
                        </div>
                      </Col>
                      <Col xs="6" lg="6">
                        <div className="pt-3">
                          <span className="text-lite-grey-sub-train">Duration: {course.duration}</span>
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="align-end my-1 mx-1">
                          <Button className="campus-button-all mx-1" onClick={() => toggleContact(indexCourse)}>
                            Enquire
                          </Button>
                        </div>
                        <div className="fixed-top-modal1">
                          <Modal
                            isOpen={openContactIndex === indexCourse}
                            className="fixed-top-modal"
                            // fullscreen
                          >
                            <ModalHeader toggle={() => toggleContact(indexCourse)} className="my-2" />
                            <div className="pt-2">
                              <ContactForm userFrom="TRAIN" />
                            </div>
                          </Modal>
                          {/* <Modal
                            show={openContactIndex === indexCourse}
                            onClose={() => toggleContact(indexCourse)}
                            disCancel={false}
                            targetId={`Contact-${indexCourse}`}
                            title=""
                            size="MD"
                          >
                            <ContactForm userFrom="TRAIN" />
                          </Modal> */}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ))}
        <div className="pt-5" />
      </Animator>
    </div>
  );
};

export default IntroTraining;
