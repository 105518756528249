import { MediaByIdResponse, MediaByIdResults } from './media-by-id.service.types';

export const MediaByIdTransform: (response: MediaByIdResponse) => MediaByIdResults = (jobPositionByIdResults) => {
  const { data, is_error, message } = jobPositionByIdResults;
  const result :MediaByIdResults = {
    data: {
      MediaTp: '',
      MediaId: '',
      medSts: '',
      newsKey: '',
      subLine: '',
      subTtl: '',
      updtOn: '',
      author: {
        fNme: '',
        lNme: '',
      },
      previewUrl: '',
      descHtmlUrl: '',
    },
    isError: false,
    message: '',
  };

  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
