import React from 'react';
import { ListCampusProps } from '../../onboard.type';
import CXTable from '../../../../../../utils/CXTable/cx.table';

export const AllRecruitOrgList: React.FC<ListCampusProps> = ({
  pagedData, currentPage, recordsPerPage, totalRecords, handlePageChange, setEditRecord, setDeleteRecord,
}) => {
  const columns = [
    { header: 'Client ID', accessor: 'ClntId' },
    { header: 'Recruit Org', accessor: 'nme' },
    { header: 'Email', accessor: 'cntEId' },
    { header: 'Phone1', accessor: '' },
    { header: 'Phone2', accessor: '' },
    { header: 'Action', accessor: '' },
  ];
  return (
    <div>
      <CXTable
        tableId="recruit"
        columns={columns}
        data={pagedData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        setEditRecord={setEditRecord}
        setDeleteRecord={setDeleteRecord}
        setOtherObj={() => (false)}
        oSts=""
      />
    </div>
  );
};

export default AllRecruitOrgList;
