import { JobByDetailsResponse, JobByDetailsResults } from './job-details.service.types';

export const JobByDetailsTransform: (response: JobByDetailsResponse) => JobByDetailsResults = (jobPositionByIdResults) => {
  const { data, is_error, message } = jobPositionByIdResults;
  const result :JobByDetailsResults = {
    data: {
      pStg: '',
      JobCd: '',
      JobId: '',
      forClnt: '',
      nme: '',
      pCd: '',
      wrkPref: '',
      city: '',
      st: '',
      addr: '',
      cNme: '',
      logoUrl: '',
      jobTp: [],
      schdl: '',
      oSchdl: '',
      isPlndDt: false,
      plndDt: '',
      vacncy: 0,
      timelne: '',
      payBy: '',
      amnt: '',
      amntTp: '',
      suppPay: [],
      oSuppPay: [],
      perk: [],
      oPerk: [],
      jdFile: '',
      jDCntPh: '',
      dailUpdEml: '',
      potCndEml: '',
      isFemale: '',
      isAbled: '',
      skills: [],
      oSkills: [],
      desg: '',
    },
    isError: false,
    message: '',
  };

  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
