import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Card, CardHeader, CardBody, Button, Form, Input, CardFooter,
  CardImg,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../../../utils/service/localstorage-service';
import { NResumeTypeProps } from '../../resume.type';
import { CertAddDataInput } from '../../../../../../../services/campus/certifications/update-cert/update-cert.types';
import { APIHeader, UserType } from '../../../../../../../utils/constants';
import {
  clearCert,
  clearResultCert,
  clearUploadS3FilesReq, getCert, uploadS3FilesReq,
} from '../../../../../../../store/campusXcel/actions';
import { getConfig } from '../../../../../../../utils/config/config';
import { UploadS3FilesDataInput } from '../../../../../../../services/common/upload-s3-files/upload-s3-files.types';
import { FileDetails } from '../../../../../../../services/common/get-s3-files/get-s3files.types';
import Modal from '../../../../../../../utils/Modal/Modal';

const { AWS_CXUSER_BUCKET } = getConfig();

export const AddNewCertifications: React.FC<NResumeTypeProps> = ({
  rootResumeTp,
  resumeTp,
  setOperationTp,
  setAlertMsg,
  setLoaderResume,
  setSuccessShowAlert,
  setErrorShowAlert,
  showErrorsAlert,
  setIsAddApiCld,
}) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileLocalStorage();
  const [userId, setUserId] = React.useState('');
  const [modalConfirm, setModalConfirm] = useState(false);
  const [filePreview, setFilePreview] = useState('/images/common/certificate.png');
  const uploadCertS3AwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const certResponse = useSelector((state: RootState) => state.campusXcel.updateCert);
  const [minEndDate, setMinEndDate] = useState('');
  const [certImage, setCertImage] = React.useState<UploadS3FilesDataInput>({
    fileData: new FormData(),
  });
  const [myCert, setMyCert] = useState<CertAddDataInput>({
    id: '',
    ops: 'UPDATE',
    nme: '',
    certF: '',
    sDte: moment(new Date()).format('YYYY-MM-DD'),
    eDte: moment(new Date()).format('YYYY-MM-DD'),
    desc: '',
  });

  const extractFirst4AndLast3Letters = (nme: string, startDt: string): string => {
    const trimmedName = nme.trim();
    const trimDate = startDt.trim();
    if (trimmedName.length < 7) {
      setAlertMsg('Certification Name must contain at least 7 letters.');
      setErrorShowAlert(true);
      return '';
    }
    const first4Letters = trimmedName.slice(0, 4);
    const last3Letters = trimmedName.slice(-3);
    const result = `${first4Letters}${last3Letters}${trimDate}`;
    return result.toUpperCase();
  };

  React.useEffect(() => {
    if (resumeTp === 'add-cert' && rootResumeTp === 'resume-act') {
      setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    }
  }, [resumeTp, rootResumeTp, userProfile && JSON.parse(userProfile).profileData.UserCd]);

  const handleMyCert = (e:any) => {
    setMyCert({ ...myCert, [e.target.name]: e.target.value });
  };
  const handleMyCertDate = (e:any) => {
    const dateData = moment(e.target.value).format('YYYY-MM-DD');
    setMyCert({ ...myCert, [e.target.name]: dateData });
    if (e.target.name === 'sDte') {
      const formattedStartDate = moment(e.target.value).format('YYYY-MM-DD');
      setMinEndDate(formattedStartDate);
      setMyCert({ ...myCert, sDte: dateData, eDte: formattedStartDate });
    }
  };

  const handleCertImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 2 * 1024 * 1024;
    const formData = new FormData();
    const newProdImgList: FileDetails[] = [];
    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 2 MB.');
        setErrorShowAlert(true);
        return;
      }
      // Rename the file to have a .png extension
      const newFile = new File([file], `${file.name.split('.')[0]}.png`, { type: 'image/png' });
      formData.append(newFile.name, newFile);
      const objectUrl = URL.createObjectURL(newFile);
      setFilePreview(objectUrl);
      newProdImgList.push({ url: objectUrl, nme: newFile.name });
    });
    setCertImage({ ...certImage, fileData: formData });
    setMyCert({ ...myCert, certF: `${userId}-${extractFirst4AndLast3Letters(myCert.nme, myCert.sDte)}` });
  };

  const handlePreview = (e:any) => {
    e.preventDefault();
    setModalConfirm(true);
  };
  const handleSubmitCert = (status: string) => {
    if (status === 'SAVE' && userId !== '' && myCert.ops === 'UPDATE') {
      setLoaderResume(true);
      setModalConfirm(false);
      dispatch(uploadS3FilesReq({
        bucketNme: AWS_CXUSER_BUCKET,
        folderName: `campus-cert/${userProfile && JSON.parse(userProfile).profileData.cmpsCd}/${userProfile && JSON.parse(userProfile).profileData.UserCd}`,
        fileNmeInit: myCert.certF,
        formData: certImage.fileData,
        requestType: '',
        isEdit: false,
      }));
      setMyCert({ ...myCert, certF: `${userId}` });
    } else {
      setModalConfirm(false);
    }
  };

  React.useEffect(() => {
    if (uploadCertS3AwsResponse.fileName !== '' && resumeTp === 'add-cert' && rootResumeTp === 'resume-act') {
      const extractedFileName = uploadCertS3AwsResponse.fileName.split('/').pop();
      setMyCert((prevCert) => ({
        ...prevCert,
        certF: extractedFileName || '',
      }));
      if (extractedFileName) {
        dispatch(getCert({
          inputBody: {
            ...myCert,
            certF: extractedFileName,
          },
          requestType: APIHeader.REQ_UPDATE_CERT,
          uTp: UserType.CMPS,
          token: tokenData,
        }));
        dispatch(clearResultCert());
      }
    }
  }, [uploadCertS3AwsResponse.fileName, rootResumeTp, resumeTp]);

  React.useEffect(() => {
    if (certResponse.error && certResponse.message !== '' && resumeTp === 'add-cert' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(certResponse.message);
      setErrorShowAlert(true);
    }
  }, [certResponse.error, certResponse.message, rootResumeTp, resumeTp]);

  React.useEffect(() => {
    if (!certResponse.error && certResponse.message !== '' && resumeTp === 'add-cert' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(certResponse.message);
      setSuccessShowAlert(true);
      dispatch(clearUploadS3FilesReq());
      dispatch(clearCert());
      Promise.resolve(
        setMyCert({
          id: '',
          ops: 'UPDATE',
          nme: '',
          certF: '',
          sDte: moment(new Date()).format('YYYY-MM-DD'),
          eDte: moment(new Date()).format('YYYY-MM-DD'),
          desc: '',
        }),
      ).then(() => {
        setCertImage({ fileData: new FormData() });
      }).then(() => {
        setIsAddApiCld(true);
      }).then(() => {
        setOperationTp('list-cert');
      });
    }
  }, [certResponse.error, certResponse.message, rootResumeTp, resumeTp]);

  const isFileDataEmpty = (fileData: FormData): boolean => Array.from(fileData.entries()).length === 0;

  return (
    <div>
      <Row className="align-center">
        <Col lg="10" xs="12">
          <Form onSubmit={handlePreview}>
            <Card className="form-card">
              <CardHeader className="form-card-header align-center my-2">
                Add New Certifications
              </CardHeader>
              <CardBody>
                <Row className="mx-1">
                  <Col xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="text-lite ml-1">Certificate Name<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter Your Certificate Name"
                        type="text"
                        name="nme"
                        required
                        value={myCert.nme}
                        onChange={(e) => handleMyCert(e)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col lg="6" xs="12">
                    <div className="text-right-login-campus-register mx-1 pt-4">
                      <h6 className="text-lite ml-1">Start Date<span className="mandate-star">*</span></h6>
                      <Input
                        type="date"
                        name="sDte"
                        required
                        value={myCert.sDte}
                        onChange={(event) => handleMyCertDate(event)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col lg="6" xs="12">
                    <div className="text-right-login-campus-register mx-1 pt-4">
                      <h6 className="text-lite ml-1">End Date<span className="mandate-star">*</span></h6>
                      <Input
                        type="date"
                        name="eDte"
                        required
                        value={myCert.eDte}
                        min={minEndDate}
                        onChange={(event) => handleMyCertDate(event)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="text-right-login-campus-register mx-1 pt-4">
                      <h6 className="text-lite ml-1">Description<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter Your Certificate Description"
                        type="textarea"
                        name="desc"
                        required
                        value={myCert.desc}
                        onChange={(e) => handleMyCert(e)}
                        className="campus-text-area-desc"
                      />
                    </div>
                    <span className="text-lite-grey ml-1">Describe more about your certifications.</span>
                  </Col>
                  <Col xs="12">
                    <div className="text-right-login-campus-register mx-1 pt-5">
                      <h6 className="text-lite-grey">Upload Certificate</h6>
                      <Input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        name="fileData"
                        className="profile-image-btn"
                        onChange={(e) => handleCertImage(e)}
                      />
                    </div>
                    <span className="text-lite-grey ml-1">Certificate gives more recognitions</span>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="form-card-footer">
                <div className="align-center">
                  <Button className="campus-button-all mr mb-2" disabled={showErrorsAlert || isFileDataEmpty(certImage.fileData)}>
                    Preview
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>
      <Modal
        show={modalConfirm || false}
        onClose={() => (false)}
        disCancel
        targetId="certCampusData"
        title=""
        size="MD"
      >
        <Row className="my-2 mx-2">
          <Col xs="12">
            <img
              alt="..."
              height="30px"
              width="30px"
              src="/images/common/medal.png"
            />
            <span className="input-header-text ml-1">{myCert.nme}</span>
          </Col>
          <Col xs="12">
            <Row>
              <Col lg="7" xs="12">
                <Row className="align-center">
                  <Col lg="6" xs="6">
                    <div className="align-center pt-4">
                      <span className="input-checkbox-text-grey">{moment(myCert.sDte).format('DD-MMM-YYYY')}</span>
                    </div>
                    <div className="align-center">
                      <span className="text-lite-grey">Valid From</span>
                    </div>
                  </Col>
                  <Col lg="6" xs="6">
                    <div className="align-center pt-4">
                      <span className="input-checkbox-text-grey">{moment(myCert.eDte).format('DD-MMM-YYYY')}</span>
                    </div>
                    <div className="align-center">
                      <span className="text-lite-grey">Valid Till</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="5" xs="12">
                <div className="align-center my-2">
                  <CardImg
                    alt="..."
                    src={filePreview}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <span className="input-checkbox-text-grey">{myCert.desc}</span>
          </Col>
        </Row>
        <div className="align-end my-2 mr">
          <Button className="button-icon mx-1" onClick={() => handleSubmitCert('SAVE')}>
            SAVE
          </Button>
          <Button className="campus-button-all mx-1" onClick={() => handleSubmitCert('CANCEL')}>
            CANCEL
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default AddNewCertifications;
