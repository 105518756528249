import React, { useState, ReactNode } from 'react';
import './accord.scss';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface AccordionProps {
  title: any;
  children: ReactNode;
  defaultOpen: boolean;
  targetId: string;
  disabled: boolean; // Add the disabled prop
}

const JobsAccordion: React.FC<AccordionProps> = ({
  title,
  children,
  defaultOpen = false,
  targetId,
  disabled = false, // Default to false
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    if (!disabled) {
      setIsOpen(!isOpen); // Only toggle if not disabled
    }
  };

  return (
    <div
      className={`accordion-jobs ${isOpen ? 'open' : ''} ${disabled ? 'disabled' : ''}`}
      id={targetId}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer', // Provide a visual indication
        opacity: disabled ? 0.6 : 1, // Dim the accordion if disabled
      }}
    >
      <Button
        className="accordion-jobs-header"
        onClick={toggleAccordion}
        disabled={disabled} // Disable button interaction
      >
        <div className="ml-1">
          {title}
        </div>
        {isOpen
          ? <FontAwesomeIcon icon={faChevronUp} className="accordion-jobs-toggle-open mr" />
          : <FontAwesomeIcon icon={faChevronDown} className="accordion-jobs-toggle-closed mr" />}
      </Button>
      {isOpen && !disabled && <div className="accordion-jobs-content">{children}</div>}
    </div>
  );
};

export default JobsAccordion;
