import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardImg,
  Col, Form, Input, Row,
} from 'reactstrap';
import SunEditor from 'suneditor-react';
import '../dashboard.scss';
import { NewsletterInput } from '../../../../../services/sadmin/newsletter/update-newsletter/update.newsletter.types';
import { UploadS3FilesDataInput } from '../../../../../services/common/upload-s3-files/upload-s3-files.types';
import { FileDetails } from '../../../../../services/common/get-s3-files/get-s3files.types';

export type TabProps = {
    updateNewsLetter: NewsletterInput;
    setUpdateNewsLetter: (updateNewsLetter: NewsletterInput) => void;
    handleUploadNewsletter:(e: any) => void;
    setAlertMsg:(e: string) => void;
    setErrorShowAlert:(e: boolean) => void;
    previewImageFile: UploadS3FilesDataInput;
    setPreviewImageFile:(previewImageFile: UploadS3FilesDataInput) => void;
    newsHtml: UploadS3FilesDataInput;
    setNewsHtml:(newsHtml: UploadS3FilesDataInput) => void;
};

export const AddNewsLetter: React.FC<TabProps> = ({
  updateNewsLetter,
  setUpdateNewsLetter,
  handleUploadNewsletter,
  setAlertMsg,
  setErrorShowAlert,
  previewImageFile,
  setPreviewImageFile,
  newsHtml,
  setNewsHtml,
}) => {
  const [initNewsKeyword, setInitNewsKeyword] = React.useState('');
  const [contents, setContents] = React.useState('');
  const [htmlFileName, setHtmlFileName] = React.useState('');
  const htmlFileNameRef = React.useRef(htmlFileName);
  const [fileNamePreview, setFileNamePreview] = React.useState('');
  const [filePreview, setFilePreview] = React.useState('/images/common/defImage.png');

  const handlePreviewImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 1 * 1024 * 1024;
    const formData = new FormData();
    const newProdImgList: FileDetails[] = [];
    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 1 MB.');
        setErrorShowAlert(true);
        return;
      }
      const extension = file.name.split('.').pop();
      const uniqueFileName = `${fileNamePreview}_preview.${extension}`;
      const newFile = new File([file], `${uniqueFileName}.${extension}`, { type: 'image/png' });
      formData.append(newFile.name, newFile);
      const objectUrl = URL.createObjectURL(newFile);
      setFilePreview(objectUrl);
      newProdImgList.push({ url: objectUrl, nme: newFile.name });
      setUpdateNewsLetter({ ...updateNewsLetter, logoFile: uniqueFileName });
    });
    setPreviewImageFile({ ...previewImageFile, fileData: formData });
  };

  React.useEffect(() => {
    const timestamp = Date.now();
    const generatedFileName = `${timestamp}.html`;
    setHtmlFileName(generatedFileName);
    setUpdateNewsLetter({ ...updateNewsLetter, fileName: generatedFileName });
    setFileNamePreview(`${timestamp}`);
    htmlFileNameRef.current = generatedFileName;
  }, []);

  const handleEditorEvent = (content: string) => {
    setContents(content);
    const formData = new FormData();
    formData.append(htmlFileNameRef.current, new Blob([content], { type: 'text/html' }));
    setNewsHtml({ ...newsHtml, fileData: formData });
  };

  const handleChangeUpdateNewsKeyword = (e: any) => {
    setInitNewsKeyword(e.target.value);
  };

  const handleChangeNewsletter = (e: any) => {
    setUpdateNewsLetter({ ...updateNewsLetter, [e.target.name]: e.target.value });
  };

  const handleKeyPressJobKeyword = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' && initNewsKeyword.trim()) {
      e.preventDefault();
      const newJobKeyword = initNewsKeyword.split(',').map((oKey) => oKey.trim());
      setUpdateNewsLetter({
        ...updateNewsLetter,
        newsKey: [...updateNewsLetter.newsKey, ...newJobKeyword],
      });
      setInitNewsKeyword('');
    }
  };

  const removeJobKeyword = (newsKeyToRemove: string) => {
    setUpdateNewsLetter({
      ...updateNewsLetter,
      newsKey: updateNewsLetter.newsKey.filter((nKey) => nKey !== newsKeyToRemove),
    });
  };

  return (
    <div>
      <Row className="align-center pt-3">
        <Col xs="12" lg="11">
          <div className="mx-2 text-center-aligned mb-2">
            <span className="text-lite">
              Grab the reader’s attention with a captivating subject line. Keep it concise and engaging to encourage opening the email.
            </span>
          </div>
        </Col>
        <Col lg="12" xs="12">
          <Row className="mr">
            <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
              <div className="newsletter-container">
                {/* Subject Line */}
                <h2 className="newsletter-title">
                  Grab the reader’s attention with a captivating subject line.
                </h2>
                <p className="newsletter-subtitle">
                  Keep it concise and engaging to encourage opening the email.
                </p>
                <p className="newsletter-example">
                  Example: “Top Industry Insights You Can’t Miss This Month”
                </p>

                {/* Introduction */}
                <h3 className="newsletter-section-title">Introduction</h3>
                <p className="newsletter-text">
                  Start with a quick and friendly greeting. Provide a brief overview of the content to set expectations and pique the reader&apos;s interest.
                </p>

                {/* Main Content */}
                <h3 className="newsletter-section-title">Main Content</h3>
                <ul className="newsletter-list">
                  <li><strong>Blog/article summaries:</strong> Highlight key takeaways from recent blog posts or articles, encouraging the reader to explore further.</li>
                  <li><strong>New product announcements:</strong> Share updates about any new offerings or developments, giving your audience exclusive insights.</li>
                  <li><strong>Event invitations:</strong> Invite readers to upcoming webinars, events, or conferences, making sure to emphasize the value they&apos;ll gain from attending.</li>
                </ul>

                {/* CTA */}
                <h3 className="newsletter-section-title">Call-to-Action (CTA)</h3>
                <p className="newsletter-text">
                  Include a clear and bold call-to-action, guiding the reader toward the desired outcome, such as signing up for an event, reading more, or making a purchase.
                </p>
                <p className="newsletter-cta">Example: “Register Now for the Webinar”</p>

                {/* Closing/Signature */}
                <h3 className="newsletter-section-title">Closing/Signature</h3>
                <p className="newsletter-text">
                  End with a friendly and personalized closing message, possibly with an additional offer or invitation to connect on social media. Make it inviting to continue engagement.
                </p>

                {/* Footer */}
                <h3 className="newsletter-section-title">Footer</h3>
                <p className="newsletter-text">
                  Ensure the footer contains important elements such as unsubscribe options, your contact information, and any social proof like testimonials or links to your social media channels to build credibility.
                </p>
              </div>
            </Col>
            <Col lg={{ size: 8, order: 2 }} xs={{ order: 1 }}>
              <div>
                <Card className="card-border">
                  <Form onSubmit={handleUploadNewsletter}>
                    <Row className="align-center">
                      <Col xs="12" lg="11">
                        <div className="my-2 align-center">
                          <span className="text-lite-grey-sub">Create Newsletter</span>
                        </div>
                      </Col>
                      <Col xs="12" lg="11">
                        <div className="pt-1 mx-1">
                          <h6 className="text-lite align-start ml-1">Subject Title</h6>
                          <Input
                            placeholder="Enter captivating subject title"
                            type="text"
                            name="subTtl"
                            required
                            value={updateNewsLetter.subTtl}
                            onChange={handleChangeNewsletter}
                            className="campus-input"
                          />
                        </div>
                      </Col>
                      <Col xs="12" lg="11">
                        <div className="pt-4 mx-1">
                          <h6 className="text-lite align-start ml-1">Subject Line</h6>
                          <Input
                            placeholder="Enter small description"
                            type="textarea"
                            name="subLine"
                            required
                            maxLength={200}
                            value={updateNewsLetter.subLine}
                            onChange={handleChangeNewsletter}
                            className="campus-text-area"
                          />
                        </div>
                      </Col>
                      <Col xs="12" lg="11">
                        <Row className="pt-4">
                          <Col xs="12" lg="8">
                            <div className="pt-4">
                              <div className="mx-1">
                                <h6 className="text-lite align-start ml-1">Preview Image</h6>
                              </div>
                              <div className="upload-box align-center">
                                <Input
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  name="fileData"
                                  className="upload-box-input"
                                  onChange={(e) => handlePreviewImage(e)}
                                />
                                <span className="register-file-link">Choose Image</span>
                              </div>
                            </div>
                          </Col>
                          <Col xs="12" lg="4">
                            <div className="align-center pt-4">
                              <CardImg alt="logo" className="img-fluid" src={filePreview} />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" lg="11">
                        <div className="pt-4 mx-1">
                          <h6 className="text-lite align-start ml-1">Full Description</h6>
                          <SunEditor
                            height="750px"
                            width="auto"
                            setOptions={{
                              buttonList: [
                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                                  'fontColor', 'hiliteColor',
                                  'outdent', 'indent',
                                  'align', 'horizontalRule', 'list', 'lineHeight',
                                  'table', 'link', 'font', 'fontSize', 'formatBlock',
                                  'video', 'image'],
                              ],
                            }}
                            setContents={contents || ''}
                            onChange={handleEditorEvent}
                          />
                        </div>
                      </Col>
                      <Col xs="12" lg="11">
                        <div className="pt-4 mx-1 margin-state-desk">
                          <Input
                            placeholder="Enter Keywords"
                            type="text"
                            name="newsKey"
                            value={initNewsKeyword}
                            onChange={handleChangeUpdateNewsKeyword}
                            onKeyDown={handleKeyPressJobKeyword}
                            className="jobs-campus-input"
                          />
                          <span className="text-lite ml align-start">Press Enter after typing to add keywords</span>

                          <div className="mt-3 align-start badge-container">
                            {updateNewsLetter.newsKey.map((jobKey, index) => (
                              <Badge
                                key={`${index.toString()}`}
                                pill
                                className="chip-box mx-1 my-1"
                                onClick={() => removeJobKeyword(jobKey)}
                                style={{ cursor: 'pointer' }}
                              >
                                {jobKey} &times;
                              </Badge>
                            ))}
                          </div>
                        </div>
                      </Col>
                      <Col xs="11" lg="11">
                        <div className="align-center my-3 pt-2">
                          <Button
                            className="campus-button-all"
                            disabled={
                                contents === ''
                                || updateNewsLetter.newsKey.length === 0
                                || updateNewsLetter.fileName === ''
                                || updateNewsLetter.logoFile === ''
                              }
                          >
                            SAVE
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div className="my-3" />
                </Card>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default AddNewsLetter;
