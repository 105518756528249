import React, { useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {
  Button,
  CardImg,
  Col, Input, Row,
} from 'reactstrap';
import { faCropSimple } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageProps } from './post.type';
// import { FileDetails } from '../../../../../../../services/common/get-s3-files/get-s3files.types';

const MAX_FILE_SIZE = 0.8 * 1024 * 1024; // .8MB

export const ImageCrop: React.FC<ImageProps> = ({
  setCampusMediaFile, setIsPublish, setPreviewData, userType, userCode, // setMyPostDataFile, myPostFile, setMyPostData, myPostData,
}) => {
  const [cropper, setCropper] = useState<any>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [cropedUrl, setCropedUrl] = useState<string>('');
  const [isCroped, setIsCroped] = useState(false);
  const [imageSize, setImageSize] = useState(0);

  React.useEffect(() => {
    if (imageUrl !== '') {
      setPreviewData(imageUrl);
    }
    if (cropedUrl !== '') {
      setPreviewData(cropedUrl);
    }
  }, [cropedUrl, imageUrl]);

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit
    const formData = new FormData();
    const epochTime = new Date().getTime().toString(); // For unique file names

    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setImageSize(file.size);
        return;
      }

      const extension = file.name.split('.').pop(); // Extract file extension
      const baseFileName = `${userType}_${userCode}_${epochTime}`;
      const uniqueFileName = `${baseFileName}.${extension}`; // Adding the file's extension dynamically

      const newFile = new File([file], uniqueFileName, { type: file.type });
      formData.append(uniqueFileName, newFile);

      const objectUrl = URL.createObjectURL(file);
      setImageUrl(objectUrl);

      setCampusMediaFile(formData);
    });
  };

  // const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsPublish(false);
  //   setCropedUrl('');
  //   if (event.target.files && event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     const formData = new FormData();
  //     formData.append('file', file, uniqueFileName); // Use the same unique file name
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () => setImageUrl(reader.result as string));
  //     reader.readAsDataURL(file);
  //     setImageSize(file.size);
  //     setCampusMediaFile(formData); // Pass formData with the new unique file name
  //   }
  // };
  const handleCropImage = async () => {
    setIsCroped(!isCroped);
    setIsPublish(true);
  };

  const getCropData = async () => {
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      const epochTime = new Date().getTime().toString();
      const uniqueFileName = `${userType}_${userCode}_${epochTime}.png`; // Assuming PNG format

      canvas.toBlob((blob: BlobPart) => {
        const formData = new FormData();
        if (blob) {
          const newFile = new File([blob], uniqueFileName, { type: 'image/png' });
          formData.append(uniqueFileName, newFile);

          setCampusMediaFile(formData); // Pass FormData with the unique file name
          setIsPublish(false);
        }
      }, 'image/png');

      const url = await new Promise<string>((resolve) => {
        canvas.toBlob((blob: Blob) => {
          if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
          }
        }, 'image/png');
      });
      setCropedUrl(url);
    }
  };

  return (
    <div className="align-center1">
      <div className="campus-file-upload-box">
        <Input
          type="file"
          accept=".png, .jpg, .jpeg"
          name="fileData"
          className="campus-file-upload-input avatar1 my-1"
          onChange={onImageChange}
        />
        <span>Drag and drop or <span className="campus-file-link">Choose image files</span></span>
      </div>
      <Row>
        {imageUrl
        && (
        <Col xs="12">
          <div className="pt-2 align-end">
            <Button className="button-icon mx-1" onClick={handleCropImage} disabled={!!cropedUrl || imageSize > MAX_FILE_SIZE}>
              <FontAwesomeIcon icon={faCropSimple} className="button-icon" />
            </Button>
          </div>
        </Col>
        )}
        <Col xs="12">
          {imageSize < MAX_FILE_SIZE ? isCroped ? (
            imageUrl
            && (
            <div>
              <Cropper
                src={imageUrl}
                style={{ height: 'auto', width: '100%' }}
                initialAspectRatio={16 / 9}
                // minCropBoxHeight={100}
                // minCropBoxWidth={100}
                zoomable={false} // disable zooming
                zoomOnWheel={false}
                guides
                viewMode={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
              <div className="pt-2 align-end my-1">
                <Button className="campus-button-lite mx-1" onClick={getCropData}>
                  CROP
                </Button>
              </div>
              <hr className="template-border" />
              <div className="pt-2">
                {cropedUrl && <CardImg src={cropedUrl} />}
              </div>
            </div>
            )
          ) : (<CardImg src={imageUrl} />) : <div className="align-center"><span className="alert-error">File size should be less then 800kb</span></div>}
          {/* {imageUrl
            && imageSize > MAX_FILE_SIZE ? (
              <div>
                <Cropper
                  src={imageUrl}
                  style={{ height: 'auto', width: '100%' }}
                  initialAspectRatio={4 / 3}
                  minCropBoxHeight={100}
                  minCropBoxWidth={100}
                  guides={false}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
                <div className="pt-2 align-end">
                  <Button className="button-icon mx-1" onClick={getCropData}>
                    <FontAwesomeIcon icon={faCropSimple} className="button-icon-active" />
                  </Button>
                </div>
                <div className="pt-2">
                  {cropedUrl && <CardImg src={cropedUrl} />}
                </div>
              </div>
            )
            : <CardImg src={imageUrl} />} */}
        </Col>
      </Row>
    </div>

  );
};

export default ImageCrop;
