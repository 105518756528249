import { ResumeLoadPutResponse, ResumeLoadPutResults } from './update.resume.load.service.types';

export const ResumeLoadPutTransform: (response: ResumeLoadPutResponse) => ResumeLoadPutResults = (certResults) => {
  const { data, is_error, message } = certResults;
  const result :ResumeLoadPutResults = {
    data: [{
      JobCd: '',
      JobId: '',
      uId: '',
      fileName: '',
      intSts: '',
      mailSts: '',
      cndSts: '',
      cndNme: '',
    }],
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
    result.data = data;
  }
  return result;
};
