import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Card, CardBody, Input,
  Button,
  CardImg,
  InputGroup,
} from 'reactstrap';
import { faArrowLeft, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  clearUserProfilePutReq,
  getS3FilesReq,
} from '../../../../../store/campusXcel/actions';
import '../../../common/my-avatar/avatar.scss';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import LoaderData from '../../../../../utils/loader';
import { getUserProfileLocalStorage } from '../../../../../utils/service/localstorage-service';
import { MyAvatar } from '../../../common/my-avatar/avatar';
import { FileDetails } from '../../../../../services/common/get-s3-files/get-s3files.types';
import { getConfig } from '../../../../../utils/config/config';

const { AWS_CXUSER_BUCKET } = getConfig();

export const CmpsAdminAboutMe: React.FC = () => {
  const dispatch = useDispatch();
  const userProfile = getUserProfileLocalStorage();
  const [userId, setUserId] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  // const [imageURL, setImageUrl] = useState('');
  const [editAvatar, setEditAvatar] = useState(false);
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [avatarSrc, setAvatarSrc] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);
  const userProfileResponse = useSelector((state: RootState) => state.campusXcel.updateUserProfile);
  const getAvatarResponse = useSelector((state: RootState) => state.campusXcel.getS3Files);
  const [myProfile, setMyProfile] = useState({
    cEId: '',
    fNme: '',
    lNme: '',
    dlCd: '+91',
    cPh: '',
  });
  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.eId !== '') {
      setMyProfile({
        ...myProfile,
        fNme: JSON.parse(userProfile).profileData.fNme,
        lNme: JSON.parse(userProfile).profileData.lNme,
        cEId: JSON.parse(userProfile).profileData.eId,
        dlCd: JSON.parse(userProfile).profileData.dlCd,
        cPh: JSON.parse(userProfile).profileData.ph,
      });
    }
  }, [userProfile && JSON.parse(userProfile).profileData.eId]);

  React.useEffect(() => {
    setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    setUserTp(userProfile && JSON.parse(userProfile).profileData.UserTp);
  }, [userProfile && JSON.parse(userProfile).profileData.UserCd, userProfile && JSON.parse(userProfile).profileData.UserTp]);

  const handleEditAvatar = () => {
    setEditAvatar(!editAvatar);
  };

  const handleMyProfile = (e:any) => {
    setMyProfile({ ...myProfile, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (userTp !== '' && userId !== '') {
      dispatch(getS3FilesReq({
        requestType: '',
        bucketNme: AWS_CXUSER_BUCKET,
        folderName: `avatars/${userTp}-${userId}_1.png`,
        identifierTp: 'avatar',
      }));
    }
  }, [userTp, userId]);

  React.useEffect(() => {
    if (userProfileResponse.error && userProfileResponse.errorMessage !== '') {
      setLoaderProfile(false);
      setAlertMsg(userProfileResponse.message);
      setErrorShowAlert(true);
    }
  }, [userProfileResponse.error, userProfileResponse.message]);

  React.useEffect(() => {
    if (!userProfileResponse.error && userProfileResponse.message !== '') {
      Promise.resolve(
        setLoaderProfile(false),
      ).then(() => {
        dispatch(clearUserProfilePutReq());
      }).then(() => {
        setAlertMsg(userProfileResponse.message);
      }).then(() => {
        setSuccessShowAlert(true);
      });
    }
  }, [userProfileResponse.error, userProfileResponse.message]);

  React.useEffect(() => {
    if (getAvatarResponse.data.length !== 0) {
      setAvatarSrc(getAvatarResponse.data);
    }
  }, [getAvatarResponse.data]);

  return (
    <div className="pt-3 padding-mob-top mx-1">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="allign-start ml">
        <h2 className="text-line"><CardImg className="cx-main-header-img-card" src="/images/icons/my-profile.png" alt="batches" /> My Personal Details</h2>
      </div>
      <div className="pt-4">
        <Row className="align-center">
          <Col lg="9" xs="12">
            <Card>
              <CardBody className="collapse-bg">
                <Row className="align-center">
                  <Col lg="5" xs="12">
                    {editAvatar
                      ? (
                        <div className="pt-2">
                          <MyAvatar imgUrl={avatarSrc[0].url} fileName={`${userTp}-${userId}`} folderName="avatars" defaultFIleUrl="/images/common/avatar.png" />
                          <Button className="button-icon align-center" id="editAbtMe" onClick={() => handleEditAvatar()}>
                            <FontAwesomeIcon icon={faArrowLeft} className="mr" /> back
                          </Button>
                        </div>
                      )
                      : (
                        <div className="my-2">
                          <div className="align-center">
                            {avatarSrc[0].url === ''
                              ? (
                                <CardImg
                                  alt="..."
                                  className="img-fluid rounded-circle avatar-user-profile-preview"
                                  src="/images/common/avatar.png"
                                />
                              )
                              : (
                                <CardImg
                                  alt="..."
                                  className="img-fluid rounded-circle avatar-user-profile-preview"
                                  src={avatarSrc[0].url}
                                />
                              ) }
                          </div>
                          <div className="align-center">
                            <Button className="button-icon align-center" id="editAbtMe" onClick={() => handleEditAvatar()}>
                              Edit <FontAwesomeIcon icon={faPenToSquare} className="ml" />
                            </Button>
                          </div>
                        </div>
                      )}
                  </Col>
                  <Col lg="7" xs="12">
                    <Row className="mx-1 my-2">
                      <Col xs="12" className="pt-1">
                        <span className="campus-diffable-untick-text">Personal Details</span>
                      </Col>
                      <Col lg="6" xs="12">
                        <div className="pt-3">
                          <h6 className="text-lite align-start ml-1">First Name</h6>
                          <Input
                            placeholder="Enter Your First Name"
                            type="text"
                            name="fNme"
                            value={myProfile.fNme}
                            disabled
                            onChange={handleMyProfile}
                            className="campus-input"
                          />
                        </div>
                      </Col>
                      <Col lg="6" xs="12">
                        <div className="text-right-login-campus-register pt-3">
                          <h6 className="text-lite align-start ml-1">Last Name</h6>
                          <Input
                            placeholder="Enter Your Last Name"
                            type="text"
                            name="lNme"
                            value={myProfile.lNme}
                            disabled
                            onChange={handleMyProfile}
                            className="campus-input"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-5 mx-1">
                      <Col lg="11" xs="12">
                        <span className="campus-diffable-untick-text">Contact Information</span>
                      </Col>
                      <Col lg="6" xs="12">
                        <div className="text-right-login-campus-register pt-3">
                          <h6 className="text-lite align-start ml-1">Contact Email</h6>
                          <Input
                            placeholder="Enter Your Email Id"
                            type="text"
                            name="cEId"
                            value={myProfile.cEId}
                            disabled
                            onChange={handleMyProfile}
                            className="campus-input"
                          />
                        </div>
                      </Col>
                      <Col lg="6" xs="12">
                        <div className="text-right-login-campus-register pt-3">
                          <h6 className="text-lite align-start ml-1">Phone</h6>
                          <InputGroup>
                            <Input
                              placeholder="Enter your Phone Number"
                              type="text"
                              disabled
                              name="combinedDlCdAndPh"
                              value={`${myProfile.dlCd} ${myProfile.cPh}`}
                              onChange={handleMyProfile}
                              className="campus-input"
                            />
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="my-4" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CmpsAdminAboutMe;
