import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { MultiValue } from 'react-select';
import {
  Row, Col, Card, CardBody, Button, CardFooter, Form,
  CardImg,
  CardHeader,
} from 'reactstrap';
import Creatable from 'react-select/creatable';
import { clearHobbies, getHobbies, getResultHobbies } from '../../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../../utils/alert';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { ListHobbies } from '../../../../../../utils/hobbies';
import LoaderData from '../../../../../../utils/loader';
import { getTokenFromLocalStorage } from '../../../../../../utils/service/localstorage-service';
import { ResumeTypeProps } from '../resume.type';
import { HobbiesPutDataInput } from '../../../../../../services/campus/hobbies/update-hobbies/update-hobbies.types';

export const Hobbies: React.FC<ResumeTypeProps> = ({ rootResumeTp, resumeTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [loaderResume, setLoaderResume] = useState(false);
  const getHobbiesResponse = useSelector((state: RootState) => state.campusXcel.getHobbies);
  const updateHobbiesResponse = useSelector((state: RootState) => state.campusXcel.updateHobbies);
  const [myHobbies, setMyHobbies] = useState<HobbiesPutDataInput>({
    hobb: [],
  });
  const maxLength = 290;

  const hobbyNameoptions = ListHobbies.map((hobby) => ({
    label: hobby.label,
    value: hobby.value,
  }));

  React.useEffect(() => {
    if (resumeTp === 'hobbies' && rootResumeTp === 'resume-act') {
      setLoaderResume(true);
      dispatch(getResultHobbies({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'HOBB',
      }));
    }
  }, [resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (getHobbiesResponse.isError && getHobbiesResponse.message !== '' && resumeTp === 'hobbies' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(getHobbiesResponse.message);
      setErrorShowAlert(true);
    }
  }, [getHobbiesResponse.isError, getHobbiesResponse.message, resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (!getHobbiesResponse.isError && getHobbiesResponse.message !== '' && resumeTp === 'hobbies' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setMyHobbies({ ...myHobbies, hobb: getHobbiesResponse.hobb });
    }
  }, [getHobbiesResponse.isError, getHobbiesResponse.message, resumeTp, rootResumeTp]);

  const hobbiesList = myHobbies.hobb.map((item) => ({ label: item, value: item }));
  const handleSelectHobbyData = (e: MultiValue<{ label: string; value: string; }> | null) => {
    let updatedHobby: string[] = [];
    if (e && e.length) {
      updatedHobby = e.map(({ label }) => label);
    }
    setMyHobbies({ ...myHobbies, hobb: updatedHobby });
  };
  const handleSubmitHobbies = (e:any) => {
    e.preventDefault();
    setLoaderResume(true);
    dispatch(getHobbies({
      inputBody: myHobbies,
      requestType: APIHeader.REQ_UPDATE_HOBBIES,
      uTp: UserType.CMPS,
      token: tokenData,
    }));
  };
  React.useEffect(() => {
    if (updateHobbiesResponse.error && updateHobbiesResponse.message !== '' && resumeTp === 'hobbies' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(updateHobbiesResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateHobbiesResponse.error, updateHobbiesResponse.message, resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (!updateHobbiesResponse.error && updateHobbiesResponse.message !== '' && resumeTp === 'hobbies' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(updateHobbiesResponse.message);
      setSuccessShowAlert(true);
      dispatch(clearHobbies());
      dispatch(getResultHobbies({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'HOBB',
      }));
    }
  }, [updateHobbiesResponse.error, updateHobbiesResponse.message, resumeTp, rootResumeTp]);

  const [isHobbiesTxt, setIsHobbiesTxt] = useState(false);

  const hobbiesTxt = 'Mentioning hobbies in your profile can provide several benefits in showcasing how active and personable you are. Firstly, it gives insight into your interests and passions beyond your professional life, allowing others to see a well-rounded and multi-dimensional personality. It can spark conversations and connections with like-minded individuals who share similar hobbies, fostering new relationships and networking opportunities. Additionally, highlighting hobbies demonstrates your ability to manage time effectively and maintain a healthy work-life balance. It shows that you are proactive, engaged in activities outside of work, and possess skills beyond your professional expertise. Overall, sharing hobbies in your profile helps create a more relatable and approachable image, enhancing your personal brand and making you more memorable to others.';

  const handleHobbiesText = () => {
    setIsHobbiesTxt(!isHobbiesTxt);
  };

  return (
    <Row className="margin-bottom mx-3">
      { loaderResume ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 12, order: 1 }} xs={{ order: 1 }}>
        <div className="pt-1">
          <Form onSubmit={handleSubmitHobbies}>
            <Card className="form-card-white my-1">
              <CardBody>
                <h6 className="dashboard-thread-text ml-1">My Interests</h6>
                <Creatable
                  key="hobbies"
                  options={hobbyNameoptions}
                  isClearable
                  onChange={(e) => handleSelectHobbyData(e)}
                  value={hobbiesList}
                  isMulti
                  menuPortalTarget={document.body} // Brings Menu onTop
                  styles={{
                    multiValue: (provided: any) => ({
                      ...provided,
                      background: '#f6eeeb',
                      padding: '1px',
                    }),
                    menu: (base) => ({
                      ...base,
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '10px',
                      padding: 1,
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  noOptionsMessage={() => 'No Hobbies Found'}
                  placeholder="Enter or Add Hobbies"
                />
              </CardBody>
              <CardFooter className="form-card-footer my-2 mx-2">
                <div className="align-end">
                  <Button className="campus-button-all mx-1">
                    Save
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Form>
        </div>
      </Col>
      <Col lg={{ size: 12, order: 2 }} xs={{ order: 2 }}>
        <Row className="pt-5 align-center resume-margin-top">
          <Card className="form-card-white">
            <CardHeader className="card-header-border">
              <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
              <span>Do you Know ?</span>
            </CardHeader>
          </Card>
          <Col lg="3" xs="12">
            <div className="align-center pt-4">
              <CardImg
                alt="..."
                className="mx-1"
                src="/images/campus/origami.png"
              />
            </div>
          </Col>
          <Col lg="8" xs="12">
            <div className="align-text-justify mx-1">
              <span className="dashboard-thread-text mx-1">
                {isHobbiesTxt ? `${hobbiesTxt}` : `${hobbiesTxt.slice(0, maxLength)}`}
                {isHobbiesTxt
                  ? (
                    <Button onClick={handleHobbiesText} className="mx-1 dashboard-button-less">
                      show less
                    </Button>
                  ) : (
                    <Button onClick={handleHobbiesText} className="mx-1 dashboard-button-more">
                      show more
                    </Button>
                  )}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Hobbies;
