import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ContactProps } from './contact.type';
import {
  clearContactUsPostReq,
  clearLoginToken,
  contactUsPostReq, getLoginToken,
} from '../../../store/campusXcel/actions';
import '../home/home.scss';
import { APIHeader, UserType } from '../../../utils/constants';
import { errorAlert, successAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { DialCountrInfo } from '../../../utils/MetaData/country';
import { ContactUsInput } from '../../../services/sadmin/contact-us/contact-us.types';

export const ContactForm: React.FC<ContactProps> = ({ userFrom }) => {
  const dispatch = useDispatch();
  const contactUsResponse = useSelector((state: RootState) => state.campusXcel.contactUs);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [registerLoader, setRegisterLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const [contactUsData, setContactUsData] = useState<ContactUsInput>({
    nme: '',
    dlCd: '+91',
    ph: '',
    oNme: '',
    eId: '',
    desc: '',
    cTp: '',
  });

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setContactUsData({ ...contactUsData, cTp: userFrom });
    const validEmail = emailRegex.test(contactUsData.eId);
    const validPhone = phoneRegex.test(contactUsData.ph);
    if (validEmail && validPhone) {
      setRegisterLoader(true);
      dispatch(getLoginToken({
        userName: '',
        passKey: '',
        isPublic: true,
        uTp: UserType.CXAPI,
        requestType: APIHeader.USER_LOGIN,
      }));
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  React.useEffect(() => {
    if (loginTokenData.isError && loginTokenData.message !== '') {
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginTokenData.message);
    }
  }, [loginTokenData.message, loginTokenData.isError]);

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.token !== '' && contactUsData.cTp !== '' && contactUsData.ph !== '') {
      dispatch(contactUsPostReq({
        inputBody: contactUsData,
        requestType: APIHeader.REQ_ADD_CONTACT_FORM,
        token: loginTokenData.token,
      }));
    }
  }, [loginTokenData.token, loginTokenData.isError, contactUsData.cTp, contactUsData.ph]);

  React.useEffect(() => {
    if (contactUsResponse.error && contactUsResponse.message !== '') {
      dispatch(clearContactUsPostReq());
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(contactUsResponse.message);
    }
  }, [contactUsResponse.message, contactUsResponse.error]);

  React.useEffect(() => {
    if (!contactUsResponse.error && contactUsResponse.message !== '') {
      dispatch(clearLoginToken());
      dispatch(clearContactUsPostReq());
      setRegisterLoader(false);
      setShowAlert(true);
      setAlertMsg('We have received your request, and a representative from CampusXcel will be in touch with you shortly.');
    }
  }, [contactUsResponse.error, contactUsResponse.message]);

  const handleFieldChangeRegister = (e:any) => {
    const fieldName = e.target.name;
    setContactUsData({ ...contactUsData, [fieldName]: e.target.value });
  };

  const dialNameoptions = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions = dialNameoptions && dialNameoptions.find(
    (option) => option.value === contactUsData.dlCd,
  );

  // React.useEffect(() => {
  //   if (!dialData.isError) {
  //     setLoaderDial(false);
  //     setDialListData(dialData.cntyData);
  //   }
  // }, [dialData.cntyData]);

  // React.useEffect(() => {
  //   if (dialListData.length === 0) {
  //     setLoaderDial(true);
  //     dispatch(getCNTYListReq({
  //       uTp: UserType.PUBLIC,
  //       requestType: APIHeader.REQ_GET_ALL_CNTY_META,
  //       limit: ApiLIMIT.LIMIT,
  //     }));
  //   }
  // }, [dialListData.length]);

  // const dialNameoptions = dialListData.map((dial) => ({
  //   label: dial.phCd,
  //   value: dial.phCd,
  // }));
  // const selectedDialNameOptions = dialNameoptions.find(
  //   (option) => option.value === contactUsData.dlCd,
  // );
  const handleSelectDialData = (e:any) => {
    setContactUsData({ ...contactUsData, dlCd: e.value });
  };

  return (
    <Container className="pt-1 my-3 cx-image-bg">
      { registerLoader ? <LoaderData /> : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showAlert ? (
        successAlert(true, alertMsg, showAlert, setShowAlert)
      ) : null}
      <Row className="align-center">
        <div className="align-center my-3">
          <span className="form-header-text">
            Connect with us
          </span>
        </div>
        <Col lg="11" xs="12">
          <div className="cx-contact-card">
            <Form>
              <Col lg="12" className="pt-4">
                <Input
                  placeholder="Full Name"
                  type="text"
                  className="campus-input mx-1"
                  onChange={handleFieldChangeRegister}
                  required
                  name="nme"
                  value={contactUsData.nme}
                  disabled={registerLoader}
                />
              </Col>
              <Col lg="12" className="pt-4">
                <Input
                  placeholder="Enter Organization Name if any"
                  type="text"
                  className="campus-input mx-1"
                  onChange={handleFieldChangeRegister}
                  required
                  name="oNme"
                  value={contactUsData.oNme}
                  disabled={registerLoader}
                />
              </Col>
              <Col lg="12" className="pt-4">
                <Input
                  placeholder="Enter your Email Id"
                  type="text"
                  required
                  name="eId"
                  value={contactUsData.eId}
                  disabled={registerLoader}
                  onChange={handleFieldChangeRegister}
                  className="campus-input"
                />
              </Col>
              <Col lg="12" className="pt-4">
                <Row>
                  <Col lg="4" xs="12">
                    <Select
                      value={contactUsData.dlCd === '' ? null : selectedDialNameOptions}
                      options={dialNameoptions}
                      onChange={handleSelectDialData}
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          '&:hover': { borderColor: '#8B0000' },
                          border: '1px solid #DDE0E4',
                          minHeight: '50px',
                          borderRadius: '10px',
                          padding: 1,
                          fontSize: '15px',
                          fontWeight: 400,
                          color: '#575656',
                          boxShadow: 'none',
                          backgroundColor: '#fff',
                          '&:focus': {
                            borderColor: '#E1EDF8',
                            boxShadow: 'none',
                            color: '#575656',
                            background: '#fff',
                          },
                        }),
                        option: (provided: any, state: { isSelected: any; }) => ({
                          ...provided,
                          color: state.isSelected ? '#383838' : '#212121',
                          padding: 15,
                          textAlign: 'left',
                          background: state.isSelected ? '#FAFCFB' : '#fff',
                          '&:hover': {
                            background: '#FAFCFB',
                          },
                        }),
                      }}
                      noOptionsMessage={() => 'No Dial Code Found'}
                    />
                  </Col>
                  <Col lg="8" xs="12">
                    <div className="margin-mob-contact-input">
                      <Input
                        placeholder="Enter your Phone Number"
                        type="number"
                        required
                        name="ph"
                        value={contactUsData.ph}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="campus-input mx-1"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="12" className="pt-4">
                <Input
                  placeholder="Message"
                  type="textarea"
                  name="desc"
                  value={contactUsData.desc}
                  onChange={handleFieldChangeRegister}
                  className="campus-text-area-desc"
                />
              </Col>
            </Form>
            <div className="align-end my-2 pt-4">
              <Button className="campus-button" onClick={handleSubmit}>SUBMIT</Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ContactForm;
