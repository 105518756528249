import { ListRecruitInterviewResponse, ListRecruitInterviewResults } from './list.recruit.interview.service.types';

export const ListRecruitInterviewTransform: (response: ListRecruitInterviewResponse) => ListRecruitInterviewResults = (listPJobsResults) => {
  const { data, is_error, message } = listPJobsResults;
  const result: ListRecruitInterviewResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      recruitIntvData: [],
      lastEvalKey: {
        IntvCd: '',
        IntvId: '',
      },
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
