import { GetS3FilesResponse, GetS3FilesResults } from './get-s3files.service.types';

export const GetS3FilesTransform: (response: GetS3FilesResponse) => GetS3FilesResults = (createProdImgDBResults) => {
  const { data, identifierTp } = createProdImgDBResults;
  const result: GetS3FilesResults = {
    data: [],
    identifierTp: '',
  };
  result.data = data;
  result.identifierTp = identifierTp;
  return result;
};
