import React from 'react';
import {
  Button,
  CardImg,
  Col, Row,
} from 'reactstrap';
import '../market.scss';
import Offcanvas from '../../../../utils/OffCanvas/OffcanvasComponent';
import { ContactForm } from '../../contact-form/contact-form';

export const QallAudit: React.FC = () => {
  const [contactDetailsQall, setContactDetailsQall] = React.useState(false);
  const toggleContact = () => {
    setContactDetailsQall(!contactDetailsQall);
  };
  return (
    <div className="pt-5 mx-1">
      <div className="card-market">
        <Row className="mx-2 align-center">
          <Col lg="2">
            <div className="align-start pt-2">
              <CardImg src="/images/marketplace/QallAuditLogo.png" alt="qall-audit" className="logo-market" />
            </div>
            <div className="pt-2 align-center1">
              <Button href="/qall-audit" target="_blank" rel="noopener noreferrer" className="button-icon">
                Know More
              </Button>
            </div>
          </Col>
          <Col lg="10">
            <div className="align-text-justify pt-4">
              <span>
                Introducing <span className="text-qual-market">Qall Audit</span>, an AI-powered system designed to revolutionize your
                customer call quality assessment. With advanced voice analysis technology,
                Qall Audit delivers precise, unbiased, and fast evaluations of call interactions,
                enabling businesses to gain clear insights and improve customer service standards
                instantly. <br /><br />Say goodbye to manual call audits and embrace accurate, data-driven reports
                that empower your team to enhance quality, consistency, and customer satisfaction with
                ease.
              </span>
            </div>
          </Col>
          <Col lg="12">
            <div className="card-actions align-center pt-3">
              <Button className="card-iphone-button" onClick={toggleContact}>Connect with us</Button>
            </div>
          </Col>
        </Row>
        <Offcanvas
          isOpen={contactDetailsQall}
          toggle={() => toggleContact()}
        >
          <ContactForm userFrom="MRKTQA" />
        </Offcanvas>
        <div className="mb-3 p-4" />
      </div>
    </div>
  );
};
export default QallAudit;
