import {
  faImage,
} from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import {
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  Col, Input, Modal, ModalBody, Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import '../../../campus.scss';
import ReactPlayer from 'react-player';
import { ImageCrop } from './croper';
import { PostDBInput } from '../../../../../../services/campus/dashboard/post/add-post-db/post-db.types';
import { PostFileInput } from '../../../../../../services/campus/dashboard/post/create-post-college/create-post.types';
import { FileDetails } from '../../../../../../services/common/get-s3-files/get-s3files.types';
import {
  getPostPublish, postDBReq, clearUploadS3FilesReq, delPostPublish, clearPostDBReq, clearPostListReq, clearPostPublish,
} from '../../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../../utils/alert';
import { CmntTypeInfo, APIHeader, UserType } from '../../../../../../utils/constants';
import LoaderData from '../../../../../../utils/loader';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../../utils/service/localstorage-service';
import { AddCollegeProps } from '../college.type';
import { getConfig } from '../../../../../../utils/config/config';

const { AWS_CXUSER_BUCKET } = getConfig();
const maxLength = 130;

const CreatePost: React.FC<AddCollegeProps> = ({ setPrevPostDataLengthRef }) => {
  const dispatch = useDispatch();
  // const [imageURL] = useState('');
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileLocalStorage();
  const [mediaUrl, setMediaUrl] = useState('');
  const [previewData, setPreviewData] = useState('');
  const [isTextPublish, setIsTextPublish] = useState(true);
  const [imageLinkPreview] = useState('imageOnly'); // setImageLinkPreview
  const [videoLinkPreview] = useState('videoOnly'); // setVideoLinkPreview
  const [modalMedia, setModalMedia] = useState('');
  // const [mediaFileNme, setMediaFileNme] = useState('');
  // const [mediaFileNmeExt, setMediaFileNmeExt] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [isPublish, setIsPublish] = useState(true);
  const [publishConfirm, setIsPublishConfirmed] = useState(false);
  const [loaderPublish, setLoaderPublish] = useState(false);
  const getAvatarResponse = useSelector((state: RootState) => state.campusXcel.getS3Files);
  const publishResponse = useSelector((state: RootState) => state.campusXcel.createPostData);
  const addPostDBResponse = useSelector((state: RootState) => state.campusXcel.addPostDBData);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [isTruncated, setIsTruncated] = useState(true);
  const [avatarSrc, setAvatarSrc] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);

  const [myPostData, setMyPostData] = useState<PostDBInput>({
    post: '',
    isFile: false,
    fileTp: '', // video or image
    url: '',
    urlTp: '', // video or image
    fileNme: '',
    cmntTp: 'all',
  });

  const [myPostFile, setMyPostDataFile] = useState<PostFileInput>({
    fileData: new FormData(),
  });

  const handleMyPost = (e:any) => {
    setMyPostData({ ...myPostData, [e.target.name]: e.target.value });
  };
  const handleMyPostImage = (e:any) => {
    setMyPostData({ ...myPostData, [e.target.name]: e.target.value, urlTp: 'image' });
  };
  const handleMyPostVideo = (e:any) => {
    setMyPostData({ ...myPostData, [e.target.name]: e.target.value, urlTp: 'video' });
  };
  const handleUpdateMediaFile = (formData: FormData) => {
    // Extract file names from FormData
    const fileEntries = Array.from(formData.entries());
    const fileName = fileEntries.map(([, file]) => {
      if (file instanceof File) {
        return file.name; // Return the file name
      }
      return '';
    }).filter((name) => name !== ''); // Filter out empty names

    if (fileName.length > 0) {
      setMyPostDataFile({ fileData: formData });
      setMyPostData({
        ...myPostData,
        url: '', // Set URL if needed
        isFile: true,
        fileTp: 'image',
        fileNme: fileName[0], // Use the first file name
      });

      // Log the file name for debugging
    }
  };

  // React.useEffect(() => {
  //   const userData = userProfile && JSON.parse(userProfile).profileData.UserCd;
  //   const fileNme = `${userData}_${epochTime}.${mediaFileNmeExt}`;
  //   setMediaFileNme(fileNme);
  // }, [mediaFileNme, mediaFileNmeExt]);

  const handleVideoFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit
    const formData = new FormData(); // New formData for uploading files
    const newProdImgList: FileDetails[] = [];

    const userType = `${userProfile && JSON.parse(userProfile).profileData.UserTp}`;
    const userCd = `${userProfile && JSON.parse(userProfile).profileData.UserCd}`;

    // Process each file in the input
    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 5 MB.');
        setErrorShowAlert(true);
        return;
      }

      // Create a unique file name WITHOUT manually adding the `.mp4` extension
      const epochTime = new Date().getTime().toString();
      const baseFileName = `${userType}_${userCd}_${epochTime}`;
      const uniqueFileName = `${baseFileName}.mp4`; // Adding .mp4 here

      // Rename the file using the unique name and append to FormData
      // const newFile = new File([file], uniqueFileName, { type: 'video/mp4' });
      formData.append(uniqueFileName, file);

      // Generate the object URL for preview
      const objectUrl = URL.createObjectURL(file);
      setMediaUrl(objectUrl); // Set the media URL for preview

      // Add file details to the newProdImgList array
      newProdImgList.push({ url: objectUrl, nme: baseFileName });
    });

    // Update the state with the new FormData and file information
    setMyPostDataFile({
      ...myPostFile,
      fileData: formData, // Attach the updated FormData
    });

    setMyPostData({
      ...myPostData,
      url: newProdImgList[0]?.url, // Use the first file's URL for preview
      isFile: true,
      fileNme: newProdImgList[0]?.nme, // Use the first file's new name
      fileTp: 'video', // Set the file type as 'video'
    });
  };

  const cmntOptions = CmntTypeInfo.map((commentTp) => ({
    label: commentTp.label,
    value: commentTp.value,
  }));

  const selectedCmntOptions = cmntOptions.find(
    (option) => option.value === myPostData.cmntTp,
  );
  const handleSelectCommentTp = (e:any) => {
    if (e) {
      setMyPostData({ ...myPostData, cmntTp: e.value });
    } else {
      setMyPostData({ ...myPostData, cmntTp: 'all' });
    }
  };
  const handleSubmitPost = (status: string) => {
    if (status === 'publish') {
      setModalConfirm(true);
    } else if (status === 'cancel') {
      setModalMedia('');
      setIsTextPublish(true);
      setMyPostData({
        ...myPostData,
        post: '',
        isFile: false,
        fileTp: '',
        url: '',
        urlTp: '',
        fileNme: '',
        cmntTp: 'all',
      });
      setMyPostDataFile({ ...myPostFile, fileData: new FormData() });
    }
  };
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };
  const handleInputMedia = (media: string) => {
    if (media === 'video') {
      setModalMedia('video');
      setMyPostData({
        ...myPostData, url: '', isFile: false, fileTp: '', fileNme: '',
      });
      setMyPostDataFile({ ...myPostFile, fileData: new FormData() });
    } else if (media === 'image') {
      setModalMedia('image');
      setMyPostData({
        ...myPostData, url: '', isFile: false, fileTp: '', fileNme: '',
      });
      setMyPostDataFile({ ...myPostFile, fileData: new FormData() });
    } else {
      setModalMedia('');
      setMyPostData({
        ...myPostData, url: '', isFile: false, fileTp: '', fileNme: '',
      });
      setMyPostDataFile({ ...myPostFile, fileData: new FormData() });
    }
  };
  // const handleImageTpMedia = (status: string) => {
  //   if (status === 'imageLink') {
  //     setImageLinkPreview('imageLink');
  //     // setModalCropMedia(false);
  //     setMyPostDataFile({ ...myPostFile, fileData: new FormData() });
  //     setMyPostData({
  //       ...myPostData, url: '', isFile: false, fileTp: '', fileNme: '',
  //     });
  //   }
  //   if (status === 'imageOnly') {
  //     setImageLinkPreview('imageOnly');
  //     setMyPostData({
  //       ...myPostData, url: '', isFile: false, fileTp: '', fileNme: '',
  //     });
  //   }
  // };
  // const handleVideoTpMedia = (status: string) => {
  //   if (status === 'videoLink') {
  //     setVideoLinkPreview('videoLink');
  //     setMyPostData({
  //       ...myPostData, url: '', isFile: false, fileTp: '', fileNme: '',
  //     });
  //     setMyPostDataFile({ ...myPostFile, fileData: new FormData() });
  //   }
  //   if (status === 'videoOnly') {
  //     setVideoLinkPreview('videoOnly');
  //     setMyPostData({
  //       ...myPostData, url: '', isFile: false, fileTp: '', fileNme: '',
  //     });
  //   }
  // };
  const handleConfirmPublish = (status: string) => {
    if (status === 'publish') {
      setIsPublishConfirmed(true);
    } else if (status === 'cancel') {
      setModalConfirm(false);
    }
  };

  React.useEffect(() => {
    if (publishConfirm) {
      setLoaderPublish(true);
      if (myPostData.isFile) {
        dispatch(getPostPublish({
          bucketNme: AWS_CXUSER_BUCKET,
          folderName: `campus-posts/${userProfile && JSON.parse(userProfile).profileData.cmpsCd}/${userProfile && JSON.parse(userProfile).profileData.UserCd}`,
          fileNme: myPostData.fileNme,
          formData: myPostFile.fileData,
          requestType: '',
          isEdit: false,
        }));
      } else {
        dispatch(postDBReq({
          inputBody: myPostData,
          requestType: APIHeader.REQ_ADD_POST_DB,
          uTp: UserType.CMPS,
          token: tokenData,
          acadId: userProfile && JSON.parse(userProfile).profileData.cmpsCd,
        }));
      }
    }
  }, [publishConfirm]);

  React.useEffect(() => {
    if (publishResponse.fileName === 'none') {
      setLoaderPublish(false);
      setAlertMsg('Upload Failed');
      dispatch(clearUploadS3FilesReq());
      setErrorShowAlert(true);
      setIsPublishConfirmed(false);
      setMediaUrl('');
    }
  }, [publishResponse.fileName]);

  React.useEffect(() => {
    if (publishResponse.fileName !== '') {
      const resFileName: string = publishResponse.fileName ? publishResponse.fileName.split('/').pop() || '' : '';
      setMyPostData((prevState) => {
        const updatedPostData = { ...prevState, fileNme: resFileName };

        // Dispatch the API call with the updated state
        dispatch(postDBReq({
          inputBody: updatedPostData,
          requestType: APIHeader.REQ_ADD_POST_DB,
          uTp: UserType.CMPS,
          token: tokenData,
          acadId: userProfile && JSON.parse(userProfile).profileData.cmpsCd,
        }));

        // Return the updated state
        return updatedPostData;
      });
      setMediaUrl('');
      setIsPublishConfirmed(false);
    }
  }, [publishResponse.fileName]);

  // React.useEffect(() => {
  //   const handlePublish = async () => {
  //     if (publishConfirm) {
  //       setLoaderPublish(true);
  //       if (myPostData.isFile) {
  //         dispatch(getPostPublish({
  //           bucketNme: AWS_CXUSER_BUCKET,
  //           folderName: `campus-posts/${userProfile && JSON.parse(userProfile).profileData.cmpsCd}/${userProfile && JSON.parse(userProfile).profileData.UserCd}`,
  //           fileNme: myPostData.fileNme,
  //           formData: myPostFile.fileData,
  //           requestType: '',
  //           isEdit: false,
  //         }));

  //         if (publishResponse.fileName !== '') {
  //           setMyPostData({ ...myPostData, fileNme: publishResponse.fileName });
  //           dispatch(postDBReq({
  //             inputBody: myPostData,
  //             requestType: APIHeader.REQ_ADD_POST_DB,
  //             uTp: UserType.CMPS,
  //             token: tokenData,
  //             acadId: userProfile && JSON.parse(userProfile).profileData.cmpsCd,
  //           }));
  //           setIsPublishConfirmed(false);
  //         }
  //       } else {
  //         dispatch(postDBReq({
  //           inputBody: myPostData,
  //           requestType: APIHeader.REQ_ADD_POST_DB,
  //           uTp: UserType.CMPS,
  //           token: tokenData,
  //           acadId: userProfile && JSON.parse(userProfile).profileData.cmpsCd,
  //         }));
  //         setIsPublishConfirmed(false);
  //       }
  //     }
  //   };
  //   handlePublish();
  // }, [publishConfirm, myPostData.isFile, publishResponse.fileName]);

  React.useEffect(() => {
    if (addPostDBResponse.error && addPostDBResponse.message !== '') {
      const resFileName: string = publishResponse.fileName ? publishResponse.fileName.split('/').pop() || '' : '';
      if (resFileName !== '') {
        Promise.resolve(
          dispatch(delPostPublish({
            bucketNme: AWS_CXUSER_BUCKET,
            fileNme: publishResponse.fileName,
            requestType: '',
          })),
        ).then(() => {
          setLoaderPublish(false);
        }).then(() => {
          dispatch(clearPostDBReq());
        }).then(() => {
          dispatch(clearPostListReq());
          setPrevPostDataLengthRef(1);
        })
          .then(() => {
            setAlertMsg(addPostDBResponse.message);
            setErrorShowAlert(true);
          });
      }
      setLoaderPublish(false);
      setAlertMsg(addPostDBResponse.message);
      setErrorShowAlert(true);
      dispatch(clearPostPublish());
      setMediaUrl('');
      setIsPublishConfirmed(false);
    }
  }, [addPostDBResponse.error, addPostDBResponse.message, publishResponse.fileName]);

  React.useEffect(() => {
    if (!addPostDBResponse.error && addPostDBResponse.message !== '') {
      Promise.resolve(
        setAlertMsg(addPostDBResponse.message),
      ).then(() => {
        setLoaderPublish(false);
      }).then(() => {
        setSuccessShowAlert(true);
      }).then(() => {
        dispatch(clearPostDBReq());
      })
        .then(() => {
          dispatch(clearPostPublish());
        })
        .then(() => {
          setModalMedia('');
        });
      setModalConfirm(false);
      setMyPostData({
        post: '',
        isFile: false,
        fileTp: '', // video or image
        url: '',
        urlTp: '', // video or image
        fileNme: '',
        cmntTp: 'all',
      });
      setMediaUrl('');
      setIsPublishConfirmed(false);
    }
  }, [addPostDBResponse.error, addPostDBResponse.message]);

  React.useEffect(() => {
    if (myPostData.post === '') {
      setIsTextPublish(true);
      setIsPublish(true);
    }
  }, [myPostData.post]);

  React.useEffect(() => {
    if (myPostData.post !== '') {
      setIsTextPublish(false);
      setIsPublish(false);
    }
  }, [myPostData.post]);

  React.useEffect(() => {
    if (getAvatarResponse.data.length !== 0) {
      setAvatarSrc(getAvatarResponse.data);
    }
  }, [getAvatarResponse.data]);

  return (
    <div className="pt-3">
      { loaderPublish ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Card className="dasboard-form-card">
        <CardHeader className="form-card-header">
          <span className="modal-header-text mx-3">Publish your post to the wider audience</span>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs="12">
              <div className="align-end">
                <Select
                  value={selectedCmntOptions}
                  options={cmntOptions}
                  onChange={handleSelectCommentTp}
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      // minHeight: '5px !important',
                      borderRadius: '5px',
                      padding: '0 5px',
                      margin: '0 10px',
                      minWidth: '200px !important',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#8c8f95',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#8c8f95',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      // padding: 15,
                      textAlign: 'left',
                      fontSize: '14px',
                      fontWeight: 400,
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                />
              </div>
              <div className="align-end">
                <span className="text-lite-grey-comments mx-2">Who can comment</span>
              </div>
            </Col>
            <Col xs="12">
              <div className="text-right-login-campus-register mx-1 my-3">
                <Input
                  placeholder="Write your post here"
                  type="textarea"
                  name="post"
                  value={myPostData.post}
                  onChange={handleMyPost}
                  className="campus-text-area-desc"
                  maxLength={300}
                />
                <span className="text-lite-grey mx-2">Maximum 300 words allowed</span>
              </div>
            </Col>
            {modalMedia === 'video'
              ? (
                <Col xs="12">
                  <div className="align-start">
                    {/* <Button className="button-icon" onClick={() => handleVideoTpMedia('videoLink')}>
                      <FontAwesomeIcon icon={faLink} className={videoLinkPreview === 'videoLink' ? 'button-icon-active' : 'button-icon-inactive'} />
                    </Button>
                    <Button className="button-icon" onClick={() => handleVideoTpMedia('videoOnly')}>
                      <FontAwesomeIcon icon={faFileVideo} className={videoLinkPreview === 'videoOnly' ? 'button-icon-active' : 'button-icon-inactive'} />
                    </Button> */}
                  </div>
                  {videoLinkPreview === 'videoLink'
                    ? (
                      <div>
                        <span className="text-lite-grey mx-2">Provide Video URL</span>
                        <Input
                          type="text"
                          placeholder="Enter Video Url"
                          name="url"
                          value={myPostData?.url}
                          className="campus-input-search my-2 mx-2"
                          onChange={handleMyPostVideo}
                        />
                        {myPostData.url !== '' ? (
                          <ReactPlayer
                            url={myPostData.url}
                            controls
                            width="100%"
                            height="auto"
                          />
                        ) : null}
                      </div>
                    ) : videoLinkPreview === 'videoOnly'
                      ? (
                        <div className="campus-file-upload-box mx-2">
                          <Input
                            type="file"
                            accept=".mp4"
                            name="fileData"
                            className="campus-file-upload-input avatar1 my-1"
                            onChange={(e) => handleVideoFiles(e)}
                          />
                          <span>Drag and drop or <span className="campus-file-link">Choose Video File</span></span>
                        </div>
                      ) : null}
                  {mediaUrl !== ''
                    ? (
                      <Row>
                        <Col xs="12">
                          <ReactPlayer
                            url={mediaUrl}
                            controls
                            width="100%"
                            height="auto"
                          />
                        </Col>
                      </Row>
                    ) : null }
                </Col>
              ) : modalMedia === 'image'
                ? (
                  <Col xs="12">
                    {/* <div className="align-start">
                      <Button className="button-icon" onClick={() => handleImageTpMedia('imageLink')}>
                        <FontAwesomeIcon icon={faLink} className={imageLinkPreview === 'imageLink' ? 'button-icon-active' : 'button-icon-inactive'} />
                      </Button>
                      <Button className="button-icon" onClick={() => handleImageTpMedia('imageOnly')}>
                        <FontAwesomeIcon icon={faFileImage} className={imageLinkPreview === 'imageOnly' ? 'button-icon-active' : 'button-icon-inactive'} />
                      </Button>
                    </div> */}
                    {imageLinkPreview === 'imageLink'
                      ? (
                        <div>
                          <span className="text-lite-grey mx-2">Provide Image URL</span>
                          <Input
                            type="text"
                            placeholder="Enter Image Url"
                            name="url"
                            value={myPostData?.url}
                            className="campus-input-search my-2"
                            onChange={handleMyPostImage}
                          />
                          {myPostData.url !== '' ? <CardImg src={myPostData.url} /> : null}
                        </div>
                      ) : imageLinkPreview === 'imageOnly'
                        ? (
                          <div className="mx-2">
                            <ImageCrop
                              setCampusMediaFile={handleUpdateMediaFile}
                              setIsPublish={setIsPublish}
                              setPreviewData={setPreviewData}
                              // setMyPostDataFile={setMyPostDataFile}
                              // myPostFile={myPostFile}
                              // setMyPostData={setMyPostData}
                              // myPostData={myPostData}
                              userType={`${userProfile && JSON.parse(userProfile).profileData.UserTp}`}
                              userCode={`${userProfile && JSON.parse(userProfile).profileData.UserCd}`}
                            />
                            {/* <Input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              name="fileData"
                              className="profile-image-btn avatar"
                              onChange={(e) => handleVideoFiles(e)}
                            /> */}
                          </div>
                        ) : null}
                    {/* {myPostFile.fileData.has('file')
                      ? (
                        <Row>
                          <Col xs="12">
                            {modalCropMedia
                              ? <ImageCrop filePreview={mediaUrl} setCampusPhotoForm={handleUpdateMediaFile} />
                              : <CardImg src={mediaUrl} /> }
                          </Col>
                          <Col xs="12">
                            <Button className="button-icon mx-1" onClick={handleCropMedia}>
                              <FontAwesomeIcon icon={faCropSimple} className={modalCropMedia ? 'button-icon-active' : 'button-icon-inactive'} />
                            </Button>
                            <Button className="button-icon mx-1" onClick={handleCropCancelMedia}>
                              <FontAwesomeIcon icon={faRectangleXmark} />
                            </Button>
                          </Col>
                        </Row>
                      ) : null } */}
                  </Col>
                ) : null}
          </Row>
        </CardBody>
        <CardFooter className="form-card-footer collapse-bg">
          <Row>
            <Col lg="6" md="4" xs="12">
              <div className="align-start">
                <Button className="button-icon" onClick={() => handleInputMedia('image')}>
                  <FontAwesomeIcon icon={faImage} className={modalMedia === 'image' ? 'button-icon-active' : 'button-icon-inactive'} />
                </Button>
                <Button className="button-icon" onClick={() => handleInputMedia('video')}>
                  <FontAwesomeIcon icon={faVideoCamera} className={modalMedia === 'video' ? 'button-icon-active' : 'button-icon-inactive'} />
                </Button>
              </div>
            </Col>
            <Col lg="6" md="8" xs="12">
              <div className="align-end">
                <Button className="campus-button-all mx-1" onClick={() => handleSubmitPost('cancel')}>
                  Clear
                </Button>
                <Button className="campus-button-all mx-1" disabled={isPublish || isTextPublish} onClick={() => handleSubmitPost('publish')}>
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </Card>
      <Modal isOpen={modalConfirm}>
        <ModalBody>
          <div>
            <span className="mx-2 dashboard-post-text">Do you want to publish ?</span>
          </div>
          <div>
            <span className="mx-2 text-lite-grey-desg">Your Post will be visible :</span>
            <span className="dashboard-group-text">{selectedCmntOptions?.label}</span>
          </div>
          <div>
            <hr className="dashboard-hr-color" />
          </div>
          <div className="align-end pt-3">
            <Row>
              <Col lg="12" md="12" xs="12">
                <div style={{ display: 'flex' }}>
                  <div className="align-center">
                    {avatarSrc[0].url === ''
                      ? (
                        <img
                          alt="..."
                          className="cx-avatar-user-small"
                          src="/images/common/avatar.png"
                        />
                      )
                      : (
                        <img
                          alt="..."
                          className="cx-avatar-user-small"
                          src={avatarSrc[0].url}
                        />
                      ) }
                  </div>
                  <div className="ml-1 pt-1">
                    <span className="dashboard-post-text">{userProfile && JSON.parse(userProfile).profileData.fNme}{' '}{userProfile && JSON.parse(userProfile).profileData.lNme}</span>
                    <div>
                      <span className="dashboard-sub-post-text">Current Role, Number of Followers</span>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col lg="1" md="2" xs="2">
                <div className="align-center pt-1 ml">
                  {JSON.parse(getProfileUrl) === ''
                    ? (
                      <img
                        alt="..."
                        className="cx-avatar-user-small"
                        src="/images/common/avatar.png"
                      />
                    )
                    : (
                      <img
                        alt="..."
                        className="cx-avatar-user-small"
                        src={JSON.parse(getProfileUrl)}
                      />
                    ) }
                </div>
              </Col>
              <Col lg="11" md="10" xs="10">
                <div className="pt-1">
                  <span className="dashboard-post-text">{userProfile && JSON.parse(userProfile).profileData.fNme}{' '}{userProfile && JSON.parse(userProfile).profileData.lNme}</span>
                </div>
                <div>
                  <span className="dashboard-sub-post-text">Current Role, Number of Followers</span>
                </div>
              </Col> */}
              <Col xs="12" className="pt-3">
                <span className="dashboard-thread-text">{isTruncated ? `${myPostData.post.slice(0, maxLength)} ...` : `${myPostData.post} -`}</span>
                {isTruncated && (
                <Button onClick={toggleTruncate} className="dashboard-button-more">
                  show more
                </Button>
                )}
                {!isTruncated && (
                <Button onClick={toggleTruncate} className="dashboard-button-less">
                  show less
                </Button>
                )}
              </Col>
              <Col xs="12">
                {myPostData.isFile ? (myPostData.fileTp === 'image' ? (
                  previewData
                      && (
                      <img
                        alt="..."
                        style={{ width: '100%', height: 'auto' }}
                        src={previewData}
                      />
                      )
                ) : (
                  mediaUrl
                      && (
                        <ReactPlayer
                          url={mediaUrl}
                          controls
                          width="100%"
                          height="auto"
                        />
                      )
                ))
                  : (myPostData.urlTp === 'image' ? (
                    myPostData.url && (
                      <img
                        alt="..."
                        style={{ width: '100%', height: 'auto' }}
                        src={myPostData.url}
                      />
                    )
                  ) : (
                    myPostData.url && (
                      <ReactPlayer
                        url={myPostData.url}
                        controls
                        width="100%"
                        height="auto"
                      />
                    )
                  ))}
              </Col>
            </Row>
          </div>
          <div className="align-end pt-3">
            <Button className="campus-button-lite mx-1" onClick={() => handleConfirmPublish('cancel')}>
              Cancel
            </Button>
            <Button className="campus-button-lite mx-1" onClick={() => handleConfirmPublish('publish')}>
              Publish
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreatePost;
