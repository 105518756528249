import { ProfileResponse, ProfileResults } from './get-profile.service.types';

export const ProfileTransform: (response: ProfileResponse) => ProfileResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :ProfileResults = {
    profileData: {
      fNme: '',
      lNme: '',
      ph: '',
      isPKReset: false,
      lgnSts: '',
      eId: '',
      avtr: '',
      gnd: '',
      dlCd: '',
      UserCd: '',
      UserTp: '',
      isAdm: false,
      cmpsTp: '',
      cmpsCd: '',
      cmpsNme: '',
      cmpsCrs: {
        crs: '',
        strm: '',
        sDte: '',
        eDte: '',
      },
      rcrtTp: '',
      rcrtCd: '',
      rcrtNme: '',
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.profileData = data;
    result.isError = is_error;
    result.message = 'executed';
  }
  return result;
};
