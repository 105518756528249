import { CreatePostResponse, CreatePostResults } from './create-post.service.types';

export const CreatePostTransform: (response: CreatePostResponse) => CreatePostResults = (createPostDBResults) => {
  const { data } = createPostDBResults;
  const result: CreatePostResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};
