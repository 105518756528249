import React from 'react';
import CXTable from '../../../../../utils/CXTable/cx.table';
import { ListCandidatesProps } from './candidates.type';

export const AllCandidatesList: React.FC<ListCandidatesProps> = ({
  pagedData, currentPage, recordsPerPage, totalRecords,
  handlePageChange, setEditRecord, setDeleteRecord,
  statusTp, setOtherObj,
}) => {
  const columns = [
    { header: 'Batch Name', accessor: 'bNme' },
    { header: 'User ID', accessor: 'UserCd' },
    { header: 'Name', accessor: '' },
    { header: 'Gender', accessor: 'gnd' },
    { header: 'Phone', accessor: '' },
    { header: 'Email', accessor: 'eId' },
    { header: 'Stream', accessor: 'strm' },
    { header: 'Course', accessor: 'crs' },
    { header: 'Action', accessor: '' },
  ];
  return (
    <div>
      <CXTable
        tableId="cmps-cand"
        columns={columns}
        data={pagedData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        setEditRecord={setEditRecord}
        setDeleteRecord={setDeleteRecord}
        setOtherObj={setOtherObj}
        oSts={statusTp}
      />
    </div>
  );
};

export default AllCandidatesList;
