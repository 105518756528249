import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonDropdown,
  Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row,
} from 'reactstrap';
import { faMagnifyingGlass, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  clearEditEnquiryStatusReq,
  clearEditUserStatusReq,
  editEnquiryStatusReq,
  getEnquiryListReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import {
  APIHeader, UserType,
} from '../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import Modal from '../../../../../utils/Modal/Modal';
import EnquiryTable from './EnquiryTable/enquiry.table';
import { AllEnquirysResult } from '../../../../../services/sadmin/get-enquiry-list/list.enquiry.types';
import { EnquiryStatusInput } from '../../../../../services/sadmin/update-enquiry-status/update.enquiry.status.types';

export type TabProps = {
    tabTp: string;
    clientTp: string;
};

export const EnquiryDashComponent: React.FC<TabProps> = ({ tabTp, clientTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [dropdowns, setDropdowns] = useState(false);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const enquiryListResponse = useSelector((state: RootState) => state.campusXcel.getEnquiryList);
  const updateEnquiryStatusResponse = useSelector((state: RootState) => state.campusXcel.updateEnquiryStatus);
  const [, setAllEnquiryData] = React.useState<AllEnquirysResult>({
    tCnt: 0,
    fCnt: 0,
    contactData: [],
    lastEvalKey: {
      ContTp: '',
      ContId: '',
    },
  });
  const [editEnquiryRecord, setEditEnquiryRecord] = React.useState<EnquiryStatusInput>({
    ContTp: '',
    ContId: '',
    enqSts: '',
  });
  const [showUserAlertAPI, setShowUserAlertAPI] = useState('');
  const columns = [
    { header: 'Enquiry ID', accessor: 'ContId' },
    { header: 'Name', accessor: 'nme' },
    { header: 'Organization', accessor: 'oNme' },
    { header: 'Phone', accessor: '' },
    { header: 'Email', accessor: 'eId' },
    { header: 'Message', accessor: '' },
    { header: 'Updated On', accessor: '' },
    { header: 'Action', accessor: '' },
  ];
  React.useEffect(() => {
    if (tabTp === 'listENQ' && clientTp === 'ENQ') {
      setLoadingMore(true);
      dispatch(getEnquiryListReq({
        requestType: APIHeader.REQ_GET_ALL_CONTACT_FORM,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        contTp: '',
        actTp: '',
      }));
    }
  }, [tabTp, clientTp]);

  const fetchMoreData = () => {
    // setLoaderClient(true);
    setLoadingMore(true);
    if (isSearchQuery !== '') {
      dispatch(getEnquiryListReq({
        requestType: APIHeader.REQ_GET_ALL_CONTACT_FORM,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        contTp: '',
        actTp: '',
      }));
    } else {
      setLoadingMore(true);
      dispatch(getEnquiryListReq({
        requestType: APIHeader.REQ_GET_ALL_CONTACT_FORM,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        contTp: '',
        actTp: '',
      }));
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    dispatch(getEnquiryListReq({
      requestType: APIHeader.REQ_GET_ALL_CONTACT_FORM,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      contTp: '',
      actTp: '',
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    setIsSearchQuery(searchText);
    dispatch(getEnquiryListReq({
      requestType: APIHeader.REQ_GET_ALL_CONTACT_FORM,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      contTp: '',
      actTp: '',
    }));
  };

  React.useEffect(() => {
    if (enquiryListResponse.isError && enquiryListResponse.message !== '') {
      // setLoaderClient(false);
      setLoadingMore(false);
      setAlertMsg(enquiryListResponse.message);
      setErrorShowAlert(true);
    }
  }, [enquiryListResponse.isError, enquiryListResponse.message]);

  React.useEffect(() => {
    if (!enquiryListResponse.isError && enquiryListResponse.message === 'executed') {
      // setLoaderClient(false);
      setLoadingMore(false);

      if (searchText !== '') {
        setPagedData({ [currentPage]: enquiryListResponse.data.contactData });
      } else if (
        enquiryListResponse.data
        && Object.keys(enquiryListResponse.data).length > 0
        && Array.isArray(enquiryListResponse.data.contactData)
      ) {
        const newPageData = enquiryListResponse.data.contactData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllEnquiryData({
          tCnt: enquiryListResponse.data.tCnt,
          fCnt: enquiryListResponse.data.fCnt,
          contactData: enquiryListResponse.data.contactData,
          lastEvalKey: enquiryListResponse.data.lastEvalKey,
        });
        if (enquiryListResponse.data.lastEvalKey !== null) {
          setEvalKeyText(enquiryListResponse.data.lastEvalKey.ContId);
          setEvalTpText(enquiryListResponse.data.lastEvalKey.ContTp);
        }
        setTotalRecords(enquiryListResponse.data.tCnt);
      }
    }
  }, [enquiryListResponse]);

  const handleEditData = (contType: string, contId: string, crntSts: string) => {
    if (crntSts === 'APPR') {
      setShowUserAlertAPI(contId);
      setEditEnquiryRecord({
        ...editEnquiryRecord, ContTp: contType, ContId: contId, enqSts: crntSts,
      });
    } else {
      setShowUserAlertAPI('');
      setEditEnquiryRecord({
        ...editEnquiryRecord, ContTp: '', ContId: '', enqSts: '',
      });
    }
  };

  const handleUpdateUserStatusAPI = (status: string) => {
    if (status !== '') {
      setLoadingMore(true);
      dispatch(editEnquiryStatusReq({
        inputBody: editEnquiryRecord,
        requestType: APIHeader.REQ_UPDATE_CONTACT_FORM,
        uTp: UserType.CXADM,
        token: tokenData,
      }));
    } else {
      setShowUserAlertAPI('');
      setEditEnquiryRecord({
        ...editEnquiryRecord, ContTp: '', ContId: '', enqSts: '',
      });
    }
  };

  React.useEffect(() => {
    if (updateEnquiryStatusResponse.error && updateEnquiryStatusResponse.message !== '' && tabTp === 'listENQ' && clientTp === 'ENQ') {
      setLoadingMore(false);
      setShowUserAlertAPI('');
      setEditEnquiryRecord({
        ...editEnquiryRecord, ContTp: '', ContId: '', enqSts: '',
      });
      dispatch(clearEditUserStatusReq());
      setErrorShowAlert(true);
      setAlertMsg(updateEnquiryStatusResponse.message);
    }
  }, [updateEnquiryStatusResponse, tabTp, clientTp]);

  React.useEffect(() => {
    if (!updateEnquiryStatusResponse.error && updateEnquiryStatusResponse.message === 'executed' && tabTp === 'listENQ' && clientTp === 'ENQ') {
      setLoadingMore(false);
      setShowUserAlertAPI('');
      dispatch(clearEditEnquiryStatusReq());
      setEditEnquiryRecord({
        ...editEnquiryRecord, ContTp: '', ContId: '', enqSts: '',
      });
      dispatch(getEnquiryListReq({
        requestType: APIHeader.REQ_GET_ALL_CONTACT_FORM,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        contTp: '',
        actTp: '',
      }));
    }
  }, [updateEnquiryStatusResponse, tabTp, clientTp]);

  const handleShowFilterStatus = () => {
    setDropdowns(!dropdowns);
  };

  const handleListEnquiryStatus = (enqStatus: string) => {
    setLoadingMore(true);
    dispatch(getEnquiryListReq({
      requestType: APIHeader.REQ_GET_ALL_CONTACT_FORM,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      contTp: '',
      actTp: enqStatus,
    }));
  };

  return (
    <div>
      {/* <div className="mb-3 align-center">
        List of {clientTp === 'XCLN' ? 'Campus' : null}
      </div> */}
      <Modal
        show={!!(showUserAlertAPI !== '' && editEnquiryRecord.ContTp !== '' && (showUserAlertAPI === editEnquiryRecord.ContId))}
        onClose={() => (false)}
        disCancel
        targetId="blockUser"
        title=""
        size="SM"
      >
        <div className="my-2 align-center mx-4">
          <span>Do you really want to Complete this Process ?</span>
        </div>
        <div className="my-2 align-center mx-4">
          <span className="text-lite">{showUserAlertAPI}</span>
        </div>
        <div className="align-center my-3 pt-3">
          <Button className="button-icon" onClick={() => handleUpdateUserStatusAPI('APPR')}>
            COMPLETE
          </Button>
          <Button className="campus-button-all" onClick={() => handleUpdateUserStatusAPI('')}>
            CANCEL
          </Button>
        </div>
      </Modal>
      <Row className="align-start">
        {/* { loaderClient ? <LoaderData /> : null} */}
        {showSuccessAlert ? (
          successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
        ) : null}
        {showErrorsAlert ? (
          errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
        ) : null}
        <Col lg="12" xs="12">
          {loadingMore ? (
            <div className="loader-container-none align-center pt-5">
              <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
              <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
            </div>
          ) : (
            <Row className="align-start mx-1">
              <Col xs="12" lg="5">
                <div className="align-end">
                  <div className="input-wrapper">
                    <Input
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                    <Button type="button" onClick={handleSearchProjects}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  </div>
                  <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </div>
              </Col>
              <Col xs="12" lg="7">
                <div className="align-end pt-3 mr">
                  <ButtonDropdown isOpen={dropdowns} toggle={() => handleShowFilterStatus()}>
                    <DropdownToggle caret className="job-button-approve">
                      Enquiry Status
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleListEnquiryStatus('COMPLETED')} className="my-2">
                        COMPLETED
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </Col>
              <Col xs="12">
                <div className="pt-1">
                  <EnquiryTable
                    tableId="enquiryUser"
                    columns={columns}
                    data={pagedData[currentPage]}
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                    totalRecords={totalRecords}
                    onPageChange={handlePageChange}
                    handleEditData={handleEditData}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EnquiryDashComponent;
