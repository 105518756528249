import { postJSON } from '../../../../../utils';
import { getConfig } from '../../../../../utils/config/config';
import { BatchAddOptions, BatchAddResponse } from './add-batch.service.types';

const { CAMPUSXCEL_CMPUS_API_ROOT } = getConfig();

export const BatchAddRequest = ({
  requestType, uTp, inputBody, token,
}: BatchAddOptions): Promise<BatchAddResponse> => {
  if (token) {
    return postJSON(`${CAMPUSXCEL_CMPUS_API_ROOT}?uTp=${uTp}`, token, requestType, inputBody as unknown as BodyInit);
  }
  return postJSON(`${CAMPUSXCEL_CMPUS_API_ROOT}?uTp=${uTp}`, '', requestType, inputBody as unknown as BodyInit);
};
