import React from 'react';
import { CardImg } from 'reactstrap';
import { ComingSoon } from '../../../../../../scenes/unauthorized/coming-soon.component';

export const AdminRecruitEmployee: React.FC = () => (
  <div>
    <div className="allign-start ml my-2">
      <CardImg className="recruit-main-header-img-card" src="/images/icons/org-users.png" alt="batches" />
      <span className="text-lite-grey ml">MY EMPLOYEE</span>
    </div>
    <ComingSoon />
    <div className="mb-3 p-4" />
  </div>
);

export default AdminRecruitEmployee;
