import {
  batch, Fade, Animator, Move,
} from 'react-scroll-motion';
import {
  Button,
  CardImg,
  Col, Row,
} from 'reactstrap';
import './campus.scss';
import React from 'react';
import AboutCampus from './about-campus';
import { CXCampusNavbar } from '../navbar/cx-campus.navbar';
import Offcanvas from '../../../utils/OffCanvas/OffcanvasComponent';
import { ContactForm } from '../contact-form/contact-form';

export const IntroCampus: React.FC = () => {
  const FadeUp = batch(Fade(), Move());
  const [contactDetailsHome, setContactDetailsHome] = React.useState(false);

  const toggleContact = () => {
    setContactDetailsHome(!contactDetailsHome);
  };
  return (
    <div>
      <CXCampusNavbar />
      <Animator animation={batch(FadeUp)} className="pt-5">
        <div className="pt-5 bg-campus">
          <Row className="align-center pt-2 mx-3">
            <Col lg="5" xs="12">
              <div className="margin-mob-sides">
                <div className="text-center-aligned">
                  <span className="campus-header">
                    Unlocking Excellence
                  </span>
                </div>
                <div className="text-center-aligned">
                  <span className="campus-op-header">
                    Path to Academic and Career Success
                  </span>
                </div>
                <div className="pt-4 text-center-aligned mx-3">
                  <span className="text-intro">
                    Academy&apos;s Integration of Excellence and Industry Connections on a Single Platform
                  </span>
                </div>
                <div className="card-actions align-center my-4">
                  <Button className="campus-home-button" onClick={toggleContact}>ONBOARD CAMPUS</Button>
                </div>
              </div>
            </Col>
            <Col lg="5" xs="12">
              <div className="align-center mx-4 pt-3">
                <CardImg
                  alt="..."
                  src="/images/campus/campus-intro.png"
                  className="image-intro"
                />
              </div>
            </Col>
          </Row>
          <div className="pt-3">
            <Row className="home-key-highlight-box pt-3">
              <Col lg="2" xs="6">
                <div className="align-center pt-4">
                  <CardImg
                    alt="..."
                    className="image-intro-campus"
                    src="/images/icons/brand.png"
                  />
                </div>
                <div className="text-center-aligned margin-icons-bottom pt-2">
                  <span className="text-sub-about">Campus Branding</span>
                </div>
              </Col>
              <Col lg="2" xs="6">
                <div className="align-center pt-4">
                  <CardImg
                    alt="..."
                    className="image-intro-campus"
                    src="/images/icons/bulletin-board.png"
                  />
                </div>
                <div className="text-center-aligned margin-icons-bottom pt-2">
                  <span className="text-sub-about">Digital Noticeboard</span>
                </div>
              </Col>
              <Col lg="2" xs="6">
                <div className="align-center pt-4">
                  <CardImg
                    alt="..."
                    className="image-intro-campus"
                    src="/images/icons/internship.png"
                  />
                </div>
                <div className="text-center-aligned margin-icons-bottom pt-2">
                  <span className="text-sub-about">Jobs & Internship</span>
                </div>
              </Col>
              <Col lg="2" xs="6">
                <div className="align-center pt-4">
                  <CardImg
                    alt="..."
                    className="image-intro-campus"
                    src="/images/icons/leadership-campus.png"
                  />
                </div>
                <div className="text-center-aligned margin-icons-bottom pt-2">
                  <span className="text-sub-about">Carrer Guidance</span>
                </div>
              </Col>
              <Col lg="2" xs="6">
                <div className="align-center pt-4">
                  <CardImg
                    alt="..."
                    className="image-intro-campus"
                    src="/images/icons/fair.png"
                  />
                </div>
                <div className="text-center-aligned margin-icons-bottom pt-2">
                  <span className="text-sub-about">Job Fair</span>
                </div>
              </Col>
              <Col lg="2" xs="6">
                <div className="align-center pt-4">
                  <CardImg
                    alt="..."
                    className="image-intro-campus"
                    src="/images/icons/humanpictos.png"
                  />
                </div>
                <div className="text-center-aligned margin-icons-bottom pt-2">
                  <span className="text-sub-about">Industry Connects</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Animator>
      <div className="pt-1">
        <AboutCampus />
      </div>
      <Offcanvas
        isOpen={contactDetailsHome}
        toggle={() => toggleContact()}
      >
        <ContactForm userFrom="CMPS" />
      </Offcanvas>
    </div>
  );
};

export default IntroCampus;
