import React from 'react';
import {
  Button,
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const AllInOneCampus: React.FC = () => {
  const jobUrl = () => {
    window.open('/jobs', '_blank');
    window.scrollTo(0, 0);
  };

  const atsUrl = () => {
    window.open('/staffing', '_blank');
    window.scrollTo(0, 0);
  };
  const campusUrl = () => {
    window.open('/campus', '_blank');
    window.scrollTo(0, 0);
  };

  const schemesUrl = () => {
    window.open('/gov-schemes', '_blank');
    window.scrollTo(0, 0);
  };
  const skillsUrl = () => {
    window.open('/upskills', '_blank');
    window.scrollTo(0, 0);
  };
  const mentorUrl = () => {
    window.open('/mentors', '_blank');
    window.scrollTo(0, 0);
  };

  return (
    <div className="pt-5 why-campus-xcel">
      <div className="pt-5">
        <Row className="mx-1 pt-2 align-center">
          <Col lg="6">
            <div className="align-center">
              <span className="text-intro-header text-center-aligned display-6">
                Integrated Platform for Transparent Goal Achievement
              </span>
            </div>
          </Col>
          <Col xs="12" lg="8">
            <div className="align-center pt-4">
              <span className="pt-1 text-intro text-center-aligned">
                Our singular, comprehensive platform offers everything you need for career success: job opportunities in different sectors, an innovative profile management system, a wide array of campus features for administrators and students, insights into key schemes, targeted upskilling programs, and professional mentoring.
              </span>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-4">
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 1 }}>
            <CardImg src="/images/home/jobs.png" alt="jobs Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Job Opportunities</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Discover an extensive range of job listings from different sectors. Our platform helps you find the perfect role to match your skills and aspirations, making your job search efficient and effective.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={jobUrl}>Know More</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/home/ats-resume.png" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Innovative Profile Management System</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Our advanced Applicant Tracking System (ATS) allows you to manage your profile and job applications seamlessly. Track your progress, organize your documents, and optimize your job search process for better outcomes.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={atsUrl}>Know More</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/home/campus-stud.jpg" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Campus: Graduates</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Whether you’re an administrator, student, or candidate, our platform offers a variety of features to enhance the educational experience. From managing academic activities to accessing resources, our tools help streamline campus life and improve engagement.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={campusUrl}>Know More</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/home/mentors.jpg" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Mentoring: Gain Insights from Professionals</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Connect with seasoned professionals for guidance and mentorship. Gain valuable insights, advice, and support from experts who can help you navigate your career path and achieve your goals.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={mentorUrl}>Know More</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/home/upskilling.jpg" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned mx-2">
                <h3 className="about-card-title">Upskilling: Acquire Job-Ready Skills</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Prepare yourself for the job market with our targeted upskilling programs. Access industry-relevant courses and certifications that help you acquire the skills needed to excel in your career and stay competitive.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={skillsUrl}>Know More</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/home/gov-schemes.png" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Schemes: Key Initiatives</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Stay up-to-date with important schemes and initiatives. Our platform provides detailed information on various programs and benefits, helping you make informed decisions and take advantage of available opportunities.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={schemesUrl}>Know More</Button>
            </div>
          </Col>
        </Row>
        <Row className="mx-1 align-center">
          <Col xs="12" lg="9">
            <div className="pt-2 text-center-aligned mx-2">
              <span className="text-sub-intro">
                By consolidating all these essential resources into one unified platform,
                you benefit from streamlined access and a cohesive user experience.
                This integrated approach allows you to manage all aspects of your career
                development in one place, making it easier to track progress, stay informed,
                and efficiently utilize resources. With everything you need at your fingertips,
                our platform helps you achieve better results and maximize your potential for career
                success.
              </span>
            </div>
          </Col>
        </Row>
        <div className="mb-3 p-4" />
      </div>
    </div>
  );
};
export default AllInOneCampus;
