import React from 'react';
import './offcanva.scss';
import { Button, Card } from 'reactstrap';

interface OffcanvasProps {
  isOpen: boolean;
  toggle: () => void;
  children: any;
}

const Offcanvas: React.FC<OffcanvasProps> = ({ isOpen, toggle, children }) => (
  <div className={`offcanvas ${isOpen ? 'show' : ''}`}>
    <Card className="offcanvas-backdes border-none form-card-body" onClick={toggle} />
    <div className="offcanvas-content">
      <Button className="offcanvas-close" onClick={toggle}>×</Button>
      {children}
    </div>
  </div>
);

export default Offcanvas;
