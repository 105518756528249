import { ListJobsByStatusResponse, ListJobsByStatusResults } from './list-jobs.status.service.types';

export const ListJobsByStatusTransform: (response: ListJobsByStatusResponse) => ListJobsByStatusResults = (listPJobsResults) => {
  const { data, is_error } = listPJobsResults;
  const result: ListJobsByStatusResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      jobsData: [],
      lastEvalKey: {
        JobCd: '',
        JobId: '',
      },
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
