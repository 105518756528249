import React from 'react';
import {
  Button, Col, Input, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ResumeCoverLetterInput } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';

export type ResumeInfoProps = {
  handleResumeCoverLetterData: (event: any) => void;
  resumeCoverLetter:ResumeCoverLetterInput;
};

export const XcelianCoverInfo: React.FC<ResumeInfoProps> = ({
  handleResumeCoverLetterData,
  resumeCoverLetter,
}) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(resumeCoverLetter.covL).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000); // Reset "Copied" message after 2 seconds
    });
  };
  return (
    <div className="xcelian-resume-covverletter-width mx-3">
      <Row>
        <Col xs="12 pt-1" lg="7">
          <h6 className="text-lite">Cover Letter</h6>
        </Col>
        <Col xs="12" lg="5">
          <div className="align-end">
            <Button
              onClick={handleCopyToClipboard}
              className="button-transparent-jobs padding-zero"
            >
              <FontAwesomeIcon className="ml" icon={faCopy} />{isCopied ? 'Copied !' : 'Copy'}
            </Button>
          </div>
        </Col>
        <Col xs="12 pt-2">
          <Input
            placeholder="Enter Your Cover Letter Here"
            type="textarea"
            required
            name="covL"
            value={resumeCoverLetter.covL}
            onChange={handleResumeCoverLetterData}
            className="campus-text-area-desc"
          />
        </Col>
      </Row>
    </div>
  );
};

export default XcelianCoverInfo;
