import React from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Button, Card, CardImg, Col, Row,
} from 'reactstrap';
import './template1.scss';
import { faGlobe, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PreviewResumeProps } from '../resume.template.type';
import { formatUserDateNoHr } from '../../../../../../utils/constants';

export const PreviewTemplate1: React.FC<PreviewResumeProps> = ({ previewResume, myProfile }) => {
  const componentRef = React.useRef<HTMLDivElement>(null);

  // Correct way to use react-to-print in v3.x (contentRef instead of content)
  const handlePrint = useReactToPrint({
    contentRef: componentRef, // Pass the ref here directly
    documentTitle: 'Resume',
    pageStyle: `
      @page {
        margin: 5mm;
      }
      body {
        font-family: Arial, sans-serif;
        line-height: 1.1;
      }
    `,
  });

  return (
    <div className="template1-card-design">
      <div
        ref={componentRef} // Ref for the content to be printed
        id="form-content"
        style={{
          padding: '10px',
          border: 'none',
          borderRadius: '4px',
          background: '#fff',
        }}
      >
        <Card className="template1-card-design">
          <Row className="mx-1 pt-3">
            <Col xs="12">
              <div className="align-center pt-2">
                <span className="template1-header">{myProfile.fNme}{' '}{myProfile.lNme}</span>
              </div>
              <hr className="template1-hr" />
              <div className="align-center pt-1">
                <div>
                  <CardImg src="/images/template-icons/envelope.png" alt="..." className="template1-img-icon" />
                  <span className="template1-text ml">{myProfile.cEId}</span>
                </div>
                <div className="mx-3"><span className="text-lite-grey">|</span></div>
                <div>
                  <CardImg src="/images/template-icons/phone.png" alt="..." className="template1-img-icon" />
                  <span className="template1-text ml">{myProfile.dlCd} {myProfile.cPh}</span>
                </div>
                <div className="mx-3"><span className="text-lite-grey">|</span></div>
                <div>
                  <CardImg src="/images/template-icons/pin.png" alt="..." className="template1-img-icon" />
                  <span className="template1-text ml">{previewResume.city}</span>
                </div>
              </div>
            </Col>
          </Row>
          {previewResume && (previewResume.obj !== '' && previewResume.obj !== undefined)
            ? (
              <Row className="mx-1">
                <Col xs="12">
                  <div className="pt-5">
                    <CardImg src="/images/template-icons/target.png" alt="..." className="template1-img-icon-main mr" />
                    <span className="template1-header">Career Objective</span>
                  </div>
                </Col>
                <Col className="mx-1 pt-2">
                  <div className="align-text-justify">
                    <span className="template1-text">{previewResume.obj}</span>
                  </div>
                </Col>
              </Row>
            ) : null}
          {previewResume && previewResume.resSkills && previewResume.resSkills.length > 0
            ? (
              <Row className="mx-1">
                <Col xs="12">
                  <div className="pt-4">
                    <CardImg src="/images/template-icons/skills.png" alt="..." className="template1-img-icon-main mr" />
                    <span className="template1-header">Skills</span>
                  </div>
                </Col>
                <Col className="mx-1 pt-2">
                  <Row>
                    {previewResume && previewResume.resSkills && previewResume.resSkills.map((skillGroup, skillGroupIndex) => (
                      <div key={`group-${skillGroupIndex.toString()}`} className="my-1">
                        <span className="template1-subheader-italic">
                          <CardImg src="/images/template-icons/button.png" alt="..." className="template1-img-icon mr" />{skillGroup.sklName}:
                          <span className="ml template1-text">{skillGroup.skillData.join(', ')}</span>
                        </span>
                      </div>
                    ))}
                  </Row>
                </Col>
              </Row>
            ) : null}
          {previewResume && previewResume.orgData && previewResume.orgData.length > 0
            ? (
              <Row className="mx-1">
                <Col xs="12">
                  <div className="pt-4">
                    <CardImg src="/images/template-icons/briefcase.png" alt="..." className="template1-img-icon-main mr" />
                    <span className="template1-header">Professional Experience</span>
                  </div>
                </Col>
                <Col className="mx-1 pt-2">
                  {previewResume && previewResume.orgData && previewResume.orgData
                    .sort((orgA, orgB) => (orgB.isCrnt ? 1 : 0) - (orgA.isCrnt ? 1 : 0)) // Sort to bring currently working entries to the top
                    .map((org, orgIndex) => (
                      <div className="mb-4" key={`${orgIndex.toString()}`}>
                        <div className="align-start">
                          <span className="template1-subheader-italic">
                            {org.sDte !== '' ? formatUserDateNoHr(org.sDte) : 'NA'} - {org.isCrnt ? 'Currently Working' : org.eDte !== '' ? formatUserDateNoHr(org.eDte) : 'NA'}
                          </span>
                        </div>
                        <div className="align-start">
                          <span className="template1-subheader">
                            {org.dsg}, {org.org} ({org.orgLoc})
                          </span>
                        </div>
                        <div className="my-2">
                          <div className="my-1">
                            <span className="template1-subheader-italic">Collaborative Projects</span>
                          </div>
                          {org && org.prjs
                            .sort((projA, projB) => (projB.isCrnt ? 1 : 0) - (projA.isCrnt ? 1 : 0)) // Sort to bring currently working entries to the top
                            .map((project, projIndex) => (
                              <div className="mb-1" key={`${projIndex.toString()}`}>
                                <div className="align-start">
                                  <span className="template-header">
                                    {project.prjNme} {project.isCrnt ? <span className="template-about-text-current">- Currently working</span> : null}
                                  </span>
                                </div>
                                <div className="align-start align-text-justify pt-2">
                                  <span className="template1-text">
                                    {project.desc}
                                  </span>
                                </div>
                                <div className="pt-3">
                                  <div>
                                    <span className="template1-subheader-italic">Skills Achieved: </span>
                                  </div>
                                  <Row>
                                    {org.prjs[projIndex].skls.map((skill, index) => (
                                      <Col xs="auto" lg="auto" key={`prjskls-${index.toString()}`} className="template-resume-project-header ml my-1">
                                        <CardImg src="/images/template-icons/button.png" alt="..." className="template1-img-icon mr" />
                                        {skill}
                                      </Col>
                                    ))}
                                  </Row>
                                </div>
                                <div className="pt-2">
                                  <div className="pt-2">
                                    <span className="template1-subheader-italic mr">My Roles & Responsibilities</span>
                                  </div>
                                  <div className="ml">
                                    {org.prjs[projIndex].myRls.map((role, index) => (
                                      <div key={`prjrols-${index.toString()}`} className="align-text-justify my-1">
                                        <span className="xcelian-resume-project-header">
                                          <CardImg src="/images/template-icons/button.png" alt="..." className="template1-img-icon mr" />
                                          {role}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </Col>
              </Row>
            ) : null}
          {previewResume && previewResume.certData && previewResume.certData.length > 0
            ? (
              <Row className="mx-1">
                <Col xs="12">
                  <div className="pt-4">
                    <CardImg src="/images/template-icons/cert.png" alt="..." className="template1-img-icon-main mr" />
                    <span className="template1-header">Certifications & Achievements</span>
                  </div>
                </Col>
                <Col className="mx-1 pt-2">
                  {previewResume && previewResume.certData && previewResume.certData
                    .map((cert, certIndex) => (
                      <div className="mb-3" key={`${certIndex.toString()}`}>
                        <div className="align-start">
                          <span className="template-header">
                            <CardImg src="/images/template-icons/button.png" alt="..." className="template1-img-icon mx-1" /> {cert.certNme}
                          </span>
                        </div>
                        {cert.sDte !== '' && cert.eDte !== ''
                          ? (
                            <div className="align-start ml-2">
                              <span className="template1-subheader-italic">
                                {cert.sDte !== '' ? formatUserDateNoHr(cert.sDte) : 'NA'} - {cert.eDte !== '' ? formatUserDateNoHr(cert.eDte) : 'NA'}
                              </span>
                            </div>
                          ) : null}
                        <div className="align-start ml-2">
                          <span className="template1-text">
                            {cert.desc}
                          </span>
                        </div>
                      </div>
                    ))}
                </Col>
              </Row>
            ) : null}
          {previewResume && previewResume.langData && previewResume.langData.length > 0
            ? (
              <Row className="mx-1">
                <Col xs="12">
                  <div className="pt-4">
                    <span className="template1-header">Language Proficiency</span>
                  </div>
                </Col>
                <Col className="mx-1 pt-2">
                  {previewResume && previewResume.langData && previewResume.langData
                    .map((lang, langIndex) => (
                      <div className="mb-3" key={`${langIndex.toString()}`}>
                        <div className="align-start">
                          <span className="template-header">
                            <FontAwesomeIcon icon={faGlobe} className="mr template1-exp-icon" />
                            {lang.langNme}
                          </span>
                          <span className="mx-2 text-lite">-</span>
                          <span className="template-resume-project-header">
                            {lang.level}
                          </span>
                        </div>
                      </div>
                    ))}
                </Col>
              </Row>
            ) : null}
          {previewResume && previewResume.eduData && previewResume.eduData.length > 0
            ? (
              <Row className="mx-1">
                <Col xs="12">
                  <div className="pt-4">
                    <span className="template1-header">Education</span>
                  </div>
                </Col>
                <Col className="mx-1 pt-2">
                  {previewResume && previewResume.eduData && previewResume.eduData
                    .map((edu, eduIndex) => (
                      <div className="mb-3" key={`${eduIndex.toString()}`}>
                        <div className="align-start">
                          <span className="template-header">
                            <FontAwesomeIcon icon={faGraduationCap} className="mr template1-exp-icon" />
                            {edu.cmpsNme}
                          </span>
                        </div>
                        <div className="align-start ml">
                          <span className="template-resume-project-header">
                            {edu.spec}
                          </span>
                        </div>
                        <div className="align-start ml">
                          <span className="template-resume-project-header">
                            {edu.sDte !== '' ? formatUserDateNoHr(edu.sDte) : 'NA'} - {edu.eDte !== '' ? formatUserDateNoHr(edu.eDte) : 'NA'}
                          </span>
                        </div>
                      </div>
                    ))}
                </Col>
              </Row>
            ) : null}
          <Row className="mx-1">
            {previewResume
  && previewResume.dob !== '' && previewResume.dob !== undefined
  && previewResume.addr !== '' && previewResume.addr !== undefined
  && previewResume.city !== '' && previewResume.city !== undefined
  && previewResume.st !== '' && previewResume.st !== undefined
  && previewResume.pCd !== '' && previewResume.pCd !== undefined
              ? (
                <Col xs="12">
                  <div className="pt-4">
                    <span className="template1-header">Personal Information</span>
                  </div>
                </Col>
              ) : null}
            {previewResume.dob !== '' && previewResume.dob !== undefined
              ? (
                <Col xs="12">
                  <div className="pt-1">
                    <span className="template1-subheader-italic">DOB: </span>
                    <span className="template1-text">{previewResume.dob !== '' ? formatUserDateNoHr(previewResume.dob) : 'NA'}</span>
                  </div>
                </Col>
              ) : null}
            {previewResume
  && previewResume.addr !== '' && previewResume.addr !== undefined
  && previewResume.city !== '' && previewResume.city !== undefined
  && previewResume.st !== '' && previewResume.st !== undefined
  && previewResume.pCd !== '' && previewResume.pCd !== undefined
              ? (
                <Col xs="12">
                  <div className="pt-1">
                    <span className="template1-subheader-italic">Address: </span>
                    <span className="template1-text">{previewResume.addr}, {previewResume.city}, {previewResume.st} - {previewResume.pCd}</span>
                  </div>
                </Col>
              ) : null}
          </Row>
          <div className="pt-4" />
        </Card>
      </div>

      <div className="align-end pt-2 mx-1 my-1">
        <Button
          onClick={() => handlePrint()} // Directly calling handlePrint
          className="campus-button-all"
        >
          Download PDF
        </Button>
      </div>
      <div className="align-start pt-2 ml-1">
        <span className="text-lite-grey">For best results, please disable &quot;Headers and Footers&quot; in the print settings before saving.</span>
      </div>
    </div>
  );
};

export default PreviewTemplate1;
