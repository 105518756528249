import React from 'react';
import { Row } from 'reactstrap';
// import { Link } from 'react-router-dom';
import './unauthorized.scss';

export const CXUnauthorized: React.FC = () => (
  <Row className="unauthorized-container">
    <div className="vert-center-unauthorized">
      <span className="error-header">403</span>
      <div>
        <span className="error-sub-header">Sorry but Unauthorized</span>
      </div>
    </div>
  </Row>
);

export default CXUnauthorized;
