import { ResumeProfileResponse, ResumeProfileResults } from './get-profile.service.types';

// const AppTransform: (input: ResumeProfileResult) => ResumeProfileResult = (profile) => ({
//   lNme: profile.lNme,
// });

export const ResumeProfileTransform: (response: ResumeProfileResponse) => ResumeProfileResults = (resumeProfileResults) => {
  const { data, is_error, message } = resumeProfileResults;
  const result :ResumeProfileResults = {
    perData: {
      currLoc: '',
      cEId: '',
      diffAbl: false,
      cnty: '',
      diffAblRsn: '',
      addr: '',
      pin: '',
      cty: '',
      st: '',
      langKnwn: [],
      isPrefPAN: false,
      dOB: '',
      dlCd: '',
      cPh: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.perData.currLoc = data.perData.currLoc ?? '';
    result.perData.cEId = data.perData.cEId ?? '';
    result.perData.diffAbl = data.perData.diffAbl ?? false;
    result.perData.cnty = data.perData.cnty ?? '';
    result.perData.diffAblRsn = data.perData.diffAblRsn ?? 'NONE';
    result.perData.isPrefPAN = data.perData.isPrefPAN ?? false;
    result.perData.dOB = data.perData.dOB ?? '';
    result.perData.addr = data.perData.addr ?? '';
    result.perData.pin = data.perData.pin ?? '';
    result.perData.st = data.perData.st ?? '';
    result.perData.cty = data.perData.cty ?? '';
    result.perData.dlCd = data.perData.dlCd ?? '';
    result.perData.cPh = data.perData.cPh ?? '';
    result.perData.lNme = data.perData.lNme ?? '';
    result.perData.fNme = data.perData.fNme ?? '';
    result.perData.prefLoc = data.perData.prefLoc ?? [];
    result.perData.langKnwn = data.perData.langKnwn ?? [];
    result.message = 'executed';
  }
  return result;
};
