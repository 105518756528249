import React from 'react';
import {
  Button,
  CardImg,
  Col, Row,
} from 'reactstrap';
import './ats.scss';
import Offcanvas from '../../../utils/OffCanvas/OffcanvasComponent';
import { ContactForm } from '../contact-form/contact-form';

export const AboutAts: React.FC = () => {
  const [contactDetailsATSAbout, setContactDetailsATSAbout] = React.useState(false);
  const toggleContact = () => {
    setContactDetailsATSAbout(!contactDetailsATSAbout);
  };
  // const jobUrl = () => {
  //   window.open('/jobs', '_blank');
  //   window.scrollTo(0, 0);
  // };

  // const atsUrl = () => {
  //   window.open('/ats', '_blank');
  //   window.scrollTo(0, 0);
  // };
  // const campusUrl = () => {
  //   window.open('/campus', '_blank');
  //   window.scrollTo(0, 0);
  // };

  // const schemesUrl = () => {
  //   window.open('/gov-schemes', '_blank');
  //   window.scrollTo(0, 0);
  // };
  // const skillsUrl = () => {
  //   window.open('/upskills', '_blank');
  //   window.scrollTo(0, 0);
  // };
  // const mentorUrl = () => {
  //   window.open('/mentors', '_blank');
  //   window.scrollTo(0, 0);
  // };

  return (
    <div className="pt-5 why-campus-xcel">
      <div className="pt-5">
        <Row className="mx-1 pt-2 align-center">
          <Col lg="10">
            <div className="align-center">
              <span className="text-intro-header text-center-aligned display-6">
                Our Competitive Advantage: Why Choose Us
              </span>
            </div>
          </Col>
          <Col xs="12" lg="7">
            <div className="align-center pt-4">
              <span className="pt-1 text-intro text-center-aligned">
                We don’t just offer a recruitment platform; we provide a comprehensive solution that transforms the way you hire. By addressing the major pain points in the current recruitment process, we ensure that your organization can attract, evaluate, and onboard top talent more efficiently and effectively than ever before.
              </span>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-4">
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 1 }}>
            <CardImg src="/images/ats/branding.jpg" alt="jobs Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Employer Branding</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Incorporate employer branding features within the platform, allowing companies to showcase their culture, values, and work environment. Include options for custom-branded career pages, employee testimonials, video tours, and social media integration.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={toggleContact}>Contact Sales</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 5, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/ats/cand-matching.png" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">AI-Driven Candidate Matching</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">AI algorithms match candidates to job descriptions by evaluating multiple parameters, ensuring the selection of the most suitable candidates for each job. This significantly reduces the time spent on initial screenings while enhancing the quality of shortlisted candidates, helping you find the right talent quickly.</p>
              </div>
            </div>
            {/* <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={atsUrl}>Know More</Button>
            </div> */}
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/ats/schedule.jpg" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Automated Interview Scheduling</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Say goodbye to scheduling conflicts. Our AI-powered scheduling assistant analyzes the availability of both candidates and interviewers, automatically setting up interviews at the most convenient times. Candidates can even self-schedule, adding a layer of flexibility.</p>
              </div>
            </div>
            {/* <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={campusUrl}>Know More</Button>
            </div> */}
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/ats/quality.jpg" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Integrated Assessment Tools</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">We offer a suite of integrated assessment tools, including technical tests, cognitive assessments, and personality quizzes. These tools are seamlessly incorporated into the hiring workflow, allowing for comprehensive candidate evaluations without leaving the platform.</p>
              </div>
            </div>
            {/* <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={mentorUrl}>Know More</Button>
            </div> */}
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/ats/assesment.png" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned mx-2">
                <h3 className="about-card-title">Real-Time Feedback and Analytics</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Our platform enables real-time feedback collection from interviewers, with AI-driven analytics to summarize key insights. A centralized dashboard provides visualizations of candidate performance and trends, making it easier to collaborate and make data-driven decisions.</p>
              </div>
            </div>
            {/* <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={skillsUrl}>Know More</Button>
            </div> */}
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/ats/questionnaire.jpg" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Customizable Interview Templates and Guides</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">To ensure consistency and fairness in the interview process, we provide customizable interview templates and guides. These resources include industry-specific questions, evaluation criteria, and best practices, tailored to different roles and industries.</p>
              </div>
            </div>
            {/* <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={schemesUrl}>Know More</Button>
            </div> */}
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/ats/offer-letter.png" alt="ats Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content pt-3">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Automated Offer and Onboarding Process</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Our platform streamlines the offer management process with automated offer letter generation and negotiation tools. Digital onboarding workflows, including background checks and document signing, are integrated directly into the platform, reducing time-to-hire and enhancing the candidate experience.</p>
              </div>
            </div>
            {/* <div className="card-actions align-center">
              <Button disabled className="card-iphone-button" onClick={schemesUrl}>Know More</Button>
            </div> */}
          </Col>
        </Row>
        <Offcanvas
          isOpen={contactDetailsATSAbout}
          toggle={() => toggleContact()}
        >
          <ContactForm userFrom="CORP" />
        </Offcanvas>
        <div className="mb-3 p-4" />
      </div>
    </div>
  );
};
export default AboutAts;
