import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { RootState } from 'typesafe-actions';
import {
  Row, Col,
  CardHeader,
  CardBody,
  Card,
  Button,
} from 'reactstrap';
import {
  faArrowLeft,
  faLightbulb, faPlay, faQuoteLeft, faQuoteRight,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResumeVideo } from '../../../common/video-profile/video';
import { ResumeTypeProps } from '../resume.type';
import { LoaderData } from '../../../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../../../utils/alert';
import {
  clearResumeVideo, clearUploadS3FilesReq, clearVideo, getResumeVideo, getVideo,
} from '../../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../../utils/service/localstorage-service';
import { ResumeVideoDataResData } from '../../../../../../services/campus/resume-data/video-profile/get-video-url/get-video.types';
import { getConfig } from '../../../../../../utils/config/config';
import { VideoInput } from '../../../../../../services/campus/resume-data/video-profile/update-video/video.types';

const { AWS_CXUSER_BUCKET } = getConfig();

export const VideoProfileComponent: React.FC<ResumeTypeProps> = ({ rootResumeTp, resumeTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileLocalStorage();
  const [userId, setUserId] = React.useState('');
  const [cmpsCd, setCmpsCd] = React.useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [loaderVideoResume, setLoaderVideoResume] = useState(false);
  const [uploadFileName, setUploadFileName] = useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const getVideoUrlResponse = useSelector((state: RootState) => state.campusXcel.getVideoResume);
  const updateVideoResponse = useSelector((state: RootState) => state.campusXcel.updateVideoResume);
  const [campusVideoData, setcampusVideoData] = useState<ResumeVideoDataResData>({
    video: '',
  });
  const [campusVideoUpdateData, setcampusVideoUpdateData] = useState<VideoInput>({
    video: '',
  });

  const handleShowVideo = () => {
    setShowVideo(!showVideo);
  };

  React.useEffect(() => {
    if (resumeTp === 'video' && rootResumeTp === 'resume-act') {
      setLoaderVideoResume(true);
      dispatch(getResumeVideo({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'VIDEO',
      }));
    }
  }, [resumeTp, rootResumeTp]);

  React.useEffect(() => {
    setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    setCmpsCd(userProfile && JSON.parse(userProfile).profileData.cmpsCd);
  }, [userProfile && JSON.parse(userProfile).profileData.UserCd, userProfile && JSON.parse(userProfile).profileData.cmpsCd]);

  React.useEffect(() => {
    if (getVideoUrlResponse.isError && getVideoUrlResponse.message !== '' && resumeTp === 'video' && rootResumeTp === 'resume-act') {
      setLoaderVideoResume(false);
      setAlertMsg(getVideoUrlResponse.message);
      setErrorShowAlert(true);
    }
  }, [getVideoUrlResponse.isError, getVideoUrlResponse.message, resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (!getVideoUrlResponse.isError && getVideoUrlResponse.message === 'executed' && resumeTp === 'video' && rootResumeTp === 'resume-act') {
      setLoaderVideoResume(false);
      setcampusVideoData({ ...campusVideoData, video: getVideoUrlResponse.video });
    }
  }, [getVideoUrlResponse.isError, getVideoUrlResponse.message, resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (uploadFileName !== '' && showVideo && alertMsg === 'Uploaded Successfully') {
      setLoaderVideoResume(true);
      setcampusVideoUpdateData((prevState) => ({
        ...prevState,
        video: uploadFileName,
      }));
    }
  }, [uploadFileName, showVideo, alertMsg]);

  React.useEffect(() => {
    if (campusVideoUpdateData.video !== '') {
      setUploadFileName('');
      dispatch(getVideo({
        inputBody: campusVideoUpdateData,
        requestType: APIHeader.REQ_UPDATE_VIDEO,
        uTp: UserType.CMPS,
        token: tokenData,
      }));
    }
  }, [campusVideoUpdateData.video]);

  React.useEffect(() => {
    if (updateVideoResponse.error && updateVideoResponse.message !== '') {
      setLoaderVideoResume(false);
      setAlertMsg(updateVideoResponse.message);
      setErrorShowAlert(true);
      setcampusVideoUpdateData({ ...campusVideoUpdateData, video: '' });
    }
  }, [updateVideoResponse.error, updateVideoResponse.message]);

  React.useEffect(() => {
    if (!updateVideoResponse.error && updateVideoResponse.message !== '') {
      setAlertMsg(updateVideoResponse.message);
      setSuccessShowAlert(true);
      setShowVideo(false);
      setcampusVideoUpdateData({ ...campusVideoUpdateData, video: '' });
      dispatch(clearVideo());
      dispatch(clearResumeVideo());
      dispatch(clearUploadS3FilesReq());
      dispatch(getResumeVideo({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'VIDEO',
      }));
      setLoaderVideoResume(false);
    }
  }, [updateVideoResponse.error, updateVideoResponse.message]);

  return (
    <div className="pt-1 margin-bottom mx-2">
      { loaderVideoResume ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="align-center">
        <Col xs="12" lg="12">
          <Card className="video-card">
            <CardHeader className="campus-header-with-bg align-center">
              <div className=" my-2">
                <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
                <span className="video-header-text">Present Yourself in</span>
                <FontAwesomeIcon className="video-header-text mx-2" icon={faQuoteLeft} />
                <span className="video-quote-text">60 Seconds</span>
                <FontAwesomeIcon className="video-header-text mx-2" icon={faQuoteRight} />
              </div>
            </CardHeader>
            <CardBody className="mx-1 padding-zero">
              {campusVideoData.video !== '' && !showVideo
                ? (
                  <Row className="align-center my-3">
                    <Col xs="12" lg="6">
                      <ReactPlayer
                        url={campusVideoData.video}
                        controls
                        width="100%"
                        height="auto"
                        playsinline
                        config={{
                          vimeo: {
                            playerOptions: {
                              fullscreen: true,
                            },
                          },
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
              {!showVideo
                ? (
                  <div>
                    <div className="text-center-aligned">
                      <span className="text-lite-grey">Highlight These Key Points for a Stronger Video Profile</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Introduction: </span>
                      <span className="video-sub-header-text">Provide a brief introduction to who you are and your academic background.</span>
                      <span className="text-lite">(What is your name, and what are you currently studying?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Skills and Strengths: </span>
                      <span className="video-sub-header-text">Highlight your key strengths relevant to your career goals or the job market.</span>
                      <span className="text-lite">(What are your top skills or strengths, and how have you applied them?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Achievements: </span>
                      <span className="video-sub-header-text">Mention a noteworthy accomplishment that demonstrates your abilities.</span>
                      <span className="text-lite">(Can you briefly share one significant achievement or project you’re proud of?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Career Aspirations: </span>
                      <span className="video-sub-header-text">Show ambition and direction regarding their future career path.</span>
                      <span className="text-lite">(What are your future career goals or aspirations?)</span>
                    </div>
                    <div className="pt-3">
                      <FontAwesomeIcon className="video-icon-text mr" icon={faPlay} />
                      <span className="video-header-text">Why You? </span>
                      <span className="video-sub-header-text">Give a quick value proposition that sets you apart from others.</span>
                      <span className="text-lite">(Why should an employer or recruiter choose you?)</span>
                    </div>
                    <div className="align-center my-3">
                      <Button className="campus-button-all" onClick={handleShowVideo}>
                        <FontAwesomeIcon className="mr" icon={faVideoCamera} />
                        CREATE VIDEO PROFILE
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="align-end">
                      <Button className="button-icon" onClick={handleShowVideo}>
                        <FontAwesomeIcon className="mr" icon={faArrowLeft} />
                        Back
                      </Button>
                    </div>
                    <Row>
                      <Col xs="12" lg="8">
                        <ResumeVideo
                          fileNameId={`${cmpsCd}`}
                          awsFolder={`campus-video-prof/${cmpsCd}/${userId}`}
                          awsBucket={AWS_CXUSER_BUCKET}
                          setUploadFileName={setUploadFileName}
                          setErrorShowAlert={setErrorShowAlert}
                          setAlertMsg={setAlertMsg}
                        />
                      </Col>
                      <Col xs="12" lg="4">
                        Texutals here
                      </Col>
                    </Row>
                  </div>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};

export default VideoProfileComponent;
