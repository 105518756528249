import { ListNewsletterResponse, ListNewsletterResults } from './list-newsletter.service.types';

export const ListNewsletterTransform: (response: ListNewsletterResponse) => ListNewsletterResults = (listNewsletterResults) => {
  const { data, is_error, message } = listNewsletterResults;
  const result: ListNewsletterResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        MediaTp: '',
        MediaId: '',
      },
      mediaData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
