import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Input, Button, Spinner,
} from 'reactstrap';
import GetAllComments from './get-all-comments';
import { CommentListProps } from './comments.type';
import { CommentInput } from '../../../../../../services/campus/dashboard/comments/add-comment/comment.types';
import { addCommentReq, clearCommentReq } from '../../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../../utils/service/localstorage-service';

const CommentsComponent: React.FC<CommentListProps> = ({
  listComment, activityId, academyId, setCallCommentBack, userAdmin,
}) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [loaderSpinner, setLoaderSpinner] = useState(false);
  const commentResponse = useSelector((state: RootState) => state.campusXcel.addCommentData);
  const [commentData, setCommentData] = useState<CommentInput>({
    comment: '',
  });
  // const [listUpdatedComment, setListUpdatedComment] = useState<CommentData>({
  //   data: [],
  // });
  // console.log('Parent', activityId, listComment, alertMsg, showErrorsAlert, showSuccessAlert);
  const handleCommentData = (e:any) => {
    setCommentData({ ...commentData, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (showSuccessAlert) {
      setCallCommentBack(activityId);
    }
  }, [listComment.data.length, showSuccessAlert]);

  const handleComment = (postId: string) => {
    setSuccessShowAlert(false);
    setLoaderSpinner(true);
    dispatch(addCommentReq({
      inputBody: commentData,
      requestType: APIHeader.REQ_ADD_COMMENTS,
      uTp: UserType.CMPS,
      token: tokenData,
      actId: postId,
      cmntId: '',
      acdId: academyId,
    }));
  };

  React.useEffect(() => {
    if (commentResponse.error && commentResponse.message !== '') {
      // setLoaderPost(false);
      setErrorShowAlert(true);
      setLoaderSpinner(false);
    }
  }, [commentResponse.error, commentResponse.message]);

  React.useEffect(() => {
    if (!commentResponse.error && commentResponse.message !== '') {
      dispatch(clearCommentReq());
      setCommentData({ ...commentData, comment: '' });
      setSuccessShowAlert(true);
      setLoaderSpinner(false);
    }
  }, [commentResponse.error, commentResponse.message]);

  return (
    <div className="mx-3 comment-list-scroll">
      <Row className="pt-3 my-3 dashboard-comment-bg">
        <Col lg="12" xs="12">
          <Input
            placeholder="Enter Comment..."
            type="textarea"
            name="comment"
            value={commentData.comment}
            className="campus-text-area mx-2"
            onChange={handleCommentData}
          />
          {showErrorsAlert ? <span className="wrong-pass-key mx-2">Failed Try Again !!!</span> : null}
          {showSuccessAlert ? <span className="dashboard-post-text-grey mx-2">Comment Added Successfully !!!</span> : null}
        </Col>
        <Col lg="12" xs="12">
          <div className="align-end pt-2">
            <Button className="campus-button-all align-center pt-1" onClick={() => handleComment(activityId)} disabled={commentData.comment === ''}>
              Submit
            </Button>
          </div>
        </Col>
      </Row>
      <div className="align-center">
        {loaderSpinner ? <Spinner className="alert-error" /> : null}
      </div>
      {listComment.data.map((comment, index) => (
        <div key={comment.cmntId + index.toString()} className="pt-1">
          <GetAllComments userAdmin={userAdmin} comment={comment} activityId={activityId} setCallCommentBack={setCallCommentBack} academyId={academyId} />
        </div>
      ))}
    </div>
  );
};

export default CommentsComponent;
