import React from 'react';
import {
  Button,
  Col, Input, Label,
  Row,
} from 'reactstrap';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EducationInput, EduDetails } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';
import { errorAlert } from '../../../../../utils/alert';

export type ResumeInfoProps = {
  setResumeEducation: (resumeEducation:EducationInput)=> void;
  resumeEducation:EducationInput;
};

export const XcelianEducationInfo: React.FC<ResumeInfoProps> = ({
  setResumeEducation,
  resumeEducation,
}) => {
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');

  const handleEduChange = (index: number, field: keyof EduDetails, value: any) => {
    const updatedEduData = [...resumeEducation.eduData];
    updatedEduData[index] = { ...updatedEduData[index], [field]: value };
    setResumeEducation({ ...resumeEducation, eduData: updatedEduData });
  };

  const addEduDetails = () => {
    const lastEdu = resumeEducation.eduData[resumeEducation.eduData.length - 1];

    // Check for empty fields in the last entry
    if (lastEdu && (lastEdu.cmpsNme === '' || lastEdu.cmpsLoc === '' || lastEdu.sDte === '' || lastEdu.eDte === '' || lastEdu.spec === '')) {
      setAlertMsg('Some fields are empty. Kindly fill them before adding the next education entry.');
      setErrorShowAlert(true);
      return;
    }

    setResumeEducation({
      ...resumeEducation,
      eduData: [
        ...resumeEducation.eduData,
        {
          cmpsNme: '',
          cmpsLoc: '',
          sDte: '',
          eDte: '',
          spec: '',
        },
      ],
    });
  };

  const removeEduDetails = (index: number) => {
    const updatedEduData = resumeEducation.eduData.filter((_, i) => i !== index);
    setResumeEducation({ ...resumeEducation, eduData: updatedEduData });
  };

  return (
    <div>
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {resumeEducation.eduData.map((edu, index) => (
        <div key={`${index.toString()}`}>
          <div className="align-end">
            <Button className="button-icon" onClick={() => removeEduDetails(index)}>
              <FontAwesomeIcon className="xcelian-resume-project-icon" icon={faMinusSquare} />
            </Button>
          </div>
          <Row>
            <Col xs="12">
              <Label for={`cmpsNme-${index}`} className="text-lite ml">College Name</Label>
              <Input
                type="text"
                id={`cmpsNme-${index}`}
                name="cmpsNme"
                value={edu.cmpsNme}
                onChange={(e) => handleEduChange(index, 'cmpsNme', e.target.value)}
                className="campus-input"
              />
            </Col>
            <Col xs="12 pt-3">
              <Label for={`cmpsLoc-${index}`} className="text-lite ml">Location</Label>
              <Input
                type="text"
                id={`cmpsLoc-${index}`}
                name="cmpsLoc"
                value={edu.cmpsLoc}
                onChange={(e) => handleEduChange(index, 'cmpsLoc', e.target.value)}
                className="campus-input"
              />
            </Col>
            <Col xs="12 pt-3" lg="6 pt-3">
              <Label for={`sDte-${index}`} className="text-lite ml">Start Date</Label>
              <Input
                type="date"
                id={`sDte-${index}`}
                name="sDte"
                value={edu.sDte}
                onChange={(e) => handleEduChange(index, 'sDte', e.target.value)}
                className="campus-input"
              />
            </Col>
            <Col xs="12 pt-3" lg="6 pt-3">
              <Label for={`eDte-${index}`} className="text-lite ml">End Date</Label>
              <Input
                type="date"
                id={`eDte-${index}`}
                name="eDte"
                value={edu.eDte}
                onChange={(e) => handleEduChange(index, 'eDte', e.target.value)}
                className="campus-input"
              />
            </Col>
            <Col xs="12 pt-3">
              <Label for={`spec-${index}`} className="text-lite ml">Specialization</Label>
              <Input
                type="text"
                id={`spec-${index}`}
                name="spec"
                value={edu.spec}
                onChange={(e) => handleEduChange(index, 'spec', e.target.value)}
                className="campus-input"
              />
            </Col>
          </Row>
          <hr className="hr-lite my-4" />
        </div>
      ))}
      <div className="align-center">
        <Button className="campus-button-all" onClick={addEduDetails}>Add Education</Button>
      </div>
    </div>
  );
};

export default XcelianEducationInfo;
