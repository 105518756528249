import React from 'react';
import Navbar from '../navbar/cx.navbar';
import IntroCampusXcel from './introducing-cx';
import { AllInOneCampus } from './all-in-one';
import { AboutCampusXcel } from './about-us';

export const PublicCXHomeComponent: React.FC = () => (
  <div className="campusXcel-bg-component">
    <Navbar />
    <IntroCampusXcel />
    <AllInOneCampus />
    <AboutCampusXcel />
    {/* <WhyCampusXcel />
      <XcelianCampus />
      <CampusXcelData />
       */}
  </div>
);

export default PublicCXHomeComponent;
