import React from 'react';
import {
  Button,
  CardImg,
  Col, Row, TabContent, TabPane,
} from 'reactstrap';
import '../resume.scss';
import Accordion from '../../../../../../utils/Accordion/Accordion';
import { RootResumeProps } from '../resume.type';
import { Objective } from './objective';
import ComingSoon from '../../../../../../scenes/unauthorized/coming-soon.component';
import { Hobbies } from './hobbies';
import { Certifications } from './certifications/cert.component';
import { VideoProfileComponent } from './video.resume';

export const ActivityComponent: React.FC<RootResumeProps> = ({ rootResumeTp }) => {
  const [activeTab, setActiveTab] = React.useState('obj');
  return (
    <Row className="resume-margin-top">
      <Col lg="3" xs="12">
        <div className="mx-2">
          <Accordion
            title={(
              <span className="input-card-header-sub-text">All Resume Activity</span>
          )}
            defaultOpen
            targetId="myCampusResume"
          >
            <div className="pt-1">
              <Button className="campus-resume-button" active={activeTab === 'obj'} onClick={() => setActiveTab('obj')}>
                <CardImg src="/images/icons/objective.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Objective
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-resume-button" active={activeTab === 'work-exp'} onClick={() => setActiveTab('work-exp')}>
                <CardImg src="/images/icons/work-exp.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Work Exposure
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-resume-button" active={activeTab === 'video'} onClick={() => setActiveTab('video')}>
                <CardImg src="/images/icons/record.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Video Profile
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-resume-button" active={activeTab === 'certificate'} onClick={() => setActiveTab('certificate')}>
                <CardImg src="/images/icons/digital-certificate.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                My Certifications
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-resume-button" active={activeTab === 'hobbies'} onClick={() => setActiveTab('hobbies')}>
                <CardImg src="/images/icons/hobbies.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Intrests
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-resume-button" active={activeTab === 'marksheet'} onClick={() => setActiveTab('marksheet')}>
                <CardImg src="/images/icons/marksheet.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Marksheet
              </Button>
            </div>
          </Accordion>
        </div>
      </Col>
      <Col lg="9" xs="12">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="obj">
            <div className="allign-start ml">
              <CardImg className="cx-resume-img-card" src="/images/icons/objective.png" alt="resume" /><span className="cx-resume-header-text">Objective</span>
            </div>
            <Objective resumeTp={activeTab} rootResumeTp={rootResumeTp} />
          </TabPane>
          <TabPane tabId="work-exp">
            <div className="allign-start ml">
              <CardImg className="cx-resume-img-card" src="/images/icons/work-exp.png" alt="work-exp" /><span className="cx-resume-header-text">My Experience</span>
            </div>
          </TabPane>
          <TabPane tabId="video">
            <div className="allign-start ml">
              <CardImg className="cx-resume-img-card" src="/images/icons/record.png" alt="video" /><span className="cx-resume-header-text">Video Profile</span>
            </div>
            <VideoProfileComponent resumeTp={activeTab} rootResumeTp={rootResumeTp} />
          </TabPane>
          <TabPane tabId="certificate">
            <div className="allign-start ml">
              <CardImg className="cx-resume-img-card" src="/images/icons/digital-certificate.png" alt="certificate" /><span className="cx-resume-header-text">My Certifications</span>
            </div>
            <Certifications resumeTp={activeTab} rootResumeTp={rootResumeTp} />
          </TabPane>
          <TabPane tabId="hobbies">
            <div className="allign-start ml">
              <CardImg className="cx-resume-img-card" src="/images/icons/hobbies.png" alt="hobbies" /><span className="cx-resume-header-text">Hobbies</span>
            </div>
            <Hobbies resumeTp={activeTab} rootResumeTp={rootResumeTp} />
          </TabPane>
          <TabPane tabId="marksheet">
            <div className="allign-start ml">
              <CardImg className="cx-resume-img-card" src="/images/icons/marksheet.png" alt="marksheet" /><span className="cx-resume-header-text">Marksheet</span>
            </div>
            <ComingSoon />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default ActivityComponent;
