import React from 'react';
import {
  Button,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LangDetails, LangInput } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';
import { errorAlert } from '../../../../../utils/alert';
import { LangProficiencyType } from '../../../../../utils/constants';

export type ResumeInfoProps = {
  setResumeLang: (updater: (prevState: LangInput) => LangInput) => void;
  resumeLang: LangInput;
};

export const XcelianLanguageInfo: React.FC<ResumeInfoProps> = ({
  setResumeLang,
  resumeLang,
}) => {
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');

  const handleInputChange = (index: number, field: keyof LangDetails, value: string) => {
    const updatedLangData: LangDetails[] = [...resumeLang.langData];
    if (index >= 0 && index < updatedLangData.length) {
      updatedLangData[index] = { ...updatedLangData[index], [field]: value };
    }
    setResumeLang((prevState) => ({
      ...prevState,
      langData: updatedLangData,
    }));
  };

  const addLangDetails = () => {
    const lastLang = resumeLang.langData[resumeLang.langData.length - 1];
    if (lastLang && (lastLang.langNme === '' || lastLang.level === '')) {
      setAlertMsg('Some fields are empty. Kindly fill them before adding the next language entry.');
      setErrorShowAlert(true);
      return;
    }
    const newLanguage: LangDetails = {
      langNme: '',
      level: '',
    };

    setResumeLang((prevState) => ({
      ...prevState,
      langData: [...prevState.langData, newLanguage],
    }));
  };

  const removeLangDetails = (index: number) => {
    const updatedLangData = resumeLang.langData.filter((_, i) => i !== index);
    setResumeLang((prevState) => ({
      ...prevState,
      langData: updatedLangData,
    }));
  };

  const langProfTpoptions = LangProficiencyType.map((prof) => ({
    label: prof.label,
    value: prof.value,
  }));

  const handleSelectCourseTpData = (selectedOption: any, index: number) => {
    setResumeLang((prevState) => {
      const newLangData = [...prevState.langData];
      newLangData[index].level = selectedOption ? selectedOption.value : '';
      return { ...prevState, langData: newLangData };
    });
  };

  return (
    <div>
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {resumeLang.langData.map((lang, index) => {
        // Define selectedLangProfTpoptions for each language entry
        const selectedLangProfTpoptions = langProfTpoptions.find(
          (option) => option.value === lang.level,
        );

        return (
          <div key={`${index.toString()}`}>
            <Row className="lang-border align-center mb-2">
              <div className="align-end">
                <Button className="button-icon padding-zero" onClick={() => removeLangDetails(index)}>
                  <FontAwesomeIcon className="xcelian-resume-project-icon" icon={faMinusSquare} />
                </Button>
              </div>
              <Col xs="12" lg="11">
                <Label for={`langNme-${index}`} className="text-lite ml">Language</Label>
                <Input
                  type="text"
                  id={`langNme-${index}`}
                  value={lang.langNme}
                  onChange={(e) => handleInputChange(index, 'langNme', e.target.value)}
                  className="campus-input"
                />
              </Col>
              <Col xs="12 pt-2" lg="11">
                <Label for={`level-${index}`} className="text-lite ml">Level</Label>
                <Select
                  value={selectedLangProfTpoptions || null}
                  options={langProfTpoptions}
                  isClearable
                  onChange={(selectedOption) => handleSelectCourseTpData(selectedOption, index)}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      position: 'relative',
                    }),
                    control: (base) => ({
                      ...base,
                      '&:hover': { borderColor: '#F6B26B' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '10px',
                      padding: 1,
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      textAlign: 'left',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  noOptionsMessage={() => 'No Level Found'}
                  placeholder="Enter Level"
                />
              </Col>
              <div className="mb-3" />
            </Row>
          </div>
        );
      })}
      <div className="align-center pt-4">
        <Button className="campus-button-all" onClick={addLangDetails}>Add New Language</Button>
      </div>
    </div>
  );
};

export default XcelianLanguageInfo;
