import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import { faMagnifyingGlass, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  clearEditUserStatusReq,
  editUserStatusReq,
  getClientUserListReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import {
  APIHeader, UserType,
} from '../../../../../utils/constants';
import { AllClientUserResult } from '../../../../../services/sadmin/list-client-user/list-client-user.types';
import XcelianTable from './XcelianTable/xcelian.table';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { UserStatusInput } from '../../../../../services/sadmin/update-user-status/update.user.status.types';
import Modal from '../../../../../utils/Modal/Modal';

export type TabProps = {
    tabTp: string;
    clientTp: string;
};

export const XcelianDashComponent: React.FC<TabProps> = ({ tabTp, clientTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const clientListUserResponse = useSelector((state: RootState) => state.campusXcel.getClientUserList);
  const updateUserStatusResponse = useSelector((state: RootState) => state.campusXcel.updateUserStatus);
  const [, setAllCientUserData] = React.useState<AllClientUserResult>({
    tCnt: 0,
    fCnt: 0,
    usrData: [],
    lastEvalKey: {
      UserTp: '',
      UserCd: '',
    },
  });
  const [editUserRecord, setEditUserRecord] = React.useState<UserStatusInput>({
    UserTp: '',
    UserCd: '',
    usrSts: '',
  });
  const [showUserAlertAPI, setShowUserAlertAPI] = useState('');
  const [showUserName, setShowUserName] = useState('');
  const [showUserStatus, setShowUserStatus] = useState('');
  const columns = [
    { header: 'User ID', accessor: 'UserCd' },
    { header: 'Name', accessor: '' },
    { header: 'Phone', accessor: '' },
    { header: 'Updated On', accessor: '' },
    { header: 'Email', accessor: 'eId' },
    { header: 'Login Status', accessor: '' },
    { header: 'Email', accessor: 'eId' },
    { header: 'Action', accessor: '' },
  ];
  React.useEffect(() => {
    if (tabTp === 'listXCLN' && clientTp === 'XCLN') {
      setLoadingMore(true);
      dispatch(getClientUserListReq({
        requestType: APIHeader.REQ_GET_CLIENT_USER,
        uTp: UserType.CXADM,
        limit: recordsPerPage, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  }, [tabTp, clientTp]);

  const fetchMoreData = () => {
    // setLoaderClient(true);
    setLoadingMore(true);
    if (isSearchQuery !== '') {
      dispatch(getClientUserListReq({
        requestType: APIHeader.REQ_GET_CLIENT_USER,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    } else {
      setLoadingMore(true);
      dispatch(
        getClientUserListReq({
          requestType: APIHeader.REQ_GET_CLIENT_USER,
          uTp: UserType.CXADM,
          limit: recordsPerPage, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
          clntTp: clientTp,
          isMeta: false,
          clntSts: 'ACTIVE',
        }),
      );
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    dispatch(getClientUserListReq({
      requestType: APIHeader.REQ_GET_CLIENT_USER,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      clntTp: clientTp,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    setIsSearchQuery(searchText);
    dispatch(getClientUserListReq({
      requestType: APIHeader.REQ_GET_CLIENT_USER,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      clntTp: clientTp,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  React.useEffect(() => {
    if (clientListUserResponse.isError && clientListUserResponse.message !== '') {
      // setLoaderClient(false);
      setLoadingMore(false);
      setAlertMsg(clientListUserResponse.message);
      setErrorShowAlert(true);
    }
  }, [clientListUserResponse.isError, clientListUserResponse.message]);

  React.useEffect(() => {
    if (!clientListUserResponse.isError && clientListUserResponse.message === 'executed') {
      // setLoaderClient(false);
      setLoadingMore(false);

      if (searchText !== '') {
        setPagedData({ [currentPage]: clientListUserResponse.data.usrData });
      } else if (
        clientListUserResponse.data
        && Object.keys(clientListUserResponse.data).length > 0
        && Array.isArray(clientListUserResponse.data.usrData)
      ) {
        const newPageData = clientListUserResponse.data.usrData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllCientUserData({
          tCnt: clientListUserResponse.data.tCnt,
          fCnt: clientListUserResponse.data.fCnt,
          usrData: clientListUserResponse.data.usrData,
          lastEvalKey: clientListUserResponse.data.lastEvalKey,
        });
        if (clientListUserResponse.data.lastEvalKey !== null) {
          setEvalKeyText(clientListUserResponse.data.lastEvalKey.UserCd);
          setEvalTpText(clientListUserResponse.data.lastEvalKey.UserTp);
        }
        setTotalRecords(clientListUserResponse.data.tCnt);
      }
    }
  }, [clientListUserResponse]);

  const handleEditData = (userType: string, userCode: string, crntSts: string, uName: string) => {
    if (crntSts === 'APPR') {
      setShowUserStatus('Approve');
      setShowUserAlertAPI(userCode);
      setShowUserName(uName);
      setEditUserRecord({
        ...editUserRecord, UserTp: userType, UserCd: userCode, usrSts: crntSts,
      });
    } else if (crntSts === 'BLCK') {
      setShowUserStatus('Block');
      setShowUserAlertAPI(userCode);
      setShowUserName(uName);
      setEditUserRecord({
        ...editUserRecord, UserTp: userType, UserCd: userCode, usrSts: crntSts,
      });
    } else {
      setShowUserStatus('');
      setShowUserAlertAPI('');
      setShowUserName('');
      setEditUserRecord({
        ...editUserRecord, UserTp: '', UserCd: '', usrSts: '',
      });
    }
  };

  const handleUpdateUserStatusAPI = (status: string) => {
    if (status !== '') {
      setLoadingMore(true);
      dispatch(editUserStatusReq({
        inputBody: editUserRecord,
        requestType: APIHeader.REQ_UPDATE_USER_STATUS,
        uTp: UserType.CXADM,
        token: tokenData,
      }));
    } else {
      setShowUserStatus('');
      setShowUserAlertAPI('');
      setShowUserName('');
      setEditUserRecord({
        ...editUserRecord, UserTp: '', UserCd: '', usrSts: '',
      });
    }
  };

  React.useEffect(() => {
    if (updateUserStatusResponse.error && updateUserStatusResponse.message !== '' && tabTp === 'listXCLN' && clientTp === 'XCLN') {
      setLoadingMore(false);
      setShowUserStatus('');
      setShowUserAlertAPI('');
      setShowUserName('');
      setEditUserRecord({
        ...editUserRecord, UserTp: '', UserCd: '', usrSts: '',
      });
      dispatch(clearEditUserStatusReq());
      setErrorShowAlert(true);
      setAlertMsg(updateUserStatusResponse.message);
    }
  }, [updateUserStatusResponse, tabTp, clientTp]);

  React.useEffect(() => {
    if (!updateUserStatusResponse.error && updateUserStatusResponse.message === 'executed' && tabTp === 'listXCLN' && clientTp === 'XCLN') {
      setLoadingMore(false);
      setShowUserStatus('');
      setShowUserAlertAPI('');
      setShowUserName('');
      dispatch(clearEditUserStatusReq());
      setEditUserRecord({
        ...editUserRecord, UserTp: '', UserCd: '', usrSts: '',
      });
      dispatch(getClientUserListReq({
        requestType: APIHeader.REQ_GET_CLIENT_USER,
        uTp: UserType.CXADM,
        limit: recordsPerPage, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  }, [updateUserStatusResponse, tabTp, clientTp]);

  return (
    <div>
      {/* <div className="mb-3 align-center">
        List of {clientTp === 'XCLN' ? 'Campus' : null}
      </div> */}
      <Modal
        show={!!(showUserAlertAPI !== '' && editUserRecord.UserCd !== '' && (showUserAlertAPI === editUserRecord.UserCd))}
        onClose={() => (false)}
        disCancel
        targetId="blockUser"
        title=""
        size="SM"
      >
        <div className="my-2 align-center mx-4">
          <span>Do you really want to {showUserStatus} ?</span>
        </div>
        <div className="my-2 align-center mx-4">
          <span className="text-lite">{showUserAlertAPI}</span>
        </div>
        <div className="align-center mx-4">
          <span className="text-lite-sub">{showUserName}</span>
        </div>
        <div className="align-center my-3 pt-3">
          <Button className="button-icon" onClick={() => handleUpdateUserStatusAPI(showUserStatus)}>
            {showUserStatus}
          </Button>
          <Button className="campus-button-all" onClick={() => handleUpdateUserStatusAPI('')}>
            CANCEL
          </Button>
        </div>
      </Modal>
      <Row className="align-start">
        {/* { loaderClient ? <LoaderData /> : null} */}
        {showSuccessAlert ? (
          successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
        ) : null}
        {showErrorsAlert ? (
          errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
        ) : null}
        <Col lg="12" xs="12">
          {loadingMore ? (
            <div className="loader-container-none align-center pt-5">
              <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
              <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
            </div>
          ) : (
            <Row className="align-start mx-1">
              <Col xs="12" lg="5">
                <div className="align-end">
                  <div className="input-wrapper">
                    <Input
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                    <Button type="button" onClick={handleSearchProjects}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  </div>
                  <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </div>
              </Col>
              <Col xs="12">
                <div className="pt-1">
                  <XcelianTable
                    tableId="xcelianUser"
                    columns={columns}
                    data={pagedData[currentPage]}
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                    totalRecords={totalRecords}
                    onPageChange={handlePageChange}
                    handleEditData={handleEditData}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default XcelianDashComponent;
