import { JobDataPutResponse, JobDataPutResults } from './update.job.data.service.types';

export const JobDataPutTransform: (response: JobDataPutResponse) => JobDataPutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :JobDataPutResults = {
    message: '',
    error: false,
    data: {
      JobCd: '',
      JobId: '',
      NxtStg: '',
    },
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
