import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
} from 'reactstrap';
import '../../../../recruiter.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdatePayPhase5TypeProps } from '../../../../recruiter.type';
import {
  editJobsReq,
} from '../../../../../../../../store/campusXcel/actions';
import {
  APIHeader, UserType,
} from '../../../../../../../../utils/constants';

export const UpdateJobPhase5: React.FC<UpdatePayPhase5TypeProps> = ({
  // jobPhase,
  setLoadingJob,
  setErrorShowAlert,
  setAlertMsg,
  tokenData,
  setAddJobRes,
  addJobRes,
  updatePh5Pref,
  setUpdatePh5Pref,
  apiReqTp,
  setApiReqTp,
}) => {
  const dispatch = useDispatch();
  const updateJobPhase5Response = useSelector((state: RootState) => state.campusXcel.updateJob);
  const [initJobKeyword, setInitJobKeyword] = React.useState('');
  // const handleBackToPhase2 = () => {
  //   setApiReqTp('EDIT-BACK-REQ-TO-PH4');
  // };

  // Handle Job Keyword

  const handleKeyPressJobKeyword = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' && initJobKeyword.trim()) {
      e.preventDefault();
      const newJobKeyword = initJobKeyword.split(',').map((oSpay) => oSpay.trim());
      setUpdatePh5Pref({
        ...updatePh5Pref,
        jobKey: [...updatePh5Pref.jobKey, ...newJobKeyword],
      });
      setInitJobKeyword('');
    }
  };

  const removeJobKeyword = (jobKeyToRemove: string) => {
    setUpdatePh5Pref({
      ...updatePh5Pref,
      jobKey: updatePh5Pref.jobKey.filter((jobKey) => jobKey !== jobKeyToRemove),
    });
  };

  const handleChangeUpdateJobKeyword = (e: any) => {
    setInitJobKeyword(e.target.value);
  };

  // Handle Job Keyword
  const handleChangePref = (event: any) => {
    setUpdatePh5Pref({ ...updatePh5Pref, [event.target.name]: event.target.value });
  };

  const handleUpdatePhase5Pay = (e: any) => {
    e.preventDefault();
    if (updatePh5Pref.JobCd !== '' || updatePh5Pref.JobId !== '') {
      if (apiReqTp === 'NEW-ADD-REQ-TO-PH5') {
        setLoadingJob(true);
        dispatch(editJobsReq({
          inputBody: updatePh5Pref,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PHASE5',
        }));
      }
      if (apiReqTp === 'EDIT-BACK-REQ-TO-PH5' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH5' || apiReqTp === 'PSTG5-req') {
        setLoadingJob(true);
        dispatch(editJobsReq({
          inputBody: updatePh5Pref,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PHASE5',
        }));
      }
    }
  };

  React.useEffect(() => {
    if (updateJobPhase5Response.error && updateJobPhase5Response.message !== '') {
      setLoadingJob(false);
      setAlertMsg(updateJobPhase5Response.message);
      setErrorShowAlert(true);
    }
  }, [updateJobPhase5Response.error, updateJobPhase5Response.message]);

  React.useEffect(() => {
    if (!updateJobPhase5Response.error && updateJobPhase5Response.data.JobCd !== '' && updateJobPhase5Response.data.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH5') {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase5Response.data.JobCd,
        JobId: updateJobPhase5Response.data.JobId,
        NxtStg: updateJobPhase5Response.data.NxtStg,
      });
      setApiReqTp('NEW-ADD-REQ-TO-PH6');
    }
  }, [updateJobPhase5Response.error, updateJobPhase5Response.data.JobCd, updateJobPhase5Response.data.JobId]);

  React.useEffect(() => {
    if (!updateJobPhase5Response.error && updateJobPhase5Response.data.JobCd !== '' && updateJobPhase5Response.data.JobId !== '' && (apiReqTp === 'EDIT-BACK-REQ-TO-PH5' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH5' || apiReqTp === 'PSTG5-req')) {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase5Response.data.JobCd,
        JobId: updateJobPhase5Response.data.JobId,
        NxtStg: updateJobPhase5Response.data.NxtStg,
      });
      setApiReqTp('EDIT-FORWARD-REQ-TO-PH6');
    }
  }, [updateJobPhase5Response.error, updateJobPhase5Response.data.JobCd, updateJobPhase5Response.data.JobId]);

  const handleCheckIsCV = (status: boolean) => {
    setUpdatePh5Pref({ ...updatePh5Pref, isCV: status });
  };

  const handleCheckIsAppTlne = (status: boolean) => {
    setUpdatePh5Pref({ ...updatePh5Pref, isAppTlne: status });
  };

  return (
    <div className="my-1">
      <Form className="job-add-card" onSubmit={handleUpdatePhase5Pay}>
        <div className="pt-2 mb-3 align-center">
          <span className="text-lite-grey-sub">Set Prefrences</span>
        </div>
        <Row className="align-center pt-5">
          <Col xs="10">
            <div className="cx-jobs-dashed-card mb-2">
              <div className="align-center">
                <span className="text-lite-grey">Application Prefrence</span>
              </div>
              <div className="align-start">
                <Row>
                  <Col xs="12" lg="11">
                    <div className="pt-2">
                      <span className="form-check-job-text align-start ml-1">Ask potential candidate for CV</span>
                      <Row className="pt-2 collapse-bg ml-1">
                        <Col lg="6" xs="6">
                          <div className="align-start">
                            <Input
                              type="radio"
                              checked={updatePh5Pref.isCV === true}
                              className="campus-radio-box cursor-pointer"
                              onChange={() => handleCheckIsCV(true)}
                            />
                            <Label check className="form-check-text ml">Yes, require a CV</Label>
                          </div>
                        </Col>
                        <Col lg="6" xs="6">
                          <div className="align-start">
                            <Input
                              type="radio"
                              checked={updatePh5Pref.isCV === false}
                              className="campus-radio-box cursor-pointer"
                              onChange={() => handleCheckIsCV(false)}
                            />
                            <Label check className="form-check-text ml">NO, don&apos;t ask for CV</Label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs="12" lg="11">
                    <div className="pt-4">
                      <span className="form-check-job-text align-start ml-1">Is there Application deadline ?</span>
                      <Row className="pt-2 collapse-bg ml-1">
                        <Col lg="3" xs="3">
                          <div className={updatePh5Pref.isAppTlne ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                            <Input
                              type="radio"
                              checked={updatePh5Pref.isAppTlne === true}
                              className="campus-radio-box cursor-pointer"
                              onChange={() => handleCheckIsAppTlne(true)}
                            />
                            <Label check className="form-check-text ml">Yes</Label>
                          </div>
                        </Col>
                        <Col lg="3" xs="3">
                          <div className={updatePh5Pref.isAppTlne ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                            <Input
                              type="radio"
                              checked={updatePh5Pref.isAppTlne === false}
                              className="campus-radio-box cursor-pointer"
                              onChange={() => handleCheckIsAppTlne(true)}
                            />
                            <Label check className="form-check-text ml">No</Label>
                          </div>
                        </Col>
                        {updatePh5Pref.isAppTlne
                          ? (
                            <Col lg="6" xs="6">
                              <div className="align-center my-1">
                                <Input
                                  type="date"
                                  name="appTlne"
                                  required={updatePh5Pref.isAppTlne}
                                  min={new Date().toISOString().split('T')[0]}
                                  value={updatePh5Pref.appTlne}
                                  onChange={handleChangePref}
                                  className="campus-input"
                                />
                              </div>
                            </Col>
                          ) : null}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs="10">
            <div className="cx-jobs-dashed-card mb-2">
              <div className="align-center pt-3">
                <span className="text-lite-grey">Communication Prefrence</span>
              </div>
              <Row className="align-start pt-4">
                <Col lg="6">
                  <div className="align-start pt-3">
                    <span className="form-check-job-text">Let applicant contact you directly for this job</span>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="align-center">
                    <Input
                      placeholder="Enter Mobile Number"
                      type="text"
                      name="jDCntPh"
                      value={updatePh5Pref.jDCntPh}
                      onChange={handleChangePref}
                      className="campus-input"
                    />
                  </div>
                  {/* <span className="mr-1 text-lite align-end">Mobile Number</span> */}
                  <div className="pt-3" />
                </Col>
                {/* <Col lg="1" /> */}
              </Row>
              <Row className="align-start pt-4">
                <Col lg="6">
                  <div className="align-start pt-3">
                    <span className="form-check-job-text align-start">Let potential Candidate contact you via Email</span>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="align-center">
                    <Input
                      placeholder="Enter Email Id"
                      type="text"
                      name="potCndEml"
                      value={updatePh5Pref.potCndEml}
                      onChange={handleChangePref}
                      className="campus-input"
                    />
                  </div>
                  {/* <span className="mr-1 text-lite align-end">Mobile Number</span> */}
                  <div className="pt-3" />
                </Col>
                {/* <Col lg="1" /> */}
              </Row>
              <Row className="align-start pt-4">
                <Col lg="6">
                  <div className="align-start pt-5">
                    <span className="form-check-job-text align-start">Communication Prefrence</span>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="pt-3">
                    <span className="ml-1 text-lite align-start">Send daily update to</span>
                    <Input
                      placeholder="Enter Email Id"
                      type="text"
                      name="dailUpdEml"
                      value={updatePh5Pref.dailUpdEml}
                      onChange={handleChangePref}
                      className="campus-input"
                    />
                  </div>
                  {/* <span className="mr-1 text-lite align-end">Mobile Number</span> */}
                  <div className="pt-3" />
                </Col>
                {/* <Col lg="1" /> */}
              </Row>
            </div>
          </Col>
          <Col xs="10">
            <div className="cx-jobs-dashed-card mb-2">
              <div className="align-center pt-4">
                <span className="text-lite-grey">Provide Keywords for Job Search</span>
              </div>
              <div className="pt-2 mx-1 margin-state-desk">
                <Input
                  placeholder="Enter Keywords"
                  type="text"
                  name="initJobKeyword"
                  value={initJobKeyword}
                  onChange={handleChangeUpdateJobKeyword}
                  onKeyDown={handleKeyPressJobKeyword}
                  className="jobs-campus-input"
                />
                <span className="text-lite ml align-start">Press Enter after typing to add keywords</span>

                <div className="mt-3 align-start badge-container">
                  {updatePh5Pref.jobKey.map((jobKey, index) => (
                    <Badge
                      key={`${index.toString()}`}
                      pill
                      className="chip-box mx-1 my-1"
                      onClick={() => removeJobKeyword(jobKey)}
                      style={{ cursor: 'pointer' }}
                    >
                      {jobKey} &times;
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col xs="10">
            <Row>
              <Col xs="6" lg="6">
                {/* <div className="align-start mx-2">
                  <Button className="button-transparent-jobs mr-2" onClick={handleBackToPhase2}>
                    <FontAwesomeIcon icon={faArrowLeft} className="mr" />
                    Back
                  </Button>
                </div> */}
              </Col>
              <Col xs="6" lg="6">
                <div className="align-end mx-2">
                  <Button className="button-transparent" disabled={initJobKeyword !== ''}>
                    Continue
                    <FontAwesomeIcon icon={faArrowRight} className="ml" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <div className="pt-4" />
    </div>
  );
};

export default UpdateJobPhase5;
