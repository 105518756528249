import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  faAngleLeft, faAngleRight, faFilter, faMagnifyingGlass, faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row, Col, Input, Button,
  Label,
  Card,
} from 'reactstrap';
import { APIHeader, ListCourseType, UserType } from '../../../../../utils/constants';
import { CandProps } from './candidates.type';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { AllCandidatesResult, SendMailData } from '../../../../../services/campus/college-admin/acad-students/list-candidates/list-candidates.types';
import { candStsReq, clearCandStsReq, getCandidatesListReq } from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import AllCandidatesList from './candidates.table';
import { CandPutStsDataInput } from '../../../../../services/campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.types';
import Modal from '../../../../../utils/Modal/Modal';
import Offcanvas from '../../../../../utils/OffCanvas/OffcanvasComponent';

export const ListCandidateData: React.FC<CandProps> = ({ statusTp, campusId }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(10);
  const [isClickClose, setIsClickClose] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const [modalBlockCand, setModalBlockCand] = React.useState(false);
  const [modalApproveCand, setModalApproveCand] = React.useState(false);
  const [modalMailAgainCand, setModalMailAgainCand] = React.useState(false);
  const [currentCoursePage, setCurrentCoursePage] = React.useState(0);
  const itemsBatchPerPage = 6; // Number of items per page
  const totalCoursePages = Math.ceil(ListCourseType.length / itemsBatchPerPage);
  const paginatedIndusItems = ListCourseType.slice(currentCoursePage * itemsBatchPerPage, (currentCoursePage + 1) * itemsBatchPerPage);
  const candListDataRes = useSelector((state: RootState) => state.campusXcel.getCandList);
  const candStsDataRes = useSelector((state: RootState) => state.campusXcel.updateCandStatus);
  const [blockUser, setDeleteRecord] = React.useState<CandPutStsDataInput>({
    UserTp: '',
    UserCd: '',
    cSts: '',
    bNme: '',
    lNme: '',
    fNme: '',
  });
  const [approveUser, setEditUserRecord] = React.useState<CandPutStsDataInput>({
    UserTp: '',
    UserCd: '',
    cSts: '',
    bNme: '',
    lNme: '',
    fNme: '',
  });
  const [allCandidateData, setAllCandidateData] = React.useState<AllCandidatesResult>({
    tCnt: 0,
    fCnt: 0,
    candListData: [],
    lastEvalKey: {
      UserTp: '',
      UserCd: '',
    },
  });
  const [otherObj, setOtherObj] = React.useState<SendMailData>({
    UserTp: '',
    UserCd: '',
    bNme: '',
    lNme: '',
    fNme: '',
  });

  React.useEffect(() => {
    if (statusTp !== '' && campusId !== '') {
      setLoadingMore(true);
      dispatch(getCandidatesListReq({
        requestType: APIHeader.REQ_ALL_CAMPUS_CAND,
        uTp: UserType.CMPS,
        limit: recordsPerPage,
        candSts: statusTp,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        cmpsCd: campusId,
      }));
    }
  }, [statusTp, campusId]);

  const fetchMoreData = () => {
    // setLoaderClient(true);
    setLoadingMore(true);
    if (isSearchQuery !== '') {
      dispatch(getCandidatesListReq({
        requestType: APIHeader.REQ_ALL_CAMPUS_CAND,
        uTp: UserType.CMPS,
        limit: recordsPerPage,
        candSts: statusTp,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        isSearch: 'YES',
        token: tokenData,
        cmpsCd: campusId,
      }));
    } else {
      setLoadingMore(true);
      dispatch(
        getCandidatesListReq({
          requestType: APIHeader.REQ_ALL_CAMPUS_CAND,
          uTp: UserType.CMPS,
          limit: recordsPerPage,
          candSts: statusTp,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
          cmpsCd: campusId,
        }),
      );
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    dispatch(getCandidatesListReq({
      requestType: APIHeader.REQ_ALL_CAMPUS_CAND,
      uTp: UserType.CMPS,
      limit: recordsPerPage,
      candSts: statusTp,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      cmpsCd: campusId,
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    setIsSearchQuery(searchText);
    dispatch(getCandidatesListReq({
      requestType: APIHeader.REQ_ALL_CAMPUS_CAND,
      uTp: UserType.CMPS,
      limit: recordsPerPage,
      candSts: statusTp,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      cmpsCd: campusId,
    }));
  };

  React.useEffect(() => {
    if (candListDataRes.isError && candListDataRes.message !== '') {
      setLoadingMore(false);
      setAlertMsg(candListDataRes.message);
      setErrorShowAlert(true);
    }
  }, [candListDataRes.isError, candListDataRes.message]);

  React.useEffect(() => {
    if (!candListDataRes.isError && candListDataRes.message === 'executed') {
      setLoadingMore(false);
      if (searchText !== '') {
        setPagedData({ [currentPage]: candListDataRes.data.candListData });
      } else if (
        candListDataRes.data
        && Object.keys(candListDataRes.data).length > 0
        && Array.isArray(candListDataRes.data.candListData)
      ) {
        const newPageData = candListDataRes.data.candListData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllCandidateData({
          tCnt: candListDataRes.data.tCnt,
          fCnt: candListDataRes.data.fCnt,
          candListData: candListDataRes.data.candListData,
          lastEvalKey: candListDataRes.data.lastEvalKey,
        });
        if (candListDataRes.data.lastEvalKey !== null) {
          setEvalKeyText(candListDataRes.data.lastEvalKey.UserCd);
          setEvalTpText(candListDataRes.data.lastEvalKey.UserTp);
        }
        setTotalRecords(candListDataRes.data.tCnt);
      }
    }
  }, [candListDataRes]);

  React.useEffect(() => {
    if (blockUser.UserTp !== '' && blockUser.UserCd !== '') {
      setModalBlockCand(true);
      setEditUserRecord({ ...approveUser, UserTp: '', UserCd: '' });
    }
  }, [blockUser.UserTp, blockUser.UserCd]);

  React.useEffect(() => {
    if (approveUser.UserTp !== '' && approveUser.UserCd !== '') {
      setModalApproveCand(true);
      setDeleteRecord({ ...blockUser, UserTp: '', UserCd: '' });
    }
  }, [approveUser.UserTp, approveUser.UserCd]);

  React.useEffect(() => {
    if (otherObj.UserTp !== '' && otherObj.UserCd !== '') {
      setModalMailAgainCand(true);
    }
  }, [otherObj.UserTp, otherObj.UserCd]);

  const handleRevokeCandidate = (status: string) => {
    if (status === 'revoke') {
      setLoadingMore(true);
      Promise.resolve(
        dispatch(candStsReq({
          requestType: APIHeader.REQ_UPDATE_CANDIDATE_STATUS,
          uTp: UserType.CMPS,
          token: tokenData,
          currentSts: 'NACC',
          candCode: blockUser.UserCd,
          candType: blockUser.UserTp,
        })),
      );
    } else if (status === 'cancel') {
      setModalBlockCand(false);
      setEditUserRecord({ ...approveUser, UserTp: '', UserCd: '' });
      setDeleteRecord({ ...blockUser, UserTp: '', UserCd: '' });
      setOtherObj({
        UserTp: '',
        UserCd: '',
        bNme: '',
        lNme: '',
        fNme: '',
      });
    }
    setModalBlockCand(false);
  };

  const handleApproveCandidate = (status: string) => {
    if (status === 'approve') {
      setLoadingMore(true);
      Promise.resolve(
        dispatch(candStsReq({
          requestType: APIHeader.REQ_UPDATE_CANDIDATE_STATUS,
          uTp: UserType.CMPS,
          token: tokenData,
          currentSts: 'APPR',
          candCode: approveUser.UserCd,
          candType: approveUser.UserTp,
        })),
      );
    } else if (status === 'cancel') {
      setModalApproveCand(false);
      setOtherObj({
        UserTp: '',
        UserCd: '',
        bNme: '',
        lNme: '',
        fNme: '',
      });
      setEditUserRecord({ ...approveUser, UserTp: '', UserCd: '' });
      setDeleteRecord({ ...blockUser, UserTp: '', UserCd: '' });
    }
    setModalApproveCand(false);
  };

  const handleSendMailAgain = (status: string) => {
    if (status === 'approve') {
      setLoadingMore(true);
      Promise.resolve(
        dispatch(candStsReq({
          requestType: APIHeader.REQ_UPDATE_CANDIDATE_STATUS,
          uTp: UserType.CMPS,
          token: tokenData,
          currentSts: 'SEND_AGAIN',
          candCode: otherObj.UserCd,
          candType: otherObj.UserTp,
        })),
      );
    } else if (status === 'cancel') {
      setModalMailAgainCand(false);
      setOtherObj({
        UserTp: '',
        UserCd: '',
        bNme: '',
        lNme: '',
        fNme: '',
      });
      setEditUserRecord({ ...approveUser, UserTp: '', UserCd: '' });
      setDeleteRecord({ ...blockUser, UserTp: '', UserCd: '' });
    }
    setModalMailAgainCand(false);
  };

  React.useEffect(() => {
    if (candStsDataRes.error && candStsDataRes.message !== '') {
      setLoadingMore(false);
      setAlertMsg(candStsDataRes.message);
      setErrorShowAlert(true);
      dispatch(clearCandStsReq());
    }
  }, [candStsDataRes.error, candStsDataRes.message]);

  React.useEffect(() => {
    if (!candStsDataRes.error && candStsDataRes.message !== '') {
      setLoadingMore(false);
      setAlertMsg(candStsDataRes.data);
      setSuccessShowAlert(true);
      dispatch(clearCandStsReq());
      dispatch(getCandidatesListReq({
        requestType: APIHeader.REQ_ALL_CAMPUS_CAND,
        uTp: UserType.CMPS,
        limit: recordsPerPage,
        candSts: statusTp,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        cmpsCd: campusId,
      }));
      setOtherObj({
        UserTp: '',
        UserCd: '',
        bNme: '',
        lNme: '',
        fNme: '',
      });
      setEditUserRecord({ ...approveUser, UserTp: '', UserCd: '' });
      setDeleteRecord({ ...blockUser, UserTp: '', UserCd: '' });
    }
  }, [candStsDataRes.error, candStsDataRes.message]);

  const toggleFilter = () => {
    setIsClickClose(!isClickClose);
  };

  const handleCourseNext = () => {
    if (currentCoursePage < totalCoursePages - 1) {
      setCurrentCoursePage(currentCoursePage + 1);
    }
  };

  const handleCoursePrevious = () => {
    if (currentCoursePage > 0) {
      setCurrentCoursePage(currentCoursePage - 1);
    }
  };

  return (
    <div>
      <Row className="align-start">
        {/* { loaderClient ? <LoaderData /> : null} */}
        {showSuccessAlert ? (
          successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
        ) : null}
        {showErrorsAlert ? (
          errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
        ) : null}
        <Col lg="12" xs="12">
          {loadingMore ? (
            <div className="loader-container-none align-center pt-5">
              <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
              <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
            </div>
          ) : (
            <Row className="align-start mx-1">
              <Col lg="3" xs="12">
                <div className="ml">
                  {allCandidateData.tCnt > 0
                    ? (
                      <div className="align-start">
                        <Button className="button-icon" onClick={toggleFilter}>
                          <FontAwesomeIcon icon={faFilter} className="mr" />
                          Filter
                        </Button>
                        <Offcanvas
                          isOpen={isClickClose}
                          toggle={() => setIsClickClose(!isClickClose)}
                        >
                          <div className="ml-1">
                            All Filter
                          </div>
                          <Card className="card-jobs-lite">
                            <div className="pt-1">
                              <div className="ml-1">
                                <span className="xcelian-filter-sub-header-text">Campus Stream</span>
                              </div>
                              <Row className="pt-3 ml">
                                {paginatedIndusItems.map((item, index) => (
                                  <Col xs="12" lg="12" className="checkbox-label ml" key={`${index.toString()}`}>
                                    <Input type="checkbox" id={`option${index}`} className="campus-check-box" />
                                    <Label className="jobs-type-text" htmlFor={`option${index}`}>{item.label}</Label>
                                  </Col>
                                ))}
                                <div className="pagination-buttons-filter-jobs align-end mr-1 my-1">
                                  <Button className="btn-filter-jobs" onClick={handleCoursePrevious} disabled={currentCoursePage === 0}>
                                    <FontAwesomeIcon className="text-subheader-white mx-2" icon={faAngleLeft} />
                                  </Button>
                                  <Button className="btn-filter-jobs ml" onClick={handleCourseNext} disabled={currentCoursePage === totalCoursePages - 1}>
                                    <FontAwesomeIcon className="text-subheader-white mx-2" icon={faAngleRight} />
                                  </Button>
                                </div>
                              </Row>
                            </div>
                          </Card>
                        </Offcanvas>
                      </div>
                    ) : null}
                </div>
              </Col>
              <Col xs="12" lg="6">
                {allCandidateData.tCnt > 0
                  ? (
                    <div className="align-end">
                      <div className="input-wrapper">
                        <Input
                          type="text"
                          placeholder="Search..."
                          value={searchText}
                          onChange={handleSearchChange}
                        />
                        <Button type="button" onClick={handleSearchProjects}>
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                      </div>
                      <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                        <FontAwesomeIcon icon={faRefresh} />
                      </Button>
                    </div>
                  ) : null }
              </Col>
              <Col xs="12">
                <div className="pt-1">
                  {statusTp !== '' && campusId !== '' && pagedData[currentPage]
                    ? (
                      <AllCandidatesList
                        pagedData={pagedData[currentPage]}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        recordsPerPage={recordsPerPage}
                        totalRecords={totalRecords}
                        setEditRecord={setEditUserRecord}
                        setDeleteRecord={setDeleteRecord}
                        statusTp={statusTp}
                        setOtherObj={setOtherObj}
                      />
                    ) : null}
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Modal
        show={modalBlockCand || false}
        onClose={() => (false)}
        disCancel
        targetId="approveCandData"
        title=""
        size="MD"
      >
        <div className="align-center pt-3">
          <span className="text-lite">Do you really want to revoke access?</span>
        </div>
        <div className="my-2 align-center">
          <span className="card-toggle-text">Batch Name : {blockUser.bNme}</span>
        </div>
        <div className="my-2 align-center">
          <span className="card-toggle-text">Candidate: {blockUser.fNme} {blockUser.lNme}</span>
        </div>
        <div className="align-center my-3 mx-2">
          <Button className="button-icon-know-more mx-1" onClick={() => handleRevokeCandidate('revoke')}>
            REVOKE
          </Button>
          <Button className="campus-button-all mx-1" onClick={() => handleRevokeCandidate('cancel')}>
            CANCEL
          </Button>
        </div>
      </Modal>

      <Modal
        show={modalApproveCand || false}
        onClose={() => (false)}
        disCancel
        targetId="blockCandData"
        title=""
        size="MD"
      >
        <div className="align-center pt-3">
          <span className="text-lite">Do you want to grant access?</span>
        </div>
        <div className="my-2 align-center">
          <span className="card-toggle-text">Batch Name : {approveUser.bNme}</span>
        </div>
        <div className="my-2 align-center">
          <span className="card-toggle-text">Candidate: {approveUser.fNme} {approveUser.lNme}</span>
        </div>
        <div className="align-center my-3 mx-2">
          <Button className="button-icon-know-more mx-1" onClick={() => handleApproveCandidate('approve')}>
            APPROVE
          </Button>
          <Button className="campus-button-all mx-1" onClick={() => handleApproveCandidate('cancel')}>
            CANCEL
          </Button>
        </div>
      </Modal>

      <Modal
        show={modalMailAgainCand || false}
        onClose={() => (false)}
        disCancel
        targetId="blockCandData"
        title=""
        size="MD"
      >
        <div className="align-center pt-3">
          <span className="text-lite">Do you want to send mail of status again?</span>
        </div>
        <div className="my-2 align-center">
          <span className="card-toggle-text">Batch Name : {otherObj.bNme}</span>
        </div>
        <div className="my-2 align-center">
          <span className="card-toggle-text">Candidate: {otherObj.fNme} {otherObj.lNme}</span>
        </div>
        <div className="align-center my-3 mx-2">
          <Button className="button-icon-know-more mx-1" onClick={() => handleSendMailAgain('approve')}>
            SEND
          </Button>
          <Button className="campus-button-all mx-1" onClick={() => handleSendMailAgain('cancel')}>
            CANCEL
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default ListCandidateData;
