import { getCommonApiToken } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { ListClientOptions, ListClientResponse } from './list-client.service.types';

const { CAMPUSXCEL_SADMIN_API_ROOT } = getConfig();

export const ListClientRequest = ({
  limit, evalKey, evalTp, searchBy, isSearch, isFilter, filterBy, clntTp, isMeta, uTp, requestType, token, clntSts,
}: ListClientOptions): Promise<ListClientResponse> => {
  if (isMeta) {
    return getCommonApiToken(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&clntTp=${clntTp}&clntSts=${clntSts}&isMeta=YES`, requestType, token);
  } return getCommonApiToken(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&clntTp=${clntTp}&clntSts=${clntSts}`, requestType, token);
};
