import React from 'react';
import { useNavigate } from 'react-router';
import {
  Button,
  CardImg,
  Col, Row,
} from 'reactstrap';
import './campus.scss';
import Offcanvas from '../../../utils/OffCanvas/OffcanvasComponent';
import { ContactForm } from '../contact-form/contact-form';

export const AboutCampus: React.FC = () => {
  const history = useNavigate();
  const [contactDetailsAbout, setContactDetailsAbout] = React.useState(false);
  const campusUrl = () => {
    history('/campus/register');
    window.scrollTo(0, 0);
  };
  const toggleContact = () => {
    setContactDetailsAbout(!contactDetailsAbout);
  };
  return (
    <div className="pt-5 why-campus-xcel">
      <div className="pt-5">
        <Row className="mx-1 pt-2 align-center">
          <Col lg="10">
            <div className="align-center">
              <span className="text-intro-header text-center-aligned display-6">
                Your Gateway to Academic Excellence and Career Success
              </span>
            </div>
          </Col>
          <Col xs="12" lg="7">
            <div className="align-center pt-4">
              <span className="pt-1 text-intro text-center-aligned">
                Our platform is designed to bridge the gap between academia and industry,
                providing students and colleges with the tools they need to excel in an increasingly
                digital world. From digital notice boards to personalized career guidance,
                CampusXcel is your comprehensive solution for enhancing the educational experience and paving the way for successful careers
              </span>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-4">
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/campus/college-brand.png" alt="jobs Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">College Branding</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Boost your college’s brand with our branding tools. Showcase your institution’s strengths, achievements, and unique offerings to attract prospective students and industry partnerships.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={toggleContact}>Contact Us</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/campus/digital-notice.png" alt="campus Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Digital Notice Board</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Keep your campus connected with our dynamic digital notice board. Share announcements, updates, and events in real-time, ensuring that students are always in the loop.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={toggleContact}>Contact Us</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/campus/industry-connect.jpg" alt="campus Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Industry Connections</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">CampusXcel connects students with industry leaders, offering unparalleled access to mentorship, networking opportunities, and real-world insights.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={campusUrl}>Register</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/campus/campus-internship.png" alt="campus Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Jobs and Internships</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">CampusXcel offers a curated list of job and internship opportunities tailored to students’ skills and interests. Find the perfect match for your career goals right from your dashboard.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={campusUrl}>Register</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/campus/campus-skill.jpg" alt="campus Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned mx-2">
                <h3 className="about-card-title">Skilling and Development</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">CampusXcel provides access to a variety of skilling programs designed to enhance students’ employability. Whether it’s technical skills or soft skills, our platform offers courses that prepare students for the demands of the modern workplace.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={campusUrl}>Register</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/campus/paper.jpg" alt="campus Image" />
          </Col>
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
            <div className="card-content">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Paper Publications</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Publish and showcase student research and papers on a global platform. CampusXcel makes it easy for students to share their work with the academic community.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={campusUrl}>Register</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-center my-5 pt-5">
          <Col lg={{ size: 4, order: 1 }} xs={{ order: 1 }}>
            <CardImg className="about-image-platform" src="/images/campus/job-fair.jpg" alt="campus Image" />
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
            <div className="card-content pt-3">
              <div className="text-center-aligned">
                <h3 className="about-card-title">Job Fairs</h3>
              </div>
              <div className="align-text-justify mx-3">
                <p className="card-description pt-3">Host or participate in virtual and on-campus job fairs. Connect students with potential employers and provide them with the opportunity to network and secure job offers.</p>
              </div>
            </div>
            <div className="card-actions align-center">
              <Button className="card-iphone-button" onClick={campusUrl}>Register</Button>
            </div>
          </Col>
        </Row>
        <Offcanvas
          isOpen={contactDetailsAbout}
          toggle={() => toggleContact()}
        >
          <ContactForm userFrom="CMPS" />
        </Offcanvas>
        <div className="mb-3 p-4" />
      </div>
    </div>
  );
};
export default AboutCampus;
