import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Card, Form, CardHeader, Input, Button,
  CardImg,
} from 'reactstrap';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTokenFromLocalStorage } from '../../../../../../utils/service/localstorage-service';
import {
  APIHeader, UserType,
} from '../../../../../../utils/constants';
import {
  clearObjective,
  clearResumeDataObj,
  getObjective, getResumeDataObj,
} from '../../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../../utils/alert';
import LoaderData from '../../../../../../utils/loader';
import { ObjectiveInput } from '../../../../../../services/campus/resume-data/objective/update-obj/objective.types';
import { ResumeTypeProps } from '../resume.type';

const maxLength = 290;
export const Objective: React.FC<ResumeTypeProps> = ({ rootResumeTp, resumeTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [loaderResume, setLoaderResume] = useState(false);
  const getObjectiveData = useSelector((state: RootState) => state.campusXcel.getObjectiveResume);
  const objectiveResponse = useSelector((state: RootState) => state.campusXcel.updateObjectiveResume);
  const [campusObjectiveData, setcampusObjectiveData] = useState<ObjectiveInput>({
    obj: '',
  });

  const handleCampusObjective = (e:any) => {
    setcampusObjectiveData({ ...campusObjectiveData, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (resumeTp === 'obj' && rootResumeTp === 'resume-act') {
      setLoaderResume(true);
      dispatch(getResumeDataObj({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'OBJ',
      }));
    }
  }, [resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (getObjectiveData.isError && getObjectiveData.message !== '' && resumeTp === 'obj' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(getObjectiveData.message);
      setErrorShowAlert(true);
    }
  }, [getObjectiveData.isError, getObjectiveData.message, resumeTp, rootResumeTp]);

  React.useEffect(() => {
    if (!getObjectiveData.isError && getObjectiveData.message === 'executed' && resumeTp === 'obj' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setcampusObjectiveData({ ...campusObjectiveData, obj: getObjectiveData.obj });
    }
  }, [getObjectiveData.isError, getObjectiveData.message, resumeTp, rootResumeTp]);

  const handleSubmitObjective = (e:any) => {
    e.preventDefault();
    setLoaderResume(true);
    dispatch(clearResumeDataObj());
    dispatch(getObjective({
      inputBody: campusObjectiveData,
      requestType: APIHeader.REQ_UPDATE_OBJECTIVE,
      uTp: UserType.CMPS,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (objectiveResponse.error && objectiveResponse.message !== '') {
      setLoaderResume(false);
      setAlertMsg(objectiveResponse.message);
      setErrorShowAlert(true);
    }
  }, [objectiveResponse.error, objectiveResponse.message]);

  React.useEffect(() => {
    if (!objectiveResponse.error && objectiveResponse.message !== '') {
      setAlertMsg(objectiveResponse.message);
      setSuccessShowAlert(true);
      dispatch(clearObjective());
      dispatch(getResumeDataObj({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_GET_CMPS_CANDIDATE_ID,
        requestData: 'OBJ',
      }));
      setLoaderResume(false);
    }
  }, [objectiveResponse.error, objectiveResponse.message]);

  const [isObjectiveTxt, setIsObjectiveTxt] = useState(false);
  const objectiveTxt = 'Including an objective statement in a resume is important as it communicates your career goals, aligns your intentions with potential employers, and demonstrates focus and direction. It enables you to tailor your application to specific job opportunities, differentiating yourself from other candidates. Moreover, an objective statement contributes to resume organization, personal branding, and customization, highlighting your unique strengths and interests. However, it is crucial to consider industry-specific norms and requirements. By incorporating an objective statement effectively, you can enhance your chances of standing out and capturing the attention of hiring managers in a competitive job market.';
  const handleObjectiveText = () => {
    setIsObjectiveTxt(!isObjectiveTxt);
  };

  return (
    <div className="pt-1 margin-bottom mx-2">
      { loaderResume ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="align-center">
        <Col lg={{ size: 12, order: 1 }} xs={{ order: 1 }} className="pt-3">
          <Form onSubmit={handleSubmitObjective}>
            <Card className="form-card-white">
              <div className="text-right-login-campus-register mx-1">
                <h6 className="text-lite">Objective</h6>
                <Input
                  placeholder="Enter Your Objective Here"
                  type="textarea"
                  required
                  name="obj"
                  value={campusObjectiveData.obj}
                  onChange={handleCampusObjective}
                  className="campus-text-area-desc"
                />
              </div>
              <div className="align-end pt-3">
                <Button className="campus-button-all mx-1">
                  Save
                </Button>
              </div>
            </Card>
          </Form>
        </Col>
        <Col lg={{ size: 12, order: 2 }} xs={{ order: 2 }}>
          <Row className="pt-5 align-center resume-margin-top">
            <Card className="form-card-white">
              <CardHeader className="card-header-border">
                <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
                <span>Do you Know ?</span>
              </CardHeader>
            </Card>
            <Col lg="8" xs="12">
              <div className="align-text-justify mx-1">
                <span className="dashboard-thread-text mx-1">
                  {isObjectiveTxt ? `${objectiveTxt}` : `${objectiveTxt.slice(0, maxLength)}`}
                  {isObjectiveTxt
                    ? (
                      <Button onClick={handleObjectiveText} className="mx-1 dashboard-button-less">
                        show less
                      </Button>
                    ) : (
                      <Button onClick={handleObjectiveText} className="mx-1 dashboard-button-more">
                        show more
                      </Button>
                    )}
                </span>
              </div>
            </Col>
            <Col lg="4" xs="12">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="mx-1"
                  src="/images/campus/objective.png"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};

export default Objective;
