import { ShareListInterviewResponse, ShareListInterviewResults } from './share.list.interview.service.types';

export const ShareListInterviewTransform: (response: ShareListInterviewResponse) => ShareListInterviewResults = (listPJobsResults) => {
  const { data, is_error, message } = listPJobsResults;
  const result: ShareListInterviewResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      recruitIntvData: [],
      lastEvalKey: {
        IntvCd: '',
        IntvId: '',
      },
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
