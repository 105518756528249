import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col, Row,
  UncontrolledTooltip,
} from 'reactstrap';
import './news.scss';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListNewsletterResultData } from '../../../../services/sadmin/newsletter/get-newsletter/list-newsletter.types';
import { formatUserDate } from '../../../../utils/constants';

  interface TableProps {
    clickedIndex: string;
    isMobileJob: boolean;
    data: ListNewsletterResultData[];
    handleGetNewsDetails: (UrlHtml: string, htmlTitleDetail: string, MediaType: string, MediaId: string) => void;
    handleUpdateNewsViewCount: (MediaType: string, MediaId: string) => void;
  }

const AllNewsletter: React.FC<TableProps> = ({
  data,
  isMobileJob,
  clickedIndex,
  handleGetNewsDetails,
  handleUpdateNewsViewCount,
}) => {
  // const history = useNavigate();
  const [errorAlert, setErrorAlert] = React.useState('');
  const [mediaAlert, setMediaAlert] = React.useState('');
  const copyToClipboard = (shareUrl: string, mediaType: string, mediaId: string) => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setMediaAlert(`DESK-${mediaId}`);
    });
    handleUpdateNewsViewCount(mediaType, mediaId);
  };
  const shareOnMobile = async (shareUrl: string, mediaType: string, mediaId: string) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'CampusXcel News Article',
          text: 'Check out this news article on CampusXcel!',
          url: shareUrl,
        });
        setMediaAlert(`MOB-${mediaId}`); // Sharing was successful
      } catch (error) {
        setMediaAlert(`MOB-${mediaId}`);
        setErrorAlert('Sharing canceled by user');
      }
    } else {
      setMediaAlert(`MOB-${mediaId}`);
      setErrorAlert('Sharing canceled by user');
    }
    handleUpdateNewsViewCount(mediaType, mediaId);
  };
  return (
    <div className="">
      {data.length > 0
        ? (
          <div>
            <Row className="align-start margin-jobs-xcln-card">
              {data.map((newsData, index) => (
                <Col lg="12 my-1" xs="12 my-1" key={`job-info${index.toString()}`}>
                  <Card className="cards-news-public">
                    <CardBody className="padding-zero">
                      <div className="new-layout-newsletter">
                        <Row className="align-items-center">
                          <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
                            <div className="align-center">
                              <CardImg
                                alt="..."
                                src={newsData.previewUrl}
                                className="public-newsletter-img"
                              />
                            </div>
                          </Col>
                          <Col lg={{ size: 8, order: 1 }} xs={{ order: 1 }}>
                            <div className="align-text-justify">
                              <Button disabled={clickedIndex === newsData.MediaId} onClick={() => handleGetNewsDetails(newsData.descHtmlUrl, newsData.subTtl, newsData.MediaTp, newsData.MediaId)} className="public-newsletter-button-header">
                                {newsData.subTtl}
                              </Button>
                            </div>
                            <div className="align-start">
                              <span className="text-lite">
                                {newsData.author.fNme} {newsData.author.lNme}
                              </span>
                              <span className="text-lite mx-2">
                                |
                              </span>
                              <span className="text-lite">
                                {formatUserDate(newsData.updtOn)}
                              </span>
                              <span className="text-lite mx-2">
                                |
                              </span>
                              <span className="text-lite">
                                views({newsData.mView ? newsData.mView : 0})
                              </span>
                              <span className="text-lite mx-2">
                                |
                              </span>
                              {isMobileJob ? (
                                <Button
                                  id={`mobile-share-${newsData.MediaId}`}
                                  className="button-icon padding-zero ml-1"
                                  onClick={() => shareOnMobile(
                                    `${window.location.origin}/news-article/${newsData.MediaTp}/${newsData.MediaId}`,
                                    newsData.MediaTp,
                                    newsData.MediaId,
                                  )}
                                >
                                  <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
                                </Button>
                              ) : (
                                <Button
                                  id={`desktop-share-${newsData.MediaId}`}
                                  className="button-icon padding-zero ml-1"
                                  onClick={() => copyToClipboard(
                                    `${window.location.origin}/news-article/${newsData.MediaTp}/${newsData.MediaId}`,
                                    newsData.MediaTp,
                                    newsData.MediaId,
                                  )}
                                >
                                  <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
                                </Button>
                              )}
                              {/* Tooltip for sharing actions */}
                              <UncontrolledTooltip
                                delay={0}
                                target={isMobileJob ? `mobile-share-${newsData.MediaId}` : `desktop-share-${newsData.MediaId}`}
                              >
                                {mediaAlert === `DESK-${newsData.MediaId}` ? 'Copied to Clipboard'
                                  : mediaAlert === `MOB-${newsData.MediaId}` && errorAlert === '' ? 'Share'
                                    : mediaAlert === `MOB-${newsData.MediaId}` && errorAlert !== '' ? errorAlert
                                      : 'Share'}
                              </UncontrolledTooltip>
                            </div>
                            <div className="align-text-justify pt-3">
                              <span className="dash-sadmin-news-description">
                                {newsData.subLine}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="table-norecords">
            <span className="table-text-no">No Newsletter Found</span>
          </div>
        )}
    </div>
  );
};

export default AllNewsletter;
