import React from 'react';
import {
  Button,
  CardImg,
  Col, Row, TabContent, TabPane,
} from 'reactstrap';
import '../onboard.scss';
import Accordion from '../../../../../utils/Accordion/Accordion';
import AddClient from '../clients/add.client.component';
import { OrderProps } from '../onboard.type';
import { ListClient } from '../clients/list.client.component';
import AddClientUser from '../clients/add.client-user.component';
import ListClientUser from '../clients/list.client-user.component';

export const CampusComponent: React.FC<OrderProps> = ({ ordTp }) => {
  const [activeTab, setActiveTab] = React.useState('listCampus');

  return (
    <Row className="onboard-margin-top">
      <Col lg="3" xs="12">
        <div className="mx-2">
          <Accordion
            title={(
              <span className="input-card-header-sub-text">Campus Action Tabs</span>
          )}
            defaultOpen
            targetId="myCampus"
          >
            <div className="pt-1 align-start">
              <Button className="campus-onboard-button" active={activeTab === 'listCampus'} onClick={() => setActiveTab('listCampus')}>
                <CardImg src="/images/icons/campus-list.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Campus List
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-onboard-button" active={activeTab === 'addCampus'} onClick={() => setActiveTab('addCampus')}>
                <CardImg src="/images/icons/campus-info.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Add Campus
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-onboard-button" active={activeTab === 'listCandidates'} onClick={() => setActiveTab('listCandidates')}>
                <CardImg src="/images/icons/grads-list.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                All Users
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-onboard-button" active={activeTab === 'addCandidates'} onClick={() => setActiveTab('addCandidates')}>
                <CardImg src="/images/icons/graduate-cap.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Add Campus User
              </Button>
            </div>
          </Accordion>
        </div>
      </Col>
      <Col lg="9" xs="12">
        <TabContent activeTab={activeTab}>
          {activeTab === 'listCampus' && (
          <TabPane tabId="listCampus">
            <div className="ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/campus-list.png" alt="campus-list" />
              <span className="cx-resume-header-text">Campus List</span>
            </div>
            <ListClient ordTp={ordTp} tabTp={activeTab} clientTp="CMPS" />
          </TabPane>
          )}

          {activeTab === 'addCampus' && (
          <TabPane tabId="addCampus">
            <div className="allign-start ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/campus-info.png" alt="campus-add" />
              <span className="cx-resume-header-text">Add New Campus</span>
            </div>
            <AddClient ordTp={ordTp} tabTp={activeTab} clientTp="CMPS" />
          </TabPane>
          )}

          {activeTab === 'listCandidates' && (
          <TabPane tabId="listCandidates">
            <div className="allign-start ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/grads-list.png" alt="list-candidate" />
              <span className="cx-resume-header-text">Campus User List</span>
            </div>
            <ListClientUser ordTp={ordTp} tabTp={activeTab} clientTp="CMPS" />
          </TabPane>
          )}

          {activeTab === 'addCandidates' && (
          <TabPane tabId="addCandidates">
            <div className="allign-start ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/graduate-cap.png" alt="add-candidate" />
              <span className="cx-resume-header-text">Add New Campus User</span>
            </div>
            <AddClientUser ordTp={ordTp} tabTp={activeTab} clientTp="CMPS" />
          </TabPane>
          )}
        </TabContent>

      </Col>
    </Row>
  );
};

export default CampusComponent;
