import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Button, Card, Col, Form, Input, Row,
} from 'reactstrap';
import { getTokenFromLocalStorage, setUserProfileLocalStorage } from '../../../../utils/service/localstorage-service';
import { successAlert, errorAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import { clearPassKeyReq, passKeyReq } from '../../../../store/campusXcel/actions';
import { APIHeader } from '../../../../utils/constants';
import { ResetPwdProps } from './reset-passkey.type';
import { PassKeyPutDataInput } from '../../../../services/common/update-passkey/update-passkey.types';

export const ModalPasskeyUpdate: React.FC<ResetPwdProps> = ({ setIsResetPasskey, uTp }) => {
  const dispatch = useDispatch();
  const generatedToken = getTokenFromLocalStorage();
  const [matchPassKey, setMatchPassKey] = useState(false);
  const [disableText, setDisableText] = useState(true);
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const loginProfileData = useSelector((state: RootState) => state.campusXcel.getProfileData);
  const passKeyResponse = useSelector((state: RootState) => state.campusXcel.updatePassKey);
  const [myPassKey, setMyPassKey] = useState<PassKeyPutDataInput>({
    psKey: '',
    prePsKey: '',
  });
  const [confirmPassKey, setConfirmPassKey] = useState(
    {
      confirmKey: '',
    },
  );

  const handleMyPassKey = (e:any) => {
    setMyPassKey({ ...myPassKey, [e.target.name]: e.target.value });
  };

  const handleConfirmPassKey = (e:any) => {
    setConfirmPassKey({ ...confirmPassKey, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (loginTokenData.token !== '' && !loginProfileData.profileData.isPKReset) {
      setUserProfileLocalStorage(JSON.stringify(loginProfileData));
    }
  }, [loginProfileData]);

  React.useEffect(() => {
    if ((confirmPassKey.confirmKey === myPassKey.psKey) && (confirmPassKey.confirmKey !== '' && myPassKey.psKey !== '')) {
      setMatchPassKey(true);
      setDisableText(false);
    } else {
      setMatchPassKey(false);
    }
  }, [confirmPassKey.confirmKey, myPassKey.psKey]);

  const handleSubmitPassKey = (e: any) => {
    e.preventDefault();
    setLoaderProfile(true);
    dispatch(passKeyReq({
      inputBody: myPassKey,
      uTp,
      requestType: APIHeader.REQ_UPDATE_PASSWORD,
      token: generatedToken,
    }));
  };

  React.useEffect(() => {
    if (passKeyResponse.error && passKeyResponse.message !== '') {
      setLoaderProfile(false);
      setAlertMsg(passKeyResponse.message);
      setErrorShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  React.useEffect(() => {
    if (!passKeyResponse.error && passKeyResponse.message !== '') {
      setUserProfileLocalStorage(JSON.stringify(loginProfileData));
      if (loginProfileData.profileData.isPKReset) {
        setIsResetPasskey(true);
      }
      setLoaderProfile(false);
      dispatch(clearPassKeyReq());
      setMyPassKey({
        psKey: '',
        prePsKey: '',
      });
      setConfirmPassKey({
        confirmKey: '',
      });
      setAlertMsg(passKeyResponse.message);
      setSuccessShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  return (
    <Row className="mx-1 align-center">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col xs="12" lg="11">
        <Form onSubmit={handleSubmitPassKey}>
          <Card className="form-card mx-3">
            <div className="text-right-login-campus-register pt-3 mx-3">
              <Input
                placeholder="Current Password"
                type="password"
                name="prePsKey"
                value={myPassKey.prePsKey}
                required
                onChange={handleMyPassKey}
                className="campus-input"
              />
            </div>
            <div className="text-right-login-campus-register pt-4 mx-3">
              <Input
                placeholder="New Password"
                type="password"
                name="psKey"
                value={myPassKey.psKey}
                required
                onChange={handleMyPassKey}
                className="campus-input"
              />
            </div>
            <div className="text-right-login-campus-register pt-4 mx-3">
              <Input
                placeholder="Confirm your Password"
                type="password"
                name="confirmKey"
                required
                value={confirmPassKey.confirmKey}
                onChange={handleConfirmPassKey}
                className="campus-input"
              />
              {myPassKey.psKey !== '' && confirmPassKey.confirmKey !== '' ? <div className="float-right-passkey-campus mx-1"> {matchPassKey ? <span className="correct-pass-key align-start">Password matched</span> : <span className="wrong-pass-key">Password mismatch</span>}</div> : null}
            </div>
            <div className="align-end my-3 mx-3 pt-3">
              <Button className="campus-button mx-1" disabled={loaderProfile || !matchPassKey || disableText}>
                Save
              </Button>
            </div>
          </Card>
        </Form>
        <div className="pt-3" />
      </Col>
    </Row>
  );
};

export default ModalPasskeyUpdate;
