import { UserStatusPutResponse, UserStatusPutResults } from './update.user.status.service.types';

export const UserStatusPutTransform: (response: UserStatusPutResponse) => UserStatusPutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :UserStatusPutResults = {
    message: '',
    error: false,
    data: {
      id: '',
      message: '',
    },
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
