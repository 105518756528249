import React from 'react';
import {
  CardImg,
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './resume.scss';
import { ActivityComponent } from './activity/activity.component';

export const ResumeComponent: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('resume-act');

  return (
    <div>
      <div className="allign-start ml">
        <h2 className="text-line"><CardImg className="cx-main-header-img-card" src="/images/icons/cvs.png" alt="resume" /> Resume Details</h2>
      </div>
      <Row className="onboard-main-margin-top">
        <Col xs="12">
          <Nav tabs className="campus-onboard-tab">
            <div className="pt-1">
              <div className="campus-column-onboard-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'resume-act'} onClick={() => setActiveTab('resume-act')}>
                    <div className="align-center">
                      <CardImg src="/images/icons/cv-act.png" className="campus-tab-onboard-icon-img" alt="Icon 1" />
                    </div>
                    <div className="align-center">
                      <span className="campus-tab-text mx-1">Activity</span>
                    </div>
                  </NavLink>
                </NavItem>
              </div>
              <div className="campus-column-onboard-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'preview'} onClick={() => setActiveTab('preview')}>
                    <div className="align-center">
                      <CardImg src="/images/icons/resume-pdf.png" className="campus-tab-onboard-icon-img" alt="Icon 3" />
                    </div>
                    <div className="align-center">
                      <span className="campus-tab-text mx-1">Preview</span>
                    </div>
                  </NavLink>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="resume-act">
              <ActivityComponent rootResumeTp={activeTab} />
            </TabPane>
            <TabPane tabId="preview">
              Preiew
            </TabPane>
          </TabContent>
        </Col>
        <div className="pt-5" />
      </Row>
    </div>
  );
};

export default ResumeComponent;
