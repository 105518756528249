import React from 'react';
import {
  Button,
  Col,
  Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import AllNewsletter from './list.newsletter';
import './news.scss';
import DetailsNewsletter from './details.newsletter';
import { AllNewslettersResult } from '../../../../services/sadmin/newsletter/get-newsletter/list-newsletter.types';
import { listNewsletterReq, clearListNewsletterReq, editMediaViewReq } from '../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../utils/constants';
import LoaderData from '../../../../utils/loader';
import Offcanvas from '../../../../utils/OffCanvas/OffcanvasComponent';
import { MediaViewInput } from '../../../../services/sadmin/newsletter/update-views/update.media.views.types';

export type DataProps = {
  token: string;
};

const NewsLetterXcelianComponent: React.FC<DataProps> = ({
  token,
}) => {
  const dispatch = useDispatch();
  const isMobileJob = window.innerWidth <= 468;
  const [loading, setLoading] = React.useState(false);
  const [htmlUrl, setHtmlUrl] = React.useState('');
  const [htmlTitle, setHtmlTitle] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [recordsPerPage] = React.useState<number>(30);
  const [isClickClose, setIsClickClose] = React.useState(false);
  const [clickedIndex, setClickedIndex] = React.useState('');
  const listNewsletterResponse = useSelector((state: RootState) => state.campusXcel.listNewsletter);
  const [listNewsLetter, setListNewsLetter] = React.useState<AllNewslettersResult>({
    tCnt: 0,
    fCnt: 0,
    mediaData: [],
    lastEvalKey: {
      MediaTp: '',
      MediaId: '',
    },
  });
  const [, setUpdateNewsLetterView] = React.useState<MediaViewInput>({
    MediaTp: '',
    MediaId: '',
  });

  const toggleResumeCloseTemplate = () => {
    setIsClickClose(false);
    setClickedIndex('');
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(listNewsletterReq({
      requestType: APIHeader.REQ_ALL_MEDIA,
      uTp: UserType.XCLN,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token,
      mediaTp: 'NWSLTR',
      medSts: 'ACTIVE',
    }));
  }, []);

  const fetchMoreData = () => {
    setLoading(true);
    dispatch(listNewsletterReq({
      requestType: APIHeader.REQ_ALL_MEDIA,
      uTp: UserType.XCLN,
      limit: recordsPerPage,
      evalKey: evalKeyText,
      evalTp: evalTpText,
      isFilter: 'NO',
      isSearch: 'NO',
      searchBy: 'NO',
      token,
      mediaTp: 'NWSLTR',
      medSts: 'ACTIVE',
    }));
  };

  React.useEffect(() => {
    if (listNewsletterResponse.isError && listNewsletterResponse.message !== '') {
      setLoading(false);
      dispatch(clearListNewsletterReq());
    }
  }, [listNewsletterResponse.isError, listNewsletterResponse.message]);

  React.useEffect(() => {
    if (!listNewsletterResponse.isError && listNewsletterResponse.message === 'executed') {
      setLoading(false);

      if (
        listNewsletterResponse.data
        && Object.keys(listNewsletterResponse.data).length > 0
        && Array.isArray(listNewsletterResponse.data.mediaData)
      ) {
        setListNewsLetter((prevState) => {
          // Reset if it's a search case
          const newMediaData = listNewsletterResponse.data.mediaData.filter(
            (newItem) => !prevState?.mediaData.some(
              (existingItem) => existingItem.MediaId === newItem.MediaId,
            ),
          );

          return {
            tCnt: listNewsletterResponse.data.tCnt,
            fCnt: listNewsletterResponse.data.fCnt,
            mediaData: [...(prevState?.mediaData || []), ...newMediaData], // Append filtered media data if not searching
            lastEvalKey: listNewsletterResponse.data.lastEvalKey,
          };
        });

        if (listNewsletterResponse.data.lastEvalKey !== null) {
          setEvalKeyText(listNewsletterResponse.data.lastEvalKey.MediaId);
          setEvalTpText(listNewsletterResponse.data.lastEvalKey.MediaTp);
        } else {
          setEvalKeyText('');
          setEvalTpText('');
        }
      }

      // Clear response after processing
      dispatch(clearListNewsletterReq());
    }
  }, [listNewsletterResponse.data, listNewsletterResponse.isError, listNewsletterResponse.message]);

  const handleUpdateNewsViewCount = (MediaType: string, MediaId: string) => {
    setUpdateNewsLetterView((prevState) => {
      const updatedViewData = { ...prevState, MediaTp: MediaType, MediaId };
      dispatch(editMediaViewReq({
        inputBody: updatedViewData,
        requestType: APIHeader.REQ_UPDATE_MEDIA_VIEWS,
        uTp: UserType.XCLN,
        token,
      }));
      return updatedViewData;
    });
  };

  const handleGetNewsDetails = async (UrlHtml: string, htmlTitleDetail: string, MediaType: string, MediaId: string) => {
    if (UrlHtml) {
      setLoading(true); // Start loader
      try {
        const response = await fetch(UrlHtml);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        setHtmlUrl(text);
        setHtmlTitle(htmlTitleDetail);
        setIsClickClose(true);
        setClickedIndex(MediaId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching news details:', error);
      } finally {
        setLoading(false); // Stop loader
      }
    }
    handleUpdateNewsViewCount(MediaType, MediaId);
  };

  return (
    <div>
      {loading ? <LoaderData /> : null}
      <div className="">
        {!isMobileJob
          ? (
            <Row>
              <Col lg="12">
                <div>
                  <Row>
                    <Col lg="7">
                      <AllNewsletter
                        isMobileJob={isMobileJob}
                        clickedIndex={clickedIndex}
                        data={listNewsLetter.mediaData}
                        handleGetNewsDetails={handleGetNewsDetails}
                        handleUpdateNewsViewCount={handleUpdateNewsViewCount}
                      />
                      <div className="align-center pt-4">
                        {listNewsLetter.mediaData.length > 0
                          ? <Button disabled={evalKeyText === ''} onClick={fetchMoreData} className="campus-button-all">Load More...</Button> : null}
                      </div>
                    </Col>
                    <Col lg="5">
                      {/* Advertisement Here Get info */}
                      <Offcanvas
                        isOpen={isClickClose}
                        toggle={toggleResumeCloseTemplate}
                      >
                        {htmlUrl !== ''
                          ? (
                            <div className="dash-sadmin-news-border pt-4">
                              <DetailsNewsletter
                                htmlContent={htmlUrl}
                                htmlTitle={htmlTitle}
                              />
                            </div>
                          ) : null}
                      </Offcanvas>
                      {/* {
                        loading ? (
                          <div>Loading...</div>
                        ) : (
                          htmlUrl !== '' ? (
                            <div className="dash-sadmin-news-border">
                              <DetailsNewsletter
                                htmlContent={htmlUrl}
                                htmlTitle={htmlTitle}
                              />
                            </div>
                          ) : null
                        )
                        } */}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <div className="pt-3">
                  <Row>
                    <Col xs="12">
                      <AllNewsletter
                        isMobileJob={isMobileJob}
                        clickedIndex={clickedIndex}
                        data={listNewsLetter.mediaData}
                        handleGetNewsDetails={handleGetNewsDetails}
                        handleUpdateNewsViewCount={handleUpdateNewsViewCount}
                      />
                      <div className="align-center pt-4">
                        {listNewsLetter.mediaData.length > 0
                          ? <Button disabled={evalKeyText === ''} onClick={fetchMoreData} className="campus-button-all">Load More...</Button> : null}
                      </div>
                    </Col>
                    <Col xs="12">
                      <Offcanvas
                        isOpen={isClickClose}
                        toggle={toggleResumeCloseTemplate}
                      >
                        {htmlUrl !== ''
                          ? (
                            <div className="dash-sadmin-news-border pt-4">
                              <DetailsNewsletter
                                htmlContent={htmlUrl}
                                htmlTitle={htmlTitle}
                              />
                            </div>
                          ) : null}
                      </Offcanvas>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default NewsLetterXcelianComponent;
