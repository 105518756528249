export const ListHobbies = [
  { label: 'Accordion', value: 'Accordion' },
  { label: 'Acoustic Guitar', value: 'Acoustic Guitar' },
  { label: 'Acro dance', value: 'Acro dance' },
  { label: 'Adventure racing', value: 'Adventure racing' },
  { label: 'Aikido', value: 'Aikido' },
  { label: 'Airbrushing', value: 'Airbrushing' },
  { label: 'Aircraft Spotting', value: 'Aircraft Spotting' },
  { label: 'Ajaeng', value: 'Ajaeng' },
  { label: 'All-terrain vehicle', value: 'All-terrain vehicle' },
  { label: 'Alp-horn', value: 'Alp-horn' },
  { label: 'Amateur Radio', value: 'Amateur Radio' },
  { label: 'American football', value: 'American football' },
  { label: 'Animal Fancy', value: 'Animal Fancy' },
  { label: 'Animation', value: 'Animation' },
  { label: 'Ant Keeping', value: 'Ant Keeping' },
  { label: 'Antique collecting', value: 'Antique collecting' },
  { label: 'Apple picking', value: 'Apple picking' },
  { label: 'Apples to Apples card game', value: 'Apples to Apples card game' },
  { label: 'Aquarium (Freshwater & Saltwater)', value: 'Aquarium (Freshwater & Saltwater)' },
  { label: 'Archery', value: 'Archery' },
  { label: 'Architectural models', value: 'Architectural models' },
  { label: 'Arpeggione', value: 'Arpeggione' },
  { label: 'Article writing', value: 'Article writing' },
  { label: 'Artwork collecting', value: 'Artwork collecting' },
  { label: 'Astrology', value: 'Astrology' },
  { label: ' Astronomy', value: 'Astronomy ' },
  { label: 'Atumpan', value: 'Atumpan' },
  { label: 'Audiophile', value: 'Audiophile' },
  { label: 'Australian Football', value: 'Australian Football' },
  { label: 'Auto-harp', value: 'Auto-harp' },
  { label: 'Axe Throwing', value: 'Axe Throwing' },
  { label: 'B/RTR Spades card game', value: 'B/RTR Spades card game' },
  { label: 'Backgammon', value: 'Backgammon' },
  { label: 'Badminton', value: 'Badminton' },
  { label: 'Bagpipes', value: 'Bagpipes' },
  { label: 'Ballet dancing', value: 'Ballet dancing' },
  { label: 'Balloon Animals', value: 'Balloon Animals' },
  { label: 'Ballroom dancing', value: 'Ballroom dancing' },
  { label: 'Bang! card game', value: 'Bang! card game' },
  { label: 'Banjo', value: 'Banjo' },
  { label: 'Base Jumping', value: 'Base Jumping' },
  { label: 'Baseball', value: 'Baseball' },
  { label: 'Basket weaving', value: 'Basket weaving' },
  { label: 'Basketball', value: 'Basketball' },
  { label: 'Bass drum', value: 'Bass drum' },
  { label: 'Bassoon', value: 'Bassoon' },
  { label: 'Bassoon dancing', value: 'Bassoon dancing' },
  { label: 'Baton Twirling', value: 'Baton Twirling' },
  { label: 'Beach Volleyball', value: 'Beach Volleyball' },
  { label: 'Beach-combing', value: 'Beach-combing' },
  { label: 'Beach/Sun Tanning', value: 'Beach/Sun Tanning' },
  { label: 'Beat Boxing', value: 'Beat Boxing' },
  { label: 'Beekeeping', value: 'Beekeeping' },
  { label: 'Beer Brewing', value: 'Beer Brewing' },
  { label: 'Bell', value: 'Bell' },
  { label: 'Bell Ringing', value: 'Bell Ringing' },
  { label: 'Belly dancing', value: 'Belly dancing' },
  { label: 'Bernie dancing', value: 'Bernie dancing' },
  { label: 'Bicycle Polo', value: 'Bicycle Polo' },
  { label: 'Bicycle racing', value: 'Bicycle racing' },
  { label: 'Bicycling', value: 'Bicycling' },
  { label: 'Bid whist card game', value: 'Bid whist card game' },
  { label: 'Billiards', value: 'Billiards' },
  { label: 'Bird watching', value: 'Bird watching' },
  { label: 'Blackjack card game', value: 'Blackjack card game' },
  { label: 'Blacksmithing', value: 'Blacksmithing' },
  { label: 'Bluff card game', value: 'Bluff card game' },
  { label: 'Board games / Tabletop games', value: 'Board games / Tabletop games' },
  { label: 'Boat Models', value: 'Boat Models' },
  { label: 'Boating', value: 'Boating' },
  { label: 'Bobsledding', value: 'Bobsledding' },
  { label: 'Bodybuilding', value: 'Bodybuilding' },
  { label: 'Bohnanza card game', value: 'Bohnanza card game' },
  { label: 'Bollywood dancing', value: 'Bollywood dancing' },
  { label: 'Bongo', value: 'Bongo' },
  { label: 'Book collecting', value: 'Book collecting' },
  { label: 'Book Restoration', value: 'Book Restoration' },
  { label: 'Book Writing', value: 'Book Writing' },
  { label: ' Book-binding', value: ' Book-binding' },
  { label: 'Bowling', value: 'Bowling' },
  { label: 'Brazilian Jiu-Jitsu (BJJ)', value: 'Brazilian Jiu-Jitsu (BJJ)' },
  { label: 'Bread Making', value: 'Bread Making' },
  { label: 'Break dancing', value: 'Break dancing' },
  { label: 'Breeding an animal', value: 'Breeding an animal' },
  { label: 'Bridge card game', value: 'Bridge card game' },
  { label: 'Bring Food To The Disabled', value: 'Bring Food To The Disabled' },
  { label: 'Building A House For Habitat For Humanity', value: 'Building A House For Habitat For Humanity' },
  { label: 'Bull Riding', value: 'Bull Riding' },
  { label: 'Bus Spotting', value: 'Bus Spotting' },
  { label: 'Butterfly Watching', value: 'Butterfly Watching' },
  { label: 'Button collecting', value: 'Button collecting' },
  { label: 'Cake Decorating', value: 'Cake Decorating' },
  { label: 'Calligraphy', value: 'Calligraphy' },
  { label: 'Camel Racing', value: 'Camel Racing' },
  { label: 'Camping', value: 'Camping' },
  { label: ' Candle-making', value: ' Candle-making' },
  { label: 'Canning', value: 'Canning' },
  { label: 'Canoeing', value: 'Canoeing' },
  { label: 'Capoeira Dance / Martial Arts', value: 'Capoeira Dance / Martial Arts' },
  { label: 'Car Race', value: 'Car Race' },
  { label: 'Card collecting', value: 'Card collecting' },
  { label: 'Card counting', value: 'Card counting' },
  { label: 'Cardboard engineering', value: 'Cardboard engineering' },
  { label: 'Carolling', value: 'Carolling' },
  { label: 'Cartography', value: 'Cartography' },
  { label: 'Cartooning', value: 'Cartooning' },
  { label: 'Cassino', value: 'Cassino' },
  { label: 'Castanets', value: 'Castanets' },
  { label: 'Cave Diving', value: 'Cave Diving' },
  { label: 'Cello', value: 'Cello' },
  { label: 'Ceramics', value: 'Ceramics' },
  { label: 'Chariot racing', value: 'Chariot racing' },
  { label: 'Cheer-leading', value: 'Cheer-leading' },
  { label: 'Cheese making', value: 'Cheese making' },
  { label: 'Chess', value: 'Chess' },
  { label: 'Chess Boxing', value: 'Chess Boxing' },
  { label: 'Chess Tournaments', value: 'Chess Tournaments' },
  { label: 'Church Going / Faith', value: 'Church Going / Faith' },
  { label: 'Cigar Smoking / Pipe Smoking', value: 'Cigar Smoking / Pipe Smoking' },
  { label: 'Citadels', value: 'Citadels' },
  { label: 'Clarinet', value: 'Clarinet' },
  { label: 'Clavichord', value: 'Clavichord' },
  { label: 'Climbing', value: 'Climbing' },
  { label: 'Cloud Watching', value: 'Cloud Watching' },
  { label: 'Co-splaying', value: 'Co-splaying' },
  { label: 'Coaching', value: 'Coaching' },
  { label: 'Coding', value: 'Coding' },
  { label: 'Coffee roasting', value: 'Coffee roasting' },
  { label: 'Coin collecting', value: 'Coin collecting' },
  { label: 'Color Guard', value: 'Color Guard' },
  { label: 'Coloring', value: 'Coloring' },
  { label: 'Comic Books collecting', value: 'Comic Books collecting' },
  { label: 'Computer building', value: 'Computer building' },
  { label: 'Computer programming', value: 'Computer programming' },
  { label: 'Con-worlding', value: 'Con-worlding' },
  { label: 'Conga drum', value: 'Conga drum' },
  { label: 'Contrabassoon', value: 'Contrabassoon' },
  { label: 'Contract', value: 'Contract' },
  { label: 'Cooking', value: 'Cooking' },
  { label: 'Cooking Competitions', value: 'Cooking Competitions' },
  { label: 'Cornet', value: 'Cornet' },
  { label: 'Coup', value: 'Coup' },
  { label: 'Couponing', value: 'Couponing' },
  { label: 'Crazy Eights', value: 'Crazy Eights' },
  { label: 'Cribbage', value: 'Cribbage' },
  { label: 'Cricket', value: 'Cricket' },
  { label: 'Crocheting', value: 'Crocheting' },
  { label: 'Croquet', value: 'Croquet' },
  { label: 'Cross country running', value: 'Cross country running' },
  { label: 'Cross-country skiing', value: 'Cross-country skiing' },
  { label: 'Cross-Stitch', value: 'Cross-Stitch' },
  { label: 'Crossword Puzzles', value: 'Crossword Puzzles' },
  { label: 'Cryptography', value: 'Cryptography' },
  { label: 'Curling', value: 'Curling' },
  { label: 'Cycling', value: 'Cycling' },
  { label: 'Cymbals', value: 'Cymbals' },
  { label: 'Dancing', value: 'Dancing' },
  { label: 'Dartchery', value: 'Dartchery' },
  { label: 'Darts', value: 'Darts' },
  { label: 'Debate', value: 'Debate' },
  { label: 'Decathlon', value: 'Decathlon' },
  { label: 'Deer hunting', value: 'Deer hunting' },
  { label: 'Deltiology (postcard collecting)', value: 'Deltiology (postcard collecting)' },
  { label: 'Demolitino Derby', value: 'Demolitino Derby' },
  { label: 'Diecast Cars collecting', value: ' Diecast Cars collecting' },
  { label: 'Digital Arts', value: 'Digital Arts' },
  { label: 'Diorama Making', value: 'Diorama Making' },
  { label: 'Dirt track racing', value: 'Dirt track racing' },
  { label: 'Dirt track riding', value: 'Dirt track riding' },
  { label: 'Disc Golf', value: 'Disc Golf' },
  { label: 'Disco', value: 'Disco' },
  { label: 'DJ’ing', value: 'DJ’ing' },
  { label: 'Dodge-ball', value: 'Dodge-ball' },
  { label: 'Dog Sports', value: 'Dog Sports' },
  { label: 'Dog Training', value: 'Dog Training' },
  { label: 'Doll collection', value: 'Doll collection' },
  { label: 'Dolphin Watching', value: 'Dolphin Watching' },
  { label: 'Dominion', value: 'Dominion' },
  { label: 'Dominoes', value: 'Dominoes' },
  { label: 'Donut making', value: 'Donut making' },
  { label: 'Double bass dancing', value: 'Double bass dancing' },
  { label: 'Double Solitaire', value: 'Double Solitaire' },
  { label: 'Drag racing', value: 'Drag racing' },
  { label: 'Draughts (also known as Checkers)', value: 'Draughts (also known as Checkers)' },
  { label: 'Drawing / doodling', value: 'Drawing / doodling' },
  { label: 'Drifting cars', value: 'Drifting cars' },
  { label: 'Driving', value: 'Driving' },
  { label: 'Drone Racing', value: 'Drone Racing' },
  { label: 'Drone Flying', value: 'Drone Flying' },
  { label: 'Drums', value: 'Drums' },
  { label: 'Drunken Boxing (form of martial arts)', value: 'Drunken Boxing (form of martial arts)' },
  { label: 'Duathlon', value: 'Duathlon' },
  { label: 'Dulcian', value: 'Dulcian' },
  { label: 'Dumpster Diving', value: 'Dumpster Diving' },
  { label: 'Dungeons and Dragons (D&D)', value: 'Dungeons and Dragons (D&D)' },
  { label: 'Dutch Blitz', value: 'Dutch Blitz' },
  { label: 'Dynamophone', value: 'Dynamophone' },
  { label: 'E-Books', value: 'E-Books' },
  { label: 'Eclipse chasing', value: 'Eclipse chasing' },
  { label: 'Egyptian Ratscrew', value: 'Egyptian Ratscrew' },
  { label: 'Electronics (creating, collecting, using)', value: 'Electronics (creating, collecting, using)' },
  { label: 'Elements (periodical table) collecting', value: 'Elements (periodical table) collecting' },
  { label: 'Embroidery', value: 'Embroidery' },
  { label: 'Engraving', value: 'Engraving' },
  { label: 'Equestrianism', value: 'Equestrianism' },
  { label: 'Eskrima', value: 'Eskrima' },
  { label: 'Etching', value: 'Etching' },
  { label: 'Euchre', value: 'Euchre' },
  { label: 'Exercise', value: 'Exercise' },
  { label: 'Exploding Kittens', value: 'Exploding Kittens' },
  { label: 'Falconry', value: 'Falconry' },
  { label: 'Family Business', value: 'Family Business' },
  { label: 'Fantasy Sports', value: 'Fantasy Sports' },
  { label: 'Farming', value: 'Farming' },
  { label: 'Fashion Design', value: 'Fashion Design' },
  { label: 'Fell-running', value: 'Fell-running' },
  { label: 'Felting', value: 'Felting' },
  { label: 'Fencing', value: 'Fencing' },
  { label: 'Fiddle', value: 'Fiddle' },
  { label: 'Field hockey', value: 'Field hockey' },
  { label: 'FIFA series', value: 'FIFA series' },
  { label: 'Figure skating', value: 'Figure skating' },
  { label: 'Fire dancing', value: 'Fire dancing' },
  { label: 'Fire Poi', value: 'Fire Poi' },
  { label: 'Firearm models', value: 'Firearm models' },
  { label: 'Fish Tournaments', value: 'Fish Tournaments' },
  { label: 'Fish-keeping', value: 'Fish-keeping' },
  { label: 'Flag Football', value: '' },
  { label: 'Flamenco dancing', value: 'Flamenco dancing' },
  { label: 'Floor-ball', value: 'Floor-ball' },
  { label: 'Flower arranging', value: 'Flower arranging' },
  { label: 'Flower collecting and pressing', value: 'Flower collecting and pressing' },
  { label: 'Flute', value: 'Flute' },
  { label: 'Flutophone', value: 'Flutophone' },
  { label: 'Fluxx card game', value: 'Fluxx card game' },
  { label: 'Flying Drones', value: 'Flying Drones' },
  { label: 'Flying Men dancing', value: 'Flying Men dancing' },
  { label: 'Foot Racing', value: 'Foot Racing' },
  { label: 'Foraging', value: 'Foraging' },
  { label: 'Foreign language learning', value: 'Foreign language learning' },
  { label: 'Formula Racing', value: 'Formula Racing' },
  { label: 'Fossil hunting / Fossilizing', value: 'Fossil hunting / Fossilizing' },
  { label: 'Four Wheeling', value: 'Four Wheeling' },
  { label: 'Foxtrot dancing', value: 'Foxtrot dancing' },
  { label: 'Freelance model', value: 'Freelance model' },
  { label: 'Frisbee Golf', value: 'Frisbee Golf' },
  { label: 'Gambling', value: 'Gambling' },
  { label: 'Gardening', value: 'Gardening' },
  { label: 'Gasbah', value: 'Gasbah' },
  { label: 'Geedal', value: 'Geedal' },
  { label: 'Genealogy', value: 'Genealogy' },
  { label: 'Geocaching', value: 'Geocaching' },
  { label: 'Geography', value: 'Geography' },
  { label: 'Ghost hunting', value: 'Ghost hunting' },
  { label: 'Gijak', value: 'Gijak' },
  { label: 'Gin Rummy', value: 'Gin Rummy' },
  { label: 'Glass etching', value: 'Glass etching' },
  { label: 'Glassblowing', value: 'Glassblowing' },
  { label: 'Glockenspiel', value: 'Glockenspiel' },
  { label: 'Glockenspiel dancing', value: 'Glockenspiel dancing' },
  { label: 'Glow-sticking', value: 'Glow-sticking' },
  { label: 'Gnoming', value: 'Gnoming' },
  { label: 'Gods’ Gambit', value: 'Gods’ Gambit' },
  { label: 'GoFish', value: 'GoFish' },
  { label: 'GoGo dancing', value: 'GoGo dancing' },
  { label: 'Gold digging', value: 'Gold digging' },
  { label: 'Golf', value: 'Golf' },
  { label: 'Golfing', value: 'Golfing' },
  { label: 'Gongoozling', value: 'Gongoozling' },
  { label: 'Gongs', value: 'Gongs' },
  { label: 'Gongs dancing', value: 'Gongs dancing' },
  { label: 'Goose Hunting', value: 'Goose Hunting' },
  { label: 'Graffiti', value: 'Graffiti' },
  { label: 'Graphic Design', value: 'Graphic Design' },
  { label: 'Greek Classical dancing', value: 'Greek Classical dancing' },
  { label: 'Guillotine', value: 'Guillotine' },
  { label: 'Guitar', value: 'Guitar' },
  { label: 'Gun collecting', value: 'Gun collecting' },
  { label: 'Gundam Models', value: 'Gundam Models' },
  { label: 'Gunsmithing', value: 'Gunsmithing' },
  { label: 'Gymnastics', value: 'Gymnastics' },
  { label: 'Gyotaku', value: 'Gyotaku' },
  { label: 'Hacky Sack', value: 'Hacky Sack' },
  { label: 'Hair Braiding', value: 'Hair Braiding' },
  { label: 'Halam', value: 'Halam' },
  { label: 'Hammocking', value: 'Hammocking' },
  { label: 'Hanabi', value: 'Hanabi' },
  { label: 'Handball', value: 'Handball' },
  { label: 'Handwriting Analysis', value: 'Handwriting Analysis' },
  { label: 'Hang gliding', value: 'Hang gliding' },
  { label: 'Hapkido', value: 'Hapkido' },
  { label: 'Harmonica', value: 'Harmonica' },
  { label: 'Harp', value: 'Harp' },
  { label: 'Harpsichord', value: 'Harpsichord' },
  { label: 'Hat collecting', value: 'Hat collecting' },
  { label: 'Hearthstone', value: 'Hearthstone' },
  { label: 'Hearts', value: 'Hearts' },
  { label: 'Heli-skiing', value: 'Heli-skiing' },
  { label: 'Herping', value: 'Herping' },
  { label: 'Hiking / Backpacking', value: 'Hiking / Backpacking' },
  { label: 'Hip Hop dancing', value: 'Hip Hop dancing' },
  { label: 'History', value: 'History' },
  { label: 'Home Brewing', value: 'Home Brewing' },
  { label: 'Home Decorating', value: 'Home Decorating' },
  { label: 'Home Theater', value: 'Home Theater' },
  { label: 'Horseback riding (Equestrianism)', value: 'Horseback riding (Equestrianism)' },
  { label: 'Hot Air Ballooning', value: 'Hot Air Ballooning' },
  { label: 'Hula Hooping', value: 'Hula Hooping' },
  { label: 'Hunting', value: 'Hunting' },
  { label: 'Hurdy Gurdy', value: 'Hurdy Gurdy' },
  { label: 'Hydroponics', value: 'Hydroponics' },
  { label: 'Hyooshigi', value: 'Hyooshigi' },
  { label: 'Ice carving', value: 'Ice carving' },
  { label: 'Ice diving', value: 'Ice diving' },
  { label: 'Ice fishing', value: 'Ice fishing' },
  { label: 'Ice hockey', value: 'Ice hockey' },
  { label: 'Ice sailing', value: 'Ice sailing' },
  { label: 'Ice skating', value: 'Ice skating' },
  { label: 'Igil', value: 'Igil' },
  { label: 'Igloo building', value: 'Igloo building' },
  { label: 'Illusion drawing', value: 'Illusion drawing' },
  { label: 'Impersonations', value: 'Impersonations' },
  { label: 'Indoor', value: 'Indoor' },
  { label: 'Ink pens', value: 'Ink pens' },
  { label: 'Inline skating', value: 'Inline skating' },
  { label: 'Insect collecting', value: 'Insect collecting' },
  { label: 'Inventing new things', value: 'Inventing new things' },
  { label: 'Investing money', value: 'Investing money' },
  { label: 'Irish Bouzouki', value: 'Irish Bouzouki' },
  { label: 'Jail-breaking', value: 'Jail-breaking' },
  { label: 'Javelin throwing', value: 'Javelin throwing' },
  { label: 'Jaw Harp', value: 'Jaw Harp' },
  { label: 'Jazz guitar', value: 'Jazz guitar' },
  { label: 'Jazz piano', value: 'Jazz piano' },
  { label: 'Jazz saxophone', value: 'Jazz saxophone' },
  { label: 'Jazz-ercise', value: 'Jazz-ercise' },
  { label: 'Jazz-ercise', value: 'Jazz-ercise' },
  { label: 'Jelly / Jam making', value: 'Jelly / Jam making' },
  { label: 'Jet engines', value: 'Jet engines' },
  { label: 'Jet plane models', value: 'Jet plane models' },
  { label: 'Jet skiing', value: 'Jet skiing' },
  { label: 'Jewelry making', value: 'Jewelry making' },
  { label: 'Jigsaw puzzles', value: 'Jigsaw puzzles' },
  { label: 'Jingle Bells', value: 'Jingle Bells' },
  { label: 'Jujitsu', value: 'Jujitsu' },
  { label: 'Jockeying', value: 'Jockeying' },
  { label: 'Jogging', value: 'Jogging' },
  { label: 'Joke writing', value: 'Joke writing' },
  { label: 'Journaling', value: 'Journaling' },
  { label: 'Judo', value: 'Judo' },
  { label: 'Juggling balls, pins, or other objects', value: 'Juggling balls, pins, or other objects' },
  { label: 'Juicing fruits & berries', value: 'Juicing fruits & berries' },
  { label: 'Jumping out of planes', value: 'Jumping out of planes' },
  { label: 'Jumping rope', value: 'Jumping rope' },
  { label: 'Junk collection', value: 'Junk collection' },
  { label: 'Kabosy', value: 'Kabosy' },
  { label: 'Kaffir Piano', value: 'Kaffir Piano' },
  { label: 'Kagul', value: 'Kagul' },
  { label: 'Kamanche', value: 'Kamanche' },
  { label: 'Kanklės', value: 'Kanklės' },
  { label: 'Karaoke', value: 'Karaoke' },
  { label: 'Karapan Sapi', value: 'Karapan Sapi' },
  { label: 'Karate', value: 'Karate' },
  { label: 'Kayagum', value: 'Kayagum' },
  { label: 'Kayaking', value: 'Kayaking' },
  { label: 'Kazoo', value: 'Kazoo' },
  { label: 'Kendo', value: 'Kendo' },
  { label: 'Kenpo', value: 'Kenpo' },
  { label: 'Kettle Drum', value: 'Kettle Drum' },
  { label: 'Key collecting', value: 'Key collecting' },
  { label: 'Keyboard', value: 'Keyboard' },
  { label: 'Khim', value: 'Khim' },
  { label: 'Khloy', value: 'Khloy' },
  { label: 'Khlui', value: 'Khlui' },
  { label: 'Kickboxing', value: 'Kickboxing' },
  { label: 'Kick-sled', value: 'Kick-sled' },
  { label: 'Kings in the Corner', value: 'Kings in the Corner' },
  { label: 'Kite boarding or Kite surfing', value: 'Kite boarding or Kite surfing' },
  { label: 'Kite flying', value: 'Kite flying' },
  { label: 'Kite making', value: 'Kite making' },
  { label: 'Kizomba dancing', value: 'Kizomba dancing' },
  { label: 'Knapping', value: 'Knapping' },
  { label: 'Knee-boarding', value: 'Knee-boarding' },
  { label: 'Knife collecting', value: 'Knife collecting' },
  { label: 'Knife Throwing', value: 'Knife Throwing' },
  { label: 'Knife-making', value: 'Knife-making' },
  { label: 'Kora', value: 'Kora' },
  { label: 'Koto', value: 'Koto' },
  { label: 'Koudi', value: 'Koudi' },
  { label: 'Krav Maga', value: 'Krav Maga' },
  { label: 'Kung Fu', value: 'Kung Fu' },
  { label: 'Lace-making', value: 'Lace-making' },
  { label: 'Lacrosse', value: 'Lacrosse' },
  { label: 'Landscaping / lawn care', value: 'Landscaping / lawn care' },
  { label: 'Lapidary', value: 'Lapidary' },
  { label: 'LARPing', value: 'LARPing' },
  { label: 'Laser Tag', value: 'Laser Tag' },
  { label: 'Lasso throwing', value: 'Lasso throwing' },
  { label: 'Lawn Darts', value: 'Lawn Darts' },
  { label: 'League of Legends', value: 'League of Legends' },
  { label: 'Legendary Encounters: lien', value: 'Legendary Encounters: lien' },
  { label: 'Legendary: Marvel', value: 'Legendary: Marvel' },
  { label: 'Lego building', value: 'Lego building' },
  { label: 'Letter-boxing', value: 'Letter-boxing' },
  { label: 'Letters', value: 'Letters' },
  { label: 'Ligretto', value: 'Ligretto' },
  { label: 'Line dance dancing', value: 'Line dance dancing' },
  { label: 'Linya break dancing', value: 'Linya break dancing' },
  { label: 'Lithography', value: 'Lithography' },
  { label: 'Live steam models', value: 'Live steam models' },
  { label: 'Lobster racing', value: 'Lobster racing' },
  { label: 'Lock-picking', value: 'Lock-picking' },
  { label: 'Locomotive & Train Models', value: 'Locomotive & Train Models' },
  { label: 'Long jumping', value: 'Long jumping' },
  { label: 'Long boarding', value: 'Long boarding' },
  { label: 'LoveLetter', value: 'LoveLetter' },
  { label: 'Luck and Logic', value: 'Luck and Logic' },
  { label: 'Luge', value: 'Luge' },
  { label: 'Lute', value: 'Lute' },
  { label: 'Map making', value: 'Map making' },
  { label: 'Marble collecting', value: 'Marble collecting' },
  { label: 'Miming', value: 'Miming' },
  { label: 'Mineral collecting', value: 'Mineral collecting' },
  { label: 'Mixed Martial Arts (MMA)', value: 'Mixed Martial Arts (MMA)' },
  { label: 'Model aircraft', value: 'Model aircraft' },
  { label: 'Model cars', value: 'Model cars' },
  { label: 'Model commercial vehicles', value: 'Model commercial vehicles' },
  { label: 'Model construction vehicles', value: 'Model construction vehicles' },
  { label: 'Model military vehicles', value: 'Model military vehicles' },
  { label: 'Model rockets', value: 'Model rockets' },
  { label: 'Model Ships', value: 'Model Ships' },
  { label: 'Modern Imaginative dancing', value: 'Modern Imaginative dancing' },
  { label: 'Monopoly Deal', value: 'Monopoly Deal' },
  { label: 'MONSTER REJECTS -The Monsters That Nobody Loves', value: 'MONSTER REJECTS -The Monsters That Nobody Loves' },
  { label: 'Moonwalk', value: 'Moonwalk' },
  { label: 'Mountain Bike', value: 'Mountain Bike' },
  { label: 'Mountain biking', value: 'Mountain biking' },
  { label: 'Mountain climbing / Mountaineering', value: 'Mountain climbing / Mountaineering' },
  { label: 'Mountain Marathon', value: 'Mountain Marathon' },
  { label: 'Movie Memorabilia collecting', value: 'Movie Memorabilia collecting' },
  { label: 'Muay Thai', value: 'Muay Thai' },
  { label: 'Munchkin', value: 'Munchkin' },
  { label: 'Music Albums and Records collecting', value: 'Music Albums and Records collecting' },
  { label: 'Musical box', value: 'Musical box' },
  { label: 'Nail Art', value: 'Nail Art' },
  { label: 'Napping', value: 'Napping' },
  { label: 'Native American dancing', value: 'Native American dancing' },
  { label: 'Necklace making', value: 'Necklace making' },
  { label: 'Needlepoint', value: 'Needlepoint' },
  { label: 'Nerf guns', value: 'Nerf guns' },
  { label: 'Nerts', value: 'Nerts' },
  { label: 'Ninjutsu', value: 'Ninjutsu' },
  { label: 'Novel writing', value: 'Novel writing' },
  { label: 'Nunchuck', value: 'Nunchuck' },
  { label: 'Oboe', value: 'Oboe' },
  { label: 'Obstacle course running', value: 'Obstacle course running' },
  { label: 'Offroading', value: 'Offroading' },
  { label: 'Oil panting', value: 'Oil panting' },
  { label: 'Old Maid', value: 'Old Maid' },
  { label: 'Open-wheel', value: 'Open-wheel' },
  { label: 'Opera singing', value: 'Opera singing' },
  { label: 'Orchestra', value: 'Orchestra' },
  { label: 'Organ', value: 'Organ' },
  { label: 'Organizing items', value: 'Organizing items' },
  { label: 'Origami', value: 'Origami' },
  { label: 'Ostrich racing', value: 'Ostrich racing' },
  { label: 'Paintball', value: 'Paintball' },
  { label: 'Painting', value: 'Painting' },
  { label: 'Paper mache', value: 'Paper mache' },
  { label: 'Paper-making', value: 'Paper-making' },
  { label: 'Parachuting', value: 'Parachuting' },
  { label: 'Paragliding', value: 'Paragliding' },
  { label: 'Parkour', value: 'Parkour' },
  { label: 'Penny collecting', value: 'Penny collecting' },
  { label: 'Photography', value: 'Photography' },
  { label: 'People watching', value: 'People watching' },
  { label: 'Phase', value: 'Phase' },
  { label: 'Piano', value: 'Piano' },
  { label: 'Pickup truck', value: 'Pickup truck' },
  { label: 'Pig racing', value: 'Pig racing' },
  { label: 'Pigeon racing', value: 'Pigeon racing' },
  { label: 'Pilates', value: 'Pilates' },
  { label: 'Piloting planes', value: 'Piloting planes' },
  { label: 'Ping pong', value: 'Ping pong' },
  { label: 'Pinochle', value: 'Pinochle' },
  { label: 'Podcasting', value: 'Podcasting' },
  { label: 'Poetry writing', value: 'Poetry writing' },
  { label: 'Poi making', value: 'Poi making' },
  { label: 'Pokémon Trading Card Game', value: 'Pokémon Trading Card Game' },
  { label: 'Poker', value: 'Poker' },
  { label: 'Pole Dancing', value: 'Pole Dancing' },
  { label: 'Polo', value: 'Polo' },
  { label: 'Pottery', value: 'Pottery' },
  { label: 'Power-blocking', value: 'Power-blocking' },
  { label: 'President', value: 'President' },
  { label: 'Presidential Poker', value: 'Presidential Poker' },
  { label: 'Production car', value: 'Production car' },
  { label: 'Protesting', value: 'Protesting' },
  { label: 'Puppetry', value: 'Puppetry' },
  { label: 'Puzzles', value: 'Puzzles' },
  { label: 'Pyrotechnics', value: 'Pyrotechnics' },
  { label: 'Quarter collecting', value: 'Quarter collecting' },
  { label: 'Quickstep dancing', value: 'Quickstep daRace for the Galaxyncing' },
  { label: 'Quilting', value: 'Quilting' },
  { label: 'R/C Boats', value: 'R/C Boats' },
  { label: 'R/C Cars', value: 'R/C Cars' },
  { label: 'R/C Helicopters', value: 'R/C Helicopters' },
  { label: 'R/C Planes', value: 'R/C Planes' },
  { label: 'Race for the Galaxy', value: 'Race for the Galaxy' },
  { label: 'Race-walking', value: 'Race-walking' },
  { label: 'Racquetball', value: 'Racquetball' },
  { label: 'Radio race', value: '' },
  { label: 'Radio-controlled model racing', value: 'Radio-controlled model racing' },
  { label: 'Rafting', value: 'Rafting' },
  { label: 'Rail fans (trainspotting)', value: 'Rail fans (trainspotting)' },
  { label: 'Rail transport modeling', value: 'Rail transport modeling' },
  { label: 'Rallycross', value: 'Rallycross' },
  { label: 'Rappelling (Abseiling)', value: 'Rappelling (Abseiling)' },
  { label: 'Ratuki', value: 'Ratuki' },
  { label: 'Reading', value: 'Reading' },
  { label: 'Reading Books', value: 'Reading Books' },
  { label: 'Reading to children', value: 'Reading to children' },
  { label: 'Reading to the elderly', value: 'Reading to the elderly' },
  { label: 'Recorder', value: 'Recorder' },
  { label: 'Remote controlled Boats', value: 'Remote controlled Boats' },
  { label: 'Remote controlled planes', value: 'Remote controlled planes' },
  { label: 'Renaissance Faire', value: 'Renaissance Faire' },
  { label: 'Rescuing animals', value: 'Rescuing animals' },
  { label: 'Road bicycle', value: 'Road bicycle' },
  { label: 'Road racing', value: 'Road racing' },
  { label: 'Road running', value: 'Road running' },
  { label: 'Robotics', value: 'Robotics' },
  { label: 'Rock balancing', value: 'Rock balancing' },
  { label: 'Rock climbing', value: 'Rock climbing' },
  { label: 'Rocket shooting', value: 'Rocket shooting' },
  { label: 'Rogaining', value: 'Rogaining' },
  { label: 'Role-playing', value: 'Role-playing' },
  { label: 'Roller derby', value: 'Roller derby' },
  { label: 'Roller skating', value: 'Roller skating' },
  { label: 'Roller skiing', value: 'Roller skiing' },
  { label: 'ROOK', value: 'ROOK' },
  { label: 'Rubix cube', value: 'Rubix cube' },
  { label: 'Rugby', value: 'Rugby' },
  { label: 'Rummy', value: 'Rummy' },
  { label: 'Sailing', value: 'Sailing' },
  { label: 'Sambo', value: 'Sambo' },
  { label: 'Sand art', value: 'Sand art' },
  { label: 'Sand castle building', value: 'Sand castle building' },
  { label: 'Saxophone', value: 'Saxophone' },
  { label: 'Scale model building', value: 'Scale model building' },
  { label: 'Scrabble Slam', value: 'Scrabble Slam' },
  { label: 'Scrap-booking', value: 'Scrap-booking' },
  { label: 'Screw The Dealer', value: 'Screw The Dealer' },
  { label: 'Scuba diving', value: 'Scuba diving' },
  { label: 'Sculling or Rowing', value: 'Sculling or Rowing' },
  { label: 'Sea Glass collecting', value: 'Sea Glass collecting' },
  { label: 'Seashell collecting', value: 'Seashell collecting' },
  { label: 'Sentinels of the Multiverse', value: 'Sentinels of the Multiverse' },
  { label: 'Sewing', value: 'Sewing' },
  { label: 'Shooting sport', value: 'Shooting sport' },
  { label: 'Shopping', value: 'Shopping' },
  { label: 'Shortwave listening', value: 'Shortwave listening' },
  { label: 'Shuffleboard', value: 'Shuffleboard' },
  { label: 'Singing', value: 'Singing' },
  { label: 'Skat', value: 'Skat' },
  { label: 'Skateboarding', value: 'Skateboarding' },
  { label: 'Skeet Shooting', value: 'Skeet Shooting' },
  { label: 'Sketching', value: 'Sketching' },
  { label: 'Ski race', value: 'Ski race' },
  { label: 'Skiing', value: 'Skiing' },
  { label: 'Skim boarding', value: 'Skim boarding' },
  { label: 'Skip Boo', value: 'Skip Boo' },
  { label: 'Sky diving', value: 'Sky diving' },
  { label: 'Slack-lining', value: 'Slack-lining' },
  { label: 'Slingshots', value: 'Slingshots' },
  { label: 'Slot cars racing', value: 'Slot cars racing' },
  { label: 'Skijoring racing', value: 'Skijoring racing' },
  { label: 'Snail racing', value: 'Snail racing' },
  { label: 'Snare drum', value: 'Snare drum' },
  { label: 'Snorkeling', value: 'Snorkeling' },
  { label: 'Snowboarding', value: 'Snowboarding' },
  { label: 'Snowshoeing', value: 'Snowshoeing' },
  { label: 'Soap-making', value: 'Soap-making' },
  { label: 'Soca dancing', value: 'Soca dancing' },
  { label: 'Soccer / Association Football', value: 'Soccer / Association Football' },
  { label: 'Socializing', value: 'Socializing' },
  { label: 'Solitaire', value: 'Solitaire' },
  { label: 'Songs', value: 'Songs' },
  { label: 'Speed skating', value: 'Speed skating' },
  { label: 'Speed-cubing (Rubik’s cube)', value: 'Speed-cubing (Rubik’s cube)' },
  { label: 'Spelunking', value: 'Spelunking' },
  { label: 'Spit', value: 'Spit' },
  { label: 'Splendor', value: 'Splendor' },
  { label: 'Spoons', value: 'Spoons' },
  { label: 'Sport Labyrinth', value: 'Sport Labyrinth' },
  { label: 'Sports car racing', value: 'Sports car racing' },
  { label: 'Sports Cards (Baseball, Football, Basketball, Hockey) collecting', value: 'Sports Cards (Baseball, Football, Basketball, Hockey) collecting' },
  { label: 'Sprint car racing', value: 'Sprint car racing' },
  { label: 'Squash', value: 'Squash' },
  { label: 'Stamp collecting', value: 'Stamp collecting' },
  { label: 'Stand-up Comedy', value: 'Stand-up Comedy' },
  { label: 'Star Realms', value: 'Star Realms' },
  { label: 'Steel drum', value: 'Steel drum' },
  { label: 'Steeplechase racing', value: 'Steeplechase racing' },
  { label: 'Stock car racing', value: 'Stock car racing' },
  { label: 'Stone collecting', value: 'Stone collecting' },
  { label: 'Stone Skipping', value: 'Stone Skipping' },
  { label: 'Storm Chasing', value: 'Storm Chasing' },
  { label: 'Storytelling', value: 'Storytelling' },
  { label: 'String Figures', value: 'String Figures' },
  { label: 'Sudoku', value: 'Sudoku' },
  { label: 'Surf Fishing', value: 'Surf Fishing' },
  { label: 'Surfing', value: 'Surfing' },
  { label: 'Survival-ism', value: 'Survival-ism' },
  { label: 'Survivalism', value: 'Survivalism' },
  { label: 'Sushi Go', value: 'Sushi Go' },
  { label: 'Swim races', value: 'Swim races' },
  { label: 'Swimming', value: 'Swimming' },
  { label: 'Sword collecting', value: 'Sword collecting' },
  { label: 'Table football', value: 'Table football' },
  { label: 'Table Tennis (ping pong)', value: 'Table Tennis (ping pong)' },
  { label: 'Taekwondo', value: 'Taekwondo' },
  { label: 'Tailoring clothes', value: 'Tailoring clothes' },
  { label: 'Tambourine', value: 'Tambourine' },
  { label: 'Tang Soo Do', value: 'Tang Soo Do' },
  { label: 'Tango dancing', value: 'Tango dancing' },
  { label: 'Tapdancing', value: 'Tapdancing' },
  { label: 'Tatting', value: 'Tatting' },
  { label: 'Taxidermy', value: 'Taxidermy' },
  { label: 'Teach Cooking Lessons', value: 'Teach Cooking Lessons' },
  { label: 'Tennis', value: 'Tennis' },
  { label: 'Tesla Coils', value: 'Tesla Coils' },
  { label: 'Tetris', value: 'Tetris' },
  { label: 'Textiles', value: 'Textiles' },
  { label: 'The Code Card Game', value: 'The Code Card Game' },
  { label: 'Theremin', value: 'Theremin' },
  { label: 'Tie dye clothes', value: 'Tie dye clothes' },
  { label: 'Tool collecting', value: 'Tool collecting' },
  { label: 'Topiary', value: 'Topiary' },
  { label: 'Touring car racing', value: 'Touring car racing' },
  { label: 'Tower running', value: 'Tower running' },
  { label: 'Toy collecting', value: 'Toy collecting' },
  { label: 'Track cycling', value: 'Track cycling' },
  { label: 'Track running', value: 'Track running' },
  { label: 'Trail racing', value: 'Trail racing' },
  { label: 'Trail running', value: 'Trail running' },
  { label: 'Train collecting', value: 'Train collecting' },
  { label: 'Training Animals', value: 'Training Animals' },
  { label: 'Traveling', value: 'Traveling' },
  { label: 'Treasure Hunting', value: 'Treasure Hunting' },
  { label: 'Triangle', value: 'Triangle' },
  { label: 'Triathalon', value: 'Triathalon' },
  { label: 'Trombone', value: 'Trombone' },
  { label: 'Truck racing', value: 'Truck racing' },
  { label: 'Trumpet', value: 'Trumpet' },
  { label: 'Tuba', value: 'Tuba' },
  { label: 'Tubing', value: 'Tubing' },
  { label: 'Turtle racing', value: 'Turtle racing' },
  { label: 'Tutoring Children', value: 'Tutoring Children' },
  { label: 'TV watching', value: 'TV watching' },
  { label: 'Twenty-two', value: 'Twenty-two' },
  { label: 'Ukulele', value: 'Ukulele' },
  { label: 'Ultimate Disc', value: 'Ultimate Disc' },
  { label: 'Umpiring sports', value: 'Umpiring sports' },
  { label: 'Unicycling', value: 'Unicycling' },
  { label: 'UNO', value: 'UNO' },
  { label: 'Up and Down the River', value: 'Up and Down the River' },
  { label: 'Upholstery', value: 'Upholstery' },
  { label: 'Urban exploration', value: 'Urban exploration' },
  { label: 'Vehicle restoration', value: 'Vehicle restoration' },
  { label: 'Video Game collecting', value: 'Video Game collecting' },
  { label: 'Video Games', value: 'Video Games' },
  { label: 'Video-philia', value: 'Video-philia' },
  { label: 'Viennese Waltz dancing', value: 'Viennese Waltz dancing' },
  { label: 'Vintage cars', value: 'Vintage cars' },
  { label: 'Viola', value: 'Viola' },
  { label: 'Violin', value: 'Violin' },
  { label: 'Volleyball', value: 'Volleyball' },
  { label: 'Volunteer abroad', value: 'Volunteer abroad' },
  { label: 'Volunteer at a homeless shelter', value: 'Volunteer at a homeless shelter' },
  { label: 'Volunteer at a hospital', value: 'Volunteer at a hospital' },
  { label: 'Volunteer at a local church', value: 'Volunteer at a local church' },
  { label: 'Volunteer at a park', value: 'Volunteer at a park' },
  { label: 'Volunteer at a retirement home', value: 'Volunteer at a retirement home' },
  { label: 'Volunteer at a womens shelter', value: 'Volunteer at a womens shelter' },
  { label: 'Volunteer at an animal shelter', value: 'Volunteer at an animal shelter' },
  { label: 'Volunteer at the food pantry', value: 'Volunteer at the food pantry' },
  { label: 'Volunteer at the library', value: 'Volunteer at the library' },
  { label: 'Volunteer at the museum', value: 'Volunteer at the museum' },
  { label: 'Volunteer at the red cross', value: 'Volunteer at the red cross' },
  { label: 'Volunteer for after school programs', value: 'Volunteer for after school programs' },
  { label: 'Waltz dancing', value: 'Waltz dancing' },
  { label: 'War', value: 'War' },
  { label: 'War-hammer', value: 'War-hammer' },
  { label: 'Watching movies', value: 'Watching movies' },
  { label: 'Watching sporting events', value: 'Watching sporting events' },
  { label: 'Weather Watcher', value: 'Weather Watcher' },
  { label: 'Weight lifting', value: 'Weight lifting' },
  { label: 'Werewolf', value: 'Werewolf' },
  { label: 'Whale Watching', value: 'Whale Watching' },
  { label: 'Whist', value: 'Whist' },
  { label: 'Whittling', value: 'Whittling' },
  { label: 'Windsurfing', value: 'Windsurfing' },
  { label: 'Winemaking', value: 'Winemaking' },
  { label: 'Wing Chun', value: 'Wing Chun' },
  { label: 'Wing-suit Flying', value: 'Wing-suit Flying' },
  { label: 'Wonders', value: 'Wonders' },
  { label: 'Woodcarving', value: 'Woodcarving' },
  { label: 'World-building', value: 'World-building' },
  { label: 'Wrestling', value: 'Wrestling' },
  { label: 'Xiangqi (Chinese Chess)', value: 'Xiangqi (Chinese Chess)' },
  { label: 'Xylophone', value: 'Xylophone' },
  { label: 'Yacht sailing', value: 'Yacht sailing' },
  { label: 'Yard work', value: 'Yard work' },
  { label: 'Yo-yoing', value: 'Yo-yoing' },
  { label: 'Yodel', value: 'Yodel' },
  { label: 'Yoga', value: 'Yoga' },
  { label: 'You’re So Bad! Card Game', value: 'You’re So Bad! Card Game' },
  { label: 'Yu-Gi-Oh! Trading Card Game', value: 'Yu-Gi-Oh! Trading Card Game' },
  { label: 'Ziplining', value: 'Ziplining' },
  { label: 'Zither', value: 'Zither' },
  { label: 'Zoo Volunteer', value: 'Zoo Volunteer' },
  { label: 'Zumba dancing', value: 'Zumba dancing' },
];
