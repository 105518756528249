import { RegisterEmployMNTRSResponse, RegisterEmployMNTRSResults } from './register.service.types';

export const RegisterEmployMNTRSTransform: (response: RegisterEmployMNTRSResponse) => RegisterEmployMNTRSResults = (registerEMPLOYResults) => {
  const { data, is_error, message } = registerEMPLOYResults;
  const result: RegisterEmployMNTRSResults = {
    id: 0,
    message: '',
    error: false,
    errorMessage: '',
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.data = data;
  }
  return result;
};
