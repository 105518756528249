import { XclnResumeFilePutResponse, XclnResumeFilePutResults } from './update.resume.file.service.types';

export const XclnResumeFilePutTransform: (response: XclnResumeFilePutResponse) => XclnResumeFilePutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :XclnResumeFilePutResults = {
    message: '',
    error: false,
    jdFileName: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
    result.jdFileName = data;
  }
  return result;
};
