import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import {
  Card, Row, Col, Button,
  CardImg,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { NAllCertProps } from '../../resume.type';
import Modal from '../../../../../../../utils/Modal/Modal';
import { CertAddDataInput } from '../../../../../../../services/campus/certifications/update-cert/update-cert.types';
import { getTokenFromLocalStorage } from '../../../../../../../utils/service/localstorage-service';
import { APIHeader, UserType } from '../../../../../../../utils/constants';
import { clearCert, getCert } from '../../../../../../../store/campusXcel/actions';
import { CertData } from '../../../../../../../services/campus/certifications/get-cert/get-cert.types';

export const ListCert: React.FC<NAllCertProps> = ({
  rootResumeTp,
  resumeTp,
  certDetails,
  index,
  setAlertMsg,
  setLoaderResume,
  setSuccessShowAlert,
  setErrorShowAlert,
  handleEditStatus,
  setIsDELApiCld,
  isDELApiCld,
}) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const maxLength = 150;
  const [isCertTxt, setIsCertTxt] = React.useState(false);
  const [isZoom, setIsZoom] = React.useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = React.useState(false);
  const certResponse = useSelector((state: RootState) => state.campusXcel.updateCert);
  const [myDelCert, setMyDelCert] = React.useState<CertAddDataInput>({
    id: '',
    ops: '',
    nme: '',
    certF: '',
    sDte: moment(new Date()).format('YYYY-MM-DD'),
    eDte: moment(new Date()).format('YYYY-MM-DD'),
    desc: '',
  });

  const toggleZoomCert = () => {
    setIsZoom(!isZoom);
  };

  const handleCertText = () => {
    setIsCertTxt(!isCertTxt);
  };

  const handleCertDELETE = (editDetails: CertData) => {
    setMyDelCert({
      ...myDelCert, id: editDetails.id, nme: editDetails.nme, certF: editDetails.certF, ops: 'DELETE',
    });
    setIsDeleteConfirmed(true);
  };
  const handleConfirmDelete = (status: string) => {
    if (status === 'DEL') {
      dispatch(getCert({
        inputBody: myDelCert,
        requestType: APIHeader.REQ_UPDATE_CERT,
        uTp: UserType.CMPS,
        token: tokenData,
      }));
    } else {
      setIsDeleteConfirmed(false);
      setMyDelCert({
        ...myDelCert,
        id: '',
        ops: '',
        nme: '',
        certF: '',
        sDte: moment(new Date()).format('YYYY-MM-DD'),
        eDte: moment(new Date()).format('YYYY-MM-DD'),
        desc: '',
      });
    }
  };

  React.useEffect(() => {
    if (certResponse.error && certResponse.message !== '' && resumeTp === 'list-cert' && rootResumeTp === 'resume-act') {
      setLoaderResume(false);
      setAlertMsg(certResponse.message);
      setErrorShowAlert(true);
      setIsDeleteConfirmed(false);
    }
  }, [certResponse.error, certResponse.message, rootResumeTp, resumeTp]);

  React.useEffect(() => {
    if (!certResponse.error && certResponse.message !== '' && resumeTp === 'list-cert' && rootResumeTp === 'resume-act' && isDELApiCld === '') {
      setLoaderResume(false);
      setIsDeleteConfirmed(false);
      setAlertMsg(certResponse.message);
      dispatch(clearCert());
      Promise.resolve(
        setMyDelCert({
          id: '',
          ops: '',
          nme: '',
          certF: '',
          sDte: moment(new Date()).format('YYYY-MM-DD'),
          eDte: moment(new Date()).format('YYYY-MM-DD'),
          desc: '',
        }),
      ).then(() => {
        setIsDELApiCld('EXECUTED');
      }).then(() => {
        setSuccessShowAlert(true);
      });
    }
  }, [certResponse.error, certResponse.message, rootResumeTp, resumeTp, isDELApiCld]);

  return (
    <div className="my-2">
      <Card className="card-shadow">
        <Row className="my-2 mx-2">
          <Col xs="12">
            {certDetails.certF === ''
              ? (
                <CardImg
                  alt="..."
                  className="cert-image-list-icon"
                  src="/images/common/medal.png"
                />
              )
              : (
                <CardImg
                  alt="..."
                  className="cert-image-list-icon"
                  src={certDetails.certUrl}
                />
              ) }
            <span className="input-header-text pt-2 ml">{certDetails.nme}</span>
          </Col>
          <Col xs="12">
            <div className="pt-2 ml-1">
              <span className="dashboard-thread-text mx-1">
                {isCertTxt ? `${certDetails.desc}` : `${certDetails.desc.slice(0, maxLength)}`}
                {certDetails.desc.length > maxLength && (
                  isCertTxt ? (
                    <Button onClick={handleCertText} className="mx-1 dashboard-button-less">
                      show less
                    </Button>
                  ) : (
                    <Button onClick={handleCertText} className="mx-1 dashboard-button-more">
                      show more
                    </Button>
                  )
                )}
              </span>
            </div>
          </Col>
          <Col lg="12" xs="12">
            <Row className="align-center">
              <Col lg="4" xs="12">
                <div className="align-center pt-3">
                  <CardImg
                    alt="..."
                    className="cert-all-image-list cursor-pointer"
                    src={certDetails.certUrl}
                    onClick={toggleZoomCert}
                  />
                </div>
                <Modal
                  show={isZoom || false}
                  onClose={toggleZoomCert}
                  disCancel={false}
                  targetId="zoomCertId"
                  title={(
                    <div className="mx-1 my-2">
                      <span className="input-header-text pt-2 ml">{certDetails.nme}</span>
                    </div>
                    )}
                  size="XL"
                >
                  <CardImg
                    alt="..."
                    src={certDetails.certUrl}
                  />
                </Modal>
              </Col>
              <Col lg="6" xs="12">
                <Row className="align-center">
                  <Col lg="6" xs="6">
                    <div className="align-center">
                      <span className="input-checkbox-text-grey">{moment(certDetails.sDte).format('DD-MMM-YYYY')}</span>
                    </div>
                    <div className="align-center">
                      <span className="text-lite-grey">Valid From</span>
                    </div>
                  </Col>
                  <Col lg="6" xs="6">
                    <div className="align-center">
                      <span className="input-checkbox-text-grey">{moment(certDetails.eDte).format('DD-MMM-YYYY')}</span>
                    </div>
                    <div className="align-center">
                      <span className="text-lite-grey">Valid Till</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <div className="align-end mb-1">
              <Button className="button-icon mx-1" onClick={() => handleCertDELETE(certDetails)}>
                DELETE
              </Button>
              <Button className="campus-button-all" id={`editId${index.toString()}`} onClick={() => handleEditStatus(certDetails)}>
                EDIT <FontAwesomeIcon icon={faPenToSquare} />
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
      <Modal
        show={isDeleteConfirmed || false}
        onClose={() => (false)}
        disCancel
        targetId="delCert"
        title=""
        size="SM"
      >
        <div className="my-2 align-center mx-4">
          <span>Do you really want to delete ?</span>
        </div>
        <div className="my-2 align-center mx-4">
          <span>{certDetails.nme}</span>
        </div>
        <div className="align-center mx-4">
          <CardImg
            alt="..."
            className="cert-image-list"
            src={certDetails.certUrl}
          />
        </div>
        <div className="align-center my-3 pt-3">
          <Button className="button-icon" onClick={() => handleConfirmDelete('DEL')}>
            DELETE
          </Button>
          <Button className="campus-button-all" onClick={() => handleConfirmDelete('CANCEL')}>
            CANCEL
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ListCert;
