import React from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Col, Row,
} from 'reactstrap';
import { clearJobByDetailsReq, getLoginToken, jobByDetailsReq } from '../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../utils/constants';
import { LoaderData } from '../../../utils/loader';
import { JobsResult } from '../../../services/staffing/job-board/get-job-details/job-details.types';
import JobPublicDetails from './job-details';
import CXJobsNavbar from '../navbar/cx-jobs.navbar';

const PublicJobShare: React.FC = () => {
  const dispatch = useDispatch();
  const { jobCd, jobId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const getJobDetailsResponse = useSelector((state: RootState) => state.campusXcel.getJobByDetails);
  const [jobDetails, setJobDetails] = React.useState<JobsResult>({
    jobSts: '',
    pStg: '',
    JobCd: '',
    JobId: '',
    forClnt: '',
    nme: '',
    cNme: '',
    pCd: '',
    wrkPref: '',
    city: '',
    st: '',
    logoUrl: '',
    jobTp: [],
    schdl: '',
    oSchdl: '',
    isPlndDt: false,
    plndDt: '',
    vacncy: 0,
    timelne: '',
    payBy: '',
    amnt: '',
    amntTp: '',
    suppPay: [],
    oSuppPay: [],
    perk: [],
    oPerk: [],
    expR1: 0,
    expR2: 0,
    jdFile: '',
    jdFileUrl: '',
    jDCntPh: '',
    dailUpdEml: '',
    potCndEml: '',
    jobKey: [],
    isCV: false,
    updtOn: '',
    isFemale: '',
    isAbled: '',
    desg: '',
    skills: [],
    oSkills: [],
  });

  React.useEffect(() => {
    if (jobCd !== '' && jobId !== '') {
      setLoading(true);
      dispatch(getLoginToken({
        userName: '',
        passKey: '',
        isPublic: true,
        uTp: UserType.CXAPI,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  }, [jobCd, jobId]);

  React.useEffect(() => {
    if (loginTokenData.message !== '' && jobCd && jobId) {
      if (loginTokenData.isError) {
        setLoading(false);
      } else if (loginTokenData.token && jobCd && jobId) {
        setLoading(true);
        dispatch(jobByDetailsReq({
          requestType: APIHeader.REQ_GET_JOB_DETAILS_BY_ID,
          uTp: UserType.CXAPI,
          token: loginTokenData.token,
          jobCd,
          jobId,
        }));
      }
    }
  }, [loginTokenData, jobCd, jobId]);

  React.useEffect(() => {
    if (getJobDetailsResponse.isError && getJobDetailsResponse.message !== '') {
      setLoading(false);
      dispatch(clearJobByDetailsReq());
    }
  }, [getJobDetailsResponse.isError, getJobDetailsResponse.message]);

  React.useEffect(() => {
    if (!getJobDetailsResponse.isError && getJobDetailsResponse.message === 'executed') {
      setLoading(false);
      dispatch(clearJobByDetailsReq());
      setJobDetails(getJobDetailsResponse.data);
    }
  }, [getJobDetailsResponse.isError, getJobDetailsResponse.message]);

  return (
    <div>
      {loading ? <LoaderData /> : null}
      <CXJobsNavbar />
      <Row className="align-center pt-5">
        <Col lg="8" xs="12">
          <div className="pub-jobs-dmin-height1 pt-5"><JobPublicDetails jobDetails={jobDetails} /></div>
        </Col>
        {/* <Col lg="4" xs="11" />
        Advertisement here */}
      </Row>
    </div>
  );
};
export default PublicJobShare;
