import React from 'react';
import Creatable from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Label, Input, Button,
} from 'reactstrap';
import { MultiValue } from 'react-select';
import { ResumeSkillsDetails, ResumeSkillsInput } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';
import { AllMETAResult } from '../../../../../services/common/metadata-db/list-meta.types';
import { listMETAReq, clearListMETAReq } from '../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../utils/constants';
import { errorAlert } from '../../../../../utils/alert';

export type ResumeInfoProps = {
  tokenData: string;
  resumeSkills: ResumeSkillsInput;
  setResumeSkills: (updater: (prevState: ResumeSkillsInput) => ResumeSkillsInput) => void;
};

export const XcelianSkillsInfo: React.FC<ResumeInfoProps> = ({
  tokenData,
  resumeSkills,
  setResumeSkills,
}) => {
  const dispatch = useDispatch();
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [loadingMeta, setLoadingMeta] = React.useState(false);
  const [skillListData, setSkillListData] = React.useState<AllMETAResult[]>([]);
  const getMetaInfoResponse = useSelector((state: RootState) => state.campusXcel.listMetaData);

  const handleGetSkillChange = (inputValue: string) => {
    if (inputValue.length > 2) {
      setLoadingMeta(true);
      dispatch(listMETAReq({
        searchBy: inputValue,
        isSearch: 'YES',
        limit: 30,
        requestType: APIHeader.REQ_ALL_METADATA,
        uTp: UserType.RCRT,
        metaTp: 'SKILL',
        token: tokenData,
      }));
    }
  };

  React.useEffect(() => {
    if (!getMetaInfoResponse.isError && getMetaInfoResponse.message === 'executed') {
      setLoadingMeta(false);
      setSkillListData((prevData) => {
        const existingNames = new Set(prevData.map((record) => record.nme));
        const uniqueNewData = getMetaInfoResponse.data.filter((record) => !existingNames.has(record.nme));
        return [...prevData, ...uniqueNewData];
      });
    }
    dispatch(clearListMETAReq());
  }, [getMetaInfoResponse]);

  const addSkillDetails = () => {
    setResumeSkills((prevState) => {
      const skills = Array.isArray(prevState.skills) ? prevState.skills : [];
      const lastSkill = skills[skills.length - 1];

      if (lastSkill && (lastSkill.sklName.trim() === '' || !Array.isArray(lastSkill.skillData))) {
        setAlertMsg('Some fields are empty. Kindly fill them before adding the next skill entry.');
        setErrorShowAlert(true);
        return prevState; // Return unchanged state if validation fails
      }

      const newSkillEntry: ResumeSkillsDetails = {
        sklName: '',
        skillData: [], // Start with an empty array
      };

      return {
        ...prevState,
        skills: [...skills, newSkillEntry], // Add the new skill entry
      };
    });
  };

  const handleSelectSkillData = (selectedVal: MultiValue<{ label: string; value: string; }> | null, index: number) => {
    setResumeSkills((prevState) => {
      const newSkillData = [...prevState.skills];
      newSkillData[index].skillData = selectedVal ? selectedVal.map((option) => option.value) : [];
      return { ...prevState, skills: newSkillData };
    });
  };

  // Handle creating new skills
  const handleCreateSkill = (newSkill: string, index: number) => {
    setResumeSkills((prevState) => {
      const newSkillData = [...prevState.skills];
      // Add new skill to the skillData of the current index
      if (newSkill) {
        newSkillData[index].skillData = [...newSkillData[index].skillData, newSkill];
      }
      return { ...prevState, skills: newSkillData };
    });
  };

  return (
    <div>
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {(resumeSkills.skills || []).map((skill, index) => {
        const skillNameoptions = skillListData.map((skillDet) => ({
          label: `${skillDet.nme}`,
          value: `${skillDet.nme}`,
        }));

        return (
          <div key={`${index.toString()}`}>
            <Row className="lang-border align-center mb-2">
              <Col xs="12" lg="11">
                <Label for={`sklName-${index}`} className="text-lite ml">Skill Type</Label>
                <Input
                  type="text"
                  id={`sklName-${index}`}
                  value={skill.sklName}
                  onChange={(e) => {
                    const updatedSkills = [...(resumeSkills.skills || [])];
                    updatedSkills[index] = {
                      ...updatedSkills[index],
                      sklName: e.target.value, // Update sklName for the specific index
                    };
                    setResumeSkills((prevState) => ({
                      ...prevState,
                      skills: updatedSkills,
                    }));
                  }}
                  className="campus-input"
                />
              </Col>
              <Col xs="12 pt-2" lg="11">
                <Label for={`skill-${index}`} className="text-lite ml">Skills</Label>
                <Creatable
                  key="skills"
                  options={skillNameoptions}
                  isClearable
                  onInputChange={handleGetSkillChange}
                  onChange={(e) => handleSelectSkillData(e, index)}
                  onCreateOption={(newSkill) => handleCreateSkill(newSkill, index)} // Add this line for creating new skills
                  value={skill.skillData.map((skillDet) => ({ label: skillDet, value: skillDet }))} // Ensure correct format
                  isMulti
                  menuPortalTarget={document.body} // Brings Menu onTop
                  styles={{
                    multiValue: (provided) => ({
                      ...provided,
                      background: '#f6eeeb',
                      padding: '1px',
                    }),
                    control: (base) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '70px',
                      borderRadius: '10px',
                      padding: 1,
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  isLoading={loadingMeta}
                  noOptionsMessage={() => 'No Skills Found'}
                  placeholder="Enter or Add New Skills"
                />
              </Col>
              <div className="pt-2" />
            </Row>
          </div>
        );
      })}
      <div className="align-center pt-4">
        <Button className="campus-button-all" onClick={addSkillDetails}>Add New Skill Set</Button>
      </div>
    </div>
  );
};

export default XcelianSkillsInfo;
