import { ListEnquiryResponse, ListEnquiryResults } from './list.enquiry.service.types';

export const ListEnquiryTransform: (response: ListEnquiryResponse) => ListEnquiryResults = (listEnquiryResults) => {
  const { data, is_error, message } = listEnquiryResults;
  const result: ListEnquiryResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        ContTp: '',
        ContId: '',
      },
      contactData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
