import { ClientAddResponse, ClientAddResults } from './add-new-clients.service.types';

export const ClientAddTransform: (response: ClientAddResponse) => ClientAddResults = (batchResults) => {
  const { data, is_error, message } = batchResults;
  const result :ClientAddResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data;
  }
  return result;
};
