import { NewsletterPutResponse, NewsletterPutResults } from './update.newsletter.service.types';

export const NewsletterPutTransform: (response: NewsletterPutResponse) => NewsletterPutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :NewsletterPutResults = {
    message: '',
    error: false,
    fileData: {
      MediaTp: '',
      MediaId: '',
      fileHtmlDesc: '',
      logoFileName: '',
    },
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.fileData = data;
    result.message = 'executed';
  }
  return result;
};
