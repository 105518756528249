import { ShareUrlInterviewResponse, ShareUrlInterviewResults } from './share.url.interview.service.types';

export const ShareUrlInterviewTransform: (response: ShareUrlInterviewResponse) => ShareUrlInterviewResults = (listPJobsResults) => {
  const { data, is_error, message } = listPJobsResults;
  const result: ShareUrlInterviewResults = {
    data: '',
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
