import { SubscriberPutResponse, SubscriberPutResults } from './update.subscriber.service.types';

export const SubscriberPutTransform: (response: SubscriberPutResponse) => SubscriberPutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :SubscriberPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
