import React from 'react';
import { ListCampusProps } from '../onboard.type';
import CXTable from '../../../../../utils/CXTable/cx.table';

export const AllCampusUserList: React.FC<ListCampusProps> = ({
  pagedData, currentPage, recordsPerPage, totalRecords, handlePageChange, setEditRecord, setDeleteRecord,
}) => {
  const columns = [
    { header: 'User ID', accessor: 'UserCd' },
    { header: 'C-User', accessor: '' },
    { header: 'C-Phone', accessor: '' },
    { header: 'Email', accessor: 'eId' },
    { header: 'Client', accessor: 'cCd' },
    { header: 'Action', accessor: '' },
  ];
  return (
    <div>
      <CXTable
        tableId="cmps-user"
        columns={columns}
        data={pagedData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        setEditRecord={setEditRecord}
        setDeleteRecord={setDeleteRecord}
        setOtherObj={() => (false)}
        oSts=""
      />
    </div>
  );
};

export default AllCampusUserList;
