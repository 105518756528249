import { RegisterXCLNResponse, RegisterXCLNResults } from './register.service.types';

export const RegisterXCLNTransform: (response: RegisterXCLNResponse) => RegisterXCLNResults = (registerXCLNResults) => {
  const { data, is_error, message } = registerXCLNResults;
  const result: RegisterXCLNResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
