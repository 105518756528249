import { GetS3CxMetaResponse, GetS3CxMetaResults } from './get-s3-cx-meta.service.types';

export const GetS3CxMetaTransform: (response: GetS3CxMetaResponse) => GetS3CxMetaResults = (getMetaResults) => {
  const { success, data, message } = getMetaResults;
  const result: GetS3CxMetaResults = {
    success: false,
    data: [],
    message: '',
  };
  if (!success) {
    result.success = success;
    result.message = message;
  } else {
    result.success = success;
    result.message = message;
    result.data = data;
  }
  return result;
};
