import { UpdateS3CxMetaResponse, UpdateS3CxMetaResults } from './update-s3-cx-meta.service.types';

export const UpdateS3CxMetaTransform: (response: UpdateS3CxMetaResponse) => UpdateS3CxMetaResults = (metaResults) => {
  const { success, message } = metaResults;
  const result: UpdateS3CxMetaResults = {
    success: false,
    message: '',
  };
  result.success = success;
  result.message = message;
  return result;
};
