import { faUsps } from '@fortawesome/free-brands-svg-icons';
import {
  faNewspaper,
} from '@fortawesome/free-regular-svg-icons';
// faPaperPlane,
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardImg,
  Col, Input, Row,
} from 'reactstrap';
import '../../campus.scss';
import Published from './published/published';
import { ForAddCollegeProps } from './college.type';
import CreatePost from './post/create-post';
import { getUserProfileLocalStorage } from '../../../../../utils/service/localstorage-service';
import EditorComponent from '../../campus-user/dashboard/industry/editor/editor.component';

const College: React.FC<ForAddCollegeProps> = ({ myPost, setPrevPostDataLengthRef }) => {
  const [published, setPublished] = useState(true);
  const userProfile = getUserProfileLocalStorage();
  const [article, setArticle] = useState(false);
  const [post, setPost] = useState(false);
  const [acadId, setAcadId] = useState('');
  const [shared, setShared] = useState(false);
  const [draft, setDraft] = useState(false);
  const handleShowPublished = () => {
    setPublished(true);
    setArticle(false);
    setPost(false);
    setShared(false);
    setDraft(false);
  };
  // const handleShowShared = () => {
  //   setShared(true);
  //   setPublished(false);
  //   setArticle(false);
  //   setPost(false);
  //   setDraft(false);
  // };
  // const handleShowDraft = () => {
  //   setDraft(true);
  //   setPublished(false);
  //   setArticle(false);
  //   setPost(false);
  //   setShared(false);
  // };
  const handleShowArticle = () => {
    setArticle(true);
    setPublished(false);
    setPost(false);
    setShared(false);
    setDraft(false);
  };
  const handleShowPost = () => {
    setPost(true);
    setPublished(false);
    setArticle(false);
    setShared(false);
    setDraft(false);
  };

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.cmpsCd !== '') {
      setAcadId(userProfile && JSON.parse(userProfile).profileData.cmpsCd);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.cmpsCd]);

  return (
    <div className="mx-1">
      <Card className="dasboard-form-card">
        <Row>
          <Col lg="9" xs="8">
            <div className="align-center pt-1">
              <Button className="button-icon" onClick={handleShowPublished}>
                <CardImg src="/images/icons/3d-house.png" className="dashboard-home-icon mb" alt="Home Icon" />
              </Button>
              <Input
                placeholder="search..."
                className="campus-input-search"
              />
            </div>
          </Col>
          {userProfile && JSON.parse(userProfile).profileData.isAdm ? (
            <Col lg="3" xs="4">
              <div className="align-end pt-1">
                <Button className="button-icon" onClick={handleShowArticle}>
                  <span className="text-lite-grey">
                    <FontAwesomeIcon className="quest-menu-icon mx-1" icon={faNewspaper} />
                    Article
                  </span>
                </Button>
                <Button className="button-icon" onClick={handleShowPost}>
                  <span className="text-lite-grey">
                    <FontAwesomeIcon className="quest-menu-icon mx-1" icon={faUsps} />
                    Post
                  </span>
                </Button>
              </div>
            </Col>
          ) : (
            <Col lg="3" xs="4">
              <div className="align-end pt-1">
                <Button className="button-icon" onClick={handleShowPost}>
                  <span className="text-lite-grey">
                    <FontAwesomeIcon className="quest-menu-icon mx-1" icon={faUsps} />
                    Post
                  </span>
                </Button>
              </div>
            </Col>
          )}
          {/* <Button className="button-icon" onClick={handleShowShared}>
                <span className="text-lite-grey">
                  <FontAwesomeIcon className="quest-menu-icon mx-1" icon={faPaperPlane} />
                  Shared
                </span>
              </Button> */}
        </Row>
      </Card>
      {published ? <Published myPost={myPost} academyId={acadId} userAdmin={userProfile && JSON.parse(userProfile).profileData.isAdm} /> : null}
      {draft ? 'Drafteeee' : null}
      {shared ? 'Shareeee' : null}
      {article ? <EditorComponent /> : null}
      {post ? <CreatePost setPrevPostDataLengthRef={setPrevPostDataLengthRef} /> : null}
      <div className="pt-4" />
    </div>
  );
};

export default College;
