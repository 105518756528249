import { ClientUserAddResponse, ClientUserAddResults } from './add-new-client-user.service.types';

export const ClientUserAddTransform: (response: ClientUserAddResponse) => ClientUserAddResults = (batchResults) => {
  const { data, is_error, message } = batchResults;
  const result :ClientUserAddResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data;
  }
  return result;
};
