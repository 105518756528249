import { JobByIdResponse, JobByIdResults } from './job-by-id.service.types';

export const JobByIdTransform: (response: JobByIdResponse) => JobByIdResults = (jobPositionByIdResults) => {
  const { data, is_error, message } = jobPositionByIdResults;
  const result :JobByIdResults = {
    data: {
      pStg: '',
      JobCd: '',
      JobId: '',
      nme: '',
      pCd: '',
      wrkPref: '',
      city: '',
      st: '',
      addr: '',
      jobTp: [],
      jobKey: [],
      isCV: false,
      isAppTlne: false,
      appTlne: '',
      schdl: '',
      oSchdl: '',
      isPlndDt: false,
      plndDt: '',
      vacncy: 0,
      timelne: '',
    },
    isError: false,
    message: '',
  };

  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
