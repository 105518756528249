import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import {
  Row, Col, Input, Button, InputGroup, NavLink, Form,
  CardImg,
} from 'reactstrap';
import './login.scss';
import { RootState } from 'typesafe-actions';
import React, { useState } from 'react';
import { getForgotPassword, getLoginToken, getProfile } from '../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../utils/constants';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { LoaderData } from '../../../../utils/loader';
import { setTokenToLocalStorage, setUserProfileLocalStorage } from '../../../../utils/service/localstorage-service';
// import { LoaderData } from '../../../utils/loader';

export const LoginCampusXcel: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isOTP, setIsOTP] = React.useState(false);
  const [isPassword, setIsPassword] = React.useState(true);
  const [loginOTP, setLoginOTP] = React.useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [loginPassKey, setLoginPassKey] = useState('');
  const [loginUserName, setLoginUserName] = useState('');
  const [inputOTP, setInputOTP] = useState('');
  const [loginOTPUserName, setLoginOTPUserName] = useState('');
  const [forgotUserName, setForgotUserName] = useState('');
  const [isOTPAll, setIsOTPAll] = useState(true);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const loginProfileData = useSelector((state: RootState) => state.campusXcel.getProfileData);
  const forgotPasswordData = useSelector((state: RootState) => state.campusXcel.forgotPassKeyData);

  const registerPage = () => {
    history('/campus/register');
    window.scrollTo(0, 0);
  };
  // const handleUserType = () => {
  //   setUserType(!isUserType);
  // };
  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setIsOTP(false);
    setIsPassword(false);
    setLoginOTP(false);
  };
  const handleSwitchToPassword = () => {
    setIsForgotPassword(false);
    setIsOTP(false);
    setIsPassword(true);
    setLoginOTP(false);
  };
  const handleSwitchToOTP = () => {
    setIsForgotPassword(false);
    setIsOTP(true);
    setIsPassword(false);
    setLoginOTP(false);
  };
  const handlePassKeyChange = (e: any) => {
    setLoginPassKey(e.target.value);
  };
  const handleUserNameChange = (e: any) => {
    setLoginUserName(e.target.value);
  };
  const handleOTPUserNameChange = (e: any) => {
    setLoginOTPUserName(e.target.value);
  };
  const handleForgotUserNameChange = (e: any) => {
    setForgotUserName(e.target.value);
  };

  const handleOTPChange = (e: any) => {
    setInputOTP(e);
    if (e.toString().length === 4 && e.toString().match(/^\d{4}$/) !== null) {
      setIsOTPAll(false);
    } else {
      setIsOTPAll(true);
    }
  };
  const handlePasswordSubmit = async (e:any) => {
    e.preventDefault();
    if (loginUserName !== '' && loginPassKey !== '') {
      setIsLoader(true);
      dispatch(getLoginToken({
        userName: loginUserName,
        passKey: loginPassKey,
        isPublic: false,
        uTp: UserType.CMPS,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  };
  React.useEffect(() => {
    if (loginTokenData.isError && loginTokenData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginTokenData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginTokenData.message, loginTokenData.isError]);

  const handleSubmitOTP = async (e:any) => {
    e.preventDefault();
    if (loginOTPUserName !== '') {
      if (!isLoader) {
        setIsLoader(!isLoader);
      }
      dispatch(getLoginToken({
        userName: loginOTPUserName,
        uTp: UserType.CMPS,
        isPublic: false,
        requestType: APIHeader.REQ_GENERATE_OTP,
        passKey: '',
      }));
    }
  };

  const handleLoginByOTP = () => {
    if (!isLoader) {
      setIsLoader(!isLoader);
    }
    dispatch(getLoginToken({
      userName: loginOTPUserName,
      isPublic: false,
      uTp: UserType.CMPS,
      requestType: APIHeader.REQ_VERIFY_OTP,
      passKey: inputOTP,
    }));
  };
  const handleForgotPasskeySubmit = (e:any) => {
    e.preventDefault();
    setIsLoader(!isLoader);
    dispatch(getForgotPassword({
      userName: forgotUserName,
      uTp: UserType.CMPS,
      requestType: APIHeader.REQ_FORGOT_PASSWORD,
      token: loginTokenData.token,
    }));
  };

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.token !== '') {
      setIsLoader(false);
      if (isOTP && loginTokenData.token === 'OTP Mail sent successfully') {
        setIsForgotPassword(false);
        setIsOTP(false);
        setIsPassword(false);
        setLoginOTP(true);
      } else if (!isForgotPassword && (loginPassKey !== '' || inputOTP !== '')) {
        dispatch(getProfile({
          uTp: UserType.CMPS,
          requestType: APIHeader.REQ_USER_PROFILE_INFO,
          token: loginTokenData.token,
        }));
      }
      setTokenToLocalStorage(loginTokenData.token);
    }
  }, [loginTokenData.token, loginTokenData.isError]);

  // React.useEffect(() => {
  //   if (!loginTokenData.isError && loginTokenData.token !== '') {
  //     setIsLoader(false);
  //     if (loginTokenData.token === 'OTP Mail sent successfully') {
  //       setIsForgotPassword(false);
  //       setIsOTP(false);
  //       setIsPassword(false);
  //       setLoginOTP(true);
  //     } else if (!isForgotPassword) {
  //       setTokenToLocalStorage(loginTokenData.token);
  //       dispatch(getProfile({
  //         uTp: UserType.CMPS,
  //         requestType: APIHeader.REQ_USER_PROFILE_INFO,
  //         token: loginTokenData.token,
  //       }));
  //     }
  //   }
  // }, [loginTokenData.token, loginTokenData.isError]);

  React.useEffect(() => {
    if (loginProfileData.isError && loginProfileData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginProfileData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginProfileData.message, loginProfileData.isError]);

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message !== '') {
      setIsLoader(false);
      setUserProfileLocalStorage(JSON.stringify(loginProfileData));
      if (!loginProfileData.profileData.isAdm && loginProfileData.profileData.UserTp === 'CMPS') {
        history('/campus/exclusive/dashboard');
      } else if (loginProfileData.profileData.isAdm && loginProfileData.profileData.UserTp === 'CMPS') {
        history('/cx/admin/dashboard');
      } else {
        history('/unauthorized');
      }
    }
  }, [loginProfileData.message, loginProfileData.isError]);

  React.useEffect(() => {
    if (forgotPasswordData.isError && forgotPasswordData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(forgotPasswordData.message);
    }
  }, [forgotPasswordData.message, forgotPasswordData.isError]);

  React.useEffect(() => {
    if (!forgotPasswordData.isError && forgotPasswordData.forgotPasswordData !== '') {
      setIsLoader(false);
      setSuccessShowAlert(true);
      setAlertMsg(forgotPasswordData.forgotPasswordData);
    }
  }, [forgotPasswordData.isError, forgotPasswordData.forgotPasswordData]);

  return (
    <div className="xcelian-login-bg">
      { isLoader ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(true, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <NavLink href="/campus" to="/campus" className="nav-logo">
        <CardImg
          style={{
            height: '45px', width: 'auto',
          }}
          src="/campusXcel.png"
          alt="campusXcelLogo"
        />
      </NavLink>
      <div className="my-4">
        <div className="align-center mx-3">
          <span className="form-header-text">
            {isForgotPassword ? 'Campus - Reset Password' : 'Campus Login'}
          </span>
        </div>
        <Row className="align-center margin-mob-top">
          <Col lg={{ size: 3, order: 1 }} xs={{ size: 11, order: 2 }}>
            <div className="mx-2">
              <CardImg
                src="/images/campus/login-campus.png"
                alt="menu-logo"
                className="mx-1"
              />
            </div>
          </Col>
          <Col lg={{ size: 4, order: 2 }} xs={{ size: 11, order: 1 }}>
            <div>
              {isPassword
                ? (
                  <div className="pt-1 mx-1">
                    <Form onSubmit={handlePasswordSubmit} className="mx-2">
                      <div className="text-right-login-campus pt-5">
                        <div className="align-end mr-1">
                          <Button
                            onClick={handleSwitchToOTP}
                            className="button-icon"
                            id="otp"
                          >
                            <span className="campus-file-link">Login by OTP</span>
                          </Button>
                        </div>
                        <Input
                          placeholder="Email Id / Phone No."
                          type="text"
                          required
                          className="campus-input"
                          value={loginUserName}
                          onChange={handleUserNameChange}
                        />
                      </div>
                      <div className="text-right-login-campus pt-4">
                        <InputGroup>
                          <Input
                            placeholder="Password"
                            type="password"
                            // type={passwordShown ? 'text' : 'password'}
                            required
                            value={loginPassKey}
                            onChange={handlePassKeyChange}
                            className="campus-input"
                          />
                          {/* <InputGroupText className="campus-input cursor-pointer mx-1" onClick={handleClickShowPassword}>
                            {passwordShown
                              ? (<FontAwesomeIcon icon={faEye} className="pwd-eye-icon" />)
                              : (<FontAwesomeIcon icon={faEyeSlash} className="pwd-eye-close-icon" />
                              )}
                          </InputGroupText> */}
                        </InputGroup>
                      </div>
                      <div className="align-end my-3">
                        <NavLink className="text-lite-grey-sub cursor-pointer mx-3" to="#" onClick={handleForgotPassword}>Forgot password ?</NavLink>
                      </div>
                      <div className="pt-4 mx-2">
                        <Button className="campus-button">
                          LOGIN
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
            </div>
            <div>
              {isOTP
                ? (
                  <div className="pt-2">
                    <Form onSubmit={handleSubmitOTP}>
                      <div className="text-right-login-campus pt-5">
                        <div className="align-end mr-1">
                          <Button
                            onClick={handleSwitchToPassword}
                            className="button-icon"
                            id="passkey"
                          >
                            <span className="campus-file-link">Login by Password</span>
                          </Button>
                        </div>
                        <Input
                          placeholder="Email Id / Phone No."
                          type="text"
                          required
                          className="campus-input"
                          onChange={handleOTPUserNameChange}
                        />
                      </div>
                      <div className="align-end pt-2 mx-2">
                        <NavLink className="forgot-password-text cursor-pointer mx-3" to="#" onClick={handleForgotPassword}>Forgot password ?</NavLink>
                      </div>
                      <div className="pt-4 mx-2">
                        <Button className="campus-button">
                          OTP
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
            </div>
            <div>
              {isForgotPassword
                ? (
                  <div className="pt-5">
                    <Form onSubmit={handleForgotPasskeySubmit}>
                      <div className="text-right-login-campus pt-5">
                        <Input
                          placeholder="Email Id / Phone No."
                          type="text"
                          required
                          value={forgotUserName}
                          onChange={handleForgotUserNameChange}
                          className="campus-input"
                        />
                      </div>
                      <div className="align-end pt-2 mx-2">
                        <NavLink className="text-lite-grey-sub cursor-pointer mx-1" to="#" onClick={handleSwitchToPassword}> Back to login ?</NavLink>
                      </div>
                      <div className="text-right-login-campus align-end pt-4 mx-2">
                        <Button className="campus-button mx-1" disabled={!(forgotUserName !== '')}>
                          SUBMIT
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
            </div>
            <div>
              {loginOTP
                ? (
                  <div className="pt-2">
                    <div className="text-right-login-campus mx-3">
                      <div className="align-center pt-3">
                        <h6 className="dashboard-thread-text">Enter verification code, sent on your email</h6>
                      </div>
                      <div className="align-center pt-3">
                        <OtpInput
                          value={inputOTP}
                          onChange={handleOTPChange}
                          numInputs={4}
                          separator={(
                            <span> </span>
                              )}
                          focusStyle={{
                            border: '1px solid #8B0000',
                            outline: 'none',
                          }}
                          inputStyle={{
                            width: '2.7rem',
                            height: '2.7rem',
                            margin: '0.5rem',
                            fontSize: '1.2rem',
                            borderRadius: 4,
                            border: '1px solid #DDE0E4',
                          }}
                        />
                      </div>
                    </div>
                    <div className="pt-4 mx-3">
                      <Button disabled={isOTPAll} className="campus-button" onClick={handleLoginByOTP}>
                        LOGIN
                      </Button>
                    </div>
                  </div>
                ) : null}
            </div>
            <div className="align-center pt-2">
              <span className="mx-3 text-lite-grey">Don&apos;t have an account ? <Button className="button-transparent" onClick={registerPage}>Sign Up </Button></span>
            </div>
            <div className="align-center">
              <span className="mx-1 text-lite-grey">Terms &amp; Use</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default LoginCampusXcel;
