import { getPublicJSON, getCommonApiToken } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { ListClientUserOptions, ListClientUserResponse } from './list-client-user.service.types';

const { CAMPUSXCEL_SADMIN_API_ROOT } = getConfig();

export const ListClientUserRequest = ({
  limit, evalKey, evalTp, searchBy, isSearch, isFilter, filterBy, clntTp, isMeta, uTp, requestType, token, clntSts,
}: ListClientUserOptions): Promise<ListClientUserResponse> => {
  if (isMeta) {
    return getPublicJSON(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&clntTp=${clntTp}&clntSts=${clntSts}&isMeta=YES`, requestType);
  } return getCommonApiToken(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&clntTp=${clntTp}&clntSts=${clntSts}&isMeta=NO`, requestType, token);
};
