import React from 'react';
import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import '../market.scss';
import ContactForm from '../../contact-form/contact-form';
import { WDSFooter } from '../../home/footer';

export const QallAuditDesc: React.FC = () => {
  console.log('');
  return (
    <div className="mx-1">
      <Row>
        <Col xs="12" lg="12">
          <Row>
            <Col xs="4" lg="2">
              <div className="align-start pt-2">
                <CardImg src="/images/marketplace/QallAuditLogo.png" alt="qall-audit" className="logo-market-nav" />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="12">
          <Row className="align-center">
            <Col lg="5" xs="12">
              <div className="text-center-aligned">
                <span className="text-qual-market-home">AI-powered system designed to revolutionize your customer call quality assessment</span>
              </div>
              <div className="pt-4 text-center-aligned">
                <span className="text-qual-market-grey">
                  Achieve superior call quality assurance, ensuring compliance and service excellence with precision monitoring
                </span>
              </div>
            </Col>
            <Col lg="5" xs="12">
              <div className="align-center">
                <CardImg src="/images/marketplace/qall-home.jpg" className="logo-market-home-qall" alt="qall-audit-home" />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="12">
          <Row>
            <Col xs="12" lg="12">
              <div className="align-center pt-5">
                <span className="text-intro-header-market">Key Features</span>
              </div>
            </Col>
            <Col xs="12" lg="12">
              <div className="pt-5">
                <Row className="mx-1">
                  <Col xs="12 my-4" lg="4">
                    <div className="align-center">
                      <CardImg src="/images/marketplace/call-vis.png" alt="call-vis" className="logo-market-key mr-1" />
                      <span className="text-qall-key-header-market">Total Call Visibility</span>
                    </div>
                    <div className="align-text-justify pt-3 mx-3">
                      <span className="text-qual-market-grey">Monitor Every Interaction Ensure complete oversight with 100% call analysis, empowering you to capture every detail and make data-driven decisions.</span>
                    </div>
                  </Col>
                  <Col xs="12 my-4" lg="4">
                    <div className="align-center">
                      <CardImg src="/images/marketplace/call-support.png" alt="call-support" className="logo-market-key mr-1" />
                      <span className="text-qall-key-header-market">Performance Scoring for Operators</span>
                    </div>
                    <div className="align-text-justify pt-3 mx-3">
                      <span className="text-qual-market-grey">Drive Agent Excellence Objective scoring to assess and boost operator performance, helping you pinpoint strengths and areas for improvement.</span>
                    </div>
                  </Col>
                  <Col xs="12 my-4" lg="4">
                    <div className="align-center">
                      <CardImg src="/images/marketplace/translating.png" alt="translating" className="logo-market-key mr-1" />
                      <span className="text-qall-key-header-market">Multilingual Capabilities</span>
                    </div>
                    <div className="align-text-justify pt-3 mx-3">
                      <span className="text-qual-market-grey">Breaking Language Barriers Our system supports a wide range of languages, including rare ones, to ensure seamless communication across diverse teams and customers.</span>
                    </div>
                  </Col>
                </Row>
                <Row className="mx-1 pt-5">
                  <Col xs="12 my-4" lg="4">
                    <div className="align-center">
                      <CardImg src="/images/marketplace/dash-exp.png" alt="dash-exp" className="logo-market-key mr-1" />
                      <span className="text-qall-key-header-market">Fast and Easy Integration</span>
                    </div>
                    <div className="align-text-justify pt-3 mx-3">
                      <span className="text-qual-market-grey">Quick Setup, Instant Impact Experience rapid deployment and integration, getting your system up and running in just a few weeks for minimal downtime.</span>
                    </div>
                  </Col>
                  <Col xs="12 my-4" lg="4">
                    <div className="align-center">
                      <CardImg src="/images/marketplace/user-call.png" alt="user-call" className="logo-market-key mr-1" />
                      <span className="text-qall-key-header-market">Tailored User Experience</span>
                    </div>
                    <div className="align-text-justify pt-3 mx-3">
                      <span className="text-qual-market-grey">Customization at Your Fingertips An intuitive and flexible interface designed to meet your specific business needs, providing a personalized user experience for every account.</span>
                    </div>
                  </Col>
                  <Col xs="12 my-4" lg="4">
                    <div className="align-center">
                      <CardImg src="/images/marketplace/reporting.png" alt="reporting" className="logo-market-key mr-1" />
                      <span className="text-qall-key-header-market">Insightful Reporting and Analytics</span>
                    </div>
                    <div className="align-text-justify pt-3 mx-3">
                      <span className="text-qual-market-grey">Data-Driven Insights Gain actionable insights with advanced reporting, offering customizable dashboards that visualize your operator performance and scoring results in real time.</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="12">
          <Row className="align-center bg-contact-qall">
            <Col xs="12" lg="6">
              <div className="pt-5 align-center">
                <ContactForm userFrom="MRKTQA" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-5" />
      <footer className="footer">
        <WDSFooter />
      </footer>
    </div>
  );
};
export default QallAuditDesc;
