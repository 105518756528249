import React from 'react';
import {
  CardImg,
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import '../admin.scss';
import { getUserProfileLocalStorage } from '../../../../../utils/service/localstorage-service';
import { ListCandidateData } from './candidate.list';

export const CampusCandidates: React.FC = () => {
  const userProfile = getUserProfileLocalStorage();
  const [activeTab, setActiveTab] = React.useState('PADM');
  const [campusId, setCampusId] = React.useState('');

  React.useEffect(() => {
    setCampusId(userProfile && JSON.parse(userProfile).profileData.cmpsCd);
  }, [userProfile && JSON.parse(userProfile).profileData.cmpsCd]);

  return (
    <Row className="onboard-main-margin-top">
      <div className="allign-start ml">
        <h2 className="text-line"><CardImg className="cx-main-header-img-card" src="/images/icons/grads-list.png" alt="batches" /> My Candidates</h2>
      </div>
      <Col xs="12">
        <Nav tabs className="campus-onboard-tab">
          <div className="ml">
            <div className="campus-column-onboard-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'PADM'} onClick={() => setActiveTab('PADM')}>
                  <div className="align-center">
                    <CardImg src="/images/icons/pending-user.png" className="campus-tab-onboard-icon-img" alt="Icon 1" />
                  </div>
                  <div className="align-center">
                    <span className="campus-tab-text mx-1">Pending</span>
                  </div>
                </NavLink>
              </NavItem>
            </div>
            <div className="campus-column-onboard-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'NACC'} onClick={() => setActiveTab('NACC')}>
                  <div className="align-center">
                    <CardImg src="/images/icons/block-user.png" className="campus-tab-onboard-icon-img" alt="Icon 3" />
                  </div>
                  <div className="align-center">
                    <span className="campus-tab-text mx-1">Blocked</span>
                  </div>
                </NavLink>
              </NavItem>
            </div>
            <div className="campus-column-onboard-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'APPR'} onClick={() => setActiveTab('APPR')}>
                  <div className="align-center">
                    <CardImg src="/images/icons/approved-user.png" className="campus-tab-onboard-icon-img" alt="Icon 2" />
                  </div>
                  <div className="align-center">
                    <span className="campus-tab-text mx-1">Approved</span>
                  </div>
                </NavLink>
              </NavItem>
            </div>
          </div>
        </Nav>
      </Col>
      <Col xs="12">
        <div className="pt-5">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <ListCandidateData statusTp={activeTab} campusId={campusId} />
            </TabPane>
          </TabContent>
        </div>
      </Col>
      <div className="pt-5" />
    </Row>
  );
};

export default CampusCandidates;
