import { VideoResponse, VideoResults } from './video.service.types';

export const VideoTransform: (response:VideoResponse) => VideoResults = (objectiveResults) => {
  const { data, is_error, message } = objectiveResults;
  const result : VideoResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
