import { ResumeVideoResponse, ResumeVideoResults } from './get-video.service.types';

export const ResumeVideoTransform: (response: ResumeVideoResponse) => ResumeVideoResults = (resumeVideoResults) => {
  const { data, is_error, message } = resumeVideoResults;
  const result :ResumeVideoResults = {
    video: '',
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.video = data.video ?? '';
    result.message = 'executed';
  }
  return result;
};
