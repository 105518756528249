import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Input, InputGroup, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import {
  updateS3CxMetaReq,
  clearUpdateS3CxMetaReq,
  getS3CxMetaReq,
  clearGetS3CxMetaReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import { getConfig } from '../../../../../utils/config/config';
import LoaderData from '../../../../../utils/loader';
import { MetaInput } from '../../../../../services/aIntelligence/update-cx-meta/update-s3-cx-meta.types';
import { DialCountrInfo } from '../../../../../utils/MetaData/country';
import { MetaRecord } from '../../../../../services/aIntelligence/get-all-cx-meta/get-s3-cx-meta.types';
import { CampusProps } from '../onboard.type';

const { AWS_CXADMIN_BUCKET } = getConfig();

export const AddCampus: React.FC<CampusProps> = ({
  ordTp, tabTp, clientTp, college, setCollege,
}) => {
  const dispatch = useDispatch();
  const [loaderClient, setLoaderClient] = useState(false);
  const [loaderListClient, setLoaderListClient] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [modalAddCollege, setModalAddCollege] = useState(false);
  const [collegeListData, setCollegeListData] = useState<MetaRecord[]>([]);
  const addCollegeResponse = useSelector((state: RootState) => state.campusXcel.updateS3CxMeta);
  const getCollegeResponse = useSelector((state: RootState) => state.campusXcel.getS3CxMeta);
  const [newCollege, setNewCollege] = useState<MetaInput>({
    nme: '',
    univ: '',
    cty: '',
    st: '',
  });

  React.useEffect(() => {
    if (ordTp === 'campus' && tabTp === 'addCampus' && clientTp === 'CMPS') {
      setLoaderListClient(true);
      dispatch(getS3CxMetaReq({
        bucketName: AWS_CXADMIN_BUCKET,
        fileName: 'college.json',
        folderName: 'cx-meta-data',
        search: '',
        maxResults: 30,
        nextToken: '',
        requestType: '',
      }));
    }
  }, [ordTp, tabTp, clientTp]);

  React.useEffect(() => {
    if (getCollegeResponse.success) {
      setLoaderListClient(false);
      setCollegeListData((prevData) => {
        const existingNames = new Set(prevData.map((record) => record.nme));
        const uniqueNewData = getCollegeResponse.data.filter((record) => !existingNames.has(record.nme));
        return [...prevData, ...uniqueNewData];
      });
      dispatch(clearGetS3CxMetaReq());
    }
  }, [getCollegeResponse.success]);

  const handleChangeClient = (event: any) => {
    setCollege({ ...college, [event.target.name]: event.target.value });
  };

  const handleChangeNewClient = (event: any) => {
    setNewCollege({ ...newCollege, [event.target.name]: event.target.value });
  };

  const handleOpenCollegeModal = () => {
    setModalAddCollege(!modalAddCollege);
  };

  const clgNameoptions = collegeListData.map((colg) => ({
    label: `${colg.nme}`,
    value: `${colg.nme}|${colg.univ}|${colg.cty}|${colg.st}`,
    university: colg.univ,
    city: colg.cty,
    state: colg.st,
  }));
  const selectedCollegeNameOptions = clgNameoptions && clgNameoptions.find(
    (option) => (option.value === `${college.nme}|${college.univ}|${college.citySt}`),
  );

  const dialNameoptions1 = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions1 = dialNameoptions1 && dialNameoptions1.find(
    (option) => option.value === college.dlCd1,
  );

  const dialNameoptions2 = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions2 = dialNameoptions2 && dialNameoptions2.find(
    (option) => option.value === college.dlCd2,
  );

  const handleSelectDialData1 = (e:any) => {
    setCollege({ ...college, dlCd1: e.value });
  };

  const handleSelectDialData2 = (e:any) => {
    setCollege({ ...college, dlCd2: e.value });
  };

  const handleInputCollegeChange = (e:string) => {
    if (e) {
      setLoaderListClient(true);
      dispatch(getS3CxMetaReq({
        bucketName: AWS_CXADMIN_BUCKET,
        fileName: 'college.json',
        folderName: 'cx-meta-data',
        search: e,
        maxResults: 20,
        nextToken: '',
        requestType: '',
      }));
    }
  };
  const handleSelectCollegeData = (selectedOption: any) => {
    if (selectedOption) {
      // Do something with the selected option
      setCollege({
        ...college,
        nme: selectedOption.label,
        univ: selectedOption.university,
        citySt: `${selectedOption.city}, ${selectedOption.state}`,
      });
    }
  };

  const handleUpdateMetaData = (e: any) => {
    e.preventDefault();
    setLoaderClient(true);
    dispatch(updateS3CxMetaReq({
      bucketNme: AWS_CXADMIN_BUCKET,
      fileName: 'college.json',
      folderName: 'cx-meta-data',
      inputBody: newCollege,
      requestType: '',
    }));
  };
  React.useEffect(() => {
    if (addCollegeResponse.message !== '') {
      setLoaderClient(false);
      if (addCollegeResponse.success) {
        setModalAddCollege(false);
        setAlertMsg(addCollegeResponse.message);
        setSuccessShowAlert(true);
        setNewCollege({
          nme: '',
          univ: '',
          cty: '',
          st: '',
        });
      } else {
        setAlertMsg(addCollegeResponse.message);
        setErrorShowAlert(true);
      }
      dispatch(clearUpdateS3CxMetaReq());
    }
  }, [addCollegeResponse.message]);

  return (
    <div className="pt-3">
      <div className="mb-3 align-center">
        New College
      </div>
      { loaderClient ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="align-center">
        <Col lg="11" xs="12">
          <div className="align-end mr-1">
            <Button className="button-transparent align-center" onClick={handleOpenCollegeModal}>
              Add New
            </Button>
          </div>
          <div className="text-right-login-campus-register">
            <Select
              value={college.nme === '' ? null : selectedCollegeNameOptions}
              options={clgNameoptions}
              onChange={handleSelectCollegeData}
              onInputChange={handleInputCollegeChange}
              styles={{
                control: (base: any) => ({
                  ...base,
                  '&:hover': { borderColor: '#8B0000' },
                  border: '1px solid #DDE0E4',
                  minHeight: '50px',
                  borderRadius: '10px',
                  padding: 1,
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#575656',
                  boxShadow: 'none',
                  backgroundColor: '#fff',
                  '&:focus': {
                    borderColor: '#E1EDF8',
                    boxShadow: 'none',
                    color: '#575656',
                    background: '#fff',
                  },
                }),
                option: (provided: any, state: { isSelected: any; }) => ({
                  ...provided,
                  color: state.isSelected ? '#383838' : '#212121',
                  padding: 15,
                  textAlign: 'left',
                  background: state.isSelected ? '#FAFCFB' : '#fff',
                  '&:hover': {
                    background: '#FAFCFB',
                  },
                }),
              }}
              noOptionsMessage={() => 'No College Found'}
              placeholder="Select College..."
              isLoading={loaderListClient}
            />
          </div>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <InputGroup>
              <Select
                value={college.dlCd1 === '' ? null : selectedDialNameOptions1}
                options={dialNameoptions1}
                onChange={handleSelectDialData1}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    // border: '1px solid #F9CB9C',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Dial Code Found'}
              />
              <Input
                placeholder="Phone Number 1"
                type="number"
                required
                name="cntPh1"
                value={college.cntPh1}
                onChange={handleChangeClient}
                className="campus-input mx-1"
              />
            </InputGroup>
          </div>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <InputGroup>
              <Select
                value={college.dlCd2 === '' ? null : selectedDialNameOptions2}
                options={dialNameoptions2}
                onChange={handleSelectDialData2}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    // border: '1px solid #F9CB9C',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Dial Code Found'}
              />
              <Input
                placeholder="Phone Number 2"
                type="number"
                name="cntPh2"
                value={college.cntPh2}
                onChange={handleChangeClient}
                className="campus-input mx-1"
              />
            </InputGroup>
          </div>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <Input
              placeholder="Contact Email"
              type="text"
              name="cntEId"
              required
              value={college.cntEId}
              onChange={handleChangeClient}
              className="campus-input"
            />
          </div>
        </Col>
      </Row>
      <Modal isOpen={modalAddCollege}>
        <ModalHeader title="Add new College" toggle={handleOpenCollegeModal}>
          <span className="proj-text-color">Add new College</span>
        </ModalHeader>
        <ModalBody className="mx-2">
          <Row className="card-border align-center">
            <Col lg="10" xs="12">
              <div className="text-right-login-campus-register pt-4 mx-1">
                <Input
                  placeholder="Clientemy Name"
                  type="text"
                  name="nme"
                  required
                  value={newCollege.nme}
                  onChange={handleChangeNewClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col lg="10" xs="12">
              <div className="text-right-login-campus-register pt-4 mx-1">
                <Input
                  placeholder="University Associated"
                  type="text"
                  name="univ"
                  required
                  value={newCollege.univ}
                  onChange={handleChangeNewClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col lg="10" xs="12">
              <div className="text-right-login-campus-register pt-4 mx-1">
                <Input
                  placeholder="City of Campus"
                  type="text"
                  name="cty"
                  required
                  value={newCollege.cty}
                  onChange={handleChangeNewClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col lg="10" xs="12">
              <div className="text-right-login-campus-register pt-4 mx-1">
                <Input
                  placeholder="State of Campus"
                  type="text"
                  name="st"
                  required
                  value={newCollege.st}
                  onChange={handleChangeNewClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="align-center my-3">
                <Button className="campus-button-all mx-1" onClick={handleUpdateMetaData}>
                  SAVE
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddCampus;
