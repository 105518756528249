import { JobViewPutResponse, JobViewPutResults } from './update.job.views.service.types';

export const JobViewPutTransform: (response: JobViewPutResponse) => JobViewPutResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :JobViewPutResults = {
    message: '',
    error: false,
    viewCnt: 0,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.viewCnt = data.id;
    result.message = 'executed';
  }
  return result;
};
