import {
  faCommentAlt, faPaperPlane,
} from '@fortawesome/free-regular-svg-icons';
import { RootState } from 'typesafe-actions';
import {
  faBan,
  faThumbsUp, faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import '../../../campus.scss';
import ReactPlayer from 'react-player';
import ArticleComponent from '../article/article.component';
import LikesComponent from '../likes/likes.component';
import { CollegeProps } from '../college.type';
import { CommentData } from '../../../../../../services/campus/dashboard/comments/get-all-comments/list-comment.types';
import { UserLikesData } from '../../../../../../services/campus/dashboard/likes/get-all-likes/list-user-likes.types';
import {
  getCommentListReq, clearCommentListReq, addFollowReq, addLikeReq, getUserLikesListReq, clearUserLikesListReq,
} from '../../../../../../store/campusXcel/actions';
import { formatDate } from '../../../../../../utils';
import { successAlert, errorAlert } from '../../../../../../utils/alert';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import LoaderData from '../../../../../../utils/loader';
import { getTokenFromLocalStorage } from '../../../../../../utils/service/localstorage-service';
import CommentsComponent from '../comments/comments.component';

const maxLength = 130;

const Published: React.FC<CollegeProps> = ({ myPost, academyId, userAdmin }) => {
  const dispatch = useDispatch();
  // const [imageURL] = useState('');
  const tokenData = getTokenFromLocalStorage();
  const [modalLikes, setModalLikes] = useState(false);
  const [modalArticle, setModalArticle] = useState(false);
  const [openLargeText, setOpenLargeText] = useState('');
  const [openCommentIndex, setOpenCommentIndex] = useState(-1);
  const [loaderSpinner, setLoaderSpinner] = useState(false);
  const [isJustFollowed, setIsJustFollowed] = useState(false);
  const [isLikeIndx, setIsLikeIndx] = useState('');
  const [isLikeMssg, setIsLikeMssg] = useState('');
  const [isShare, setIsShare] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [loaderPost, setLoaderPost] = useState(false);
  const [loaderUserLike, setLoaderUserLike] = useState(false);
  const [callCommentBack, setCallCommentBack] = useState('');
  const followResponse = useSelector((state: RootState) => state.campusXcel.addFollowData);
  const likeResponse = useSelector((state: RootState) => state.campusXcel.addLikeData);
  const getCommentResponse = useSelector((state: RootState) => state.campusXcel.getCommentList);
  const getUserLikesResponse = useSelector((state: RootState) => state.campusXcel.getUserLikesList);
  const [listComment, setListComment] = useState<CommentData>({
    data: [],
  });
  const [listUserLikes, setListUserLikes] = useState<UserLikesData>({
    tCnt: 0,
    fCnt: 0,
    likeUserData: [],
  });
  const handleLargeText = (index: string) => {
    setOpenLargeText(index);
  };

  const handleComment = (index: number, activitId: string) => {
    setLoaderSpinner(true);
    dispatch(getCommentListReq({
      requestType: APIHeader.REQ_GET_ALL_COMMENTS,
      uTp: UserType.CMPS,
      token: tokenData,
      actId: activitId,
      acdId: academyId,
    }));
    if (index === openCommentIndex) {
      setOpenCommentIndex(-1);
    } else {
      setOpenCommentIndex(index);
    }
  };
  React.useEffect(() => {
    if (callCommentBack !== '') {
      setLoaderSpinner(true);
      dispatch(getCommentListReq({
        requestType: APIHeader.REQ_GET_ALL_COMMENTS,
        uTp: UserType.CMPS,
        token: tokenData,
        acdId: academyId,
        actId: callCommentBack,
      }));
    }
  }, [callCommentBack]);

  React.useEffect(() => {
    if (getCommentResponse.isError && getCommentResponse.message !== '') {
      if (getCommentResponse.message === 'No record found') {
        setListComment({ ...listComment, data: [] });
        dispatch(clearCommentListReq());
        setCallCommentBack('');
        setLoaderSpinner(false);
      }
    }
  }, [getCommentResponse.isError, getCommentResponse.message]);

  React.useEffect(() => {
    if (!getCommentResponse.isError && getCommentResponse.message !== '') {
      setListComment({ ...listComment, data: getCommentResponse.data });
      dispatch(clearCommentListReq());
      setCallCommentBack('');
      setLoaderSpinner(false);
    }
  }, [getCommentResponse.isError, getCommentResponse.message]);

  // const handleLike = () => {
  //   setIsLike(!isLike);
  // };
  const handleShare = () => {
    setIsShare(!isShare);
  };

  const handleSubmitArticle = (status: string) => {
    if (status === 'publish') {
      // eslint-disable-next-line no-console
      console.log('Save logic');
    } else {
      setModalArticle(false);
    }
    setModalArticle(false);
  };

  const handleSubmitFollow = (folowCd:string, followTp:string) => {
    setLoaderPost(true);
    dispatch(addFollowReq({
      requestType: APIHeader.REQ_ADD_FOLLOWER,
      uTp: UserType.CMPS,
      token: tokenData,
      folwUsrCd: folowCd,
      folwUsrTP: followTp,
    }));
  };

  React.useEffect(() => {
    if (followResponse.error && followResponse.message !== '') {
      setLoaderPost(false);
      setAlertMsg(followResponse.message);
      setErrorShowAlert(true);
    }
  }, [followResponse.error, followResponse.message]);

  React.useEffect(() => {
    if (!followResponse.error && followResponse.message !== '') {
      setLoaderPost(false);
      setIsJustFollowed(true);
      setAlertMsg(followResponse.message);
      setSuccessShowAlert(true);
    }
  }, [followResponse.error, followResponse.message]);

  const handleSubmitLike = (activityId:string) => {
    // setLoaderPost(true);
    setIsLikeIndx(activityId);
    dispatch(addLikeReq({
      requestType: APIHeader.REQ_ADD_LIKE,
      uTp: UserType.CMPS,
      token: tokenData,
      actId: activityId,
    }));
  };

  // React.useEffect(() => {
  //   if (likeResponse.error && likeResponse.message !== '') {
  //     // setLoaderPost(false);
  //     setAlertMsg(likeResponse.message);
  //     setErrorShowAlert(true);
  //   }
  // }, [likeResponse.error, likeResponse.message]);

  React.useEffect(() => {
    if (!likeResponse.error && likeResponse.message !== '') {
      setIsLikeMssg('executed');
      // setLoaderPost(false);
      // setAlertMsg(likeResponse.message);
      // setSuccessShowAlert(true);
    }
  }, [likeResponse.error, likeResponse.message]);
  const handleCloseShowLikes = () => {
    setModalLikes(false);
  };

  const handleShowLikes = (activitId:string) => {
    setModalLikes(true);
    setLoaderUserLike(true);
    dispatch(getUserLikesListReq({
      requestType: APIHeader.REQ_GET_ALL_USER_LIKES,
      uTp: UserType.CMPS,
      token: tokenData,
      actId: activitId,
    }));
  };

  React.useEffect(() => {
    if (getUserLikesResponse.isError && getUserLikesResponse.message !== '') {
      setLoaderUserLike(false);
      setModalLikes(false);
    }
  }, [getUserLikesResponse.isError, getUserLikesResponse.message]);

  React.useEffect(() => {
    if (!getUserLikesResponse.isError && getUserLikesResponse.message !== '') {
      setListUserLikes(getUserLikesResponse);
      dispatch(clearUserLikesListReq());
      setLoaderUserLike(false);
    }
  }, [getUserLikesResponse.isError, getUserLikesResponse.message]);

  return (
    <div>
      { loaderPost ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {myPost.postData.map((postData, indx) => (
        <Row key={postData.authrCd + indx.toString()}>
          <Col xs="12">
            <Card className="dasboard-form-card my-2">
              <CardHeader className="form-card-header">
                <Row>
                  <Col lg="9" md="8" xs="12">
                    <div style={{ display: 'flex' }}>
                      <div className="align-center">
                        <CardImg
                          alt="User Avatar"
                          className="cx-avatar-user-small"
                          src={postData.avtr || '/images/common/avatar.png'}
                          onError={(e) => {
                            const target = e.target as HTMLImageElement; // Cast target to HTMLImageElement
                            target.onerror = null; // Prevents infinite loop
                            target.src = '/images/common/avatar.png'; // Sets default image if not found
                          }}
                        />
                      </div>
                      <div className="ml-1 pt-1">
                        <NavLink to={postData.prof} className="dashboard-link" target="_blank">
                          <span className="dashboard-post-text">
                            {postData.authNme}
                          </span>
                        </NavLink>
                        {postData.crs !== null || postData.strm !== null
                          ? (
                            <div>
                              <span className="dashboard-sub-post-text">
                                {postData.crs !== '' ? `${postData.crs}, ` : null}{postData.strm !== '' ? postData.strm : null}
                              </span>
                            </div>
                          ) : null }
                        <div>
                          {postData.sYr !== null || postData.eYr !== null ? (
                            <span className="dashboard-sub-post-text">
                              {postData.sYr !== '' && postData.eYr !== '' ? `Batch of ${postData.sYr} - ${postData.eYr},  ` : null}
                              {postData.folwCnt} Followers
                            </span>
                          ) : null }
                          <span className="dashboard-sub-post-text">
                            {postData.folwCnt} Followers
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="3" md="4" xs="12">
                    <div className="align-end">
                      {postData.isSlfFolw ? null
                        : postData.isFlng || isJustFollowed
                          ? (
                            <Button className="button-icon" onClick={() => handleSubmitFollow(postData.authrCd, 'CMPS')} disabled>
                              <span className="dashboard-follow-text">+ Following</span>
                            </Button>
                          )
                          : (
                            <Button className="button-icon" onClick={() => handleSubmitFollow(postData.authrCd, 'CMPS')}>
                              <span className="dashboard-follow-text">+ Follow</span>
                            </Button>
                          )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              {/* <CardHeader className="form-card-header">
                <Row>
                  <Col lg="1" md="3" xs="2">
                    <div className="align-center">
                      {postData.avtr === ''
                        ? (
                          <img
                            alt="..."
                            className="cx-avatar-user-small"
                            src="/images/common/avatar.png"
                          />
                        )
                        : (
                          <img
                            alt="..."
                            className="cx-avatar-user-small"
                            src={postData.avtr}
                          />
                        ) }
                    </div>
                  </Col>
                  <Col lg="8" md="6" xs="6">
                    <div className="pt-1">
                      <span className="dashboard-post-text">{postData.authNme}</span>
                    </div>
                    <div>
                      <span className="dashboard-sub-post-text">
                        {postData.crs !== '' ? `${postData.crs}, ` : null}{postData.strm !== '' ? postData.strm : null}
                      </span>
                    </div>
                    <div>
                      <span className="dashboard-sub-post-text">
                        {postData.sYr !== '' && postData.eYr !== '' ? `Batch of ${postData.sYr} - ${postData.eYr}, ` : null}
                        {postData.folwCnt} Followers
                      </span>
                    </div>
                  </Col>
                  <Col lg="3" md="3" xs="4">
                    <div className="align-end">
                      {postData.isSlfFolw ? null
                        : postData.isFlng
                          ? (
                            <Button className="button-icon" onClick={() => handleSubmitFollow(postData.authrCd, 'CMPS')} disabled>
                              <span className="dashboard-follow-text">+ Following</span>
                            </Button>
                          )
                          : (
                            <Button className="button-icon" onClick={() => handleSubmitFollow(postData.authrCd, 'CMPS')}>
                              <span className="dashboard-follow-text">+ Follow</span>
                            </Button>
                          )}
                    </div>
                  </Col>
                </Row>
              </CardHeader> */}
              <div className="align-end mr-1 pt-1">
                <span className="text-lite-grey-date">{formatDate(postData.updtOn)}</span>
              </div>
              <CardBody className="form-card-body mx-2 pt-1">
                <Row>
                  <Col xs="12">
                    <span className="dashboard-thread-text">
                      {openLargeText === `close-${indx.toString()}` ? `${postData.post}` : `${postData.post.slice(0, maxLength)}`}
                      {/* {closeLargeText === indx ? `${postData.post} -` : null} */}
                    </span>
                    {openLargeText === `close-${indx.toString()}`
                      ? (
                        <Button onClick={() => handleLargeText(`open-${indx.toString()}`)} className="dashboard-button-less mx-2">
                          show less
                        </Button>
                      ) : (
                        <Button onClick={() => handleLargeText(`close-${indx.toString()}`)} className="dashboard-button-more mx-2">
                          show more
                        </Button>
                      )}
                  </Col>
                  <Col xs="12" className="pt-1">
                    {(postData.fileTp === 'image' || postData.urlTp === 'image') && postData.url !== ''
                      ? (
                        <CardImg
                          alt="..."
                          className="post-image-dim"
                          // style={{ width: '100%', height: 'auto' }}
                          src={postData.url}
                        />
                      )
                      : (postData.fileTp === 'video' || postData.urlTp === 'video') && postData.url !== '' ? (
                        <ReactPlayer
                          url={postData.url}
                          controls
                          width="100%"
                          height="400px"
                          config={{
                            youtube: {
                              playerVars: {
                                origin: window.location.origin, // Match the origin dynamically
                              },
                            },
                            file: {
                              attributes: {
                                controlsList: 'nodownload',
                              },
                            },
                          }}
                        />
                      ) : null}
                  </Col>
                  <Col xs="12">
                    <Row className="pt-2">
                      <Col lg="6" md="7" xs="6">
                        <Button className="button-icon" onClick={() => handleShowLikes(postData.ActId)} disabled={postData.lkCnt === 0}>
                          <span className="text-lite-grey">
                            <FontAwesomeIcon className="text-lite-grey mx-1" icon={faThumbsUp} />
                            {isLikeIndx === postData.ActId && isLikeMssg === 'executed' ? (postData.lkCnt + 1) : postData.lkCnt}
                          </span>
                        </Button>
                      </Col>
                      <Col lg="6" md="5" xs="6">
                        <div className="align-end">
                          {postData.cmntTp === 'all'
                            ? <span className="mx-1 text-lite-grey">{postData.cmntCnt} comments</span> : null }
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="form-card-footer">
                <Row className="align-center">
                  <Col lg="4" md="4" xs="3">
                    {postData.isLiked
                      ? (
                        <Button className="button-icon" disabled>
                          <span className="input-checkbox-text-grey">
                            <FontAwesomeIcon className="quest-menu-icon mx-1" icon={faThumbsUp} />
                            Like
                          </span>
                        </Button>
                      )
                      : (
                        <Button className="button-icon" onClick={() => handleSubmitLike(postData.ActId)}>
                          <span className="input-checkbox-text-grey">
                            <FontAwesomeIcon className="text-lite-grey mx-1" icon={faThumbsUp} />
                            Like
                          </span>
                        </Button>
                      )}
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <div className="align-center">
                      {postData.cmntTp === 'all'
                        ? (
                          <Button className="button-icon" onClick={() => handleComment(indx, postData.ActId)}>
                            <span className="input-checkbox-text-grey">
                              <FontAwesomeIcon className={openCommentIndex === indx ? 'quest-menu-icon mx-2' : 'text-lite-grey mx-2'} icon={faCommentAlt} />
                              Comment
                            </span>
                          </Button>
                        ) : (
                          <Button className="button-icon" disabled>
                            <span className="input-checkbox-text-grey">
                              <FontAwesomeIcon className={openCommentIndex === indx ? 'quest-menu-icon mx-2' : 'text-lite-grey mx-2'} icon={faBan} />
                              Comment
                            </span>
                          </Button>
                        ) }
                    </div>
                  </Col>
                  <Col lg="4" md="4" xs="3">
                    <div className="align-end">
                      <Button className="button-icon" onClick={handleShare}>
                        <span className="input-checkbox-text-grey">
                          <FontAwesomeIcon className={isShare ? 'quest-menu-icon mx-1' : 'text-lite-grey mx-1'} icon={faPaperPlane} />
                          Share
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="my-2" />
                {openCommentIndex === indx ? (
                  <div>
                    <div className="align-center">
                      {loaderSpinner ? <Spinner className="alert-error" /> : null}
                    </div>
                    <CommentsComponent userAdmin={userAdmin} listComment={listComment} activityId={postData.ActId} setCallCommentBack={setCallCommentBack} academyId={academyId} />
                  </div>
                ) : null}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ))}
      <Modal
        isOpen={modalArticle}
        fullscreen
      >
        <ModalHeader className="form-card-header">
          <span className="modal-header-text mx-3">Publish your article to the wider audience</span>
        </ModalHeader>
        <ModalBody>
          <ArticleComponent />
        </ModalBody>
        <ModalFooter className="form-card-footer">
          <div className="align-end">
            <Button className="campus-button mx-1" onClick={() => handleSubmitArticle('cancel')}>
              cancel
            </Button>
            <Button className="campus-button mx-1" onClick={() => handleSubmitArticle('publish')}>
              Publish
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalLikes}
        className="my-modal-container-likes"
      >
        <ModalHeader className="form-card-header">
          <div className="align-end">
            <Button className="button-icon" onClick={handleCloseShowLikes}>
              <FontAwesomeIcon className="dashboard-close-button" icon={faXmarkSquare} />
              <span className="dashboard-close-text mx-1">Close</span>
            </Button>
          </div>
        </ModalHeader>
        <span className="mx-2 current-icon pt-1">Liked By</span>
        <ModalBody>
          <div className="align-center">
            {loaderUserLike ? <Spinner className="alert-error" /> : null}
          </div>
          <LikesComponent listUserLikes={listUserLikes} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Published;
