import {
  getCommonApi, getLogin, getToken, putCommonApi,
} from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { GenTokenOptions, GenTokenResponse } from './gen-token.service.types';

const { CAMPUSXCEL_USER_API_ROOT } = getConfig();

export const GenTokenRequest = ({
  uTp, userName, requestType, passKey, isPublic,
}: GenTokenOptions): Promise<GenTokenResponse> => {
  const digits = passKey.match(/^\d{4}$/);
  if (!isPublic) {
    if (userName !== '' && passKey !== '') {
      if (digits !== null) {
        return getCommonApi(`${CAMPUSXCEL_USER_API_ROOT}?uOtp=${passKey}&uTp=${uTp}&uInfo=${userName}`, requestType);
      }
      return getLogin(`${CAMPUSXCEL_USER_API_ROOT}?uTp=${uTp}`, requestType, userName, passKey);
    }
    if (userName !== '' && passKey === '') {
      return putCommonApi(`${CAMPUSXCEL_USER_API_ROOT}?uTp=${uTp}&uInfo=${userName}`, requestType);
    }
  }
  return getToken(`${CAMPUSXCEL_USER_API_ROOT}?uTp=${uTp}`, requestType);
};
