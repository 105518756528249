import React from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CardImg, Col, NavbarBrand, Row,
} from 'reactstrap';
import { getLoginToken, mediaByIdReq } from '../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../utils/constants';
import { MediaResult } from '../../../services/sadmin/newsletter/get-media-by-id/media-by-id.types';
import { LoaderData } from '../../../utils/loader';

const PublicNewsShare: React.FC = () => {
  const dispatch = useDispatch();
  const { newsTp, newsId } = useParams();
  const [htmlDetails, setHtmlDetails] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const getMediaDetailsResponse = useSelector((state: RootState) => state.campusXcel.getMediaById);
  const [mediaDetails, setMediaDetails] = React.useState<MediaResult>({
    MediaTp: '',
    MediaId: '',
    medSts: '',
    newsKey: '',
    subLine: '',
    subTtl: '',
    updtOn: '',
    author: {
      fNme: '',
      lNme: '',
    },
    previewUrl: '',
    descHtmlUrl: '',
  });

  React.useEffect(() => {
    if (newsTp !== '' && newsId !== '') {
      setLoading(true);
      dispatch(getLoginToken({
        userName: '',
        passKey: '',
        isPublic: true,
        uTp: UserType.CXAPI,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  }, [newsTp, newsId]);

  React.useEffect(() => {
    if (loginTokenData.message !== '' && newsTp && newsId) {
      if (loginTokenData.isError) {
        setLoading(false);
      } else if (loginTokenData.token && newsTp && newsId) {
        setLoading(true);
        dispatch(mediaByIdReq({
          requestType: APIHeader.REQ_MEDIA_BY_ID,
          uTp: UserType.CXAPI,
          token: loginTokenData.token,
          mediaTp: newsTp,
          mediaId: newsId,
        }));
      }
    }
  }, [loginTokenData, newsTp, newsId]);

  React.useEffect(() => {
    if (getMediaDetailsResponse.isError && getMediaDetailsResponse.message !== '') {
      setLoading(false);
    }
  }, [getMediaDetailsResponse.isError, getMediaDetailsResponse.message]);

  React.useEffect(() => {
    if (!getMediaDetailsResponse.isError && getMediaDetailsResponse.message === 'executed') {
      setLoading(false);
      setMediaDetails(getMediaDetailsResponse.data);
    }
  }, [getMediaDetailsResponse.isError, getMediaDetailsResponse.message]);

  React.useEffect(() => {
    if (mediaDetails.descHtmlUrl !== '') {
      const fetchHtml = async () => {
        setLoading(true); // Start loader
        try {
          const response = await fetch(mediaDetails.descHtmlUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const text = await response.text();
          setHtmlDetails(text);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching news details:', error);
        } finally {
          setLoading(false); // Stop loader
        }
      };

      fetchHtml(); // Call the async function
    }
  }, [mediaDetails.descHtmlUrl]);

  return (
    <div>
      {loading ? <LoaderData /> : null}
      <NavbarBrand href="/news" className="pt-1 margin-logo">
        <div className="pt-1 my-1 align-end ml-2">
          <CardImg
            alt="..."
            className="campus-acad-logo"
            src="/campusXcel.png"
          />
        </div>
      </NavbarBrand>
      <Row className="align-center">
        <Col lg="8" xs="11">
          <div className="mb-3 pt-2">
            <span className="public-news-header-text">
              {mediaDetails.subLine}
            </span>
          </div>
          <div
            className="public-newsletter"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: htmlDetails }}
          />
        </Col>
        {/* <Col lg="4" xs="11" />
        Advertisement here */}
      </Row>
    </div>
  );
};
export default PublicNewsShare;
