import { JobAddResponse, JobAddResults } from './add-job.service.types';

export const JobAddTransform: (response: JobAddResponse) => JobAddResults = (batchResults) => {
  const { data, is_error, message } = batchResults;
  const result :JobAddResults = {
    message: '',
    error: false,
    data: {
      JobCd: '',
      JobId: '',
      NxtStg: '',
    },
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.data = data;
  }
  return result;
};
