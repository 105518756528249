import React from 'react';
import {
  Button,
  CardImg,
  Col, NavbarBrand, Row,
} from 'reactstrap';
import { useParams, useLocation } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { RecruitInterviewData } from '../../../services/staffing/interviews/list-recruit-interview/list.recruit.interview.types';
import {
  clearShareListInterviewReq, getLoginToken, shareListInterviewReq,
} from '../../../store/campusXcel/actions';
import { errorAlert, successAlert } from '../../../utils/alert';
import { APIHeader, UserType } from '../../../utils/constants';
import PublicResumeDetails from './resume.details';

// interface StatusProps {
//   intvStatus: string;
//   interviewCd: string;
// }

const PublicListInterviewComponent: React.FC = () => {
  const { jobOrg, jobId, uniqCd } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const jobName = params.get('nmeJob');
  const [tokenValue, setTokenValue] = React.useState('');
  const [loadingIntv, setLoadingIntv] = React.useState(false);
  const [showErrorsIntvAlert, setErrorIntvShowAlert] = React.useState(false);
  const [showSuccessIntvAlert, setSuccessIntvShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [recordsPerPage] = React.useState<number>(30);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const listRecruitInterviewResponse = useSelector((state: RootState) => state.campusXcel.shareListInterview);
  const [listRecruitInterview, setListRecruitInterview] = React.useState<RecruitInterviewData>({
    tCnt: 0,
    fCnt: 0,
    recruitIntvData: [],
    lastEvalKey: {
      IntvCd: '',
      IntvId: '',
    },
  });

  React.useEffect(() => {
    if (jobId !== '' && uniqCd) {
      setLoadingIntv(true);
      dispatch(getLoginToken({
        userName: '',
        passKey: '',
        isPublic: true,
        uTp: UserType.CXAPI,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  }, [jobId, uniqCd]);

  React.useEffect(() => {
    if (loginTokenData.message !== '') {
      if (loginTokenData.isError) {
        setLoadingIntv(false);
      } else if (
        loginTokenData.token !== ''
        && jobId && jobOrg && uniqCd // Check if all values are defined
      ) {
        setLoadingIntv(true);
        setTokenValue(loginTokenData.token);
        dispatch(shareListInterviewReq({
          requestType: APIHeader.REQ_ALL_INTERVIEW_RECRUIT_SHARE,
          uTp: UserType.CXAPI,
          limit: recordsPerPage,
          evalKey: '',
          evalTp: '',
          isFilter: 'NO',
          isSearch: 'NO',
          token: loginTokenData.token,
          jobCd: jobOrg,
          jobId,
          intvCd: jobId,
          uniqId: uniqCd,
        }));
      }
    }
  }, [loginTokenData, jobId, jobOrg, uniqCd]);

  const fetchMoreData = () => {
    if (jobId && jobOrg && uniqCd) { // Ensure values are defined
      setLoadingIntv(true);
      dispatch(shareListInterviewReq({
        requestType: APIHeader.REQ_ALL_INTERVIEW_RECRUIT_SHARE,
        uTp: UserType.CXAPI,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        token: tokenValue,
        isSearch: 'NO',
        jobCd: jobOrg,
        jobId,
        intvCd: jobId,
        uniqId: uniqCd,
      }));
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  React.useEffect(() => {
    if (listRecruitInterviewResponse.isError && listRecruitInterviewResponse.message !== '') {
      setLoadingIntv(false);
      setAlertMsg(listRecruitInterviewResponse.message);
      setErrorIntvShowAlert(true);
      dispatch(clearShareListInterviewReq());
    }
  }, [listRecruitInterviewResponse.isError, listRecruitInterviewResponse.message]);

  React.useEffect(() => {
    if (!listRecruitInterviewResponse.isError && listRecruitInterviewResponse.message === 'executed') {
      setLoadingIntv(false);
      if (
        listRecruitInterviewResponse.data
        && Object.keys(listRecruitInterviewResponse.data).length > 0
        && Array.isArray(listRecruitInterviewResponse.data.recruitIntvData)
      ) {
        const newPageData = listRecruitInterviewResponse.data.recruitIntvData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        const firstClientName = newPageData[0]?.clntNme || '';
        setClientName(firstClientName);
        setListRecruitInterview({
          tCnt: listRecruitInterviewResponse.data.tCnt,
          fCnt: listRecruitInterviewResponse.data.fCnt,
          recruitIntvData: listRecruitInterviewResponse.data.recruitIntvData,
          lastEvalKey: listRecruitInterviewResponse.data.lastEvalKey,
        });
        if (listRecruitInterviewResponse.data.lastEvalKey !== null) {
          setEvalKeyText(listRecruitInterviewResponse.data.lastEvalKey.IntvId);
          setEvalTpText(listRecruitInterviewResponse.data.lastEvalKey.IntvCd);
        }
        setTotalRecords(listRecruitInterviewResponse.data.tCnt);
      }
    }
    dispatch(clearShareListInterviewReq());
  }, [listRecruitInterviewResponse.isError, listRecruitInterviewResponse.message]);

  return (
    <div className="bg-jobs-public">
      {showErrorsIntvAlert ? (
        errorAlert(false, alertMsg, showErrorsIntvAlert, setErrorIntvShowAlert)
      ) : null}
      {showSuccessIntvAlert ? (
        successAlert(false, alertMsg, showSuccessIntvAlert, setSuccessIntvShowAlert)
      ) : null}
      <Row className="pt-2">
        <NavbarBrand href="/jobs" className="pt-1 margin-logo">
          <div className="pt-1 my-1 align-start ml-2">
            <CardImg
              alt="..."
              className="campus-acad-logo"
              src="/campusXcel.png"
            />
          </div>
        </NavbarBrand>
        <Col xs="12">
          <div className="align-start ml-1 pt-4">
            <span className="cx-public-client-text ml">{clientName}</span>
          </div>
          <div className="align-start ml-1 pt-3">
            <span className="cx-main-header-text ml">{jobName}</span>
          </div>
        </Col>
        <Col xs="12" lg="12">
          <Row className="align-start pt-3">
            {loadingIntv ? (
              <div className="loader-campusxcel align-center">
                <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
                <span className="mx-2 text-lite">Jobs loading... <br />Please wait....</span>
              </div>
            )
              : (
                <Col lg="12" xs="12">
                  <div className="order-right-xcln-jobs">
                    <Row className="align-start">
                      <Col xs="12" lg="12">
                        <div className="align-end mr-2">
                          <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 0}
                            className="xcln-job-button-nxt "
                          >
                            <FontAwesomeIcon icon={faChevronLeft} />
                          </Button>
                          <div className="mx-2 table-margin-bot">
                            <span className="table-text">
                              {currentPage + 1} / {totalPages}
                            </span>
                          </div>
                          <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage >= totalPages - 1}
                            className="xcln-job-button-nxt"
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                          </Button>
                        </div>
                      </Col>
                      <Col xs="12" lg="12" className="">
                        <div className="pt-5">
                          {pagedData[currentPage] && listRecruitInterview.tCnt > 0
                            ? (
                              <PublicResumeDetails
                                data={pagedData[currentPage]}
                              />
                            ) : (
                              <div className="align-center">
                                <span className="table-text-no">No resumes found</span>
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PublicListInterviewComponent;
