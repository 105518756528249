import React from 'react';
import './news.scss';

  interface DataProps {
    htmlContent: string;
    htmlTitle: string;
  }

const DetailsNewsletter: React.FC<DataProps> = ({
  htmlContent,
  htmlTitle,
}) => (
  <div>
    <div className="mb-3">
      <span className="public-news-header-text">
        {htmlTitle}
      </span>
    </div>
    <div
      className="public-newsletter"
            // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  </div>
);

export default DetailsNewsletter;
