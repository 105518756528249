import { getCommonApiToken } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { ListEnquiryOptions, ListEnquiryResponse } from './list.enquiry.service.types';

const { CAMPUSXCEL_SADMIN_API_ROOT } = getConfig();

export const ListEnquiryRequest = ({
  limit, evalKey, evalTp, searchBy, isSearch, isFilter, filterBy, contTp, uTp, requestType, token, actTp,
}: ListEnquiryOptions): Promise<ListEnquiryResponse> => {
  if (contTp !== '' && actTp !== '') {
    return getCommonApiToken(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&contTp=${contTp}&actTp=${actTp}`, requestType, token);
  } if (contTp === '' && actTp !== '') {
    return getCommonApiToken(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&actTp=${actTp}`, requestType, token);
  } if (contTp !== '' && actTp === '') {
    return getCommonApiToken(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&contTp=${contTp}`, requestType, token);
  } return getCommonApiToken(`${CAMPUSXCEL_SADMIN_API_ROOT}?uTp=${uTp}&isSearch=${isSearch}&searchBy=${searchBy}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
};
