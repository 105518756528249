import React from 'react';
import {
  Button,
  CardImg,
  Col, Row, TabContent, TabPane,
} from 'reactstrap';
import '../../onboard.scss';
import Accordion from '../../../../../../utils/Accordion/Accordion';
import AddClient from '../../clients/add.client.component';
import { OrderProps } from '../../onboard.type';
import { ListClient } from '../../clients/list.client.component';
import AddClientUser from '../../clients/add.client-user.component';
import ListClientUser from '../../clients/list.client-user.component';

export const RecruiterComponent: React.FC<OrderProps> = ({ ordTp }) => {
  const [activeTab, setActiveTab] = React.useState('listRecruitOrg');

  return (
    <Row className="onboard-margin-top">
      <Col lg="3" xs="12">
        <div className="mx-2">
          <Accordion
            title={(
              <span className="input-card-header-sub-text">Recruiter Action Tabs</span>
          )}
            defaultOpen
            targetId="myCampus"
          >
            <div className="pt-1">
              <Button className="campus-onboard-button" active={activeTab === 'listRecruitOrg'} onClick={() => setActiveTab('listRecruitOrg')}>
                <CardImg src="/images/icons/recruit-org.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Recruit Org
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-onboard-button" active={activeTab === 'addRecruitOrg'} onClick={() => setActiveTab('addRecruitOrg')}>
                <CardImg src="/images/icons/org.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Add New Org
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-onboard-button" active={activeTab === 'listRecruitUser'} onClick={() => setActiveTab('listRecruitUser')}>
                <CardImg src="/images/icons/org-users.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                All User
              </Button>
            </div>
            <div className="pt-1">
              <Button className="campus-onboard-button" active={activeTab === 'addRecruitUser'} onClick={() => setActiveTab('addRecruitUser')}>
                <CardImg src="/images/icons/add-recruit-user.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                Add New User
              </Button>
            </div>
          </Accordion>
        </div>
      </Col>
      <Col lg="9" xs="12">
        <TabContent activeTab={activeTab}>
          {activeTab === 'listRecruitOrg' && (
          <TabPane tabId="listRecruitOrg">
            <div className="allign-start ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/recruit-org.png" alt="recruit-org" />
              <span className="cx-resume-header-text">Recruiter Organization</span>
            </div>
            <ListClient ordTp={ordTp} tabTp={activeTab} clientTp="RCRT" />
          </TabPane>
          )}

          {activeTab === 'addRecruitOrg' && (
          <TabPane tabId="addRecruitOrg">
            <div className="allign-start ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/org.png" alt="add-recruit-org" />
              <span className="cx-resume-header-text">Add New Organization</span>
            </div>
            <AddClient ordTp={ordTp} tabTp={activeTab} clientTp="RCRT" />
          </TabPane>
          )}

          {activeTab === 'listRecruitUser' && (
          <TabPane tabId="listRecruitUser">
            <div className="allign-start ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/org-users.png" alt="list-recruit-user" />
              <span className="cx-resume-header-text">Recruiters List</span>
            </div>
            <ListClientUser ordTp={ordTp} tabTp={activeTab} clientTp="RCRT" />
          </TabPane>
          )}

          {activeTab === 'addRecruitUser' && (
          <TabPane tabId="addRecruitUser">
            <div className="allign-start ml mb-3">
              <CardImg className="cx-resume-img-card" src="/images/icons/add-recruit-user.png" alt="add-recruit-user" />
              <span className="cx-resume-header-text">Add New Recruiter</span>
            </div>
            <AddClientUser ordTp={ordTp} tabTp={activeTab} clientTp="RCRT" />
          </TabPane>
          )}
        </TabContent>

      </Col>
    </Row>
  );
};

export default RecruiterComponent;
