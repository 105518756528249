import React from 'react';
import {
  faBars, faClose,
} from '@fortawesome/free-solid-svg-icons';
// , faShop
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './nav.scss';
import {
  Button,
  CardImg,
} from 'reactstrap';

export const CXATSNavbar: React.FC = () => {
  const history = useNavigate();
  const [click, setClick] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClick(!click);
  };

  const loginCorpPage = () => {
    history('/corp/login');
    window.scrollTo(0, 0);
  };

  const loginRecruitPage = () => {
    history('/recruit/login');
    window.scrollTo(0, 0);
  };

  return (
    <div className="navbar">
      <div className="align-start">
        <NavLink to="/" className="nav-logo">
          <CardImg
            style={{
              height: '45px', width: 'auto',
            }}
            src="/campusXcel.png"
            alt="campusXcelLogo"
          />
        </NavLink>
        <span className="ml pt-3 nav-text-logo">Talent Discovery</span>
      </div>
      <ul className={click ? 'nav-menu active pt-2 mx-3' : 'nav-menu pt-2 mx-3'}>
        {/* <li className="nav-item-login margin-mobile-bottom mx-2">
          <Button
            className="campus-ats-button-lite"
            to="/#"
            type="button"
            title="Login to CampusXcel Portal"
            onClick={loginPage}
          >
            <FontAwesomeIcon icon={faUserPlus} className="nav-icon-link-login ml" />
            <span className="mr-2 mx-2">Register</span>
          </Button>
        </li> */}
        <li className="nav-item-login margin-mobile-bottom mx-2">
          <Button
            className="campus-ats-button mr-1"
            to="/#"
            type="button"
            disabled
            title="Login to CampusXcel Portal"
            onClick={loginCorpPage}
          >
            <span className="mx-2">CORPORATE LOGIN</span>
          </Button>
          <Button
            className="campus-ats-button"
            to="/#"
            type="button"
            title="Login to CampusXcel Portal"
            onClick={loginRecruitPage}
          >
            <span className="mx-2">RECRUITER LOGIN</span>
          </Button>
        </li>
      </ul>
      <NavLink
        className="nav-icon mr-1"
        onClick={() => { handleClick(); }}
        to="#"
      >
        {changeIcon ? <FontAwesomeIcon icon={faClose} className="nav-close-btn" /> : <FontAwesomeIcon icon={faBars} className="nav-open-btn" />}
      </NavLink>
    </div>
  );
};

export default CXATSNavbar;
