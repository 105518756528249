import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { RootState } from 'typesafe-actions';
import {
  Button,
  Col,
  Form,
  Row,
} from 'reactstrap';
import '../../../../recruiter.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdatePayPhase4TypeProps } from '../../../../recruiter.type';
import {
  editJobsReq,
  uploadS3FilesReq,
} from '../../../../../../../../store/campusXcel/actions';
import {
  APIHeader, UserType,
} from '../../../../../../../../utils/constants';
import { getConfig } from '../../../../../../../../utils/config/config';
import { UploadS3FilesDataInput } from '../../../../../../../../services/common/upload-s3-files/upload-s3-files.types';

const { AWS_CXUSER_BUCKET } = getConfig();

export const UpdateJobPhase4: React.FC<UpdatePayPhase4TypeProps> = ({
  // jobPhase,
  setLoadingJob,
  setErrorShowAlert,
  setAlertMsg,
  tokenData,
  setAddJobRes,
  addJobRes,
  updatePh4JD,
  setUpdatePh4JD,
  // setUpdatePh3Pay,
  // setJobPhStatus,
  apiReqTp,
  setApiReqTp,
}) => {
  const dispatch = useDispatch();
  const updateJobPhase4Response = useSelector((state: RootState) => state.campusXcel.updateJob);
  const uploadJDS3AwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const [contents, setContents] = React.useState('');
  const [jDHtml, setJDHtml] = React.useState<UploadS3FilesDataInput>({
    fileData: new FormData(),
  });
  const handleEditorEvent = (content: string) => {
    setContents(content);
    const fileName = `${updatePh4JD.JobId}_JD.html`; // Name your file with the .html extension
    // Append the content as a Blob
    const formData = new FormData();
    formData.append(fileName, new Blob([content], { type: 'text/html' }));
    setJDHtml({ ...jDHtml, fileData: formData });
  };

  // const handleBackToPhase2 = () => {
  //   setApiReqTp('EDIT-BACK-REQ-TO-PH3');
  // };

  const handleUpdatePhase3Pay = (e: any) => {
    e.preventDefault();
    if (updatePh4JD.JobCd !== '' || updatePh4JD.JobId !== '') {
      if (apiReqTp === 'NEW-ADD-REQ-TO-PH4') {
        setLoadingJob(true);
        dispatch(uploadS3FilesReq({
          bucketNme: AWS_CXUSER_BUCKET,
          folderName: `staff-jobs/${updatePh4JD.JobCd}/${updatePh4JD.JobId}`,
          fileNmeInit: `${updatePh4JD.JobId}_JD`,
          formData: jDHtml.fileData,
          requestType: '',
          isEdit: false,
        }));
      }
      if (apiReqTp === 'EDIT-BACK-REQ-TO-PH4' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH4' || apiReqTp === 'PSTG4-req') {
        setLoadingJob(true);
        dispatch(uploadS3FilesReq({
          bucketNme: AWS_CXUSER_BUCKET,
          folderName: `staff-jobs/${updatePh4JD.JobCd}/${updatePh4JD.JobId}`,
          fileNmeInit: `${updatePh4JD.JobId}_JD`,
          formData: jDHtml.fileData,
          requestType: '',
          isEdit: false,
        }));
      }
    }
  };

  React.useEffect(() => {
    if (
      uploadJDS3AwsResponse.fileName !== ''
      && (apiReqTp === 'NEW-ADD-REQ-TO-PH4' || apiReqTp === 'PSTG4-req')
    ) {
      const extractedFileName = uploadJDS3AwsResponse.fileName.split('/').pop();
      // Create a temporary copy of the updated state
      const updatedPay = {
        ...updatePh4JD,
        jdFile: extractedFileName || '',
      };
      // Update state with the new file name
      setUpdatePh4JD(updatedPay);
      // Once the state is updated, dispatch the request
      if (extractedFileName) {
        dispatch(
          editJobsReq({
            inputBody: updatedPay,
            requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
            uTp: UserType.RCRT,
            token: tokenData,
            fldUpdtTp: 'PHASE4',
          }),
        );
      }
    }
  }, [uploadJDS3AwsResponse.fileName, apiReqTp]);

  React.useEffect(() => {
    if (updateJobPhase4Response.error && updateJobPhase4Response.message !== '') {
      setLoadingJob(false);
      setAlertMsg(updateJobPhase4Response.message);
      setErrorShowAlert(true);
    }
  }, [updateJobPhase4Response.error, updateJobPhase4Response.message]);

  React.useEffect(() => {
    if (!updateJobPhase4Response.error && updateJobPhase4Response.data.JobCd !== '' && updateJobPhase4Response.data.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH4') {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase4Response.data.JobCd,
        JobId: updateJobPhase4Response.data.JobId,
        NxtStg: updateJobPhase4Response.data.NxtStg,
      });
      setApiReqTp('NEW-ADD-REQ-TO-PH5');
      // dispatch(clearUploadS3FilesReq());
    }
  }, [updateJobPhase4Response.error, updateJobPhase4Response.data.JobCd, updateJobPhase4Response.data.JobId]);

  React.useEffect(() => {
    if (!updateJobPhase4Response.error && updateJobPhase4Response.data.JobCd !== '' && updateJobPhase4Response.data.JobId !== '' && (apiReqTp === 'EDIT-BACK-REQ-TO-PH4' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH4' || apiReqTp === 'PSTG4-req')) {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase4Response.data.JobCd,
        JobId: updateJobPhase4Response.data.JobId,
        NxtStg: updateJobPhase4Response.data.NxtStg,
      });
      setApiReqTp('EDIT-FORWARD-REQ-TO-PH5');
    }
  }, [updateJobPhase4Response.error, updateJobPhase4Response.data.JobCd, updateJobPhase4Response.data.JobId]);

  return (
    <div className="my-1">
      <Form className="job-add-card" onSubmit={handleUpdatePhase3Pay}>
        <div className="pt-2 mb-3 align-center">
          <span className="text-lite-grey-sub">Describe Your Job</span>
        </div>
        <Row className="align-center">
          <Col xs="12" lg="11">
            <div className="pt-2 mx-1">
              <SunEditor
                height="550px"
                width="auto"
                setOptions={{
                  buttonList: [
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                      'fontColor', 'hiliteColor',
                      'outdent', 'indent',
                      'align', 'horizontalRule', 'list', 'lineHeight',
                      'table', 'link', 'font', 'fontSize', 'formatBlock'],
                  ],
                }}
                setContents={contents || ''}
                onChange={handleEditorEvent}
              />
            </div>
            <div className="pt-3" />
            <hr className="hr-dasshed-lite" />
          </Col>
          <Col xs="6" lg="5">
            {/* <div className="align-start mx-2">
              <Button className="button-transparent-jobs mr-2" onClick={handleBackToPhase2}>
                <FontAwesomeIcon icon={faArrowLeft} className="mr" />
                Back
              </Button>
            </div> */}
          </Col>
          <Col xs="6" lg="5">
            <div className="align-end mx-2">
              <Button className="button-transparent">
                Continue
                <FontAwesomeIcon icon={faArrowRight} className="ml" />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="pt-4" />
    </div>
  );
};

export default UpdateJobPhase4;
