import { RegisterEmailMNTRSResponse, RegisterEmailMNTRSResults } from './register.service.types';

export const RegisterEmailMNTRSTransform: (response: RegisterEmailMNTRSResponse) => RegisterEmailMNTRSResults = (registerCMPSResults) => {
  const { data, is_error, message } = registerCMPSResults;
  const result: RegisterEmailMNTRSResults = {
    id: 0,
    message: '',
    error: false,
    errorMessage: '',
  };
  if (is_error) {
    result.error = is_error;
    result.errorMessage = message;
  } else {
    result.message = data.message;
    result.id = data.id;
  }
  return result;
};

// export const RegisterEmailMNTRSTransform: (response: RegisterEmailMNTRSResponse) => RegisterEmailMNTRSResults = (registerCMPSResults) => {
//   const { data, is_error, message } = registerCMPSResults;
//   const result: RegisterEmailMNTRSResults = {
//     message: '',
//     error: false,
//     data: {},
//   };
//   if (is_error) {
//     result.error = is_error;
//     // result.errorMessage = 'Backend Issue';
//     result.message = message;
//   } else {
//     result.data = data;
//   }
//   return result;
// };
