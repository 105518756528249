import { GetCertResponse, GetCertResults } from './get-cert.service.types';

export const GetCertTransform: (response: GetCertResponse) => GetCertResults = (certResults) => {
  const { data, is_error, message } = certResults;
  const result :GetCertResults = {
    cert: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.cert = data.cert ?? [];
    result.message = 'executed';
  }
  return result;
};
