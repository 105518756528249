import { ListClientUserResponse, ListClientUserResults } from './list-client-user.service.types';

export const ListClientUserTransform: (response: ListClientUserResponse) => ListClientUserResults = (listClientResults) => {
  const { data, is_error, message } = listClientResults;
  const result: ListClientUserResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        UserTp: '',
        UserCd: '',
      },
      usrData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
