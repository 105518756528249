import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardImg,
  Col, Nav, NavItem, Row, TabContent, TabPane,
} from 'reactstrap';
import '../../campus.scss';
// import { Industry } from './industry/industry.component';
// import Jobs from './jobs/jobs.component';
import { APIHeader, ApiLIMIT, UserType } from '../../../../../utils/constants';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../utils/service/localstorage-service';
import { getPostListReq } from '../../../../../store/campusXcel/actions';
import LoaderData from '../../../../../utils/loader';
import { errorAlert } from '../../../../../utils/alert';
import { ComingSoon } from '../../../../../scenes/unauthorized/coming-soon.component';
import { ListPostResult } from '../../../../../services/campus/dashboard/post/get-all-posts/list-post.types';
import College from '../../common/college/college.component';
// import { Personalized } from './personalized/post-dashboard.component';

export const CandidateDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const userProfile = getUserProfileLocalStorage();
  const [alertMsg, setAlertMsg] = useState('');
  const tokenData = getTokenFromLocalStorage();
  const [campusId, setCampusId] = useState('');
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [activeTab, setActiveTab] = React.useState('college');
  const [loaderDashB, setLoaderDashB] = useState(false);
  const postListData = useSelector((state: RootState) => state.campusXcel.getPostList);
  const [prevPostDataLengthRef, setPrevPostDataLengthRef] = useState(1);
  const [myPost, setMyPost] = useState<ListPostResult>({
    tCnt: 0,
    fCnt: 0,
    postData: [],
    isError: false,
    message: '',
  });

  React.useEffect(() => {
    setCampusId(userProfile && JSON.parse(userProfile).profileData.cmpsCd);
  }, [userProfile && JSON.parse(userProfile).profileData.cmpsCd]);

  const handleGetCollege = () => {
    setActiveTab('college');
  };
  const handleGetIndust = () => {
    setActiveTab('indust');
  };
  const handleGetJobs = () => {
    setActiveTab('jobs');
  };
  const handleGetPersonal = () => {
    setActiveTab('personal');
  };
  React.useEffect(() => {
    if (activeTab === 'college' && prevPostDataLengthRef === 1 && campusId !== '') {
      setLoaderDashB(true);
      dispatch(getPostListReq({
        requestType: APIHeader.REQ_GET_ALL_POST,
        limit: ApiLIMIT.LIMIT,
        uTp: UserType.CMPS,
        searchData: '',
        token: tokenData,
        acdId: campusId,
      }));
    }
  }, [activeTab, prevPostDataLengthRef, campusId]);
  React.useEffect(() => {
    if (postListData.isError && postListData.message !== '') {
      setLoaderDashB(false);
      setAlertMsg(postListData.message);
      setPrevPostDataLengthRef(0);
      setErrorShowAlert(true);
    }
  }, [postListData.isError, postListData.message]);

  React.useEffect(() => {
    if (!postListData.isError && postListData.message !== '') {
      Promise.resolve(
        postListData.postData !== undefined
          ? setMyPost(postListData)
          : setMyPost({ ...myPost, postData: [] }),
      ).then(() => {
        setPrevPostDataLengthRef(0);
      }).then(() => {
        setLoaderDashB(false);
      });
    }
  }, [postListData.isError, postListData.message]);

  return (
    <div className="border-tabs">
      { loaderDashB ? <LoaderData /> : null}
      {/* {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null} */}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="mx-1 margin-mob-dash pt-2">
        <Col lg="8" xs="12">
          <div className="pt-1">
            <Nav className="cx-tabs">
              <NavItem className={activeTab === 'college' ? 'cx-tab active' : 'cx-tab'} onClick={handleGetCollege}>
                <CardImg src="/images/icons/graduate-cap.png" className="pt-2" alt="Icon 1" />
                Campus
              </NavItem>
              <NavItem className={activeTab === 'indust' ? 'cx-tab active' : 'cx-tab'} onClick={handleGetIndust}>
                <CardImg src="/images/icons/industry.png" className="pt-2" alt="Icon 2" />
                Industry
              </NavItem>
              <NavItem className={activeTab === 'jobs' ? 'cx-tab active' : 'cx-tab'} onClick={handleGetJobs}>
                <CardImg src="/images/icons/jobs.png" className="pt-2" alt="Icon 3" />
                Jobs
              </NavItem>
              <NavItem className={activeTab === 'personal' ? 'cx-tab active' : 'cx-tab'} onClick={handleGetPersonal}>
                <CardImg src="/images/icons/saved.png" className="pt-2" alt="Icon 4" />
                Saved
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="college">
                <College myPost={myPost} setPrevPostDataLengthRef={setPrevPostDataLengthRef} />
              </TabPane>
              <TabPane tabId="indust">
                {/* <Industry /> */}
                <ComingSoon />
              </TabPane>
              <TabPane tabId="jobs">
                {/* <Jobs /> */}<ComingSoon />
              </TabPane>
              <TabPane tabId="personal">
                <ComingSoon />
              </TabPane>
            </TabContent>
          </div>
        </Col>
        <Col lg="4" xs="12">
          <div className="align-center my-2">
            New Features
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CandidateDashboard;
