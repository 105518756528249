import React from 'react';
import {
  CardImg,
  Col,
  Input,
  Label,
  Row,
  Button,
} from 'reactstrap';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CertDetails, CertInput } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';
import { errorAlert } from '../../../../../utils/alert';

export type ResumeInfoProps = {
  setResumeCert: (updater: (prevState: CertInput) => CertInput) => void;
  resumeCert: CertInput;
  handleDeleteCertImage: (filNme: string) => void;
};

export const XcelianCertificationsInfo: React.FC<ResumeInfoProps> = ({
  setResumeCert,
  resumeCert,
  handleDeleteCertImage,
}) => {
  const [alertMsg, setAlertMsg] = React.useState('');
  const [errorShowAlert, setErrorShowAlert] = React.useState(false);
  const [filePreviews, setFilePreviews] = React.useState<string[]>([]);
  const [fileExists, setFileExists] = React.useState<boolean[]>(Array(resumeCert.certData.length).fill(true));
  const [urlValidity, setUrlValidity] = React.useState(new Array(resumeCert.certData.length).fill(true));

  const checkUrl = (url: string) => new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });

  React.useEffect(() => {
    const checkUrls = async () => {
      const updatedValidity = await Promise.all(
        resumeCert.certData.map((cert, index) => checkUrl(cert.certUrl || '').then((isValid) => ({ index, isValid }))),
      );

      const newValidity = new Array(resumeCert.certData.length).fill(false);
      updatedValidity.forEach(({ index, isValid }) => {
        newValidity[index] = isValid;
      });

      setUrlValidity(newValidity); // Update state with the new validity array
    };

    checkUrls(); // Call the URL checking function
  }, [resumeCert.certData]);

  const addCertification = () => {
    const lastCert = resumeCert.certData[resumeCert.certData.length - 1];
    if (lastCert && (lastCert.certNme === '' || lastCert.desc === '' || lastCert.sDte === '' || lastCert.eDte === '')) {
      setAlertMsg('Some fields are empty. Kindly fill them before adding the next education entry.');
      setErrorShowAlert(true);
      return;
    }
    const newCertification: CertDetails = {
      certNme: '',
      desc: '',
      sDte: '',
      eDte: '',
      fileNme: '',
      certUrl: '',
      file: null,
    };

    setResumeCert((prevState) => ({
      ...prevState,
      certData: [...prevState.certData, newCertification],
    }));
  };

  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i += 1) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  const generateUniqueFileName = (originalName: string): string => {
    const randomString = generateRandomString(7);
    const extension = originalName.split('.').pop() || 'png';
    return `${randomString}-${Date.now()}.${extension}`;
  };

  const handleCertImage = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const file = files[0]; // Only handle one file
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB limit

    if (file.size > maxSizeInBytes) {
      setAlertMsg('File size exceeds the limit of 2 MB.');
      setErrorShowAlert(true);
      return;
    }

    const uniqueName = generateUniqueFileName(file.name);
    const newFile = new File([file], uniqueName, { type: file.type });
    const objectUrl = URL.createObjectURL(newFile);

    // Set file previews for the specific certification
    setFilePreviews((prev) => {
      const updatedPreviews = [...prev];
      updatedPreviews[index] = objectUrl; // Update the preview for this specific index
      return updatedPreviews;
    });

    // Update resumeCert with new file name and file object for the specific certification
    setResumeCert((prevState) => {
      const updatedCertData: CertDetails[] = [...prevState.certData];
      if (updatedCertData[index]) {
        updatedCertData[index].file = newFile;
        updatedCertData[index].fileNme = uniqueName; // Update the unique file name
      }

      return {
        ...prevState,
        certData: updatedCertData,
      } as CertInput;
    });
  };

  const handleInputChange = (index: number, field: keyof CertDetails, value: string | File | null) => {
    const updatedCertData: CertDetails[] = [...resumeCert.certData];
    if (index >= 0 && index < updatedCertData.length) {
      updatedCertData[index] = { ...updatedCertData[index], [field]: value };
    }
    setResumeCert((prevState) => ({
      ...prevState,
      certData: updatedCertData,
    }));
  };

  React.useEffect(() => {
    const newFileExists = resumeCert.certData.map((cert) => !!cert.certUrl); // Or any other check
    setFileExists(newFileExists);
  }, [resumeCert.certData]);

  const removeCert = (index: number) => {
    const updatedCertData = resumeCert.certData.filter((_, i) => i !== index);
    setResumeCert((prevState) => ({
      ...prevState,
      certData: updatedCertData,
    }));
    const newFileExists = updatedCertData.map((cert) => !!cert.certUrl); // Or any other check
    setFileExists(newFileExists);
  };

  return (
    <div>
      {errorShowAlert ? (
        errorAlert(false, alertMsg, errorShowAlert, setErrorShowAlert)
      ) : null}
      {resumeCert.certData.map((cert, index) => (
        <div key={`${index.toString()}`}>
          <div className="align-end">
            <span className="text-lite mb-2">{fileExists[index] ? 'Clear Certificate Image Before Deleting' : null}</span>
            <Button disabled={fileExists[index]} className="button-icon" onClick={() => removeCert(index)}>
              <FontAwesomeIcon className="xcelian-resume-project-icon" icon={faMinusSquare} />
            </Button>
          </div>
          <Row className="align-center">
            <Col xs="12">
              <Label for={`certNme${index}`} className="text-lite ml">Certificate Name</Label>
              <Input
                type="text"
                id={`certNme${index}`}
                value={cert.certNme}
                onChange={(e) => handleInputChange(index, 'certNme', e.target.value)}
                className="campus-input"
              />
            </Col>
            <Col xs="12 pt-4">
              <Label for={`desc${index}`} className="text-lite ml">Description</Label>
              <Input
                type="textarea"
                id={`desc${index}`}
                value={cert.desc}
                onChange={(e) => handleInputChange(index, 'desc', e.target.value)}
                className="campus-text-area-desc"
              />
            </Col>
            <Col xs="12 pt-4" lg="6 pt-4">
              <Label for={`sDte${index}`} className="text-lite ml">Start Date</Label>
              <Input
                type="date"
                id={`sDte${index}`}
                value={cert.sDte}
                onChange={(e) => handleInputChange(index, 'sDte', e.target.value)}
                className="campus-input"
              />
            </Col>
            <Col xs="12 pt-4" lg="6 pt-4">
              <Label for={`eDte${index}`} className="text-lite ml">End Date</Label>
              <Input
                type="date"
                id={`eDte${index}`}
                value={cert.eDte}
                onChange={(e) => handleInputChange(index, 'eDte', e.target.value)}
                className="campus-input"
              />
            </Col>
            <Col xs="12 pt-4">
              <Label for={`certImage${index}`} className="text-lite ml">Upload Certificate Image</Label>
              <div className="upload-box align-center">
                <Input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="upload-box-input"
                  id={`certImage${index}`}
                  onChange={(e) => handleCertImage(e, index)}
                />
                <span className="register-file-link">Choose Certificate</span>
              </div>
            </Col>
            <Col xs="12 pt-3" lg="10 pt-3">
              <div className="align-center">
                {urlValidity[index] && cert.certUrl ? (
                  <>
                    <CardImg
                      src={cert.certUrl}
                      alt={`File Preview ${index}`}
                    />
                    <Button
                      onClick={() => handleDeleteCertImage(cert.fileNme)}
                      className="button-icon padding-zero ml-1 mb-1"
                      aria-label={`Delete ${cert.fileNme}`}
                    >
                      &times;
                    </Button>
                  </>
                ) : (
                  filePreviews[index] && (
                  <CardImg src={filePreviews[index]} alt={`File Preview ${index}`} />
                  )
                )}
              </div>
            </Col>
          </Row>
          <hr className="hr-lite my-4" />
        </div>
      ))}
      <div className="align-center">
        <Button className="campus-button-all" onClick={addCertification}>Add Certification</Button>
      </div>
    </div>
  );
};

export default XcelianCertificationsInfo;
