import React, { useState } from 'react';
import {
  Button, CardImg, Col, Form, Input, InputGroup, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import Select from 'react-select';
import '../../recruiter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  addStaffClientReq,
  clearAddStaffClientReq,
  clearGetS3CxMetaReq,
  clearUpdateS3CxMetaReq,
  getS3CxMetaReq,
  updateS3CxMetaReq,
} from '../../../../../../store/campusXcel/actions';
import { getConfig } from '../../../../../../utils/config/config';
import { MetaRecord } from '../../../../../../services/aIntelligence/get-all-cx-meta/get-s3-cx-meta.types';
import { MetaInput } from '../../../../../../services/aIntelligence/update-cx-meta/update-s3-cx-meta.types';
import { StaffClientAddDataInput } from '../../../../../../services/staffing/clients/add-clients/add-new-clients.types';
import { DialCountrInfo } from '../../../../../../utils/MetaData/country';
import { AddClientTypeProps } from '../../recruiter.type';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { errorAlert } from '../../../../../../utils/alert';

const { AWS_CXADMIN_BUCKET } = getConfig();

export const AdminStaffAddClients: React.FC<AddClientTypeProps> = ({
  setOperationTp,
  setAlertMsg,
  setLoaderClient,
  setSuccessShowAlert,
  setErrorShowAlert,
  client_id,
  operationTp,
  tokenData,
}) => {
  const dispatch = useDispatch();
  const [modalAddStaffClient, setModalAddStaffClient] = useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const [staffClientListData, setStaffClientListData] = useState<MetaRecord[]>([]);
  const staffClientAddMetaResponse = useSelector((state: RootState) => state.campusXcel.updateS3CxMeta);
  const getStaffClientResponse = useSelector((state: RootState) => state.campusXcel.getS3CxMeta);
  const staffClientAddResponse = useSelector((state: RootState) => state.campusXcel.addStaffClient);
  const [newStaffClient, setNewStaffClient] = useState<MetaInput>({
    nme: '',
    cty: '',
    st: '',
  });
  const [staffClient, setStaffClient] = useState<StaffClientAddDataInput>({
    mClnt: client_id,
    nme: '',
    cntEId: '',
    dlCd1: '+91',
    cntPh1: '',
    citySt: '',
    dlCd2: '+91',
    cntPh2: '',
    txId: '',
    web: '',
  });

  React.useEffect(() => {
    if (operationTp === 'add-client') {
      setLoaderClient(true);
      dispatch(getS3CxMetaReq({
        bucketName: AWS_CXADMIN_BUCKET,
        fileName: 'recruiter-org.json',
        folderName: 'cx-meta-data',
        search: '',
        maxResults: 30,
        nextToken: '',
        requestType: '',
      }));
    }
  }, [operationTp]);

  React.useEffect(() => {
    if (staffClient.mClnt === '') {
      setStaffClient({ ...staffClient, mClnt: client_id });
    }
  }, [client_id]);

  React.useEffect(() => {
    if (getStaffClientResponse.success) {
      setLoaderClient(false);
      setStaffClientListData((prevData) => {
        const existingNames = new Set(prevData.map((record) => record.nme));
        const uniqueNewData = getStaffClientResponse.data.filter((record) => !existingNames.has(record.nme));
        return [...prevData, ...uniqueNewData];
      });
      dispatch(clearGetS3CxMetaReq());
    }
  }, [getStaffClientResponse.success]);

  const handleChangeClient = (event: any) => {
    setStaffClient({ ...staffClient, [event.target.name]: event.target.value });
  };

  const handleChangeNewClient = (event: any) => {
    setNewStaffClient({ ...newStaffClient, [event.target.name]: event.target.value });
  };

  const handleOpenStaffClientModal = () => {
    setModalAddStaffClient(!modalAddStaffClient);
  };

  const staffNameoptions = staffClientListData.map((org) => ({
    label: `${org.nme}`,
    value: `${org.nme}|${org.cty}|${org.st}`,
    city: org.cty,
    state: org.st,
  }));
  const selectedStaffClientNameOptions = staffNameoptions && staffNameoptions.find(
    (option) => (option.value === `${staffClient.nme}|${staffClient.citySt}`),
  );

  const dialNameoptions1 = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions1 = dialNameoptions1 && dialNameoptions1.find(
    (option) => option.value === staffClient.dlCd1,
  );

  const dialNameoptions2 = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions2 = dialNameoptions2 && dialNameoptions2.find(
    (option) => option.value === staffClient.dlCd2,
  );

  const handleSelectDialData1 = (e:any) => {
    setStaffClient({ ...staffClient, dlCd1: e.value });
  };

  const handleSelectDialData2 = (e:any) => {
    setStaffClient({ ...staffClient, dlCd2: e.value });
  };

  const handleInputStaffClientChange = (e:string) => {
    if (e) {
      setLoaderClient(true);
      dispatch(getS3CxMetaReq({
        bucketName: AWS_CXADMIN_BUCKET,
        fileName: 'recruiter-org.json',
        folderName: 'cx-meta-data',
        search: e,
        maxResults: 20,
        nextToken: '',
        requestType: '',
      }));
    }
  };
  const handleSelectStaffClientData = (selectedOption: any) => {
    if (selectedOption) {
      setStaffClient({
        ...staffClient,
        nme: selectedOption.label,
        citySt: `${selectedOption.city}, ${selectedOption.state}`,
      });
    }
  };

  const handleUpdateMetaData = (e: any) => {
    e.preventDefault();
    setLoaderClient(true);
    dispatch(updateS3CxMetaReq({
      bucketNme: AWS_CXADMIN_BUCKET,
      fileName: 'recruiter-org.json',
      folderName: 'cx-meta-data',
      inputBody: newStaffClient,
      requestType: '',
    }));
  };
  React.useEffect(() => {
    if (staffClientAddMetaResponse.message !== '') {
      setLoaderClient(false);
      if (staffClientAddMetaResponse.success) {
        setModalAddStaffClient(false);
        setAlertMsg(staffClientAddMetaResponse.message);
        setSuccessShowAlert(true);
        setNewStaffClient({
          nme: '',
          cty: '',
          st: '',
        });
      } else {
        setAlertMsg(staffClientAddMetaResponse.message);
        setErrorShowAlert(true);
      }
      dispatch(clearUpdateS3CxMetaReq());
    }
  }, [staffClientAddMetaResponse.message]);

  const handleSubmitStaffClient = (e: any) => {
    e.preventDefault();
    const validEmail = emailRegex.test(staffClient.cntEId);
    const validPhone1 = phoneRegex.test(staffClient.cntPh1);
    if (validEmail && validPhone1) {
      setLoaderClient(true);
      dispatch(addStaffClientReq({
        inputBody: staffClient,
        requestType: APIHeader.REQ_ONBOARD_CLIENT,
        uTp: UserType.RCRT,
        token: tokenData,
      }));
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone1) {
      setIsValidPhone(true);
    }
  };

  React.useEffect(() => {
    if (staffClientAddResponse.error && staffClientAddResponse.message !== '') {
      setLoaderClient(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      setAlertMsg(staffClientAddResponse.message);
      setErrorShowAlert(true);
      dispatch(clearAddStaffClientReq());
    }
  }, [staffClientAddResponse.error, staffClientAddResponse.message]);

  React.useEffect(() => {
    if (!staffClientAddResponse.error && staffClientAddResponse.message !== '') {
      setLoaderClient(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      Promise.resolve(dispatch(clearAddStaffClientReq()))
        .then(() => {
          setStaffClient({
            mClnt: client_id,
            nme: '',
            cntEId: '',
            dlCd1: '+91',
            cntPh1: '',
            citySt: '',
            dlCd2: '+91',
            cntPh2: '',
            txId: '',
            web: '',
          });
          setAlertMsg(staffClientAddResponse.message);
          setSuccessShowAlert(true);
          setOperationTp('list-client');
        });
    }
  }, [staffClientAddResponse.error, staffClientAddResponse.message]);
  return (
    <div className="pt-5">
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      <Row className="align-center">
        <Col xs="12" lg="10">
          <Row className="recruit-bg align-center">
            <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
              <CardImg src="/images/ats/add-client.png" alt="add-client" />
            </Col>
            <Col lg={{ size: 6, order: 2 }} xs={{ order: 1 }}>
              <div className="recruit-card my-2">
                <div className="align-center my-4">
                  <span className="text-lite-grey">Add New Client</span>
                </div>
                <Form onSubmit={handleSubmitStaffClient}>
                  <Row className="align-center my-4">
                    <Col lg="11" xs="12">
                      <div className="align-end mr-1">
                        <Button className="button-transparent align-center" onClick={handleOpenStaffClientModal}>
                          Add New
                        </Button>
                      </div>
                      <div className="text-right-login-campus-register">
                        <Select
                          value={staffClient.nme === '' ? null : selectedStaffClientNameOptions}
                          options={staffNameoptions}
                          onChange={handleSelectStaffClientData}
                          onInputChange={handleInputStaffClientChange}
                          styles={{
                            control: (base: any) => ({
                              ...base,
                              '&:hover': { borderColor: '#8B0000' },
                              border: '1px solid #DDE0E4',
                              minHeight: '50px',
                              borderRadius: '10px',
                              padding: 1,
                              textAlign: 'left',
                              fontSize: '15px',
                              fontWeight: 400,
                              color: '#575656',
                              boxShadow: 'none',
                              backgroundColor: '#fff',
                              '&:focus': {
                                borderColor: '#E1EDF8',
                                boxShadow: 'none',
                                color: '#575656',
                                background: '#fff',
                              },
                            }),
                            option: (provided: any, state: { isSelected: any; }) => ({
                              ...provided,
                              color: state.isSelected ? '#383838' : '#212121',
                              padding: 15,
                              textAlign: 'left',
                              background: state.isSelected ? '#FAFCFB' : '#fff',
                              '&:hover': {
                                background: '#FAFCFB',
                              },
                            }),
                          }}
                          noOptionsMessage={() => 'No Staff Client Found'}
                          placeholder="Select Staff Client..."
                        />
                      </div>
                    </Col>
                    <Col lg="11" xs="12">
                      <div className="text-right-login-campus-register pt-4 mx-1">
                        <InputGroup>
                          <Select
                            value={staffClient.dlCd1 === '' ? null : selectedDialNameOptions1}
                            options={dialNameoptions1}
                            onChange={handleSelectDialData1}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#8B0000' },
                                border: '1px solid #DDE0E4',
                                minHeight: '50px',
                                borderRadius: '10px',
                                padding: 1,
                                textAlign: 'left',
                                fontSize: '15px',
                                fontWeight: 400,
                                color: '#575656',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                // border: '1px solid #F9CB9C',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                            noOptionsMessage={() => 'No Dial Code Found'}
                          />
                          <Input
                            placeholder="Phone Number 1"
                            type="number"
                            name="cntPh1"
                            value={staffClient.cntPh1}
                            onChange={handleChangeClient}
                            className="campus-input mx-1"
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col lg="11" xs="12">
                      <div className="text-right-login-campus-register pt-4 mx-1">
                        <InputGroup>
                          <Select
                            value={staffClient.dlCd2 === '' ? null : selectedDialNameOptions2}
                            options={dialNameoptions2}
                            onChange={handleSelectDialData2}
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#8B0000' },
                                border: '1px solid #DDE0E4',
                                minHeight: '50px',
                                borderRadius: '10px',
                                padding: 1,
                                textAlign: 'left',
                                fontSize: '15px',
                                fontWeight: 400,
                                color: '#575656',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                // border: '1px solid #F9CB9C',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                            noOptionsMessage={() => 'No Dial Code Found'}
                          />
                          <Input
                            placeholder="Phone Number 2"
                            type="number"
                            name="cntPh2"
                            value={staffClient.cntPh2}
                            onChange={handleChangeClient}
                            className="campus-input mx-1"
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col lg="11" xs="12">
                      <div className="text-right-login-campus-register pt-4 mx-1">
                        <Input
                          placeholder="Contact Email"
                          type="text"
                          name="cntEId"
                          value={staffClient.cntEId}
                          onChange={handleChangeClient}
                          className="campus-input"
                        />
                      </div>
                    </Col>
                    <Col lg="11" xs="12">
                      <div className="text-right-login-campus-register pt-4 mx-1">
                        <Input
                          placeholder="GST Info"
                          type="text"
                          name="txId"
                          value={staffClient.txId}
                          onChange={handleChangeClient}
                          className="campus-input"
                        />
                      </div>
                    </Col>
                    <Col lg="11" xs="12">
                      <div className="text-right-login-campus-register pt-4 mx-1">
                        <Input
                          placeholder="Any Website"
                          type="text"
                          name="web"
                          value={staffClient.web}
                          onChange={handleChangeClient}
                          className="campus-input"
                        />
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="align-center my-3">
                        <Button className="campus-button-all" disabled={staffClient.mClnt === '' || staffClient.nme === ''}>
                          SAVE
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Modal isOpen={modalAddStaffClient}>
                  <ModalHeader className="my-2 mx-2" title="Add new StaffClient" toggle={handleOpenStaffClientModal}>
                    <span className="proj-text-color ml">Add new Staff Client</span>
                  </ModalHeader>
                  <ModalBody className="mx-2">
                    <Row className="chip-box align-center">
                      <Col lg="10" xs="12">
                        <div className="text-right-login-campus-register pt-4 mx-1">
                          <Input
                            placeholder="Client Name"
                            type="text"
                            name="nme"
                            required
                            value={newStaffClient.nme}
                            onChange={handleChangeNewClient}
                            className="campus-input"
                          />
                        </div>
                      </Col>
                      <Col lg="10" xs="12">
                        <div className="text-right-login-campus-register pt-4 mx-1">
                          <Input
                            placeholder="City"
                            type="text"
                            name="cty"
                            required
                            value={newStaffClient.cty}
                            onChange={handleChangeNewClient}
                            className="campus-input"
                          />
                        </div>
                      </Col>
                      <Col lg="10" xs="12">
                        <div className="text-right-login-campus-register pt-4 mx-1">
                          <Input
                            placeholder="State"
                            type="text"
                            name="st"
                            required
                            value={newStaffClient.st}
                            onChange={handleChangeNewClient}
                            className="campus-input"
                          />
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="align-center my-3 pt-2">
                          <Button className="campus-button-all mx-1" onClick={handleUpdateMetaData}>
                            SAVE
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AdminStaffAddClients;
