import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import ReactPlayer from 'react-player';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useReactMediaRecorder } from 'react-media-recorder';
import './video.scss'; // Ensure you have your CSS file imported
import { VideoProps } from './video.type';
import { uploadS3FilesReq } from '../../../../../store/campusXcel/actions';
import { LoaderData } from '../../../../../utils/loader';

export const ResumeVideo: React.FC<VideoProps> = ({
  fileNameId, awsFolder, awsBucket, setUploadFileName, setErrorShowAlert, setAlertMsg,
}) => {
  const dispatch = useDispatch();
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [duration, setDuration] = useState<number>(60);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const countdownRef = useRef<NodeJS.Timeout | null>(null);
  const [loaderVideo, setLoaderVideo] = useState(false);
  const uploadS3FilesAwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);

  const { startRecording, stopRecording } = useReactMediaRecorder({
    video: true,
    onStop: (blobUrl) => {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          setVideoBlob(blob);
          const url = URL.createObjectURL(blob);
          setVideoPreviewUrl(url);
          setIsRecording(false);
          clearInterval(countdownRef.current as NodeJS.Timeout); // Clear timer when recording stops
        });
    },
  });

  const initializeMediaStream = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    mediaStreamRef.current = stream;
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  };

  useEffect(() => {
    initializeMediaStream();

    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(
    () => () => {
      if (videoPreviewUrl) {
        URL.revokeObjectURL(videoPreviewUrl);
      }
    },
    [videoPreviewUrl],
  );

  const handleRecordingStart = () => {
    startRecording();
    setIsRecording(true);
    setDuration(60);

    // Start countdown timer
    countdownRef.current = setInterval(() => {
      setDuration((prev) => {
        if (prev <= 1) {
          stopRecording();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleRecordingStop = () => {
    stopRecording();
    setIsRecording(false);
    clearInterval(countdownRef.current as NodeJS.Timeout);
  };

  const handleUpload = async () => {
    if (!videoBlob) {
      setErrorShowAlert(true);
      setAlertMsg('No video recorded to upload.');
      return;
    }
    setLoaderVideo(true); // Set the loading state to true while the upload is in progress
    const formData = new FormData();
    try {
      const videoFileName = `${fileNameId}.mp4`;
      const videoFile = new File([videoBlob], videoFileName, { type: 'video/mp4' });
      formData.append(videoFile.name, videoFile);
      dispatch(uploadS3FilesReq({
        bucketNme: awsBucket,
        folderName: awsFolder,
        fileNmeInit: videoFileName,
        formData,
        requestType: '',
        isEdit: false,
      }));
    } catch (error) {
      setErrorShowAlert(true);
      setAlertMsg('An error occurred while uploading the video.');
    } finally {
      setLoaderVideo(false);
    }
  };

  React.useEffect(() => {
    if (uploadS3FilesAwsResponse.fileName !== '') {
      setLoaderVideo(false);
      const resFileName: string = uploadS3FilesAwsResponse.fileName ? uploadS3FilesAwsResponse.fileName.split('/').pop() || '' : '';
      setAlertMsg('Uploaded Successfully');
      setUploadFileName(resFileName);
    }
  }, [uploadS3FilesAwsResponse.fileName]);

  const handleRecordAgain = async () => {
    // Clear previous video blob and URL
    setVideoBlob(null);
    setVideoPreviewUrl(null);

    // Reinitialize media stream
    await initializeMediaStream();
  };

  return (
    <div className="video-container">
      { loaderVideo ? <LoaderData /> : null}
      {!videoBlob ? (
        <div>
          <div className="recording-section">
            <video
              ref={videoRef}
              style={{ width: '100%', height: 'auto' }}
              autoPlay
              muted
            />
            {isRecording && (
            <>
              <div className="timer-counter">
                {`${Math.floor(duration / 60)}:${duration % 60 < 10 ? '0' : ''}${duration % 60}`}
              </div>
              <div className="timer-bar">
                <div className="progress" style={{ width: `${(duration / 60) * 100}%` }} />
              </div>
            </>
            )}
          </div>
          <div className="my-2 pt-2 align-center">
            <Button
              onClick={handleRecordingStart}
              disabled={isRecording}
              className="video-button-start mr"
            >
              Start Recording
            </Button>
            {isRecording
              ? (
                <Button
                  onClick={handleRecordingStop}
                  className="video-button-stop"
                >
                  Stop Recording
                </Button>
              ) : null}
          </div>
        </div>
      ) : (
        <div className="preview-section">
          <span className="video-preview-text">Video Preview</span>
          {videoPreviewUrl && (
            <ReactPlayer
              url={videoPreviewUrl}
              controls
              width="100%"
              height="auto"
              playing={!!videoPreviewUrl}
            />
          )}
          <Button onClick={handleUpload} className="video-button-start mr">Upload Video</Button>
          <Button onClick={handleRecordAgain} className="video-button-stop">Record Again</Button>
        </div>
      )}
    </div>
  );
};

export default ResumeVideo;
