import { BatchAddResponse, BatchAddResults } from './add-batch.service.types';

export const BatchAddTransform: (response: BatchAddResponse) => BatchAddResults = (batchResults) => {
  const { data, is_error, message } = batchResults;
  const result :BatchAddResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
    result.data = data;
  }
  return result;
};
