import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, Row, Col,
  CardHeader,
  Card,
  CardImg,
} from 'reactstrap';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { JobIdResponseData } from '../../../../../../../../services/staffing/job-board/update-job-data/update.job.data.types';
import { JobPrivateData } from '../../../../../../../../services/staffing/job-board/list-jobs-private/list-jobs.private.types';

    interface TableProps {
      data: JobPrivateData;
      addJobRes: JobIdResponseData;
      setAddJobRes: (addJobRes: JobIdResponseData) => void;
      setApiReqTp: (apiReqTp: string) => void;
    }

const AllPendingPrivateJobsTable: React.FC<TableProps> = ({
  data,
  addJobRes,
  setAddJobRes,
  setApiReqTp,
}) => {
  const handleUpdatePendingJob = (jobCd: string, jobId: string, jobStatus: string) => {
    setAddJobRes({
      ...addJobRes, JobCd: jobCd, JobId: jobId, NxtStg: jobStatus,
    });
    if (jobStatus === 'PSTG1') {
      setApiReqTp('PSTG2-req');
    }
    if (jobStatus === 'PSTG2') {
      setApiReqTp('PSTG3-req');
    }
    if (jobStatus === 'PSTG3') {
      setApiReqTp('PSTG4-req');
    }
    if (jobStatus === 'PSTG4') {
      setApiReqTp('PSTG5-req');
    }
    if (jobStatus === 'PSTG5') {
      setApiReqTp('PSTG6-req');
    }
  };

  return (
    <div className="">
      {data.jobsData.length > 0
        ? (
          <div>
            <Row className="align-start">
              {data.jobsData.map((jobData, index) => (
                <Col lg="4" xs="12" key={`${index.toString()}`}>
                  <Card className="card-priv-joblist my-1">
                    <CardHeader className="form-card-header">
                      <Row>
                        <Col xs="12" lg="12">
                          <div className="my-1">
                            <CardImg className="recruit-menu-img-cx mr" src="/images/icons/jobs-pending.png" alt="jobs" />
                            <span className="dashboard-post-text">{jobData.nme}</span>
                          </div>
                        </Col>
                        <Col xs="12" lg="12">
                          <Row className="pt-2">
                            <Col xs="7" lg="8">
                              <div className="align-start ml">
                                <span className="dashboard-sub-post-text">{jobData.cNme}</span>
                              </div>
                            </Col>
                            <Col xs="5" lg="4">
                              <div className="align-end mr">
                                <Button
                                  className="job-button-notify padding-zero"
                                  onClick={() => handleUpdatePendingJob(jobData.JobCd, jobData.JobId, jobData.jobSts)}
                                >
                                  <FontAwesomeIcon icon={faClock} /> Pending
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardHeader>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : null}
    </div>
  );
};

export default AllPendingPrivateJobsTable;
