import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Card, CardImg, Nav, NavItem, TabContent, TabPane,
  NavLink,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResumeUploader from './read.resumes';
import { getTokenFromLocalStorage } from '../../../../../../../utils/service/localstorage-service';
import JobInterviewComponent from './inerview-by-job/jobs.interview.component';
import { clearShareUrlInterviewReq, shareUrlInterviewReq } from '../../../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../../../utils/constants';
import { LoaderData } from '../../../../../../../utils/loader';

const JobResumeComponent: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const isMobile = window.innerWidth <= 468;
  const tokenData = getTokenFromLocalStorage();
  const { jobOrg, jobId } = useParams();
  const location = useLocation();
  const [errorAlert, setErrorAlert] = React.useState('');
  const [interviewAlert, setInterviewAlert] = React.useState('');
  const params = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState('interview');
  const jobName = params.get('nme');
  const forClnt = params.get('forClnt');
  const getUrlShareResponse = useSelector((state: RootState) => state.campusXcel.shareUrlInterview);

  const copyToClipboard = (shareUrl: string, jobDetId: string) => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setInterviewAlert(`DESK-${jobDetId}`);
    });
  };

  const shareOnMobile = async (shareUrl: string, jobDetId: string) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'CampusXcel News Article',
          text: 'Check out this news article on CampusXcel!',
          url: shareUrl,
        });
        setInterviewAlert(`MOB-${jobDetId}`); // Sharing was successful
      } catch (error) {
        setInterviewAlert(`MOB-${jobDetId}`);
        setErrorAlert('Sharing canceled by user');
      }
    } else {
      setInterviewAlert(`MOB-${jobDetId}`);
      setErrorAlert('Sharing canceled by user');
    }
  };

  const handleGetUrlDetails = (JobCode: string, JobId: string) => {
    setLoading(true);
    dispatch(shareUrlInterviewReq({
      requestType: APIHeader.REQ_ALL_INTERVIEW_RECRUIT_SHARE_URL,
      uTp: UserType.RCRT,
      token: tokenData,
      jobCd: JobCode,
      jobId: JobId,
      intvCd: JobId,
    }));
  };

  React.useEffect(() => {
    if (getUrlShareResponse.isError && getUrlShareResponse.message !== '') {
      setLoading(false);
      setErrorAlert(getUrlShareResponse.message);
      if (isMobile) {
        setInterviewAlert(`MOB-${jobId}`);
      } else {
        setInterviewAlert(`DESK-${jobId}`);
      }
      dispatch(clearShareUrlInterviewReq());
    }
  }, [getUrlShareResponse.isError, getUrlShareResponse.message]);

  React.useEffect(() => {
    if (
      !getUrlShareResponse.isError
      && getUrlShareResponse.message === 'executed'
      && jobId // Check if jobId is defined and not an empty string
    ) {
      setLoading(false);
      const paramsDet = new URLSearchParams({
        nmeJob: jobName || '',
      }).toString();
      const newUrl = `${window.location.origin}/interview/${jobOrg}/${getUrlShareResponse.data}?${paramsDet}`;
      if (isMobile) {
        shareOnMobile(newUrl, jobId);
      } else {
        copyToClipboard(newUrl, jobId);
      }
    }
    dispatch(clearShareUrlInterviewReq());
  }, [getUrlShareResponse.isError, getUrlShareResponse.message, jobId]);

  return (
    <div className="pt-2">
      { loading ? <LoaderData /> : null}
      <div className="allign-start ml">
        <CardImg className="cx-main-header-img-card" src="/images/icons/job-info.png" alt="job" />
        <span className="text-lite-grey ml">{jobId} | {jobName}</span>
        {isMobile ? (
          <Button
            id={`mobile-share-${jobId}`}
            className="button-icon padding-zero ml-1"
            onClick={() => {
              if (jobOrg && jobId) {
                handleGetUrlDetails(jobOrg, jobId);
              }
            }}
          >
            <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
          </Button>
        ) : (
          <Button
            id={`desktop-share-${jobId}`}
            className="button-icon padding-zero ml-1"
            onClick={() => {
              if (jobOrg && jobId) {
                handleGetUrlDetails(jobOrg, jobId);
              }
            }}
          >
            <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
          </Button>
        )}
        <UncontrolledTooltip
          delay={0}
          target={isMobile ? `mobile-share-${jobId}` : `desktop-share-${jobId}`}
        >
          {interviewAlert === `DESK-${jobId}` ? 'Copied to Clipboard'
            : interviewAlert === `MOB-${jobId}` && errorAlert === '' ? 'Share'
              : interviewAlert === `MOB-${jobId}` && errorAlert !== '' ? errorAlert
                : 'Share'}
        </UncontrolledTooltip>
      </div>
      <Row className="pt-3">
        <Col xs="12">
          <Nav tabs className="campus-onboard-tab">
            <div className="pt-1">
              <div className="campus-column-onboard-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'interview'} onClick={() => setActiveTab('interview')}>
                    <div className="align-center">
                      <CardImg src="/images/icons/cv-act.png" className="campus-tab-onboard-icon-img" alt="Icon 1" />
                    </div>
                    <div className="align-center">
                      <span className="campus-tab-text mx-1">Activity</span>
                    </div>
                  </NavLink>
                </NavItem>
              </div>
              <div className="campus-column-onboard-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'upload-resume'} onClick={() => setActiveTab('upload-resume')}>
                    <div className="align-center">
                      <CardImg src="/images/icons/resume-pdf.png" className="campus-tab-onboard-icon-img" alt="Icon 3" />
                    </div>
                    <div className="align-center">
                      <span className="campus-tab-text mx-1">Load CV</span>
                    </div>
                  </NavLink>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="interview">
              <JobInterviewComponent
                intvStatus="interview"
                interviewCd={jobId || ''}
              />
            </TabPane>
            <TabPane tabId="upload-resume">
              <div className="pt-4">
                <Row className="align-center">
                  <Col xs="12" lg="11">
                    <Card className="cx-jobs-view-card">
                      <div className="align-center pt-3">
                        <span className="chip-box-jobs-pvt">{jobName}</span>
                      </div>
                      <div className="mb-3">
                        <ResumeUploader
                          jobOrg={jobOrg || ''}
                          jobId={jobId || ''}
                          forClnt={forClnt || ''}
                          tokenData={tokenData}
                          jobNme={jobName || ''}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </Col>
        <div className="pt-5" />
      </Row>
      <div className="mb-3 p-4" />
    </div>
  );
};
export default JobResumeComponent;
