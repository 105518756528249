import { ListMetaJobsResponse, ListMetaJobsResults } from './list-meta-jobs.service.types';

export const ListMetaJobsTransform: (response: ListMetaJobsResponse) => ListMetaJobsResults = (listPJobsResults) => {
  const { data, is_error, message } = listPJobsResults;
  const result: ListMetaJobsResults = {
    metaJobs: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
    result.metaJobs = data;
  }
  return result;
};
