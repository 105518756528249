import { RecruitInterviewStatusPutResponse, RecruitInterviewStatusPutResults } from './update.recruit.sts.service.types';

export const RecruitInterviewStatusPutTransform: (response: RecruitInterviewStatusPutResponse) => RecruitInterviewStatusPutResults = (certResults) => {
  const { is_error, message } = certResults;
  const result :RecruitInterviewStatusPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};
