import React from 'react';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../recruiter.scss';
import {
  faChevronLeft, faChevronRight, faMagnifyingGlass, faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListPrivateJobsProps } from '../../../../recruiter.type';
import { JobPrivateData } from '../../../../../../../../services/staffing/job-board/list-jobs-private/list-jobs.private.types';
import { clearJobStatusReq, listJobsByStatusReq, listPrivateJobReq } from '../../../../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../../../../utils/constants';
import { errorAlert, successAlert } from '../../../../../../../../utils/alert';
import AllApprovedPrivateJobsTable from './approved.jobs.table';
import AllPendingPrivateJobsTable from './pending.jobs';
import LoaderData from '../../../../../../../../utils/loader';

export const ListJobsComponent: React.FC<ListPrivateJobsProps> = ({
  operationTp,
  tokenData,
  reqClientId,
  addJobRes,
  setAddJobRes,
  setApiReqTp,
}) => {
  const dispatch = useDispatch();
  const [loadingJob, setLoadingJob] = React.useState(false);
  const [loadingJobStatus, setLoadingJobStatus] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isUpdateSent, setIsUpdateSent] = React.useState('NO');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(30);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const privateJobsResponse = useSelector((state: RootState) => state.campusXcel.listPrivateJobs);
  const privatePendingJobsResponse = useSelector((state: RootState) => state.campusXcel.listJobsByStatus);
  const [allPendingJobData, setAllPendingJobData] = React.useState<JobPrivateData>({
    tCnt: 0,
    fCnt: 0,
    jobsData: [],
    lastEvalKey: {
      JobCd: '',
      JobId: '',
    },
  });
  const [, setAllCompletedJobData] = React.useState<JobPrivateData>({
    tCnt: 0,
    fCnt: 0,
    jobsData: [],
    lastEvalKey: {
      JobCd: '',
      JobId: '',
    },
  });

  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);

  React.useEffect(() => {
    if (operationTp === 'list-jobs' && reqClientId !== '') {
      setLoadingJob(true);
      dispatch(listPrivateJobReq({
        requestType: APIHeader.REQ_ALL_JOBS_PRIVATE,
        uTp: UserType.RCRT,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        uOrgCd: reqClientId,
      }));
    }
  }, [operationTp, reqClientId]);

  React.useEffect(() => {
    if (operationTp === 'list-jobs' && reqClientId !== '' && isUpdateSent === 'YES') {
      setLoadingJob(true);
      dispatch(listPrivateJobReq({
        requestType: APIHeader.REQ_ALL_JOBS_PRIVATE,
        uTp: UserType.RCRT,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        uOrgCd: reqClientId,
      }));
    }
  }, [operationTp, reqClientId, isUpdateSent]);

  React.useEffect(() => {
    if (operationTp === 'list-jobs' && reqClientId !== '') {
      setLoadingJob(true);
      dispatch(listJobsByStatusReq({
        requestType: APIHeader.REQ_ALL_JOBS_PRIVATE,
        uTp: UserType.RCRT,
        limit: 50,
        evalKey: '',
        evalTp: '',
        isFilter: 'YES',
        isSearch: 'NO',
        filterBy: 'PENDING',
        token: tokenData,
        uOrgCd: reqClientId,
      }));
    }
  }, [operationTp, reqClientId]);

  const fetchMoreData = () => {
    setLoadingJob(true);
    if (isSearchQuery !== '') {
      dispatch(listPrivateJobReq({
        requestType: APIHeader.REQ_ALL_JOBS_PRIVATE,
        uTp: UserType.RCRT,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        uOrgCd: reqClientId,
      }));
    } else {
      setLoadingJob(true);
      dispatch(listPrivateJobReq({
        requestType: APIHeader.REQ_ALL_JOBS_PRIVATE,
        uTp: UserType.RCRT,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        uOrgCd: reqClientId,
      }));
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingJob(true);
    dispatch(listPrivateJobReq({
      requestType: APIHeader.REQ_ALL_JOBS_PRIVATE,
      uTp: UserType.RCRT,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      uOrgCd: reqClientId,
    }));
  };

  const handleSearchProjects = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingJob(true);
    dispatch(listPrivateJobReq({
      requestType: APIHeader.REQ_ALL_JOBS_PRIVATE,
      uTp: UserType.RCRT,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      uOrgCd: reqClientId,
    }));
    setIsSearchQuery(searchText);
  };

  React.useEffect(() => {
    if (privateJobsResponse.isError && privateJobsResponse.message !== '') {
      setLoadingJob(false);
      setAlertMsg(privateJobsResponse.message);
      setErrorShowAlert(true);
      setIsUpdateSent('NO');
      dispatch(clearJobStatusReq());
    }
  }, [privateJobsResponse.isError, privateJobsResponse.message, isUpdateSent]);

  React.useEffect(() => {
    if (!privateJobsResponse.isError && privateJobsResponse.message === 'executed') {
      setLoadingJob(false);
      if (searchText !== '') {
        setPagedData({ [currentPage]: privateJobsResponse.data.jobsData });
      } else if (
        privateJobsResponse.data
        && Object.keys(privateJobsResponse.data).length > 0
        && Array.isArray(privateJobsResponse.data.jobsData)
      ) {
        const newPageData = privateJobsResponse.data.jobsData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllCompletedJobData({
          tCnt: privateJobsResponse.data.tCnt,
          fCnt: privateJobsResponse.data.fCnt,
          jobsData: privateJobsResponse.data.jobsData,
          lastEvalKey: privateJobsResponse.data.lastEvalKey,
        });
        if (privateJobsResponse.data.lastEvalKey !== null) {
          setEvalKeyText(privateJobsResponse.data.lastEvalKey.JobId);
          setEvalTpText(privateJobsResponse.data.lastEvalKey.JobCd);
        }
        setTotalRecords(privateJobsResponse.data.tCnt);
      }
    }
    setIsUpdateSent('NO');
    dispatch(clearJobStatusReq());
  }, [privateJobsResponse, isUpdateSent]);

  React.useEffect(() => {
    if (privatePendingJobsResponse.isError && privatePendingJobsResponse.message !== '') {
      setLoadingJob(false);
      setAlertMsg(privatePendingJobsResponse.message);
      setErrorShowAlert(true);
      setIsUpdateSent('NO');
      dispatch(clearJobStatusReq());
    }
  }, [privatePendingJobsResponse.isError, privatePendingJobsResponse.message]);

  React.useEffect(() => {
    if (!privatePendingJobsResponse.isError && privatePendingJobsResponse.message === 'executed') {
      setLoadingJob(false);
      setAllPendingJobData({
        tCnt: privatePendingJobsResponse.data.tCnt,
        fCnt: privatePendingJobsResponse.data.fCnt,
        jobsData: privatePendingJobsResponse.data.jobsData,
        lastEvalKey: privatePendingJobsResponse.data.lastEvalKey,
      });
    }
    setIsUpdateSent('NO');
    dispatch(clearJobStatusReq());
  }, [privatePendingJobsResponse, isUpdateSent]);

  return (
    <div className="pt-4">
      { loadingJobStatus ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {operationTp === 'list-jobs'
        ? (loadingJob ? (
          <div className="loader-campusxcel align-center1 pt-5">
            <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
            <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
          </div>
        ) : (
          <Row className="align-start">
            <Col xs="12" lg="12">
              <div className="mb-2 align-start ml-2">
                <span className="text-lite-grey-sub">{allPendingJobData.tCnt === 0 ? 'NO Pending Task' : 'Pending Task'}</span>
              </div>
              <AllPendingPrivateJobsTable
                data={allPendingJobData}
                addJobRes={addJobRes}
                setAddJobRes={setAddJobRes}
                setApiReqTp={setApiReqTp}
              />
            </Col>
            <Col xs="12" lg="12">
              <div className="my-4 align-center">
                <span className="text-lite-grey-sub">Job Results</span>
              </div>
              <Row className="mb-1 margin-mob-desk-pirv-jobs">
                <Col lg={{ size: 3, order: 1 }} xs={{ size: 5, order: 2 }}>
                  <div className="align-start margin-list-appr-job-mob pt-3">
                    <span className="text-lite">Total Records: {totalRecords}</span>
                  </div>
                </Col>
                <Col lg={{ size: 3, order: 3 }} xs={{ size: 7, order: 3 }}>
                  <div className="align-end margin-list-appr-job-mob pt-2">
                    <Button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                      className="table-button"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <div className="mx-2 table-margin-bot">
                      <span className="table-text">
                        {currentPage + 1} / {totalPages}
                      </span>
                    </div>
                    <Button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages - 1}
                      className="table-button"
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </div>
                </Col>
                <Col lg={{ size: 6, order: 2 }} xs={{ size: 12, order: 1 }}>
                  <div className="align-center margin-list-search-job-mob">
                    <div className="input-wrapper">
                      <Input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearchChange}
                      />
                      <Button type="button" onClick={handleSearchProjects}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </div>
                    <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                      <FontAwesomeIcon icon={faRefresh} />
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="cx-jobs-dashed-card-main">
                {pagedData[currentPage]
                  ? (
                    <AllApprovedPrivateJobsTable
                      data={pagedData[currentPage]}
                      reqClientId={reqClientId}
                      setIsUpdateSent={setIsUpdateSent}
                      tokenData={tokenData}
                      setLoadingJobStatus={setLoadingJobStatus}
                      setAlertMsg={setAlertMsg}
                      setErrorShowAlert={setErrorShowAlert}
                      setSuccessShowAlert={setSuccessShowAlert}
                    />
                  ) : null}
              </div>
              <Row className="padding-zero">
                <Col xs="12" lg="12">
                  <div className="align-end pt-2 mr-1">
                    <Button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                      className="table-button"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <div className="mx-2 table-margin-bot">
                      <span className="table-text">
                        {currentPage + 1} / {totalPages}
                      </span>
                    </div>
                    <Button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages - 1}
                      className="table-button"
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ))
        : null}
    </div>
  );
};

export default ListJobsComponent;
