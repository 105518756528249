import { RegisterAbtMeMNTRSResponse, RegisterAbtMeMNTRSResults } from './register.service.types';

export const RegisterAbtMeMNTRSTransform: (response: RegisterAbtMeMNTRSResponse) => RegisterAbtMeMNTRSResults = (registerCMPSResults) => {
  const { data, is_error, message } = registerCMPSResults;
  const result: RegisterAbtMeMNTRSResults = {
    id: 0,
    message: '',
    error: false,
    errorMessage: '',
  };
  if (is_error) {
    result.error = is_error;
    result.errorMessage = message;
  } else {
    result.message = data.message;
    result.id = data.id;
  }
  return result;
};
