import { DelPostResponse, DelPostResults } from './del-post.service.types';

export const DelPostTransform: (response: DelPostResponse) => DelPostResults = (delPostResults) => {
  const { data } = delPostResults;
  const result: DelPostResults = {
    data: '',
  };
  result.data = data;
  return result;
};
