import { ResumeObjResponse, ResumeObjResults } from './get-obj.service.types';

export const ResumeObjTransform: (response: ResumeObjResponse) => ResumeObjResults = (resumeObjResults) => {
  const { data, is_error, message } = resumeObjResults;
  const result :ResumeObjResults = {
    obj: '',
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.obj = data.obj ?? '';
    result.message = 'executed';
  }
  return result;
};
