import { XcelianResumeShareResponse, XcelianResumeShareResults } from './xcelian.share.resume.service.types';

export const XcelianResumeShareTransform: (response: XcelianResumeShareResponse) => XcelianResumeShareResults = (resumeObjResults) => {
  const { data, is_error, message } = resumeObjResults;
  const result :XcelianResumeShareResults = {
    resumeData: {
      dob: '',
      gnd: '',
      oGnd: '',
      pCd: '',
      city: '',
      st: '',
      addr: '',
      resSkills: [],
      obj: '',
      covL: '',
      vidUrl: '',
      orgData: [],
      eduData: [],
      certData: [],
      langData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
    result.resumeData = data;
  }
  return result;
};
