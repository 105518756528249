import { GetHobbiesResponse, GetHobbiesResults } from './get-hobbies.service.types';

export const GetHobbiesTransform: (response: GetHobbiesResponse) => GetHobbiesResults = (resumeHobbResults) => {
  const { data, is_error, message } = resumeHobbResults;
  const result :GetHobbiesResults = {
    hobb: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.hobb = data.hobb ?? ['hello'];
    result.message = 'executed';
  }
  return result;
};
