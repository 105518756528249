import React, { useState } from 'react';
import { CardImg, Col, Row } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart, ArcElement, Tooltip, Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import './dashboard.scss';
import { clearGetSadminDashReq, getStaffDashReq } from '../../../../../store/campusXcel/actions';
import { UserType, APIHeader } from '../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { errorAlert, successAlert } from '../../../../../utils/alert';
import LoaderData from '../../../../../utils/loader';
import { DashResult } from '../../../../../services/staffing/dashboard/get.staff.dashboard.types';

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels); // Register datalabels plugin

export const RecruiterDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const dashboardStaffResponse = useSelector((state: RootState) => state.campusXcel.getStaffDashboard);

  const [staffDashboardData, setStaffDashboardData] = React.useState<DashResult>({
    jobPending: 0,
    jobLive: 0,
    jobBlock: 0,
  });

  // Data for Doughnut chart
  const dataStaffJobsDough = {
    labels: ['Pending', 'Live', 'Blocked'],
    datasets: [
      {
        data: [staffDashboardData.jobPending, staffDashboardData.jobLive, staffDashboardData.jobBlock],
        backgroundColor: ['#10a7c1', '#038e08', '#7d0e12'],
        hoverBackgroundColor: ['#10a7c1', '#056d06', '#7d0e12'],
      },
    ],
  };

  // Chart options with datalabels plugin
  const optionsStaffJobsDough = {
    plugins: {
      legend: {
        display: true,
        position: 'top' as const, // Ensure a valid predefined position (e.g., 'top')
      },
      tooltip: {
        backgroundColor: '#383838',
        borderColor: '#959191',
        borderWidth: 1,
        callbacks: {
          label: (context: any) => {
            const value = context.raw || 0;
            return `${context.label}: ${value.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}`; // Display label with value
          },
        },
      },
      datalabels: {
        display: true, // Enable datalabels
        color: 'white',
        font: {
          weight: 'bold' as const, // Use 'as const' for valid types
          size: 15 as const,
        },
        formatter: (value: number) => value
        ,
      },
    },
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(getStaffDashReq({
      uTp: UserType.RCRT,
      requestType: APIHeader.REQ_GET_STAFF_DASHBOARD_INFO,
      token: tokenData,
    }));
  }, [dispatch, tokenData]);

  React.useEffect(() => {
    if (dashboardStaffResponse.isError && dashboardStaffResponse.message !== '') {
      setLoading(false);
      setShowAlert(true);
      setAlertMsg(dashboardStaffResponse.message);
      dispatch(clearGetSadminDashReq());
    }
  }, [dashboardStaffResponse.isError, dashboardStaffResponse.message, dispatch]);

  React.useEffect(() => {
    if (!dashboardStaffResponse.isError && dashboardStaffResponse.message === 'executed') {
      setLoading(false);
      setStaffDashboardData(dashboardStaffResponse.data);
      dispatch(clearGetSadminDashReq());
    }
  }, [dashboardStaffResponse.data, dashboardStaffResponse.isError, dashboardStaffResponse.message, dispatch]);

  return (
    <div>
      {loading ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showAlert ? (
        successAlert(false, alertMsg, showAlert, setShowAlert)
      ) : null}
      <div className="allign-start ml my-2 padding-mob-top-subs">
        <CardImg className="recruit-main-header-img-card" src="/images/icons/dashboard.png" alt="batches" />
        <span className="text-lite-grey ml">DASHBOARD</span>
      </div>
      <div>
        <Row className="align-start">
          <Col lg="4">
            <div className="my-2 mx-2">
              <Doughnut data={dataStaffJobsDough} options={optionsStaffJobsDough} />
            </div>
            <div className="align-center">
              <span>Job Information</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default RecruiterDashboard;
