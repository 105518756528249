import React from 'react';
import { Input } from 'reactstrap';
import { ResumeObjectiveInput } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';

export type ResumeInfoProps = {
  handleResumeObjectiveData: (event: any) => void;
  resumeObjective:ResumeObjectiveInput;
};

export const XcelianObjInfo: React.FC<ResumeInfoProps> = ({
  handleResumeObjectiveData,
  resumeObjective,
}) => (
  <div className="text-right-login-campus-register mx-1">
    <h6 className="text-lite">Objective</h6>
    <Input
      placeholder="Enter Your Objective Here"
      type="textarea"
      required
      name="obj"
      value={resumeObjective.obj}
      onChange={handleResumeObjectiveData}
      className="campus-text-area-desc"
    />
  </div>
);

export default XcelianObjInfo;
