import React from 'react';
import {
  Col, Input, Label, Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ResumePersonalInfo } from '../../../../../services/staffing/xcelian/update-resume/update.resume.types';
import { getMetaPinCodeReq, clearMetaPinCodeReq } from '../../../../../store/campusXcel/actions';
import { UserType, APIHeader, ApiLIMIT } from '../../../../../utils/constants';

export type ResumeInfoProps = {
    handleResumeInfoData: (event: any) => void;
    resumePersonalInfo: ResumePersonalInfo;
    handleGenderRegister: (genderData: string)=> void;
    setResumePersonalInfo: (resumePersonalInfo : ResumePersonalInfo)=> void;
    tokenData: string;
    setLoadingPin: (loadingPin: boolean)=> void;
    loadingPin: boolean;
};

export const XcelianPersonalInfo: React.FC<ResumeInfoProps> = ({
  handleResumeInfoData,
  resumePersonalInfo,
  handleGenderRegister,
  setResumePersonalInfo,
  tokenData,
  setLoadingPin,
  loadingPin,
}) => {
  const dispatch = useDispatch();
  const today = new Date();
  const fifteenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 15));
  const maxDate = fifteenYearsAgo.toISOString().split('T')[0];
  const getPinResponse = useSelector((state: RootState) => state.campusXcel.getMetaPinCode);

  const handleCallPincodeChange = (event: any) => {
    if (event.target.value.length === 6) {
      setLoadingPin(true);
      dispatch(getMetaPinCodeReq({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_META_CITY_PIN,
        limit: ApiLIMIT.LIMIT,
        pinCode: event.target.value,
        cntryCd: 'IN',
      }));
    }
    setResumePersonalInfo({ ...resumePersonalInfo, pCd: event.target.value });
  };

  React.useEffect(() => {
    if (!getPinResponse.isError && getPinResponse.message === 'executed') {
      setLoadingPin(false);
      if (getPinResponse.data.pin !== '') {
        setResumePersonalInfo({
          ...resumePersonalInfo, pCd: getPinResponse.data.pin, city: getPinResponse.data.cty, st: getPinResponse.data.st,
        });
      } else {
        setResumePersonalInfo({
          ...resumePersonalInfo, city: 'NA', st: 'NA',
        });
      }
      dispatch(clearMetaPinCodeReq());
    }
  }, [getPinResponse.isError, getPinResponse.message]);

  return (
    <div>
      <Row>
        <Col lg="12">
          <Row className="bg-gender-register">
            <Col xs="12">
              <div className="pt-2">
                <h6 className="text-lite ml">Date of Birth</h6>
                <Input
                  type="date"
                  name="dob"
                  max={maxDate}
                  value={resumePersonalInfo.dob}
                  onChange={(event) => handleResumeInfoData(event)}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col xs="12 pt-3">
              <div className="mb-2 pt-2">
                <span className="text-lite">Gender Details</span>
              </div>
            </Col>
            <Col lg="5" xs="5">
              <Input
                type="radio"
                name="gender"
                checked={resumePersonalInfo.gnd === 'M'}
                className="campus-radio-box cursor-pointer"
                onChange={() => handleGenderRegister('M')}
              />
              <Label check className="form-check-text mx-1">Male</Label>
            </Col>
            <Col lg="7 my-1" xs="7 my-1">
              <Input
                type="radio"
                name="gender"
                checked={resumePersonalInfo.gnd === 'F'}
                className="campus-radio-box cursor-pointer"
                onChange={() => handleGenderRegister('F')}
              />
              <Label check className="form-check-text mx-1">Female</Label>
            </Col>
            <Col lg="5 my-1" xs="5 my-1">
              <Input
                type="radio"
                name="gender"
                checked={resumePersonalInfo.gnd === 'O'}
                className="campus-radio-box cursor-pointer"
                onChange={() => handleGenderRegister('O')}
              />
              <Label check className="form-check-text mx-1">Others</Label>
            </Col>
            <Col lg="7 my-1" xs="7 my-1">
              <Input
                type="radio"
                name="gender"
                checked={resumePersonalInfo.gnd === 'UNK'}
                className="campus-radio-box cursor-pointer"
                onChange={() => handleGenderRegister('UNK')}
              />
              <Label check className="form-check-text mx-1">Prefer Not to Say</Label>
            </Col>
            {resumePersonalInfo.gnd === 'O' ? (
              <Col xs="12" lg="10">
                <div className="my-2 pt-2 align-end">
                  <Input
                    placeholder="Enter your Gender"
                    type="text"
                    name="oGnd"
                    value={resumePersonalInfo.oGnd}
                    onChange={handleResumeInfoData}
                    className="campus-input mx-1"
                  />
                </div>
              </Col>
            ) : null}
            <Col xs="12 pt-3" lg="12 pt-3">
              <div className="mb-2 pt-2">
                <span className="text-lite">Address Info</span>
              </div>
              <div className="cx-xcelian-dashed-card">
                <Row className="mb-1">
                  <Col xs="12">
                    <div className="pt-2 mx-1">
                      <span className="text-lite ml-1 align-start">Pincode<span className="mandate-star">*</span></span>
                      <Input
                        placeholder="Enter Pincode"
                        type="text"
                        name="pCd"
                        required
                        value={resumePersonalInfo.pCd}
                        onChange={handleCallPincodeChange}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="pt-3 mx-1">
                      <span className="text-lite ml-1 align-start">City<span className="mandate-star">*</span></span>
                      <Input
                        placeholder="Enter City"
                        type="text"
                        name="city"
                        required
                        disabled={loadingPin}
                        value={resumePersonalInfo.city}
                        onChange={handleResumeInfoData}
                        className="campus-input"
                      />
                      {loadingPin && (
                      <div className="dot-loader align-center pt-1">
                        <div className="dot" />
                        <div className="dot" />
                        <div className="dot" />
                        <div className="dot" />
                        <div className="dot" />
                      </div>
                      )}
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="pt-3 mx-1 margin-state-desk">
                      <span className="text-lite ml-1 align-start">State<span className="mandate-star">*</span></span>
                      <Input
                        placeholder="Enter State"
                        type="text"
                        name="st"
                        required
                        disabled={loadingPin}
                        value={resumePersonalInfo.st}
                        onChange={handleResumeInfoData}
                        className="campus-input"
                      />
                      {loadingPin && (
                      <div className="dot-loader align-center pt-1">
                        <div className="dot" />
                        <div className="dot" />
                        <div className="dot" />
                        <div className="dot" />
                        <div className="dot" />
                      </div>
                      )}
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="pt-3 mr-1">
                      <span className="text-lite ml-1 align-start">Address</span>
                      <Input
                        placeholder="Enter Company Address..."
                        type="textarea"
                        name="addr"
                        value={resumePersonalInfo.addr}
                        className="campus-text-area mx-2"
                        onChange={handleResumeInfoData}
                      />
                    </div>
                    <div className="pt-2" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default XcelianPersonalInfo;
