import { GetCertRequest } from './campus/certifications/get-cert/get-cert.service';
import {
  GetCertService, GetCertOptions, GetCertResponse, GetCertResults,
} from './campus/certifications/get-cert/get-cert.service.types';
import { GetCertTransform } from './campus/certifications/get-cert/get-cert.transform';
import { CertPutRequest } from './campus/certifications/update-cert/update-cert.service';
import {
  CertPutService, CertPutOptions, CertPutResponse, CertPutResults,
} from './campus/certifications/update-cert/update-cert.service.types';
import { CertPutTransform } from './campus/certifications/update-cert/update-cert.transform';
import { BatchAddRequest } from './campus/college-admin/cmps-batches/add-batch/add-batch.service';
import {
  BatchAddService, BatchAddOptions, BatchAddResponse, BatchAddResults,
} from './campus/college-admin/cmps-batches/add-batch/add-batch.service.types';
import { BatchAddTransform } from './campus/college-admin/cmps-batches/add-batch/add-batch.transform';
import { ListBatchRequest } from './campus/college-admin/cmps-batches/list-batch/list-batch.service';
import {
  ListBatchService, ListBatchOptions, ListBatchResponse, ListBatchResults,
} from './campus/college-admin/cmps-batches/list-batch/list-batch.service.types';
import { ListBatchTransform } from './campus/college-admin/cmps-batches/list-batch/list-batch.transform';
import { CommentPutRequest } from './campus/dashboard/comments/add-comment/comment.service';
import {
  CommentService, CommentOptions, CommentResponse, CommentResults,
} from './campus/dashboard/comments/add-comment/comment.service.types';
import { CommentTransform } from './campus/dashboard/comments/add-comment/comment.transform';
import { ListCommentRequest } from './campus/dashboard/comments/get-all-comments/list-comment.service';
import {
  ListCommentService, ListCommentOptions, ListCommentResponse, ListCommentResults,
} from './campus/dashboard/comments/get-all-comments/list-comment.service.types';
import { ListCommentTransform } from './campus/dashboard/comments/get-all-comments/list-comment.transform';
import { FollowPutRequest } from './campus/dashboard/follow/add-follow/follow.service';
import {
  FollowService, FollowOptions, FollowResponse, FollowResults,
} from './campus/dashboard/follow/add-follow/follow.service.types';
import { FollowTransform } from './campus/dashboard/follow/add-follow/follow.transform';
import { LikePutRequest } from './campus/dashboard/likes/add-like/like.service';
import {
  LikeService, LikeOptions, LikeResponse, LikeResults,
} from './campus/dashboard/likes/add-like/like.service.types';
import { LikeTransform } from './campus/dashboard/likes/add-like/like.transform';
import { ListUserLikesRequest } from './campus/dashboard/likes/get-all-likes/list-user-likes.service';
import {
  ListUserLikesService, ListUserLikesOptions, ListUserLikesResponse, ListUserLikesResults,
} from './campus/dashboard/likes/get-all-likes/list-user-likes.service.types';
import { ListUserLikesTransform } from './campus/dashboard/likes/get-all-likes/list-user-likes.transform';
import { PostDBRequest } from './campus/dashboard/post/add-post-db/post-db.service';
import {
  PostDBService, PostDBOptions, PostDBResponse, PostDBResults,
} from './campus/dashboard/post/add-post-db/post-db.service.types';
import { PostDBTransform } from './campus/dashboard/post/add-post-db/post-db.transform';
import { DelPostRequest } from './campus/dashboard/post/del-post-file-s3/del-post.service';
import {
  DelPostService, DelPostOptions, DelPostResponse, DelPostResults,
} from './campus/dashboard/post/del-post-file-s3/del-post.service.types';
import { DelPostTransform } from './campus/dashboard/post/del-post-file-s3/del-post.transform';
import { ListPostRequest } from './campus/dashboard/post/get-all-posts/list-post.service';
import {
  ListPostService, ListPostOptions, ListPostResponse, ListPostResults,
} from './campus/dashboard/post/get-all-posts/list-post.service.types';
import { ListPostTransform } from './campus/dashboard/post/get-all-posts/list-post.transform';
import { GetHobbiesRequest } from './campus/hobbies/get-hobbies/get-hobbies.service';
import {
  GetHobbiesService, GetHobbiesOptions, GetHobbiesResponse, GetHobbiesResults,
} from './campus/hobbies/get-hobbies/get-hobbies.service.types';
import { GetHobbiesTransform } from './campus/hobbies/get-hobbies/get-hobbies.transform';
import { HobbiesPutRequest } from './campus/hobbies/update-hobbies/update-hobbies.service';
import {
  HobbiesPutService, HobbiesPutOptions, HobbiesPutResponse, HobbiesPutResults,
} from './campus/hobbies/update-hobbies/update-hobbies.service.types';
import { HobbiesPutTransform } from './campus/hobbies/update-hobbies/update-hobbies.transform';
import { RegisterCMPSPostRequest } from './campus/register-campus/register.service';
import {
  RegisterCMPSService, RegisterCMPSOptions, RegisterCMPSResponse, RegisterCMPSResults,
} from './campus/register-campus/register.service.types';
import { RegisterCMPSTransform } from './campus/register-campus/register.transform';
import { ResumeProfileRequest } from './campus/resume-data/get-cand-profile/get-profile.service';
import {
  ResumeProfileService, ResumeProfileOptions, ResumeProfileResponse, ResumeProfileResults,
} from './campus/resume-data/get-cand-profile/get-profile.service.types';
import { ResumeProfileTransform } from './campus/resume-data/get-cand-profile/get-profile.transform';
import { ResumeObjRequest } from './campus/resume-data/objective/get-obj/get-obj.service';
import {
  ResumeObjService, ResumeObjOptions, ResumeObjResponse, ResumeObjResults,
} from './campus/resume-data/objective/get-obj/get-obj.service.types';
import { ResumeObjTransform } from './campus/resume-data/objective/get-obj/get-obj.transform';
import { CampusObjectivePutRequest } from './campus/resume-data/objective/update-obj/objective.service';
import {
  ObjectiveService, ObjectiveOptions, ObjectiveResponse, ObjectiveResults,
} from './campus/resume-data/objective/update-obj/objective.service.types';
import { ObjectiveTransform } from './campus/resume-data/objective/update-obj/objective.transform';
import { DelOrgProjPutRequest } from './campus/resume-data/org-project/delete-org-proj/del-org-proj.service';
import {
  DelOrgProjService, DelOrgProjOptions, DelOrgProjResponse, DelOrgProjResults,
} from './campus/resume-data/org-project/delete-org-proj/del-org-proj.service.types';
import { DelOrgProjTransform } from './campus/resume-data/org-project/delete-org-proj/del-org-proj.transform';
import { ListOrgProjRequest } from './campus/resume-data/org-project/get-all-org-obj/list-org-proj.service';
import {
  ListOrgProjService, ListOrgProjOptions, ListOrgProjResponse, ListOrgProjResults,
} from './campus/resume-data/org-project/get-all-org-obj/list-org-proj.service.types';
import { ListOrgProjTransform } from './campus/resume-data/org-project/get-all-org-obj/list-org-proj.transform';
import { CampusOrgProjPutRequest } from './campus/resume-data/org-project/update-org-obj/org-proj.service';
import {
  OrgProjService, OrgProjOptions, OrgProjResponse, OrgProjResults,
} from './campus/resume-data/org-project/update-org-obj/org-proj.service.types';
import { OrgProjTransform } from './campus/resume-data/org-project/update-org-obj/org-proj.transform';
import { ResumePreviewRequest } from './campus/resume-data/preview/get-preview.service';
import {
  ResumePreviewService, ResumePreviewOptions, ResumePreviewResponse, ResumePreviewResults,
} from './campus/resume-data/preview/get-preview.service.types';
import { ResumePreviewTransform } from './campus/resume-data/preview/get-preview.transform';
import { ContactUsPostRequest } from './sadmin/contact-us/contact-us.service';
import {
  ContactUsService, ContactUsOptions, ContactUsResponse, ContactUsResults,
} from './sadmin/contact-us/contact-us.service.types';
import { ContactUsTransform } from './sadmin/contact-us/contact-us.transform';
import { ForgotPasswordRequest } from './common/forgot-password/forgot-password.service';
import {
  ForgotPasswordService, ForgotPasswordOptions, ForgotPasswordResults, ForgotPasswordResponse,
} from './common/forgot-password/forgot-password.service.types';
import { GenTokenRequest } from './common/gen-token/gen-token.service';
import {
  GenTokenService, GenTokenOptions, GenTokenResponse, GenTokenResults,
} from './common/gen-token/gen-token.service.types';
import { GenTokenTransform } from './common/gen-token/gen-token.transform';
import { FileURLRequest } from './common/get-file-url/get-file-url.service';
import {
  FileURLService, FileURLOptions, FileURLResponse, FileURLResults,
} from './common/get-file-url/get-file-url.service.types';
import { FileURLTransform } from './common/get-file-url/get-file-url.transform';
import { ProfileRequest } from './common/get-profile/get-profile.service';
import {
  ProfileService, ProfileOptions, ProfileResponse, ProfileResults,
} from './common/get-profile/get-profile.service.types';
import { ProfileTransform } from './common/get-profile/get-profile.transform';
import { PassKeyPutRequest } from './common/update-passkey/update-passkey.service';
import {
  PassKeyPutService, PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults,
} from './common/update-passkey/update-passkey.service.types';
import { PassKeyPutTransform } from './common/update-passkey/update-passkey.transform';
import { UserProfilePutRequest } from './common/update-profile/update-profile.service';
import {
  UserProfilePutService, UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults,
} from './common/update-profile/update-profile.service.types';
import { UserProfilePutTransform } from './common/update-profile/update-profile.transform';
import { createService } from './service';
import { ListJobsRequest } from './staffing/job-board/list-jobs/list-jobs.service';
import {
  ListJobsService, ListJobsOptions, ListJobsResults, ListJobsResponse,
} from './staffing/job-board/list-jobs/list-jobs.service.types';
import { RegisterXCLNPostRequest } from './staffing/xcelian/register-xcelian/register.service';
import {
  RegisterXCLNService, RegisterXCLNOptions, RegisterXCLNResponse, RegisterXCLNResults,
} from './staffing/xcelian/register-xcelian/register.service.types';
import { RegisterXCLNTransform } from './staffing/xcelian/register-xcelian/register.transform';
import { CampusMentorAccessUpdatePutRequest } from './upskilling/mentors/mentor-access-update/mentor-update.service';
import {
  MentorAccessUpdateService, MentorAccessUpdateOptions, MentorAccessUpdateResponse, MentorAccessUpdateResults,
} from './upskilling/mentors/mentor-access-update/mentor-update.service.types';
import { MentorAccessUpdateTransform } from './upskilling/mentors/mentor-access-update/mentor-update.transform';
import { ListMentorRequest } from './upskilling/mentors/mentor-list/list-mentor.service';
import {
  ListMentorService, ListMentorOptions, ListMentorResponse, ListMentorResults,
} from './upskilling/mentors/mentor-list/list-mentor.service.types';
import { ListMentorTransform } from './upskilling/mentors/mentor-list/list-mentor.transform';
import { RegisterAbtMeMNTRSRequest } from './upskilling/register-mentors/About/register.service';
import {
  RegisterAbtMeMNTRSService, RegisterAbtMeMNTRSOptions, RegisterAbtMeMNTRSResponse, RegisterAbtMeMNTRSResults,
} from './upskilling/register-mentors/About/register.service.types';
import { RegisterAbtMeMNTRSTransform } from './upskilling/register-mentors/About/register.transform';
import { RegisterPrsnlMNTRSRequest } from './upskilling/register-mentors/Personal/register.service';
import {
  RegisterPrsnlMNTRSService, RegisterPrsnlMNTRSOptions, RegisterPrsnlMNTRSResponse, RegisterPrsnlMNTRSResults,
} from './upskilling/register-mentors/Personal/register.service.types';
import { RegisterPrsnlMNTRSTransform } from './upskilling/register-mentors/Personal/register.transform';
import { RegisterEmployMNTRSRequest } from './upskilling/register-mentors/Prof-Exp/register.service';
import {
  RegisterEmployMNTRSService, RegisterEmployMNTRSOptions, RegisterEmployMNTRSResponse, RegisterEmployMNTRSResults,
} from './upskilling/register-mentors/Prof-Exp/register.service.types';
import { RegisterEmployMNTRSTransform } from './upskilling/register-mentors/Prof-Exp/register.transform';
import { RegisterEmailMNTRSPostRequest } from './upskilling/register-mentors/ValidateEmail/register.service';
import {
  RegisterEmailMNTRSService, RegisterEmailMNTRSOptions, RegisterEmailMNTRSResponse, RegisterEmailMNTRSResults,
} from './upskilling/register-mentors/ValidateEmail/register.service.types';
import { RegisterEmailMNTRSTransform } from './upskilling/register-mentors/ValidateEmail/register.transform';
import { UploadS3FilesRequest } from './common/upload-s3-files/upload-s3-files.service';
import {
  UploadS3FilesOptions, UploadS3FilesResponse, UploadS3FilesResults, UploadS3FilesService,
} from './common/upload-s3-files/upload-s3-files.service.types';
import { UploadS3FilesTransform } from './common/upload-s3-files/upload-s3-files.transform';
import { ForgotPasswordTransform } from './common/forgot-password/forgot-password.transform';
import { ListJobsTransform } from './staffing/job-board/list-jobs/list-jobs.transform';
import { CreatePostRequest } from './campus/dashboard/post/create-post-college/create-post.service';
import {
  CreatePostService, CreatePostOptions, CreatePostResponse, CreatePostResults,
} from './campus/dashboard/post/create-post-college/create-post.service.types';
import { CreatePostTransform } from './campus/dashboard/post/create-post-college/create-post.transform';
import { GetS3FilesRequest } from './common/get-s3-files/get-s3files.service';
import {
  GetS3FilesService, GetS3FilesOptions, GetS3FilesResponse, GetS3FilesResults,
} from './common/get-s3-files/get-s3files.service.types';
import { GetS3FilesTransform } from './common/get-s3-files/get-s3files.transform';
import { UpdateS3CxMetaRequest } from './aIntelligence/update-cx-meta/update-s3-cx-meta.service';
import {
  UpdateS3CxMetaService, UpdateS3CxMetaOptions, UpdateS3CxMetaResponse, UpdateS3CxMetaResults,
} from './aIntelligence/update-cx-meta/update-s3-cx-meta.service.types';
import { UpdateS3CxMetaTransform } from './aIntelligence/update-cx-meta/update-s3-cx-meta.transform';
import { GetS3CxMetaRequest } from './aIntelligence/get-all-cx-meta/get-s3-cx-meta.service';
import {
  GetS3CxMetaService, GetS3CxMetaOptions, GetS3CxMetaResponse, GetS3CxMetaResults,
} from './aIntelligence/get-all-cx-meta/get-s3-cx-meta.service.types';
import { GetS3CxMetaTransform } from './aIntelligence/get-all-cx-meta/get-s3-cx-meta.transform';
import { ClientAddRequest } from './sadmin/add-clients/add-new-clients.service';
import {
  ClientAddService, ClientAddOptions, ClientAddResponse, ClientAddResults,
} from './sadmin/add-clients/add-new-clients.service.types';
import { ClientAddTransform } from './sadmin/add-clients/add-new-clients.transform';
import { ListClientRequest } from './sadmin/list-client/list-client.service';
import {
  ListClientService, ListClientOptions, ListClientResponse, ListClientResults,
} from './sadmin/list-client/list-client.service.types';
import { ListClientTransform } from './sadmin/list-client/list-client.transform';
import { ClientUserAddRequest } from './sadmin/add-client-user/add-new-client-user.service';
import {
  ClientUserAddService, ClientUserAddOptions, ClientUserAddResponse, ClientUserAddResults,
} from './sadmin/add-client-user/add-new-client-user.service.types';
import { ClientUserAddTransform } from './sadmin/add-client-user/add-new-client-user.transform';
import { ListClientUserRequest } from './sadmin/list-client-user/list-client-user.service';
import {
  ListClientUserService, ListClientUserOptions, ListClientUserResponse, ListClientUserResults,
} from './sadmin/list-client-user/list-client-user.service.types';
import { ListClientUserTransform } from './sadmin/list-client-user/list-client-user.transform';
import { DELClientRequest } from './sadmin/delete-client/del-client.service';
import {
  DELClientService, DELClientOptions, DELClientResponse, DELClientResults,
} from './sadmin/delete-client/del-client.service.types';
import { DELClientTransform } from './sadmin/delete-client/del-client.transform';
import { ListCandidatesRequest } from './campus/college-admin/acad-students/list-candidates/list-candidates.service';
import {
  ListCandidatesService, ListCandidatesOptions, ListCandidatesResponse, ListCandidatesResults,
} from './campus/college-admin/acad-students/list-candidates/list-candidates.service.types';
import { ListCandidatesTransform } from './campus/college-admin/acad-students/list-candidates/list-candidates.transform';
import { CandPutStsRequest } from './campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.service';
import {
  CandPutStsService, CandPutStsOptions, CandPutStsResponse, CandPutStsResults,
} from './campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.service.types';
import { CandPutStsTransform } from './campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.transform';
import { ResumeVideoRequest } from './campus/resume-data/video-profile/get-video-url/get-video.service';
import {
  ResumeVideoService, ResumeVideoOptions, ResumeVideoResponse, ResumeVideoResults,
} from './campus/resume-data/video-profile/get-video-url/get-video.service.types';
import { ResumeVideoTransform } from './campus/resume-data/video-profile/get-video-url/get-video.transform';
import { CampusVideoPutRequest } from './campus/resume-data/video-profile/update-video/video.service';
import {
  VideoService, VideoOptions, VideoResponse, VideoResults,
} from './campus/resume-data/video-profile/update-video/video.service.types';
import { VideoTransform } from './campus/resume-data/video-profile/update-video/video.transform';
import { StaffClientAddRequest } from './staffing/clients/add-clients/add-new-clients.service';
import {
  StaffClientAddService, StaffClientAddOptions, StaffClientAddResponse, StaffClientAddResults,
} from './staffing/clients/add-clients/add-new-clients.service.types';
import { StaffClientAddTransform } from './staffing/clients/add-clients/add-new-clients.transform';
import { ListStaffClientRequest } from './staffing/clients/list-clients/list-client.service';
import {
  ListStaffClientService, ListStaffClientOptions, ListStaffClientResponse, ListStaffClientResults,
} from './staffing/clients/list-clients/list-client.service.types';
import { ListStaffClientTransform } from './staffing/clients/list-clients/list-client.transform';
import { JobAddRequest } from './staffing/job-board/add-new-job/add-job.service';
import {
  JobAddService, JobAddOptions, JobAddResponse, JobAddResults,
} from './staffing/job-board/add-new-job/add-job.service.types';
import { JobAddTransform } from './staffing/job-board/add-new-job/add-job.transform';
import { JobByIdRequest } from './staffing/job-board/get-job-by-id/job-by-id.service';
import {
  JobByIdService, JobByIdOptions, JobByIdResponse, JobByIdResults,
} from './staffing/job-board/get-job-by-id/job-by-id.service.types';
import { JobByIdTransform } from './staffing/job-board/get-job-by-id/job-by-id.transform';
import { ListPrivateJobsRequest } from './staffing/job-board/list-jobs-private/list-jobs.private.service';
import {
  ListPrivateJobsService, ListPrivateJobsOptions, ListPrivateJobsResponse, ListPrivateJobsResults,
} from './staffing/job-board/list-jobs-private/list-jobs.private.service.types';
import { ListPrivateJobsTransform } from './staffing/job-board/list-jobs-private/list-jobs.private.transform';
import { ListMetaJobsRequest } from './staffing/job-board/list-meta-jobs/list-meta-jobs.service';
import {
  ListMetaJobsService, ListMetaJobsOptions, ListMetaJobsResponse, ListMetaJobsResults,
} from './staffing/job-board/list-meta-jobs/list-meta-jobs.service.types';
import { ListMetaJobsTransform } from './staffing/job-board/list-meta-jobs/list-meta-jobs.transform';
import { JobDataPutRequest } from './staffing/job-board/update-job-data/update.job.data.service';
import {
  JobDataPutService, JobDataPutOptions, JobDataPutResponse, JobDataPutResults,
} from './staffing/job-board/update-job-data/update.job.data.service.types';
import { JobDataPutTransform } from './staffing/job-board/update-job-data/update.job.data.transform';
import { JobStatusRequest } from './staffing/job-board/update-job-status/job-status.service';
import {
  JobStatusService, JobStatusOptions, JobStatusResponse, JobStatusResults,
} from './staffing/job-board/update-job-status/job-status.service.types';
import { JobStatusTransform } from './staffing/job-board/update-job-status/job-status.transform';
import {
  MetaPinCodeService, MetaPinCodeOptions, MetaPinCodeResponse, MetaPinCodeResults,
} from './common/meta-city-by-pincode/get-pincode.service.types';
import { MetaPinCodeTransform } from './common/meta-city-by-pincode/get-pincode.transform';
import { MetaPinCodeRequest } from './common/meta-city-by-pincode/get-pincode.service';
import { JobByDetailsRequest } from './staffing/job-board/get-job-details/job-details.service';
import {
  JobByDetailsService, JobByDetailsOptions, JobByDetailsResponse, JobByDetailsResults,
} from './staffing/job-board/get-job-details/job-details.service.types';
import { JobByDetailsTransform } from './staffing/job-board/get-job-details/job-details.transform';
import { ListJobsByStatusRequest } from './staffing/job-board/list-jobs-by-status/list-jobs.status.service';
import {
  ListJobsByStatusService, ListJobsByStatusOptions, ListJobsByStatusResults, ListJobsByStatusResponse,
} from './staffing/job-board/list-jobs-by-status/list-jobs.status.service.types';
import { ListJobsByStatusTransform } from './staffing/job-board/list-jobs-by-status/list-jobs.status.transform';
import { ListMETARequest } from './common/metadata-db/list-meta.service';
import {
  ListMETAService, ListMETAOptions, ListMETAResponse, ListMETAResults,
} from './common/metadata-db/list-meta.service.types';
import { ListMETATransform } from './common/metadata-db/list-meta.transform';
import { InterviewStatusPutRequest } from './staffing/interviews/add-interview/update-intv.sts.service';
import {
  InterviewStatusPutService, InterviewStatusPutOptions, InterviewStatusPutResponse, InterviewStatusPutResults,
} from './staffing/interviews/add-interview/update-intv.sts.service.types';
import { InterviewStatusPutTransform } from './staffing/interviews/add-interview/update-intv.sts.transform';
import { XclnJobsRequest } from './staffing/xcelian/xcelian-jobs/xcln.jobs.service';
import {
  XclnJobsService, XclnJobsOptions, XclnJobsResponse, XclnJobsResults,
} from './staffing/xcelian/xcelian-jobs/xcln.jobs.service.types';
import { XclnJobsTransform } from './staffing/xcelian/xcelian-jobs/xcln.jobs.transform';
import { ResumeParserPutRequest } from './staffing/interviews/resume-parser/update.resume.parser.service';
import {
  ResumeParserPutService, ResumeParserPutOptions, ResumeParserPutResponse, ResumeParserPutResults,
} from './staffing/interviews/resume-parser/update.resume.parser.service.types';
import { ResumeParserPutTransform } from './staffing/interviews/resume-parser/update.resume.parser.transform';
import { ResumeLoadPutRequest } from './staffing/interviews/resume-load/update.resume.load.service';
import {
  ResumeLoadPutService, ResumeLoadPutOptions, ResumeLoadPutResponse, ResumeLoadPutResults,
} from './staffing/interviews/resume-load/update.resume.load.service.types';
import { ResumeLoadPutTransform } from './staffing/interviews/resume-load/update.resume.load.transform';
import { ListRecruitInterviewRequest } from './staffing/interviews/list-recruit-interview/list.recruit.interview.service';
import {
  ListRecruitInterviewService, ListRecruitInterviewOptions, ListRecruitInterviewResponse, ListRecruitInterviewResults,
} from './staffing/interviews/list-recruit-interview/list.recruit.interview.service.types';
import { ListRecruitInterviewTransform } from './staffing/interviews/list-recruit-interview/list.recruit.interview.transform';
import { RecruitInterviewStatusPutRequest } from './staffing/interviews/recruit-interview-status/update.recruit.sts.service';
import {
  RecruitInterviewStatusPutService, RecruitInterviewStatusPutOptions, RecruitInterviewStatusPutResponse, RecruitInterviewStatusPutResults,
} from './staffing/interviews/recruit-interview-status/update.recruit.sts.service.types';
import { RecruitInterviewStatusPutTransform } from './staffing/interviews/recruit-interview-status/update.recruit.sts.transform';
import { XclnResumePutRequest } from './staffing/resume-preview/add-resume-info/update.resume.info.service';
import {
  XclnResumePutService, XclnResumePutOptions, XclnResumePutResponse, XclnResumePutResults,
} from './staffing/resume-preview/add-resume-info/update.resume.info.service.types';
import { XclnResumePutTransform } from './staffing/resume-preview/add-resume-info/update.resume.info.transform';
import { XclnResumeFilePutRequest } from './staffing/resume-preview/add-resume-file/update.resume.file.service';
import {
  XclnResumeFilePutService, XclnResumeFilePutOptions, XclnResumeFilePutResponse, XclnResumeFilePutResults,
} from './staffing/resume-preview/add-resume-file/update.resume.file.service.types';
import { XclnResumeFilePutTransform } from './staffing/resume-preview/add-resume-file/update.resume.file.transform';
import { NewsletterPutRequest } from './sadmin/newsletter/update-newsletter/update.newsletter.service';
import {
  NewsletterPutService, NewsletterPutOptions, NewsletterPutResponse, NewsletterPutResults,
} from './sadmin/newsletter/update-newsletter/update.newsletter.service.types';
import { NewsletterPutTransform } from './sadmin/newsletter/update-newsletter/update.newsletter.transform';
import { ListNewsletterRequest } from './sadmin/newsletter/get-newsletter/list-newsletter.service';
import {
  ListNewsletterService, ListNewsletterOptions, ListNewsletterResponse, ListNewsletterResults,
} from './sadmin/newsletter/get-newsletter/list-newsletter.service.types';
import { ListNewsletterTransform } from './sadmin/newsletter/get-newsletter/list-newsletter.transform';
import { NewsletterStatusPutRequest } from './sadmin/newsletter/update-newsletter-status/update.newsletter.status.service';
import {
  NewsletterStatusPutService, NewsletterStatusPutOptions, NewsletterStatusPutResponse, NewsletterStatusPutResults,
} from './sadmin/newsletter/update-newsletter-status/update.newsletter.status.service.types';
import { NewsletterStatusPutTransform } from './sadmin/newsletter/update-newsletter-status/update.newsletter.status.transform';
import { GetSadminDashRequest } from './sadmin/dashboard/get.sadmin.dashboard.service';
import {
  GetSadminDashService, GetSadminDashOptions, GetSadminDashResponse, GetSadminDashResults,
} from './sadmin/dashboard/get.sadmin.dashboard.service.types';
import { GetSadminDashTransform } from './sadmin/dashboard/get.sadmin.dashboard.transform';
import { SubscriberPutRequest } from './sadmin/subscriber/update-subscriber/update.subscriber.service';
import {
  SubscriberPutService, SubscriberPutOptions, SubscriberPutResponse, SubscriberPutResults,
} from './sadmin/subscriber/update-subscriber/update.subscriber.service.types';
import { SubscriberPutTransform } from './sadmin/subscriber/update-subscriber/update.subscriber.transform';
import { GetStaffDashRequest } from './staffing/dashboard/get.staff.dashboard.service';
import {
  GetStaffDashService, GetStaffDashOptions, GetStaffDashResponse, GetStaffDashResults,
} from './staffing/dashboard/get.staff.dashboard.service.types';
import { GetStaffDashTransform } from './staffing/dashboard/get.staff.dashboard.transform';
import { MediaByIdRequest } from './sadmin/newsletter/get-media-by-id/media-by-id.service';
import {
  MediaByIdService, MediaByIdOptions, MediaByIdResponse, MediaByIdResults,
} from './sadmin/newsletter/get-media-by-id/media-by-id.service.types';
import { MediaByIdTransform } from './sadmin/newsletter/get-media-by-id/media-by-id.transform';
import { MediaViewPutRequest } from './sadmin/newsletter/update-views/update.media.views.service';
import {
  MediaViewPutService, MediaViewPutOptions, MediaViewPutResponse, MediaViewPutResults,
} from './sadmin/newsletter/update-views/update.media.views.service.types';
import { MediaViewPutTransform } from './sadmin/newsletter/update-views/update.media.views.transform';
import { JobViewPutRequest } from './staffing/job-board/update-job-views/update.job.views.service';
import {
  JobViewPutService, JobViewPutOptions, JobViewPutResponse, JobViewPutResults,
} from './staffing/job-board/update-job-views/update.job.views.service.types';
import { JobViewPutTransform } from './staffing/job-board/update-job-views/update.job.views.transform';
import { UserStatusPutRequest } from './sadmin/update-user-status/update.user.status.service';
import {
  UserStatusPutService, UserStatusPutOptions, UserStatusPutResponse, UserStatusPutResults,
} from './sadmin/update-user-status/update.user.status.service.types';
import { UserStatusPutTransform } from './sadmin/update-user-status/update.user.status.transform';
import { ListEnquiryRequest } from './sadmin/get-enquiry-list/list.enquiry.service';
import {
  ListEnquiryService, ListEnquiryOptions, ListEnquiryResponse, ListEnquiryResults,
} from './sadmin/get-enquiry-list/list.enquiry.service.types';
import { ListEnquiryTransform } from './sadmin/get-enquiry-list/list.enquiry.transform';
import { EnquiryStatusPutRequest } from './sadmin/update-enquiry-status/update.enquiry.status.service';
import {
  EnquiryStatusPutService, EnquiryStatusPutOptions, EnquiryStatusPutResponse, EnquiryStatusPutResults,
} from './sadmin/update-enquiry-status/update.enquiry.status.service.types';
import { EnquiryStatusPutTransform } from './sadmin/update-enquiry-status/update.enquiry.status.transform';
import { XcelianResumePreviewRequest } from './staffing/xcelian/get-xcelian-resume/xcelian.preview.service';
import {
  XcelianResumePreviewService, XcelianResumePreviewOptions, XcelianResumePreviewResponse, XcelianResumePreviewResults,
} from './staffing/xcelian/get-xcelian-resume/xcelian.preview.service.types';
import { XcelianResumePreviewTransform } from './staffing/xcelian/get-xcelian-resume/xcelian.preview.transform';
import { ShareListInterviewRequest } from './staffing/share-interview/share-list-interview/share.list.interview.service';
import {
  ShareListInterviewService, ShareListInterviewOptions, ShareListInterviewResponse, ShareListInterviewResults,
} from './staffing/share-interview/share-list-interview/share.list.interview.service.types';
import { ShareListInterviewTransform } from './staffing/share-interview/share-list-interview/share.list.interview.transform';
import { ShareUrlInterviewRequest } from './staffing/share-interview/share-url-interview/share.url.interview.service';
import {
  ShareUrlInterviewService, ShareUrlInterviewOptions, ShareUrlInterviewResponse, ShareUrlInterviewResults,
} from './staffing/share-interview/share-url-interview/share.url.interview.service.types';
import { ShareUrlInterviewTransform } from './staffing/share-interview/share-url-interview/share.url.interview.transform';
import { ResumeInfoPutRequest } from './staffing/xcelian/update-resume/update.resume.service';
import {
  ResumeInfoPutService, ResumeInfoPutOptions, ResumeInfoPutResponse, ResumeInfoPutResults,
} from './staffing/xcelian/update-resume/update.resume.service.types';
import { ResumeInfoPutTransform } from './staffing/xcelian/update-resume/update.resume.transform';
import { XcelianResumeShareRequest } from './staffing/xcelian/share-resume/xcelian.share.resume.service';
import {
  XcelianResumeShareService, XcelianResumeShareOptions, XcelianResumeShareResponse, XcelianResumeShareResults,
} from './staffing/xcelian/share-resume/xcelian.share.resume.service.types';
import { XcelianResumeShareTransform } from './staffing/xcelian/share-resume/xcelian.share.resume.transform';

const response = 'Data has been loaded successfully!';

export function loadData(): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
      if (response === undefined) {
        reject();
      }
    }, 500);
  });
}
export const contactUsService: ContactUsService = createService<ContactUsOptions, ContactUsResponse, ContactUsResults>(ContactUsPostRequest, ContactUsTransform);
export const registerCMPSService: RegisterCMPSService = createService<RegisterCMPSOptions, RegisterCMPSResponse, RegisterCMPSResults>(RegisterCMPSPostRequest, RegisterCMPSTransform);
export const genTokenService: GenTokenService = createService<GenTokenOptions, GenTokenResponse, GenTokenResults>(GenTokenRequest, GenTokenTransform);
export const forgotPasswordService: ForgotPasswordService = createService<ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults>(ForgotPasswordRequest, ForgotPasswordTransform);
export const profileService: ProfileService = createService<ProfileOptions, ProfileResponse, ProfileResults>(ProfileRequest, ProfileTransform);
export const fileURLService: FileURLService = createService<FileURLOptions, FileURLResponse, FileURLResults>(FileURLRequest, FileURLTransform);
export const userProfilePutService: UserProfilePutService = createService<UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults>(UserProfilePutRequest, UserProfilePutTransform);
export const uploadS3FilesService: UploadS3FilesService = createService<UploadS3FilesOptions, UploadS3FilesResponse, UploadS3FilesResults>(UploadS3FilesRequest, UploadS3FilesTransform);
export const getS3FilesService: GetS3FilesService = createService<GetS3FilesOptions, GetS3FilesResponse, GetS3FilesResults>(GetS3FilesRequest, GetS3FilesTransform);
export const passKeyService: PassKeyPutService = createService<PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults>(PassKeyPutRequest, PassKeyPutTransform);
export const resumeProfileService: ResumeProfileService = createService<ResumeProfileOptions, ResumeProfileResponse, ResumeProfileResults>(ResumeProfileRequest, ResumeProfileTransform);
export const getResumeObjService: ResumeObjService = createService<ResumeObjOptions, ResumeObjResponse, ResumeObjResults>(ResumeObjRequest, ResumeObjTransform);
export const objectiveService: ObjectiveService = createService<ObjectiveOptions, ObjectiveResponse, ObjectiveResults>(CampusObjectivePutRequest, ObjectiveTransform);
export const listOrgProjService: ListOrgProjService = createService<ListOrgProjOptions, ListOrgProjResponse, ListOrgProjResults>(ListOrgProjRequest, ListOrgProjTransform);
export const orgProjService: OrgProjService = createService<OrgProjOptions, OrgProjResponse, OrgProjResults>(CampusOrgProjPutRequest, OrgProjTransform);
export const deleteOrgProjService: DelOrgProjService = createService<DelOrgProjOptions, DelOrgProjResponse, DelOrgProjResults>(DelOrgProjPutRequest, DelOrgProjTransform);
export const getResumePreviewService: ResumePreviewService = createService<ResumePreviewOptions, ResumePreviewResponse, ResumePreviewResults>(ResumePreviewRequest, ResumePreviewTransform);
// export const userProfileVideoService: UploadVideoService = createService<UploadVideoOptions, UploadVideoResponse, UploadVideoResults>(UploadVideoRequest, UploadVideoTransform);
// export const videoURLService: VideoURLService = createService<VideoURLOptions, VideoURLResponse, VideoURLResults>(VideoURLRequest, VideoURLTransform);
export const hobbiesService: HobbiesPutService = createService<HobbiesPutOptions, HobbiesPutResponse, HobbiesPutResults>(HobbiesPutRequest, HobbiesPutTransform);
export const getHobbiesService: GetHobbiesService = createService<GetHobbiesOptions, GetHobbiesResponse, GetHobbiesResults>(GetHobbiesRequest, GetHobbiesTransform);
export const certService: CertPutService = createService<CertPutOptions, CertPutResponse, CertPutResults>(CertPutRequest, CertPutTransform);
export const getCertService: GetCertService = createService<GetCertOptions, GetCertResponse, GetCertResults>(GetCertRequest, GetCertTransform);
export const postPublishService: CreatePostService = createService<CreatePostOptions, CreatePostResponse, CreatePostResults>(CreatePostRequest, CreatePostTransform);
export const postListService: ListPostService = createService<ListPostOptions, ListPostResponse, ListPostResults>(ListPostRequest, ListPostTransform);
export const postDBService: PostDBService = createService<PostDBOptions, PostDBResponse, PostDBResults>(PostDBRequest, PostDBTransform);
export const delPostPublishService: DelPostService = createService<DelPostOptions, DelPostResponse, DelPostResults>(DelPostRequest, DelPostTransform);
export const addFollowService: FollowService = createService<FollowOptions, FollowResponse, FollowResults>(FollowPutRequest, FollowTransform);
export const addCommentService: CommentService = createService<CommentOptions, CommentResponse, CommentResults>(CommentPutRequest, CommentTransform);
export const addLikeService: LikeService = createService<LikeOptions, LikeResponse, LikeResults>(LikePutRequest, LikeTransform);
export const commentListService: ListCommentService = createService<ListCommentOptions, ListCommentResponse, ListCommentResults>(ListCommentRequest, ListCommentTransform);
export const userLikesListService: ListUserLikesService = createService<ListUserLikesOptions, ListUserLikesResponse, ListUserLikesResults>(ListUserLikesRequest, ListUserLikesTransform);
export const batchService: BatchAddService = createService<BatchAddOptions, BatchAddResponse, BatchAddResults>(BatchAddRequest, BatchAddTransform);
export const batchListService: ListBatchService = createService<ListBatchOptions, ListBatchResponse, ListBatchResults>(ListBatchRequest, ListBatchTransform);
export const candUpdtStsService: CandPutStsService = createService<CandPutStsOptions, CandPutStsResponse, CandPutStsResults>(CandPutStsRequest, CandPutStsTransform);
export const candidatesListService: ListCandidatesService = createService<ListCandidatesOptions, ListCandidatesResponse, ListCandidatesResults>(ListCandidatesRequest, ListCandidatesTransform);
export const registerEmailMNTRSService: RegisterEmailMNTRSService = createService<RegisterEmailMNTRSOptions, RegisterEmailMNTRSResponse, RegisterEmailMNTRSResults>(RegisterEmailMNTRSPostRequest, RegisterEmailMNTRSTransform);
export const registerPrsnlMNTRSService: RegisterPrsnlMNTRSService = createService<RegisterPrsnlMNTRSOptions, RegisterPrsnlMNTRSResponse, RegisterPrsnlMNTRSResults>(RegisterPrsnlMNTRSRequest, RegisterPrsnlMNTRSTransform);
export const registerAbtMeMNTRSService: RegisterAbtMeMNTRSService = createService<RegisterAbtMeMNTRSOptions, RegisterAbtMeMNTRSResponse, RegisterAbtMeMNTRSResults>(RegisterAbtMeMNTRSRequest, RegisterAbtMeMNTRSTransform);
export const registerEmployMNTRSService: RegisterEmployMNTRSService = createService<RegisterEmployMNTRSOptions, RegisterEmployMNTRSResponse, RegisterEmployMNTRSResults>(RegisterEmployMNTRSRequest, RegisterEmployMNTRSTransform);
export const registerXCLNService: RegisterXCLNService = createService<RegisterXCLNOptions, RegisterXCLNResponse, RegisterXCLNResults>(RegisterXCLNPostRequest, RegisterXCLNTransform);
export const addClientService: ClientAddService = createService<ClientAddOptions, ClientAddResponse, ClientAddResults>(ClientAddRequest, ClientAddTransform);
export const clientListService: ListClientService = createService<ListClientOptions, ListClientResponse, ListClientResults>(ListClientRequest, ListClientTransform);
export const mentorListService: ListMentorService = createService<ListMentorOptions, ListMentorResponse, ListMentorResults>(ListMentorRequest, ListMentorTransform);
export const mentorAccessService: MentorAccessUpdateService = createService<MentorAccessUpdateOptions, MentorAccessUpdateResponse, MentorAccessUpdateResults>(CampusMentorAccessUpdatePutRequest, MentorAccessUpdateTransform);
export const updateS3CxMetaService: UpdateS3CxMetaService = createService<UpdateS3CxMetaOptions, UpdateS3CxMetaResponse, UpdateS3CxMetaResults>(UpdateS3CxMetaRequest, UpdateS3CxMetaTransform);
export const getS3CxMetaService: GetS3CxMetaService = createService<GetS3CxMetaOptions, GetS3CxMetaResponse, GetS3CxMetaResults>(GetS3CxMetaRequest, GetS3CxMetaTransform);
export const addClientUserService: ClientUserAddService = createService<ClientUserAddOptions, ClientUserAddResponse, ClientUserAddResults>(ClientUserAddRequest, ClientUserAddTransform);
export const clientUserListService: ListClientUserService = createService<ListClientUserOptions, ListClientUserResponse, ListClientUserResults>(ListClientUserRequest, ListClientUserTransform);
export const delClientService: DELClientService = createService<DELClientOptions, DELClientResponse, DELClientResults>(DELClientRequest, DELClientTransform);
export const getResumeVideoService: ResumeVideoService = createService<ResumeVideoOptions, ResumeVideoResponse, ResumeVideoResults>(ResumeVideoRequest, ResumeVideoTransform);
export const videoService: VideoService = createService<VideoOptions, VideoResponse, VideoResults>(CampusVideoPutRequest, VideoTransform);
export const addStaffClientService: StaffClientAddService = createService<StaffClientAddOptions, StaffClientAddResponse, StaffClientAddResults>(StaffClientAddRequest, StaffClientAddTransform);
export const staffClientListService: ListStaffClientService = createService<ListStaffClientOptions, ListStaffClientResponse, ListStaffClientResults>(ListStaffClientRequest, ListStaffClientTransform);
export const addNewJobService: JobAddService = createService<JobAddOptions, JobAddResponse, JobAddResults>(JobAddRequest, JobAddTransform);
export const editJobService: JobDataPutService = createService<JobDataPutOptions, JobDataPutResponse, JobDataPutResults>(JobDataPutRequest, JobDataPutTransform);
export const jobStatusService: JobStatusService = createService<JobStatusOptions, JobStatusResponse, JobStatusResults>(JobStatusRequest, JobStatusTransform);
export const listMetaJobService: ListMetaJobsService = createService<ListMetaJobsOptions, ListMetaJobsResponse, ListMetaJobsResults>(ListMetaJobsRequest, ListMetaJobsTransform);
export const listPrivateJobsService: ListPrivateJobsService = createService<ListPrivateJobsOptions, ListPrivateJobsResponse, ListPrivateJobsResults>(ListPrivateJobsRequest, ListPrivateJobsTransform);
export const listPublicJobService: ListJobsService = createService<ListJobsOptions, ListJobsResponse, ListJobsResults>(ListJobsRequest, ListJobsTransform);
export const jobByIdService: JobByIdService = createService<JobByIdOptions, JobByIdResponse, JobByIdResults>(JobByIdRequest, JobByIdTransform);
export const getMetaPinCodeService: MetaPinCodeService = createService<MetaPinCodeOptions, MetaPinCodeResponse, MetaPinCodeResults>(MetaPinCodeRequest, MetaPinCodeTransform);
export const jobDetailsService: JobByDetailsService = createService<JobByDetailsOptions, JobByDetailsResponse, JobByDetailsResults>(JobByDetailsRequest, JobByDetailsTransform);
export const listJobsByStatusService: ListJobsByStatusService = createService<ListJobsByStatusOptions, ListJobsByStatusResponse, ListJobsByStatusResults>(ListJobsByStatusRequest, ListJobsByStatusTransform);
export const listMETAService: ListMETAService = createService<ListMETAOptions, ListMETAResponse, ListMETAResults>(ListMETARequest, ListMETATransform);
export const interviewStatusService: InterviewStatusPutService = createService<InterviewStatusPutOptions, InterviewStatusPutResponse, InterviewStatusPutResults>(InterviewStatusPutRequest, InterviewStatusPutTransform);
export const listXcelianJobService: XclnJobsService = createService<XclnJobsOptions, XclnJobsResponse, XclnJobsResults>(XclnJobsRequest, XclnJobsTransform);
export const resumeParserService: ResumeParserPutService = createService<ResumeParserPutOptions, ResumeParserPutResponse, ResumeParserPutResults>(ResumeParserPutRequest, ResumeParserPutTransform);
export const resumeLoadService: ResumeLoadPutService = createService<ResumeLoadPutOptions, ResumeLoadPutResponse, ResumeLoadPutResults>(ResumeLoadPutRequest, ResumeLoadPutTransform);
export const listRecruitInterviewService: ListRecruitInterviewService = createService<ListRecruitInterviewOptions, ListRecruitInterviewResponse, ListRecruitInterviewResults>(ListRecruitInterviewRequest, ListRecruitInterviewTransform);
export const recruitInterviewStatusService: RecruitInterviewStatusPutService = createService<RecruitInterviewStatusPutOptions, RecruitInterviewStatusPutResponse, RecruitInterviewStatusPutResults>(RecruitInterviewStatusPutRequest, RecruitInterviewStatusPutTransform);
export const xclnResumePutService: XclnResumePutService = createService<XclnResumePutOptions, XclnResumePutResponse, XclnResumePutResults>(XclnResumePutRequest, XclnResumePutTransform);
export const xclnResumeFilePutService: XclnResumeFilePutService = createService< XclnResumeFilePutOptions, XclnResumeFilePutResponse, XclnResumeFilePutResults>(XclnResumeFilePutRequest, XclnResumeFilePutTransform);
export const updateNewsletterService: NewsletterPutService = createService<NewsletterPutOptions, NewsletterPutResponse, NewsletterPutResults>(NewsletterPutRequest, NewsletterPutTransform);
export const listNewsletterService: ListNewsletterService = createService<ListNewsletterOptions, ListNewsletterResponse, ListNewsletterResults>(ListNewsletterRequest, ListNewsletterTransform);
export const updateNewsletterStatusService: NewsletterStatusPutService = createService<NewsletterStatusPutOptions, NewsletterStatusPutResponse, NewsletterStatusPutResults>(NewsletterStatusPutRequest, NewsletterStatusPutTransform);
export const getSadminDashService: GetSadminDashService = createService<GetSadminDashOptions, GetSadminDashResponse, GetSadminDashResults>(GetSadminDashRequest, GetSadminDashTransform);
export const updateSubscriberService: SubscriberPutService = createService<SubscriberPutOptions, SubscriberPutResponse, SubscriberPutResults>(SubscriberPutRequest, SubscriberPutTransform);
export const getStaffDashService: GetStaffDashService = createService<GetStaffDashOptions, GetStaffDashResponse, GetStaffDashResults>(GetStaffDashRequest, GetStaffDashTransform);
export const mediaByIdService: MediaByIdService = createService<MediaByIdOptions, MediaByIdResponse, MediaByIdResults>(MediaByIdRequest, MediaByIdTransform);
export const updateMediaViewService: MediaViewPutService = createService<MediaViewPutOptions, MediaViewPutResponse, MediaViewPutResults>(MediaViewPutRequest, MediaViewPutTransform);
export const updateJobViewService: JobViewPutService = createService<JobViewPutOptions, JobViewPutResponse, JobViewPutResults>(JobViewPutRequest, JobViewPutTransform);
export const updateUserStatusService: UserStatusPutService = createService<UserStatusPutOptions, UserStatusPutResponse, UserStatusPutResults>(UserStatusPutRequest, UserStatusPutTransform);
export const enquiryListService: ListEnquiryService = createService<ListEnquiryOptions, ListEnquiryResponse, ListEnquiryResults>(ListEnquiryRequest, ListEnquiryTransform);
export const updateEnquiryStatusService: EnquiryStatusPutService = createService<EnquiryStatusPutOptions, EnquiryStatusPutResponse, EnquiryStatusPutResults>(EnquiryStatusPutRequest, EnquiryStatusPutTransform);
export const getXcelianResumePreviewService: XcelianResumePreviewService = createService<XcelianResumePreviewOptions, XcelianResumePreviewResponse, XcelianResumePreviewResults>(XcelianResumePreviewRequest, XcelianResumePreviewTransform);
export const shareListInterviewService: ShareListInterviewService = createService<ShareListInterviewOptions, ShareListInterviewResponse, ShareListInterviewResults>(ShareListInterviewRequest, ShareListInterviewTransform);
export const shareUrlInterviewService: ShareUrlInterviewService = createService<ShareUrlInterviewOptions, ShareUrlInterviewResponse, ShareUrlInterviewResults>(ShareUrlInterviewRequest, ShareUrlInterviewTransform);
export const updateXcelianResumeInfoService: ResumeInfoPutService = createService<ResumeInfoPutOptions, ResumeInfoPutResponse, ResumeInfoPutResults>(ResumeInfoPutRequest, ResumeInfoPutTransform);
export const getXcelianResumeShareService: XcelianResumeShareService = createService<XcelianResumeShareOptions, XcelianResumeShareResponse, XcelianResumeShareResults>(XcelianResumeShareRequest, XcelianResumeShareTransform);
