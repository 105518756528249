import { MetaPinCodeResponse, MetaPinCodeResults } from './get-pincode.service.types';

export const MetaPinCodeTransform: (response: MetaPinCodeResponse) => MetaPinCodeResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :MetaPinCodeResults = {
    data: {
      pin: '',
      cty: '',
      st: '',
      cntCd: '',
      cnt: '',
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
