import { UploadS3FilesResponse, UploadS3FilesResults } from './upload-s3-files.service.types';

export const UploadS3FilesTransform: (response: UploadS3FilesResponse) => UploadS3FilesResults = (profileResults) => {
  const { data } = profileResults;
  const result: UploadS3FilesResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};
