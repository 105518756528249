import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, CardBody, CardHeader, CardImg, Col, Input, NavLink, Row, Spinner,
} from 'reactstrap';
import './comments.scss';
// faThumbsUp
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentProps } from './comments.type';
import { CommentInput } from '../../../../../../services/campus/dashboard/comments/add-comment/comment.types';
import { addCommentReq, clearCommentReq } from '../../../../../../store/campusXcel/actions';
import { formatDate } from '../../../../../../utils';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../../utils/service/localstorage-service';

const GetAllComments: React.FC<CommentProps> = ({
  comment, activityId, academyId, setCallCommentBack, userAdmin,
}) => {
  const dispatch = useDispatch();
  const [showReplies, setShowReplies] = useState(false);
  const tokenData = getTokenFromLocalStorage();
  const [showReply, setShowReply] = useState(false);
  const [loaderSpinner, setLoaderSpinner] = useState(false);
  const [childSuccessAlertComment, setChildSuccessAlertComment] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const commentResponse = useSelector((state: RootState) => state.campusXcel.addCommentData);
  const [commentData, setCommentData] = useState<CommentInput>({
    comment: '',
  });

  React.useEffect(() => {
    if (childSuccessAlertComment) {
      setCallCommentBack(activityId);
    }
  }, [childSuccessAlertComment]);

  const handleCommentData = (e:any) => {
    setCommentData({ ...commentData, [e.target.name]: e.target.value });
  };
  const toggleReplies = () => {
    setShowReplies(!showReplies);
    setShowReply(false);
  };
  const toggleReply = () => {
    setShowReply(!showReply);
    setShowReplies(false);
    setErrorShowAlert(false);
    setChildSuccessAlertComment(false);
  };

  const handleComment = (commentId: string, postId: string) => {
    setLoaderSpinner(true);
    dispatch(addCommentReq({
      inputBody: commentData,
      requestType: APIHeader.REQ_ADD_COMMENTS,
      uTp: UserType.CMPS,
      token: tokenData,
      actId: postId,
      acdId: academyId,
      cmntId: commentId,
    }));
  };
  React.useEffect(() => {
    if (commentResponse.error && commentResponse.message !== '') {
      setErrorShowAlert(true);
      setLoaderSpinner(false);
    }
  }, [commentResponse.error, commentResponse.message]);

  React.useEffect(() => {
    if (!commentResponse.error && commentResponse.message !== '') {
      dispatch(clearCommentReq());
      setCommentData({ ...commentData, comment: '' });
      setChildSuccessAlertComment(true);
      setLoaderSpinner(false);
      setShowReply(false);
    }
  }, [commentResponse.error, commentResponse.message]);

  return (
    <Row className="my-1">
      <Col xs="12">
        <Card className="dasboard-comments-card my-1">
          <CardHeader className="form-card-header">
            <Row>
              <Col lg="12" xs="12">
                <div style={{ display: 'flex' }}>
                  <div className="align-center">
                    {comment.avtr === ''
                      ? (
                        <CardImg
                          alt="..."
                          className="cx-avatar-user-small"
                          src="/images/common/avatar.png"
                        />
                      )
                      : (
                        <CardImg
                          alt="..."
                          className="cx-avatar-user-small"
                          src={comment.avtr}
                        />
                      ) }
                  </div>
                  <div className="ml-1 pt-1">
                    <NavLink to={comment.prof} className="dashboard-link" target="_blank">
                      <span className="dashboard-post-text">
                        {comment.nme}
                      </span>
                      <span className="ml author-card">author</span>
                    </NavLink>
                    <div>
                      <span className="dashboard-sub-post-text">
                        {comment.crs !== '' ? `${comment.crs}, ` : null}{comment.strm !== '' ? comment.strm : null}
                      </span>
                      {comment.crs !== '' || comment.strm !== '' ? <span className="mx-1 text-lite-grey-comments">|</span> : null}
                      <span className="dashboard-sub-post-text">
                        {comment.sYr !== '' && comment.eYr !== '' ? `Batch of ${comment.sYr} - ${comment.eYr}` : null}
                      </span>
                      {comment.sYr !== '' || comment.eYr !== '' ? <span className="mx-1 text-lite-grey-comments">|</span> : null }
                      <span className="dashboard-sub-post-text">
                        {comment.folwCnt} Followers
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col lg="3" md="4" xs="12">
                <div className="align-end">
                  {comment.isSlfFolw ? null
                    : comment.isFlng
                      ? (
                        <Button className="button-icon" onClick={() => handleSubmitFollow(comment.authCd, 'CMPS')} disabled>
                          <span className="dashboard-follow-text">+ Following</span>
                        </Button>
                      )
                      : (
                        <Button className="button-icon" onClick={() => handleSubmitFollow(comment.authCd, 'CMPS')}>
                          <span className="dashboard-follow-text">+ Follow</span>
                        </Button>
                      )}
                </div>
              </Col> */}
            </Row>
          </CardHeader>
          <div className="align-end mx-1 pt-1">
            <span className="text-lite-grey-date">{formatDate(comment.updtOn)}</span>
          </div>
          <CardBody>
            <span className="input-checkbox-text-grey">
              {comment.cmntTxt}
            </span>
            <div className="pt-2">
              <div className="align-end pt-2">
                {/* {comment.like > 0 ? (
                <>
                  <Button className="dashboard-button-icon">
                    <span className="dashboard-post-text-grey">
                      <FontAwesomeIcon className="dashboard-post-text-grey mx-1" icon={faThumbsUp} />
                      {comment.like}
                    </span>
                  </Button>
                  <span className="text-lite-grey-comments mx-3">|</span>
                </>
              ) : null}
              <Button className="dashboard-button-icon">
                <span className="dashboard-post-text-replies">
                  like
                </span>
              </Button>
              <span className="text-lite-grey-comments mx-3">|</span> */}
                <Button className="dashboard-button-icon" onClick={toggleReply}>
                  <FontAwesomeIcon className="dashboard-post-text-icon mx-1" icon={faReply} />
                  <span className="dashboard-post-text-replies">
                    reply
                  </span>
                </Button>
                {comment.replies.length > 0 ? (
                  <>
                    <span className="text-lite-grey-comments mx-3">|</span>
                    <Button className="dashboard-button-icon" onClick={toggleReplies}>
                      <span className="dashboard-post-text-grey">
                        {comment.replies.length} replies
                      </span>
                    </Button>
                  </>
                ) : null}
              </div>
              {showReply && (
              <div className="pt-2">
                <Input
                  placeholder="Enter Comment"
                  type="textarea"
                  name="comment"
                  className="campus-text-area mx-2"
                  value={commentData.comment}
                  onChange={handleCommentData}
                />
                {showErrorsAlert ? <span className="quest-menu-icon-size-sm mx-2">Failed Try Again !!!</span> : null}
                {childSuccessAlertComment ? <span className="dashboard-post-text-grey mx-2">Comment Added Successfully !!!</span> : null}
                <div className="align-end pt-2">
                  <Button className="campus-button-all align-center pt-1" onClick={() => handleComment(comment.cmntId, activityId)} disabled={commentData.comment === ''}>
                    Submit
                  </Button>
                </div>
              </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      {showReplies && (
        <div className="comment-replies">
          {comment.replies.map((reply, index) => (
            <div key={reply.cmntId + index.toString()}>
              <GetAllComments userAdmin={userAdmin} comment={reply} activityId={activityId} setCallCommentBack={setCallCommentBack} academyId={academyId} />
            </div>
          ))}
        </div>
      )}
      <div className="align-center">
        {loaderSpinner ? <Spinner className="alert-error" /> : null}
      </div>
    </Row>
  );
};

export default GetAllComments;
