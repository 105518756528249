import { ListCommentResponse, ListCommentResults } from './list-comment.service.types';

export const ListCommentTransform: (response: ListCommentResponse) => ListCommentResults = (listCommentResults) => {
  const { data, is_error, message } = listCommentResults;
  const result: ListCommentResults = {
    message: '',
    data: [],
    isError: false,
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
