import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Input, InputGroup, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import {
  updateS3CxMetaReq,
  clearUpdateS3CxMetaReq,
  getS3CxMetaReq,
  clearGetS3CxMetaReq,
} from '../../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../../utils/alert';
import { getConfig } from '../../../../../../utils/config/config';
import LoaderData from '../../../../../../utils/loader';
import { MetaInput } from '../../../../../../services/aIntelligence/update-cx-meta/update-s3-cx-meta.types';
import { DialCountrInfo } from '../../../../../../utils/MetaData/country';
import { MetaRecord } from '../../../../../../services/aIntelligence/get-all-cx-meta/get-s3-cx-meta.types';
import { RecruitOrgProps } from '../../onboard.type';

const { AWS_CXADMIN_BUCKET } = getConfig();

export const AddRecruitOrg: React.FC<RecruitOrgProps> = ({
  ordTp, tabTp, clientTp, recruitOrg, setRecruitOrg,
}) => {
  const dispatch = useDispatch();
  const [loaderClient, setLoaderClient] = useState(false);
  const [loaderListClient, setLoaderListClient] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [modalAddRecruitOrg, setModalAddRecruitOrg] = useState(false);
  const [recruitOrgListData, setRecruitOrgListData] = useState<MetaRecord[]>([]);
  const addRecruitOrgResponse = useSelector((state: RootState) => state.campusXcel.updateS3CxMeta);
  const getRecruitOrgResponse = useSelector((state: RootState) => state.campusXcel.getS3CxMeta);
  const [newRecruitOrg, setNewRecruitOrg] = useState<MetaInput>({
    nme: '',
    cty: '',
    st: '',
  });

  React.useEffect(() => {
    if (ordTp === 'recruit' && tabTp === 'addRecruitOrg' && clientTp === 'RCRT') {
      setLoaderListClient(true);
      dispatch(getS3CxMetaReq({
        bucketName: AWS_CXADMIN_BUCKET,
        fileName: 'recruiter-org.json',
        folderName: 'cx-meta-data',
        search: '',
        maxResults: 30,
        nextToken: '',
        requestType: '',
      }));
    }
  }, [ordTp, tabTp, clientTp]);

  React.useEffect(() => {
    if (getRecruitOrgResponse.success) {
      setLoaderListClient(false);
      setRecruitOrgListData((prevData) => {
        const existingNames = new Set(prevData.map((record) => record.nme));
        const uniqueNewData = getRecruitOrgResponse.data.filter((record) => !existingNames.has(record.nme));
        return [...prevData, ...uniqueNewData];
      });
      dispatch(clearGetS3CxMetaReq());
    }
  }, [getRecruitOrgResponse.success]);

  const handleChangeClient = (event: any) => {
    setRecruitOrg({ ...recruitOrg, [event.target.name]: event.target.value });
  };

  const handleChangeNewClient = (event: any) => {
    setNewRecruitOrg({ ...newRecruitOrg, [event.target.name]: event.target.value });
  };

  const handleOpenRecruitOrgModal = () => {
    setModalAddRecruitOrg(!modalAddRecruitOrg);
  };

  const recruitNameoptions = recruitOrgListData.map((org) => ({
    label: `${org.nme}`,
    value: `${org.nme}|${org.cty}|${org.st}`,
    city: org.cty,
    state: org.st,
  }));
  const selectedRecruitOrgNameOptions = recruitNameoptions && recruitNameoptions.find(
    (option) => (option.value === `${recruitOrg.nme}|${recruitOrg.citySt}`),
  );

  const dialNameoptions1 = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions1 = dialNameoptions1 && dialNameoptions1.find(
    (option) => option.value === recruitOrg.dlCd1,
  );

  const dialNameoptions2 = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions2 = dialNameoptions2 && dialNameoptions2.find(
    (option) => option.value === recruitOrg.dlCd2,
  );

  const handleSelectDialData1 = (e:any) => {
    setRecruitOrg({ ...recruitOrg, dlCd1: e.value });
  };

  const handleSelectDialData2 = (e:any) => {
    setRecruitOrg({ ...recruitOrg, dlCd2: e.value });
  };

  const handleInputRecruitOrgChange = (e:string) => {
    if (e) {
      setLoaderListClient(true);
      dispatch(getS3CxMetaReq({
        bucketName: AWS_CXADMIN_BUCKET,
        fileName: 'recruiter-org.json',
        folderName: 'cx-meta-data',
        search: e,
        maxResults: 20,
        nextToken: '',
        requestType: '',
      }));
    }
  };
  const handleSelectRecruitOrgData = (selectedOption: any) => {
    if (selectedOption) {
      // Do something with the selected option
      setRecruitOrg({
        ...recruitOrg,
        nme: selectedOption.label,
        univ: '',
        citySt: `${selectedOption.city}, ${selectedOption.state}`,
      });
    }
  };

  const handleUpdateMetaData = (e: any) => {
    e.preventDefault();
    setLoaderClient(true);
    dispatch(updateS3CxMetaReq({
      bucketNme: AWS_CXADMIN_BUCKET,
      fileName: 'recruiter-org.json',
      folderName: 'cx-meta-data',
      inputBody: newRecruitOrg,
      requestType: '',
    }));
  };
  React.useEffect(() => {
    if (addRecruitOrgResponse.message !== '') {
      setLoaderClient(false);
      if (addRecruitOrgResponse.success) {
        setModalAddRecruitOrg(false);
        setAlertMsg(addRecruitOrgResponse.message);
        setSuccessShowAlert(true);
        setNewRecruitOrg({
          nme: '',
          univ: '',
          cty: '',
          st: '',
        });
      } else {
        setAlertMsg(addRecruitOrgResponse.message);
        setErrorShowAlert(true);
      }
      dispatch(clearUpdateS3CxMetaReq());
    }
  }, [addRecruitOrgResponse.message]);

  return (
    <div className="pt-3">
      <div className="mb-3 align-center">
        New Recruit Organization
      </div>
      { loaderClient ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="align-center">
        <Col lg="11" xs="12">
          <div className="align-end mr-1">
            <Button className="button-transparent align-center" onClick={handleOpenRecruitOrgModal}>
              Add New
            </Button>
          </div>
          <div className="text-right-login-campus-register">
            <Select
              value={recruitOrg.nme === '' ? null : selectedRecruitOrgNameOptions}
              options={recruitNameoptions}
              onChange={handleSelectRecruitOrgData}
              onInputChange={handleInputRecruitOrgChange}
              styles={{
                control: (base: any) => ({
                  ...base,
                  '&:hover': { borderColor: '#8B0000' },
                  border: '1px solid #DDE0E4',
                  minHeight: '50px',
                  borderRadius: '10px',
                  padding: 1,
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#575656',
                  boxShadow: 'none',
                  backgroundColor: '#fff',
                  '&:focus': {
                    borderColor: '#E1EDF8',
                    boxShadow: 'none',
                    color: '#575656',
                    background: '#fff',
                  },
                }),
                option: (provided: any, state: { isSelected: any; }) => ({
                  ...provided,
                  color: state.isSelected ? '#383838' : '#212121',
                  padding: 15,
                  textAlign: 'left',
                  background: state.isSelected ? '#FAFCFB' : '#fff',
                  '&:hover': {
                    background: '#FAFCFB',
                  },
                }),
              }}
              noOptionsMessage={() => 'No Recruiter Org Found'}
              placeholder="Select Recruiter Org..."
              isLoading={loaderListClient}
            />
          </div>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <InputGroup>
              <Select
                value={recruitOrg.dlCd1 === '' ? null : selectedDialNameOptions1}
                options={dialNameoptions1}
                onChange={handleSelectDialData1}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    // border: '1px solid #F9CB9C',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Dial Code Found'}
              />
              <Input
                placeholder="Phone Number 1"
                type="number"
                required
                name="cntPh1"
                value={recruitOrg.cntPh1}
                onChange={handleChangeClient}
                className="campus-input mx-1"
              />
            </InputGroup>
          </div>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <InputGroup>
              <Select
                value={recruitOrg.dlCd2 === '' ? null : selectedDialNameOptions2}
                options={dialNameoptions2}
                onChange={handleSelectDialData2}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    // border: '1px solid #F9CB9C',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Dial Code Found'}
              />
              <Input
                placeholder="Phone Number 2"
                type="number"
                name="cntPh2"
                value={recruitOrg.cntPh2}
                onChange={handleChangeClient}
                className="campus-input mx-1"
              />
            </InputGroup>
          </div>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <Input
              placeholder="Contact Email"
              type="text"
              name="cntEId"
              required
              value={recruitOrg.cntEId}
              onChange={handleChangeClient}
              className="campus-input"
            />
          </div>
        </Col>
      </Row>
      <Modal isOpen={modalAddRecruitOrg}>
        <ModalHeader className="my-2 mx-2" title="Add new RecruitOrg" toggle={handleOpenRecruitOrgModal}>
          <span className="proj-text-color ml">Add new Recruit Org</span>
        </ModalHeader>
        <ModalBody className="mx-2">
          <Row className="chip-box align-center">
            <Col lg="10" xs="12">
              <div className="text-right-login-campus-register pt-4 mx-1">
                <Input
                  placeholder="Recruiter Organization Name"
                  type="text"
                  name="nme"
                  required
                  value={newRecruitOrg.nme}
                  onChange={handleChangeNewClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col lg="10" xs="12">
              <div className="text-right-login-campus-register pt-4 mx-1">
                <Input
                  placeholder="City of Organization"
                  type="text"
                  name="cty"
                  required
                  value={newRecruitOrg.cty}
                  onChange={handleChangeNewClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col lg="10" xs="12">
              <div className="text-right-login-campus-register pt-4 mx-1">
                <Input
                  placeholder="State of Organization"
                  type="text"
                  name="st"
                  required
                  value={newRecruitOrg.st}
                  onChange={handleChangeNewClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="align-center my-3 pt-2">
                <Button className="campus-button-all mx-1" onClick={handleUpdateMetaData}>
                  SAVE
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddRecruitOrg;
